import React from 'react'
import { Button, Typography } from '@mui/material'
import { MFile } from 'models/MFile'
import { Download } from '@mui/icons-material';

interface LinksAndFilesProps {
    fileData?: MFile[]
}

export const LinksAndFiles = ({ fileData }: LinksAndFilesProps) => {
    const handleDownloadFileClick = (info: MFile) => {
        const anchorLink = document.createElement("a")
        anchorLink.href = `${info.dataArchivo}`;
        anchorLink.download = `documento.pdf`
        anchorLink.click()
    }
    return (
        <div className='pt-8'>
            <Typography className='text-xl font-bold' color='primary'>Archivos y enlaces</Typography>
            {
                fileData?.map(file => (
                    <div className='flex flex-row pt-2' key={file.idServicioCdaServicio}>
                        <Typography className='font-semibold pt-2 text-principal-650 pr-4 '>{file?.nombre}</Typography>
                        <Button onClick={() => handleDownloadFileClick(file)} variant="contained" endIcon={<Download />}>
                            Descargar
                        </Button>
                    </div>
                ))
            }
        </div>
    )
}
