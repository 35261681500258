import React from 'react';
import integrationApi from 'api/integrationApi';

const useInfoSolicitante = () => {

    const urlTotp: string = "solicitante";


    const getInfoSolicitante = async (data: { tipoIdentificacion: string, numeroIdentificacion: string }) => {
        try {


            const getInfoSolicitanteRequest = await integrationApi.post<any>(
                `${urlTotp}/infoSolicitante`,
                data,
            );

            const getInfoSolicitanteResponse = await Promise.resolve(getInfoSolicitanteRequest);

            return getInfoSolicitanteResponse.data;
        } catch (error) {
            return null;
        }

    }

    return { getInfoSolicitante }

}

export default useInfoSolicitante;
