import { useHousingSubsidy } from 'hooks/useHousingSubsidy';
import { useParam } from 'hooks/useParam';
import { MHousingSubsidy, MHousingSubsidyRequest } from 'models/housingSubsidyModel';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { PARAM_LINK_NO_INFO_VIVIENDA, PARAM_NO_INFO_VIVIENDA } from 'utils/constants';
import manosComfenalco from "../../../assets/images/manosComfenalco.png";
import { Loading } from 'components/Loading';
import { HousingSubsidyInformation } from 'components/HousingSubsidyInformation';
import { Category } from 'models/category';
import moment from 'moment';
import { IsCategoryAvailable } from 'utils/CategoryAvailable';

interface HousingSubsidyProps {
    selectedCategory?: Category;
}

export const HousingSubsidy = ({ selectedCategory }: HousingSubsidyProps) => {
    const { isLogged, authData } = useSelector((state: RootState) => state.auth);
    const { getHousingSubsidy, isLoadingHousingSubsidy } = useHousingSubsidy();
    const { getParamData } = useParam();
    const [isLoading, setIsLoading] = useState(false);
    const [housingSubsidyData, setHousingSubsidyData] =
        useState<MHousingSubsidy>();
    const [currentStep, setCurrentStep] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState<string>();
    const [linkOnError, setLinkNoError] = useState<string>();

    const isWithinRange = selectedCategory && IsCategoryAvailable(selectedCategory)


    useEffect(() => {
        const getSubsidy = async () => {
            setIsLoading(true);
            if (isLogged) {
                const housingSubsidyRequest: MHousingSubsidyRequest = {
                    identificacion: authData?.afiliado?.identificacion,
                    tipoIdentificacion: authData?.afiliado?.abreviatura,
                };
                const housingSubsidyResponse = await getHousingSubsidy(
                    housingSubsidyRequest,
                );

                if (housingSubsidyResponse) {
                    const housingSubsidy = housingSubsidyResponse?.subsidio?.reduce((maxStateItem: MHousingSubsidy, currentItem: MHousingSubsidy) => {
                        if (Number(currentItem?.idEstadoSubsidio)! > Number(maxStateItem?.idEstadoSubsidio)!) {
                            return currentItem;
                        } else {
                            return maxStateItem;
                        }
                    }, housingSubsidyResponse?.subsidio[0]);

                    setHousingSubsidyData(housingSubsidy);
                    setCurrentStep(
                        Number(housingSubsidy?.idEstadoSubsidio ?? 1) - 1,
                    );
                    setHasError(false);
                } else {
                    setHasError(true);
                    await getErrorMessages();
                }



            }
            setIsLoading(false);
        };
        getSubsidy();

        return () => {
            setIsLoading(false);
            setError(undefined);
            setLinkNoError(undefined);
            setHousingSubsidyData(undefined);
        };
    }, []);

    const getErrorMessages = async () => {
        const noHousingInformationParam = await getParamData(PARAM_NO_INFO_VIVIENDA);

        const linkNoHousingInformationParam = await getParamData(
            PARAM_LINK_NO_INFO_VIVIENDA,
        );

        setError(noHousingInformationParam?.data?.valorParametro);
        setLinkNoError(linkNoHousingInformationParam?.data?.valorParametro);
    };
    return (
        <>
            {
                isLogged ? (
                    <>
                        {isLoading || isLoadingHousingSubsidy ? (
                            <Loading />
                        ) : (
                            <>
                                {
                                    !isWithinRange ?
                                        <div className='p-8'>
                                            <HousingSubsidyInformation
                                                housingSubsidy={housingSubsidyData}
                                                currentStep={currentStep}
                                                linkOnError={linkOnError}
                                                error={error}
                                                hasError={hasError}
                                            />
                                        </div> :
                                        <div className="flex flex-col w-full items-center pt-4">
                                            <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                                                <img
                                                    src={manosComfenalco}
                                                    className="h-16 w-16"
                                                />
                                                <p className="text-principal-600 font-bold">
                                                    {selectedCategory?.mensajeInactividad && selectedCategory?.mensajeInactividad}
                                                </p>
                                            </div>
                                        </div>
                                }
                            </>
                        )}
                    </>
                ) :
                    (
                        <div className="flex flex-col w-full items-center pt-4">
                            <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                                <img src={manosComfenalco} className="h-16 w-16" />
                                <p className="text-principal-600 font-bold">
                                    Por favor, inicia sesión para realizar tu compra.
                                </p>
                            </div>
                        </div>
                    )
            }
        </>
    )
}
