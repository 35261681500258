import React from 'react'
import { Pagination } from '@mui/material';

interface PaginationProps {
    totalPages: number,
    page: number,
    setPage: any
}

export const Paginatior = ({ totalPages, page, setPage }: PaginationProps) => {

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    
    return (
        <Pagination count={totalPages} page={page} onChange={handleChange} />
    )
}
