import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IndividualUserData } from 'models/individualUserDara'

export interface individualSaleState {
    individualUserData: IndividualUserData,
    errorMessage: '',
}

const initialState: individualSaleState = {
    individualUserData: { documentNumber: '', documentType: '' },
    errorMessage: '',
}

export const individualSaleSlice = createSlice({
    name: 'individualSale',
    initialState,
    reducers: {
        setIndividualUserData: (state, action: PayloadAction<IndividualUserData>) => {
            state.individualUserData = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setIndividualUserData } = individualSaleSlice.actions