import React, { useState } from 'react'
import { Typography } from '@mui/material';
import { InfoRate } from 'models/infoRate';
import { AditionalCounter } from 'models/aditionalCounter';
import { DynamicCounter } from './DynamicCounter';

interface AditionalsProp {
    counterRate: AditionalCounter[]
    setCounterRate: React.Dispatch<React.SetStateAction<AditionalCounter[]>>
    rates?: InfoRate[],
    aditionalsCounter: number,
    setAditionalsCounter: React.Dispatch<React.SetStateAction<number>>
}

export const AditionalsForm = ({ counterRate, setCounterRate, rates, aditionalsCounter, setAditionalsCounter }: AditionalsProp) => {

    return (
        <div className='w-full'>
            {rates && rates.length > 0 && <Typography className='font-bold text-lg' color='primary'>INVITADO ADICIONAL AL GRUPO FAMILIAR</Typography>}
            {
                rates?.map((rate, index) => (
                    <div className='flex flex-row justify-between w-full items-center border-b border-principal-350 py-4' key={index}>
                        <div className='w-1/4'>
                            <p className='text-principal-650 font-bold'>Edades</p>
                            <p className='text-principal-650'>De {rate?.tarifa?.edadMinima} a {rate?.tarifa?.edadMaxima} años</p>
                        </div>
                        <div>
                            <DynamicCounter
                                counterRate={counterRate}
                                setCounterRate={setCounterRate}
                                index={index}
                                aditionalsCounter={aditionalsCounter}
                                setAditionalsCounter={setAditionalsCounter}
                            />
                        </div>
                        <div className='w-1/5'>
                            <p className='text-principal-650 font-bold text-end'>{rate && rate?.tarifa?.valor.toLocaleString('es-CO', {
                                style: 'currency',
                                currency: 'COP',
                                minimumFractionDigits: 0,
                            })}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
