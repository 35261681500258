import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useGenericData } from "hooks/useGenericData";
import { MTicketsForm } from "models/tickersForm";
import { MTicketPqrRequest } from "models/ticketPqrModel";
import React, { useEffect, useState } from "react";
import {
  FieldErrors,
  UseFormHandleSubmit,
  UseFormRegister,
  useForm,
} from "react-hook-form";

interface TicketsProps {
  register: UseFormRegister<MTicketPqrRequest>;
  handleSubmit: UseFormHandleSubmit<MTicketPqrRequest, undefined>;
  errors: FieldErrors<MTicketPqrRequest>;
  getTicket: (ticketRequest: MTicketPqrRequest) => Promise<void>;
  isLoadingTicketPqr: boolean;
}
export const TicketsForm = ({
  register,
  handleSubmit,
  errors,
  getTicket,
  isLoadingTicketPqr,
}: TicketsProps) => {
  const [identificationTypes, setIdentificationTypes] = useState<any[]>([]);

  const { getIdentificationsTypes } = useGenericData();

  useEffect(() => {
    const onGetGetIdentificationsTypes = async () => {
      const identificationTypesResp = await getIdentificationsTypes();
      setIdentificationTypes(
        identificationTypesResp?.identificationTypes
          ? identificationTypesResp?.identificationTypes
          : []
      );
    };
    onGetGetIdentificationsTypes();
  }, []);

  return (
    <div className=" w-full">
      <form onSubmit={handleSubmit(getTicket)} className="flex flex-wrap">
        <div className="w-full sm:w-1/3 px-1 py-3">
          <FormControl className="w-full">
            <InputLabel id="tipo-identificacion">
              Tipo de identificación
            </InputLabel>
            <Select
              labelId="tipo-identificacion"
              label="Tipo de identificación"
              defaultValue={""}
              {...register(`tipoIdentificacion`, {
                required: "El tipo de identificación es obligatorio",
              })}
            >
              {identificationTypes?.map((id, index) => (
                <MenuItem key={index} value={id?.ABREVIATURA}>
                  {id?.DESCRIPCION}
                </MenuItem>
              ))}
            </Select>
            {errors.tipoIdentificacion && (
              <p className="text-red-500">
                {String(errors?.tipoIdentificacion?.message)}
              </p>
            )}
          </FormControl>
        </div>
        <div className="w-full sm:w-1/3 px-1 py-3">
          <TextField
            defaultValue={""}
            className="w-full"
            label="Número de documento"
            {...register(`identificacion`, {
              required: "El número de documento es obligatorio",
            })}
          />
          {errors.identificacion && (
            <p className="text-red-500">
              {String(errors.identificacion?.message)}
            </p>
          )}
        </div>
        <div className="w-full sm:w-1/3 px-1 py-3">
          <TextField
            defaultValue={""}
            className="w-full"
            label="Número de ticket"
            {...register(`idTicket`, {
              required: "El número de ticket es obligatorio",
            })}
          />
          {errors.idTicket && (
            <p className="text-red-500">{String(errors.idTicket?.message)}</p>
          )}
        </div>
        <div className="flex justify-center w-full">
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoadingTicketPqr}
          >
            Consultar
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};
