const lifeCycleStatus: { [key: string]: number } = {
    'En proceso': 1,
    'Completo': 2,
};

export const getStepTicket = (status: string) => {
    if (status in lifeCycleStatus) {
        return lifeCycleStatus[status];
    } else {
        return 0;
    }
};