import { useState } from "react";
import cartApi from "../api/cartApi";
import { Category } from "models/category";

interface CategoryDetails {
  isLoadingCategories: boolean;
}

export const useCategory = () => {
  const urlCategory = "categoria";
  const [isLoadingCategory, setIsLoadingCategory] = useState<CategoryDetails>({
    isLoadingCategories: true,
  });
  const [isLoadingActiveCategories, setIsLoadingActiveCategories] =
    useState(true);

  const getCategoriesData = async (idBusiness: string) => {
    try {
      setIsLoadingCategory({ isLoadingCategories: true });

      const categories = cartApi.get<Category[]>(
        `${urlCategory}/categoriasNegocio?id=${idBusiness}`
      );

      const categoriesResp = await Promise.resolve(categories);

      setIsLoadingCategory({ isLoadingCategories: false });

      return categoriesResp?.data.sort((a, b) => a.orden - b.orden);
    } catch (error) {
      console.log(error);
    }
  };

  const getActiveCategories = async () => {
    try {
      setIsLoadingActiveCategories(true);

      const categories = await cartApi.get<Category[]>(
        `${urlCategory}/categoriasActivas`
      );

      setIsLoadingCategory({ isLoadingCategories: false });

      return categories?.data.sort((a, b) => a.orden - b.orden);
    } catch (error) {
      console.log(error);
    }
  };

  return {
    ...isLoadingCategory,
    getCategoriesData,
    getActiveCategories,
    isLoadingActiveCategories,
  };
};
