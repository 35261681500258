import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface salesCountState {
  salesCount: number;
  errorMessage: "";
}

const initialState: salesCountState = {
  salesCount: 0,
  errorMessage: "",
};

export const salesCountSlice = createSlice({
  name: "salesCount",
  initialState,
  reducers: {
    setCountSales: (state, action: PayloadAction<number>) => {
      state.salesCount = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCountSales } = salesCountSlice.actions;
