
import React from 'react';
import manosComfenalco from '../assets/images/manosComfenalco.png';
import { Cotizante, MSchoolKitResponse } from 'models/schoolKitModel';
import { SchoolKitSummary } from './SchoolKitSummary';
import { SchoolKitStepper } from './SchoolKitStepper';

interface SchoolKitInformationProps {
    color?: string;
    currentStep: number;
    beneficiaries: Cotizante[] | undefined;
    schoolKitData: MSchoolKitResponse | undefined;
    hasNoKitError: boolean;
    noKitError: string | undefined;
    linkOnNoKitError: string | undefined;
    hasNoUserError: boolean;
    errorNoUser: string | undefined;
    linkOnNoUserError: string | undefined;
    adjudicationMessage: string | undefined;
    postulationMessage: string | undefined;
}
export const SchoolKitInformation = (props: SchoolKitInformationProps) => {
    const {
        color,
        currentStep,
        beneficiaries,
        schoolKitData,
        hasNoKitError,
        noKitError,
        linkOnNoKitError,
        errorNoUser,
        linkOnNoUserError,
        hasNoUserError,
        adjudicationMessage,
        postulationMessage,
    } = props;

    return (
        <div>
            {hasNoKitError ? (
                <div className='flex flex-col w-full items-center pt-4'>
                    <div className='flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2'>
                        <img src={manosComfenalco} className="h-16 w-16" />
                        <p className='text-principal-650 font-bold' >{noKitError}</p>
                        <a className='text-principal-600 font-bold' href={linkOnNoKitError}>
                            ¡Para más información consulta aquí!</a>
                    </div>
                </div>
            ) : (
                <div className='flex flex-col justify-center items-center w-full p-8'>
                    {!hasNoUserError ? (
                        <>
                            <div className='flex w-1/2 py-4'>
                                <SchoolKitStepper
                                    currentStep={currentStep}
                                />
                            </div>
                            <div >
                                <SchoolKitSummary
                                    beneficiaries={beneficiaries}
                                    request={schoolKitData?.solicitud}
                                    adjudicationMessage={adjudicationMessage}
                                    postulationMessage={postulationMessage}
                                />
                            </div>
                        </>
                    ) : (
                        <div className='flex flex-col w-full items-center pt-4'>
                            <div className='flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2'>
                                <img src={manosComfenalco} className="h-16 w-16" />
                                <p className='text-principal-650 font-bold'>{errorNoUser}</p>
                                <a className='text-principal-600 font-bold' href={linkOnNoUserError}>
                                    ¡Para más información consulta aquí!</a>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );


};