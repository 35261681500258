import { useContext, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface PrivateRouteProps {
  children: any;
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const isLogged = useSelector((state: RootState) => state.auth.isLogged);

  const { pathname, search } = useLocation();

  useEffect(() => {
    const lastPath = pathname + search;
    // localStorage.setItem("lastPath", lastPath);
  }, [pathname, search]);

  return isLogged ? children : <Navigate to="/" />;
};
