import { useAuthWrapperLogin } from "hooks/useAuthWrapperLogin";
import { useShoppingCar } from "hooks/useShoppingCar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "store";
import { getAuthData } from "store/slices/auth";

export const useAuthWrapper = (url: string) => {
  const dispatch = useDispatch<AppDispatch>();

  const { isLogged } = useSelector((state: RootState) => state.auth);
  const { token } = useSelector((state: RootState) => state.jwt);

  const navigate = useNavigate();

  const handleNavigate = (rute: string) => {
    navigate(rute);
  };

  const credentials = useSelector(
    (state: RootState) => state.credentials.credentials
  );

  const { getTotalCarritoCompras } = useShoppingCar();

  useEffect(() => {
    execute();
  }, []);

  const execute = async () => {

    if (url.includes("token=")) {
      let lastPath = localStorage.getItem("lastPath")
      const [userParam, tokenParam, providerIdParam, accessTokenParam] =
        url.split("&");

      if (tokenParam) {
        const [identifierUT, userToken] = tokenParam.split("=");
        const [identifierAT, userAccessToken] = accessTokenParam.split("=");
        if (identifierUT === "token" && identifierAT === "accessToken") {
          const bearerToken = `Bearer ${userAccessToken}`;

          let dataSend: any = {
            appId: credentials.ecommerceAppId,
            token: userToken,
            authToken: bearerToken,
          };

          // Get Data from firebase to search this user on the database
          // const getLoginFirebaseInfo = await getAuthData(dataSend);
          dispatch(getAuthData(dataSend));

          isLogged && token?.token && getTotalCarritoCompras();
          if (isLogged == false && token?.token) {
            handleNavigate(lastPath ? lastPath : "/home");
          }
          // TODO: call service to get user data and push to the store

          // if (usr) {
          // localStorage.setItem("accesstoken", usr.comfenAppAcessToken);
          // }
        }
      }
    }
  };
};
