import React from 'react'
import { Banner } from './Banner'
import { Typography } from '@mui/material'
import { ServiceImages } from 'models/serviceImages'
import { MSpaceDetail } from 'models/spaceDetailModel'

interface SpaceProps {
    bannerImages: ServiceImages[],
    spaceDetail: MSpaceDetail | undefined
}

export const SpaceDescription = ({ bannerImages, spaceDetail }: SpaceProps) => {
    return (
        <div className='w-full'>
            <Banner bannerImage={bannerImages} />
            <div className='px-4 py-4'>
                <div className='py-2'>
                    <Typography className='font-bold text-2xl pt-8' color='primary'>{spaceDetail?.detalleServicio?.nombre}</Typography>
                </div>
                <div className='py-4 text-principal-650'
                    dangerouslySetInnerHTML={spaceDetail?.detalleServicio && { __html: spaceDetail?.detalleServicio?.descripcion }}
                />

            </div>
        </div>
    )
}
