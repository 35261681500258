export const encrypt = (plainText: string) => {
  if (!plainText) {
    return '';
  }

  const result = b64EncodeUnicode(plainText);

  return result;
};

export const decrypt = (encryptedTextBase64: string) => {
  if (!encryptedTextBase64) {
    return '';
  }

  const result = b64DecodeUnicode(encryptedTextBase64);

  return JSON.parse(result);
};

const b64EncodeUnicode = (str: string) => {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode(parseInt(p1, 16))
  }))
}

// // Decoding base64 ⇢ UTF-8

const b64DecodeUnicode = (str: string) => {

  return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}
