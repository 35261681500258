import { Loading } from "components/Loading";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import manosComfenalco from "../../assets/images/manosComfenalco.png";
import { CarnetInfo } from "./CarnetInfo";
import { setuid } from "process";
import ListAffiliates from "./listAffiliates";
import { Beneficiary } from "models/Beneficiary";
import { Card } from "@mui/material";

export const CarnetsComponent = () => {
  const { authData, isLogged } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [userSelected, setUserSelected] = useState<Beneficiary>();
  const [userId, setUserId] = useState(0);
  const [affiliates, setAffiliates] = useState<Beneficiary[]>(
    authData.beneficiarioVOs
  );

  useEffect(() => {
    if (affiliates) {
      setUserSelected(affiliates[0]);
      setIsLoading(false);
    }
  }, []);

  const getDataCarnet = (i: number) => {
    setUserSelected(affiliates[i]);
    setUserId(i);
  };

  return (
    <div className="flex w-full">
      {isLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <div className="flex w-full p-5">
          {isLogged ? (
            <Card className="flex w-full  flex-wrap">
              <div className="flex flex-wrap  text-center justify-center p-5">
                <h2 className="w-full font-bold">Tarjeta Virtual Delagente</h2>
                {affiliates ? (
                  <ListAffiliates
                    list={affiliates}
                    onChangeBenficiary={getDataCarnet}
                    valueSelected={String(userId)}
                  />
                ) : (
                  "No hay datos de usuario cargados"
                )}
              </div>
              <div className="flex w-max p-5">
                <CarnetInfo
                  nombre={userSelected?.nombre ? userSelected?.nombre : ""}
                  activo={userSelected?.activo ? userSelected?.activo : ""}
                  categoria={
                    userSelected?.categoria ? userSelected?.categoria : ""
                  }
                  abreviatura={
                    userSelected?.abreviatura ? userSelected?.abreviatura : ""
                  }
                  identificacion={
                    userSelected?.identificacion
                      ? userSelected?.identificacion
                      : ""
                  }
                  idPers={userSelected?.idPers ? userSelected?.idPers : ""}
                ></CarnetInfo>
              </div>
            </Card>
          ) : (
            <div className="flex flex-col w-full items-center pt-4">
              <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                <img src={manosComfenalco} className="h-16 w-16" />
                <p className="text-principal-600 font-bold">
                  Por favor, inicia sesión para ver la información
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
