import { useState } from 'react';
import integrationApi from '../api/integrationApi';
import { InterviewRequest } from '../models/interviewRequestModel';
import { InterviewColResponse, InterviewEduResponse } from '../models/interviewModel';

export const useInterviewsEdu = () => {
  const [isLoadingInterviews, setIsLoadingInterviews] = useState(true);
  const urlInterviews: string = 'entrevista';

  const getInterviewsEdu = async (interviewsRequest: InterviewRequest) => {
    setIsLoadingInterviews(true);
    const interviewsEdu = await integrationApi.post<InterviewEduResponse>(
      `${urlInterviews}/getEntrevistasEdu`,
      interviewsRequest,
    );

    setIsLoadingInterviews(false);
    return interviewsEdu?.data;
  };

  const getInterviewsColegio = async (idService: number) => {
    setIsLoadingInterviews(true);
    const interviewsEdu = await integrationApi.post<InterviewColResponse>(
      `${urlInterviews}/getEntrevistasColegio`,
      { idServicio: idService },
    );

    setIsLoadingInterviews(false);
    return interviewsEdu?.data;
  };

  return {
    isLoadingInterviews,
    getInterviewsEdu,
    getInterviewsColegio
  };
};