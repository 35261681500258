import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

interface DialogProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    message: string;
    messageOnClose: string;
    messageOnConfirm: string;
    onlyClose: boolean;
}

export const CustomDialog = ({ open, onClose, onConfirm, title, message, messageOnClose, messageOnConfirm, onlyClose }: DialogProps) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {messageOnClose}
                </Button>
                {!onlyClose ?
                    <Button onClick={onConfirm} color="error" variant="contained">
                        {messageOnConfirm}
                    </Button> :
                    <></>
                }

            </DialogActions>
        </Dialog>
    );
};