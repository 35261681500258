import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { AdmissionForm } from 'components/LightForm/AdmissionForm'
import { ParticularUserForm } from 'components/LightForm/ParticularUserForm';
import ServiceType from 'components/LightForm/ServiceType';
import { TotpForm } from 'components/LightForm/TotpForm';
import { useService } from 'hooks/useService';
import { ValidationChannel } from 'models/validationChannel';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import RecognizedUser from 'components/LightForm/RecognizedUser';
import ShoppingDetail from 'components/LightForm/ShoppingDetail';
import { AdmissionData, CompraUsuario, RateFormLight, ServiceDetailLight, ShoppingCartWithValue } from 'models/lightForm';
import { ServiceTypeInterviewLightForm } from 'components/LightForm/ServiceTypeInterviewLightForm';
import InterviewForm from 'components/LightForm/InterviewForm';
import { useShoppingCar } from 'hooks/useShoppingCar';
import { AppDispatch, RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useParam } from 'hooks/useParam';
import { set } from 'date-fns';
import { SubmitHandler } from 'react-hook-form';
import { InfoSolicitante } from 'models/infoCotizante';
import { getAgeFromDateString } from 'utils/FunctionUtilities';
import { setFormLightData } from 'store/slices/auth/formLightSlice';
import { ParticularUserFormEdu } from 'components/LightForm/ParticularUserFormEdu';
import { setSuccess } from 'store/slices/status';
import { Loading } from 'components/Loading';
import { IND_ORIGEN_EDUCACION } from 'utils/constants';
import { ServiceImages } from 'models/serviceImages';
import { ServiceData } from 'models/serviceData';
import { MFile } from 'models/MFile';
import { ServiceDescription } from 'components/ServiceDescription';


export const LightForm = () => {



    const { getParamData } = useParam();
    const { social, serviceId } = useParams();

    const { shoppingCartWithValueLightForm, paySale } = useShoppingCar();
    const navigate = useNavigate();

    const { getValidationChannel, getServiceFormLight, getServiceData, getFileData } = useService();

    const [disableTraerGrupoButton, setDisableTraerGrupoButton] = useState(false);

    const [userShoppingData, setUserShoppingData] = useState(null);

    const [admissionData, setadmissionData] = useState<AdmissionData>();

    const [viaMessage, setViaMessage] = useState('');

    const [serviceDetail, setserviceDetail] = useState<ServiceDetailLight>();

    const [dataUserNotFound, setdataUserNotFound] = useState<any>();

    const [rateSelected, setrateSelected] = useState<RateFormLight>();

    const [previousStep, setpreviousStep] = useState(0);

    const [cotizante, setCotizante] = useState<InfoSolicitante>();

    const interviewDataFormLight = useSelector((state: RootState) => state.formLight.interviewData);

    const [isLoading, setLoading] = useState(false);

    // to description
    const [bannerImages, setBannerImages] = useState<ServiceImages[]>([]);
    const [serviceData, setServiceData] = useState<ServiceData>();
    const [fileData, setFileData] = useState<MFile[]>();

    const dispatch = useDispatch<AppDispatch>();

    const greenTheme = createTheme({
        palette: {
            primary: {
                main: "#135F2C",
            },
        },
    });

    useEffect(() => {

        getInfoService();
        getInfoValidationChannel();




    }, []);

    useEffect(() => {
        //console.log("Interview cambio de valor",interviewDataFormLight);            
        
    }, [interviewDataFormLight]);


    const getInfoService = async () => {
        if (serviceId) {

            const serviceDetailInfo = await getServiceFormLight(Number(serviceId));

            if (serviceDetailInfo) {

                //let getCampoJson = JSON.parse(serviceDetailInfo.campoJson);

                if (serviceDetailInfo.indOrigen === "V" || serviceDetailInfo.indOrigen === "E") {
                    serviceDetailInfo.fechaServicio = new Date(serviceDetailInfo.campoJson.servFechaInicial);
                } else {
                    serviceDetailInfo.fechaServicio = new Date();
                }
                //console.log('SERVICIO FECHA')
                //console.log(serviceDetailInfo.fechaServicio)
                console.log(serviceDetailInfo)
                setserviceDetail(serviceDetailInfo);

                const { serviceData, serviceImages } = await getServiceData(
                    Number(serviceId)
                );

                setServiceData(serviceData);
                setBannerImages(serviceImages);

                if (serviceData.indOrigen == IND_ORIGEN_EDUCACION) {
                    if (serviceId && serviceData?.indOrigen) {
                        const { fileData } = await getFileData(
                            Number(serviceId),
                            serviceData?.indOrigen
                        );
                        setFileData(fileData);
                    }
                }

            }

        }
    }

    const getInfoValidationChannel = async () => {

        let validationChannelRequest: ValidationChannel = {
            canal: String(social),
            idProducto: Number(serviceId)
        }

        const validationChannel = await getValidationChannel(validationChannelRequest);

        if (validationChannel === undefined) {
            setDisableTraerGrupoButton(true);
        }

    }

    const pay = async (dataPayment: any) => {

        if (dataPayment) {

            //console.log('LETS PAY')
            //console.log(dataPayment)

            const shoppingCartResponse = await shoppingCartWithValueLightForm(dataPayment);
            setLoading(false);
            if (shoppingCartResponse?.status === 200) {
                console.log("DataPayment",dataPayment);
                
                if (dataPayment?.entrevista && Object.keys(dataPayment.entrevista).length > 0) {
                    //Redireccionar a la pagina de inicio
                    dispatch(setSuccess("Espacio agendado exitosamente"));
                    navigate("/home");
                } else {
                    let idCompra = shoppingCartResponse?.idCompra;

                    handlePaySale(idCompra)
                }
            }
        } else setLoading(false);

    }

    const handlePaySale = async (idSale: any) => {

        const getParamUrlPasarela = await getParamData("URL_PASARELA");

        const paySaleResultInfo = await paySale(idSale);

        if (getParamUrlPasarela?.data && paySaleResultInfo) {
            const dataParameter = getParamUrlPasarela?.data?.valorParametro;
            window.location.replace(dataParameter + paySaleResultInfo?.infoPago);
        }

    };

    const getCompraUsuarios = () => {

        let compraUsuarios = [];

        //console.log('cargar compra usuarios')
        //console.log(cotizante)


        if (admissionData && rateSelected) {
            if (admissionData.categoria !== "D") {
                // reemplazar - por / en la fecha
                const fechaNacimiento = admissionData?.fechaNacimiento.replace(/-/g, "/");
                let edad = getAgeFromDateString(admissionData.fechaNacimiento);
                let usuarioCompra: CompraUsuario = {
                    tipoIdentificacion: admissionData?.tipoDocumento,
                    numIdentificacion: admissionData?.numeroDocumento,
                    nombre: admissionData?.nombre.trim(),
                    correo: admissionData?.email ? admissionData?.email : cotizante?.email,
                    fechaNacimiento: fechaNacimiento,
                    genero: cotizante?.sexo,
                    edad: edad,
                    valor: rateSelected?.valor,
                    porcentajeDcto: rateSelected?.porcentajeDcto,
                    idCate: rateSelected?.idCategoriaUsuarioCdaCategoriaUsuario,
                    edadMinima: rateSelected?.edadMinima,
                    edadMaxima: rateSelected?.edadMaxima,
                    oportunidad: 'S',
                    celular: admissionData?.celular,
                    direccion: admissionData?.direccion,
                    fechaExpedicion: cotizante?.fechaExpedicion

                };
                compraUsuarios.push(usuarioCompra);
            } else {
                if (cotizante) {
                    const fechaNacimiento = cotizante?.fechaNacimiento.replace(/-/g, "/");
                    let edad = getAgeFromDateString(cotizante.fechaNacimiento);
                    let usuarioCompra: CompraUsuario = {
                        tipoIdentificacion: cotizante?.abreviatura,
                        numIdentificacion: cotizante?.identificacion,
                        nombre: cotizante?.primerNombre + ' ' + cotizante?.segundoNombre + ' ' + cotizante?.primerApellido + ' ' + cotizante?.segundoApellido,
                        correo: cotizante?.email ? cotizante?.email : cotizante?.email,
                        fechaNacimiento: fechaNacimiento,
                        genero: cotizante?.sexo,
                        edad: edad,
                        valor: rateSelected?.valor,
                        porcentajeDcto: rateSelected?.porcentajeDcto,
                        idCate: rateSelected?.idCategoriaUsuarioCdaCategoriaUsuario,
                        edadMinima: rateSelected?.edadMinima,
                        edadMaxima: rateSelected?.edadMaxima,
                        oportunidad: 'S',
                        celular: cotizante?.celular,
                        direccion: cotizante?.direccionResidencia,
                        fechaExpedicion: cotizante?.fechaExpedicion

                    };
                    compraUsuarios.push(usuarioCompra);
                }

            }



        }

        return compraUsuarios;

    }

    const handleFormSubmit: SubmitHandler<any> = async (dataSubmit
    ) => {
        setLoading(true);
        let servFechaInicial = serviceDetail?.campoJson.servFechaInicial;
        let servFechaFinal = serviceDetail?.campoJson.servFechaFinal;

        // convertir fechas a formato anioo/mes/dia
        let fechaInicialDate = servFechaInicial ? new Date(servFechaInicial) : new Date();
        let fechaFinalDate = servFechaFinal ? new Date(servFechaFinal) : new Date();

        let fechaInicialString = "";
        let fechaFinalString = "";
        if (fechaInicialDate && fechaFinalDate) {

            //formato anio/mes/dia, dia y mes deben tener dos digitos, completar con 0

            fechaInicialString = fechaInicialDate.getFullYear() + '/' + (fechaInicialDate.getMonth() + 1).toString().padStart(2, '0') + '/' + fechaInicialDate.getDate().toString().padStart(2, '0');
            fechaFinalString = fechaFinalDate.getFullYear() + '/' + (fechaFinalDate.getMonth() + 1).toString().padStart(2, '0') + '/' + fechaFinalDate.getDate().toString().padStart(2, '0');
        }

        const edad = getAgeFromDateString(admissionData!.fechaNacimiento);


        const compraUsuarios: CompraUsuario[] = getCompraUsuarios();



        let dataPaymentShoppingCart: ShoppingCartWithValue = {
            valorTotal: rateSelected?.valor,
            porcentajeDcto: Number(rateSelected?.porcentajeDcto),
            origenCompra: 'W',
            fechaInicioServicio: fechaInicialString,
            fechaFinServicio: fechaFinalString,
            idServicio: serviceDetail?.idServicio,
            cantidad: compraUsuarios.length,
            medioCompra: 'Web',
            centroServicio: String(serviceDetail?.idCentroServicio),
            compraUsuarios: compraUsuarios,
            facturaUsuarios: null,
            entrevista: null,
            reserva: null,
            listTiempoEspacio: null,
            afiliadoIdentificacion: cotizante?.cotizanteDto ? cotizante?.cotizanteDto?.identificacion : admissionData?.numeroDocumento,
            afiliadoTipoIden: cotizante?.cotizanteDto ? cotizante?.cotizanteDto?.abreviatura : admissionData?.tipoDocumento,
            nombreSolicitante: admissionData?.nombre.trim(),
            edad: edad,
            email: admissionData?.email
        }

        if (dataSubmit?.interviewSelectedEdu) {
            //console.log("Interview ",dataSubmit?.interviewSelectedEdu)
            dataPaymentShoppingCart.entrevista = dataSubmit?.interviewSelectedEdu;
        }



        await dispatch(setFormLightData(dataPaymentShoppingCart));
        pay(dataPaymentShoppingCart)


    };

    const [step, setStep] = useState(0);

    const admissionForm = () => {
        return (<AdmissionForm setLoading={setLoading} setStep={setStep} disableButton={disableTraerGrupoButton} setAdmissionData={setadmissionData} setdataUserNotFound={setdataUserNotFound} setPreviousstep={setpreviousStep} serviceDetail={serviceDetail} />)
    }

    const toptForm = () => {
        return (<TotpForm setStep={setStep} viaMessage={viaMessage} admissionData={admissionData} setPreviousstep={setpreviousStep} />)
    }

    const particularUsarForm = () => {
        return (<ParticularUserForm isLoading={isLoading} setLoading={setLoading} setStep={setStep} setInfoUserShoppingData={setUserShoppingData} admissionData={dataUserNotFound}
            serviceDetail={serviceDetail} setRateSelected={setrateSelected} setPreviousstep={setpreviousStep} />)
    }

    const serviceInfo = () => {
        return (<ServiceType />)
    }

    const recognizedUser = () => {
        return <RecognizedUser setStep={setStep} admissionData={admissionData} setViamessage={setViaMessage} setPreviousstep={setpreviousStep} />
    }

    const shoppingDetail = () => {
        return <ShoppingDetail isLoading={isLoading} setLoading={setLoading} setStep={setStep} admissionData={admissionData} serviceDetail={serviceDetail}
            setRateSelected={setrateSelected} setPreviousstep={setpreviousStep} cotizante={cotizante} setCotizante={setCotizante} handleFormSubmit={handleFormSubmit} rateSelected={rateSelected} />
    }

    const serviceTypeInterviewLightForm = () => {
        return <ServiceTypeInterviewLightForm isLoading={isLoading} setLoading={setLoading} setStep={setStep} admissionData={admissionData} serviceDetail={serviceDetail} setRateSelected={setrateSelected}
            setPreviousstep={setpreviousStep} cotizante={cotizante} setCotizante={setCotizante} handleFormSubmit={handleFormSubmit} rateSelected={rateSelected} setInfoUserShoppingData={setUserShoppingData} />
    }
    const interviewForm = () => {
        return <InterviewForm setStep={setStep} admissionData={admissionData} serviceDetail={serviceDetail} setRateSelected={setrateSelected}
            setPreviousstep={setpreviousStep} />
    }





    return (
        <ThemeProvider theme={greenTheme}>
            {isLoading && <Loading />}
            <div className="flex w-full justify-center flex-row">
                <div className='w-4/5 cursor-pointer flex justify-start content-start items-center my-3' onClick={() => setStep(0)}>
                    {step !== 0 && <ExpandCircleDownOutlinedIcon
                        className="h-10 w-10 mr-2 cursor-pointer text-principal-250 rotate-90"
                        style={{ color: 'white' }}
                        onClick={() => setStep(0)
                        }
                    />}
                    <h1 className="text-start text-4xl text-green-800 font-bold  ">{serviceDetail?.nombre}</h1>
                </div>
            </div>
            <div className='flex justify-center  mt-5 flex-col md:flex-row sm:flex-col '>

                <div className="bg-white p-8 rounded-lg shadow-md w-full sm:w-full md:w-9/20 md:ml-5 h-auto">
                    <div className="">
                        <ServiceDescription
                            bannerImages={bannerImages}
                            serviceData={serviceData}
                            fileData={fileData}
                            />
                    </div>
                    {/* <img src={serviceDetail?.imagenes[0].dataImagen} className='rounded-xl w-full' /> */}

                    
                </div>

                <div className="bg-white p-8 rounded-lg shadow-md w-full sm:w-full md:w-9/20 md:ml-5 h-auto ">
                    {step === 0 && admissionForm()}
                    {step === 1 && toptForm()}
                    {step === 2 && particularUsarForm()}
                    {step === 3 && serviceInfo()}
                    {step === 4 && recognizedUser()}
                    {step === 5 && shoppingDetail()}
                    {step === 6 && serviceTypeInterviewLightForm()}
                    {step === 7 && interviewForm()}
                </div>
            </div>
        </ThemeProvider>
    )
}
