import { BannerData } from 'models/banner'
import { useEffect, useState } from 'react'
import cartApi from '../api/cartApi'


interface BannerDetails {
    bannerImage: BannerData[],
    isLoadingBanner: boolean
}

export const useBanner = () => {

    const urlBanner: string = "imagen"

    const [bannerImage, setBannerImage] = useState<BannerDetails>({ bannerImage: [], isLoadingBanner: true });

    const getBannerImages = async () => {
        const bannerImages = cartApi.get<BannerData[]>(`${urlBanner}/imageBanner`);

        const banerImageResp = await Promise.resolve(bannerImages);

        setBannerImage({
            bannerImage: banerImageResp?.data,
            isLoadingBanner: false,
        });

    }

    const getBannerNegocioImages = async (id: number) => {
        const bannerImages = await cartApi.get<BannerData[]>(`${urlBanner}/imageNegocioBanner?id=${id}`);

        const banerImageResp: BannerData[] = bannerImages?.data;

        return banerImageResp;
    }

    useEffect(() => {
        getBannerImages();
    }, []);

    return {
        ...bannerImage,
        getBannerNegocioImages
    }
}
