import cartApi from '../api/cartApi'
import { ServiceImages } from 'models/serviceImages';
import { ServiceCenter } from 'models/serviceCenter';
import { PopularService } from 'models/popularService';


export const useServiceCenter = () => {

    const getServiceCenterData = async (idServiceCenter: number) => {
        const serviceCenterRequest = await cartApi.post<ServiceCenter>(`centroServicio/centroServicio`, {
            idCentroServicio: idServiceCenter
        });

        const serviceCenterData: ServiceCenter = serviceCenterRequest?.data;

        const serviceImagesRequest = await cartApi.post<ServiceImages[]>(`imagen/getImagenesCentroServicio`, {
            idCentroServicio: idServiceCenter
        });

        const serviceImages: ServiceImages[] = serviceImagesRequest?.data;

        return {
            serviceCenterData,
            serviceImages
        }
    }

    const getPopularServices = async (idServiceCenter: number) => {

        const popularServicesRequest = await cartApi.post<PopularService[]>(`iconoCentroServicio/getServiciosmasPopulares`, {
            idCentroServicio: idServiceCenter
        });

        const popularServices: PopularService[] = popularServicesRequest?.data;

        return popularServices;
    }

    return {
        getServiceCenterData,
        getPopularServices
    }


}
