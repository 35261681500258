import { Checkbox, Typography } from '@mui/material';
import { Beneficiarie } from 'models/beneficiarie';
import { HotelForm, PurchaseForm } from 'models/purchaseForm';
import React from 'react'
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface BeneficiariesProps {
    register: UseFormRegister<HotelForm>,
    errors: FieldErrors<HotelForm>,
    setValue: UseFormSetValue<HotelForm>
    watch: UseFormWatch<HotelForm>
    beneficiaries: any
}
export const Beneficiaries = ({ register, errors, watch, beneficiaries }: BeneficiariesProps) => {

    const atLeastOneCheckBoxSelected = (values: PurchaseForm['benefeciariesSelected']) => values.some(Boolean);

    return (
        <>
            <div className='p-4 pt-0 border-b'>
                <Typography className='font-bold text-lg' color='primary'>HUÉSPEDES</Typography>
            </div>
            <div className='px-4 w-full'>
                {beneficiaries?.map((beneficiarie: Beneficiarie) => (
                    <div className='flex w-full justify-between items-center pb-3' key={beneficiarie?.identificacion}>
                        <div>
                            <p className='font-bold text-lg text-principal-650'>{beneficiarie?.nombre}</p>
                            <p className='text-lg text-principal-650'>Categoria {beneficiarie?.categoria}</p>
                        </div>

                        <div className='flex flex-row items-center pl-2'>
                            <Checkbox
                                {...register('benefeciariesSelected', {
                                    validate: atLeastOneCheckBoxSelected, // Utilizamos la función de validación personalizada
                                })}
                                value={beneficiarie.identificacion}
                            />
                        </div>

                    </div>
                ))}

                {errors.benefeciariesSelected && (
                    <p className='text-red-500'>Selecciona al menos un beneficiario.</p>
                )}
            </div>
        </>
    )
}
