
import requestApi from '../api/cartApi'
import { Parameter } from 'models/parameter';

export const useParam = () => {

    const urlParam = 'parametro';

    const getParamData = async (paramKey: string) => {
        try {
            const param = requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${paramKey}`);

            const paramResp = await Promise.resolve(param);

            return paramResp;
        } catch (error) {
            console.log(error);
        }
    }

    return {
        getParamData
    }
}
