import { Typography } from '@mui/material';
import { MTicketPqrResponse } from 'models/ticketPqrModel';
import React from 'react';

interface TicketsSummaryProps {
    ticketData: MTicketPqrResponse | null;
}

export const TicketsSummary = ({
    ticketData
}: TicketsSummaryProps) => {

    return (
        <div className='border rounded-lg'>

            <div className='flex justify-between border-b p-4'>
                <p className='text-principal-600 font-bold'>Número de petición</p>
                <Typography color='primary' className='font-bold'>
                    {ticketData?.id}
                </Typography>
            </div>
            <div className='border-b p-4'>
                <p className='text-principal-600 font-bold'>Asunto</p>
                <p className='text-principal-650'>
                    {ticketData?.name}
                </p>
            </div>
            <div className='border-b p-4'>
                <p className='text-principal-600 font-bold'>Descripción</p>
                <p className='text-principal-650'>
                    {ticketData?.content}
                </p>
            </div>
        </div>
    );
};
