import React, { useEffect, useState } from "react";
import { Beneficiary } from "models/Beneficiary";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  TextField,
  Typography,
} from "@mui/material";
import {
  ABREVIACION_ORIGEN_WEB,
  COMPRA_INDIVIDUAL_ACTIVA,
  OPORTUNIDAD,
  ORIGEN_WEB,
  PARAM_MENSAJE_CUOTAS_EDUCACION,
  TIPO_USUARIO,
} from "utils/constants";

import { useForm, SubmitHandler } from "react-hook-form";
import { PurchaseForm } from "models/purchaseForm";
import { UsuarioCompra } from "models/compraCero";
import { InfoRate } from "models/infoRate";
import moment from "moment";
import Stepper from "@mui/material/Stepper";
import {
  Done,
  Event,
  Person,
  RecordVoiceOver,
  SupervisedUserCircle,
  Warning,
} from "@mui/icons-material";
import {
  ClockIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { useInterviewsEdu } from "hooks/useInterviewEdu";
import { InterviewRequest } from "models/interviewRequestModel";
import { InterviewSelectedEduDTO } from "models/interviewSelected";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useNavigate } from "react-router-dom";
import { usePurchase } from "hooks/usePurchase";
import { LoadingButton } from "@mui/lab";
import { ServiceConfirmationDialog } from "pages/Services/ServiceConfirmationDialog";
import { useParam } from "hooks/useParam";
import es from 'date-fns/locale/es';
import { useShoppingCar } from "hooks/useShoppingCar";
import { Loading } from "components/Loading";
import { AdmissionData, RateFormLight, ServiceDetailLight } from "models/lightForm";
import { InfoSolicitante } from "models/infoCotizante";
import { useRates } from "hooks/useRates";
import useInfoSolicitante from "hooks/useInfoSolicitante";
import { RatesFormLightRequest } from "hooks/ratesFormLightRequest";
import { ParticularUserFormEdu } from "./ParticularUserFormEdu";


interface ServiceForm {
  setStep: React.Dispatch<React.SetStateAction<number>>,
  admissionData?: AdmissionData,
  serviceDetail?: ServiceDetailLight,
  setRateSelected?: React.Dispatch<React.SetStateAction<any>>,
  setPreviousstep: React.Dispatch<React.SetStateAction<number>>,
  cotizante?: InfoSolicitante,
  setCotizante?: React.Dispatch<React.SetStateAction<any>>,
  handleFormSubmit: SubmitHandler<any>
  rateSelected?: RateFormLight,
  setInfoUserShoppingData: React.Dispatch<React.SetStateAction<any>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  isLoading: boolean

}
export const ServiceTypeInterviewLightForm = ({
  setStep, admissionData, serviceDetail, setRateSelected, setPreviousstep, cotizante, setCotizante, handleFormSubmit,
  rateSelected, setInfoUserShoppingData, setLoading, isLoading
}: ServiceForm) => {



  const dispatch = useDispatch<AppDispatch>();
  const { isLogged, authData } = useSelector((state: RootState) => state.auth);
  const { getParamData } = useParam();
  const { getInterviewsEdu, isLoadingInterviews } = useInterviewsEdu();
  const { zeroPay, compraValor } = usePurchase();
  const { documentNumber, documentType } = useSelector(
    (state: RootState) => state.individualSale.individualUserData
  );

  const [mensajeCuotas, setMensajeCuotas] = useState('');

  const [activeStep, setActiveStep] = useState(0);

  const [skipped, setSkipped] = useState(new Set<number>());
  const [availableInterviews, setAvailableInterviews] =
    useState<InterviewSelectedEduDTO[]>();
  const [availableInterviewsByDay, setAvailableInterviewsByDay] =
    useState<InterviewSelectedEduDTO[]>();
  const [availableDays, setAvailableDays] = useState<string[]>();

  const [mobile, setMobile] = useState(window.innerWidth <= 500);
  const { getRatesFormLight } = useRates();
  const { getInfoSolicitante } = useInfoSolicitante();

  const { beneficiarioVOs, afiliado } = useSelector(
    (state: RootState) => state.auth.authData
  );

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    getValues,
    watch
  } = useForm<PurchaseForm>({
    defaultValues: {
      benefeciariesSelected: []
    },
  });

  const formValues = watch();

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
};

  const handleClose = () => {
    setOpen(false);
    formSubmit(formData!);
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState<PurchaseForm>();

  const getPaymentResponsible = async () => {
    if (admissionData) {
      const infoSolicitanteRequest: InfoSolicitante = await getInfoSolicitante(
        {
          tipoIdentificacion: admissionData?.tipoDocumento,
          numeroIdentificacion: admissionData?.numeroDocumento
        }
      );

      if (setCotizante) {
        setCotizante(infoSolicitanteRequest);
      }
    }

  }

  const findRate = async () => {

    if (serviceDetail && admissionData) {


      let fechaServicio: Date = serviceDetail.fechaServicio;

      //console.log('start consultar')
      if (fechaServicio) {
        //console.log('Start fecha servicio', fechaServicio)

        // fecha a string dia/mes/año
        let fechaServicioString: string = fechaServicio.getDate() + '/' + (fechaServicio.getMonth() + 1) + '/' + fechaServicio.getFullYear();


        let rateRequest: RatesFormLightRequest = {
          idServicio: serviceDetail?.idServicio,
          numeroIdentificacion: admissionData?.numeroDocumento,
          tipoIdentificacion: admissionData?.tipoDocumento,
          fechaNacimiento: admissionData?.fechaNacimiento,
          fechaServicio: fechaServicioString
        }

        //console.log(rateRequest)

        const getRatesFound: RateFormLight = await getRatesFormLight(rateRequest);

        if (getRatesFound) {
          setRateSelected && setRateSelected(getRatesFound);
        }
      }
    }

  }

  useEffect(() => {


    getPaymentResponsible();

    findRate();

  }, []);

  const handleFormSubmitV2: SubmitHandler<PurchaseForm> = (
    data: PurchaseForm
  ) => {
    console.log('data entro', data);
    if (data?.interviewSelected) {
      setFormData(data);
      setOpenDialog(true);
    }else {
      formSubmit(data);
    }

  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const getParam = async () => {
      const mensajeCuotasParam = await getParamData(PARAM_MENSAJE_CUOTAS_EDUCACION);
      mensajeCuotasParam?.data?.valorParametro &&
        setMensajeCuotas(mensajeCuotasParam?.data?.valorParametro);
    }

    getParam();
  }, [])

  useEffect(() => {
    if (!formValues.selectedDate) {
      return;
    }

    setValue("interviewSelected", undefined);
    setAvailableInterviewsByDay([]);

    const interviewsDay = availableInterviews?.filter(
      (interview) =>
        interview.fecha.getTime() === formValues.selectedDate!.getTime()
    );

    if (interviewsDay!.length > 0) {
      setAvailableInterviewsByDay(interviewsDay);
    }
  }, [formValues.selectedDate]);
  // Asigna la oportunidad al usuario mayor
  const updateOportunidad = (users: UsuarioCompra[]) => {
    if (isLogged && authData?.tipoUsuario !== TIPO_USUARIO) {
      const userIndex = users.findIndex(
        (user) => user.numIdentificacion === afiliado.identificacion
      );

      if (userIndex !== -1) {
        users[userIndex].oportunidad = OPORTUNIDAD;
      } else {
        const olderUser = users.reduce(
          (max, user) => (user?.edad! > max?.edad! ? user : max),
          { edad: 0 }
        );

        const olderUserIndex = users.indexOf(olderUser);

        if (olderUserIndex !== -1) {
          users[olderUserIndex].oportunidad = OPORTUNIDAD;
        } else {
          const olderAditionalUser = users.reduce(
            (max, user) => (user?.edadMaxima! > max?.edadMaxima! ? user : max),
            { edadMaxima: 0 }
          );

          const olderAditionalUserIndex = users.indexOf(olderAditionalUser);

          users[olderAditionalUserIndex].oportunidad = OPORTUNIDAD;
        }
      }
    } else {
      const userIndex = users.findIndex(
        (user) => user.numIdentificacion === documentNumber
      );

      if (userIndex !== -1) {
        users[userIndex].oportunidad = OPORTUNIDAD;
      } else {
        const olderUser = users.reduce(
          (max, user) => (user?.edad! > max?.edad! ? user : max),
          { edad: 0 }
        );

        const olderUserIndex = users.indexOf(olderUser);

        if (olderUserIndex !== -1) {
          users[olderUserIndex].oportunidad = OPORTUNIDAD;
        } else {
          const olderAditionalUser = users.reduce(
            (max, user) => (user?.edadMaxima! > max?.edadMaxima! ? user : max),
            { edadMaxima: 0 }
          );

          const olderAditionalUserIndex = users.indexOf(olderAditionalUser);

          users[olderAditionalUserIndex].oportunidad = OPORTUNIDAD;
        }
      }
    }
    return users;
  };

  // Obtiene los usuarios seleccionados para la compra
  const getPurchaseUser = (data: PurchaseForm) => {
    const interview: InterviewSelectedEduDTO = availableInterviews?.find(
      (interview) => interview.codigo === data?.interviewSelected
    )!;

    const userRates: InfoRate[] = data?.aditionalForm
      ? [
        // setInfoUserShoppingData?.tarifasCategoriaD.find(
        //   (beneficiary: InfoRate) =>
        //     beneficiary?.tarifa?.idTarifa ===
        //     Number(data.benefeciariesSelected)
        // )!,
      ]
      : [
        // rates?.tarifasUsuarios.find(
        //   (beneficiary: InfoRate) =>
        //     beneficiary.identificacionUsuario === data.benefeciariesSelected
        // )!,
      ];

    const purchaseUser: UsuarioCompra[] = userRates?.map(
      (userRate: InfoRate) => {
        if (isLogged && authData?.tipoUsuario !== TIPO_USUARIO) {
          const beneficiary = beneficiarioVOs.find(
            (beneficiary: Beneficiary) =>
              beneficiary?.identificacion === userRate?.identificacionUsuario
          );

          if (beneficiary) {
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion: beneficiary?.abreviatura,
              numIdentificacion: beneficiary?.identificacion,
              nombre: beneficiary?.nombre,
              correo: "",
              fechaNacimiento: moment(beneficiary?.fechaNacimiento).format(
                "YYYY/MM/DD"
              ),
              genero: beneficiary?.sexo,
              edad: Number(userRate?.edadUsuario),
              valor: interview
                ? userRate.tarifa?.total
                : userRate.tarifa?.total - userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: Number(
                userRate?.tarifa?.idCategoriaUsuarioCdaCategoriaUsuario
              ),
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          } else {
            const birthDate =
              data?.aditionalForm && data?.aditionalForm[0]?.birthDate;
            const fechaNac = moment(birthDate, "YYYY-MM-DD");
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentType,
              numIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentNumber,
              nombre:
                data?.aditionalForm &&
                data?.aditionalForm[0]?.firstName +
                " " +
                data?.aditionalForm[0]?.secondName,
              correo: "",
              fechaNacimiento:
                data?.aditionalForm &&
                moment(data?.aditionalForm[0]?.birthDate).format("YYYY/MM/DD"),
              genero: data?.aditionalForm && data?.aditionalForm[0]?.gender,
              edad: moment().diff(fechaNac, "years"),
              valor: interview
                ? userRate.tarifa?.total
                : userRate.tarifa?.total - userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: 4,
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          }
        } else {
          const birthDate =
            data?.aditionalForm && data?.aditionalForm[0]?.birthDate;
          const fechaNac = moment(birthDate, "YYYY-MM-DD");
          const usuarioCompra: UsuarioCompra = {
            tipoIdentificacion:
              data?.aditionalForm && data?.aditionalForm[0]?.documentType,
            numIdentificacion:
              data?.aditionalForm && data?.aditionalForm[0]?.documentNumber,
            nombre:
              data?.aditionalForm &&
              data?.aditionalForm[0]?.firstName +
              " " +
              data?.aditionalForm[0]?.secondName,
            correo: "",
            fechaNacimiento:
              data?.aditionalForm &&
              moment(data?.aditionalForm[0]?.birthDate).format("YYYY/MM/DD"),
            genero: data?.aditionalForm && data?.aditionalForm[0]?.gender,
            edad: moment().diff(fechaNac, "years"),
            valor: interview
              ? userRate.tarifa?.total
              : userRate.tarifa?.total - userRate.tarifa?.valor,
            porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
            idCate: 4,
            edadMinima: userRate?.tarifa?.edadMinima,
            edadMaxima: userRate?.tarifa?.edadMaxima,
            oportunidad: "",
          };

          return usuarioCompra;
          // }
        }
      }
    );

    return purchaseUser;
  };

  //INICIA

  const [urlPasarela, seturlPasarela] = useState("");
  useEffect(() => {
    const getParamUrlPasarela = async () => {
      const getParamUrlPasarela = await getParamData("URL_PASARELA");
      // Get url pasarela param
      if (getParamUrlPasarela?.data) {
        const dataParameter = getParamUrlPasarela?.data;
        seturlPasarela(dataParameter.valorParametro);
      }
    }
    getParamUrlPasarela();
  }, [urlPasarela]);

  const {
    paySale,
  } = useShoppingCar();



  // Realiza el envio del formulario
  const formSubmit: SubmitHandler<PurchaseForm> = async (
    data: PurchaseForm
  ) => {
    console.log("Información entrevistas",data);
    console.log(cotizante);
    
    if (cotizante && (!cotizante.email || cotizante.email.length === 0)) {
      setLoading(false);
      handleClickOpen();
      return;
    }
    openDialog && setOpenDialog((prev) => !prev);
    setLoading && setLoading(true);
    const interview: InterviewSelectedEduDTO = availableInterviews?.find(
      (interview) => interview.codigo === data?.interviewSelected
    )!;
    //console.log('interview', interview)
    if (interview && interview.fecha) {
      interview.fecha = interview.fecha
      data.interviewSelectedEdu = interview;
    }
    //console.log("Entrevista seleccionada",interview);
    

    //console.log('interview pre send',interview)

    // await dispatch(setInterviewData(interview));
    handleFormSubmit(data);
    //console.log('admission data')
    //console.log(admissionData)

  };

  //INICIA
  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  // useEffect that detects changes resize from window
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  const handleSkipToResume = () => {
    setActiveStep(3);
  };

  const handleBack = () => {
    if (activeStep === 3 && formValues.nivelIngles === "N") {
      setActiveStep(1);
      return;
    }

    if (activeStep === 3 && formValues.nivelIngles === "S") {
      setActiveStep(2);
      return;
    }

    if (activeStep === 2) {
      setActiveStep(1);
      return;
    }
    if (activeStep === 1) {
      setActiveStep(0);
      return;
    }
  };

  const handleNext = () => {

    if (activeStep === 1) {

      if (formValues.nivelIngles === "N") {
        return handleSkipToResume();
      } else {
        getInterviewsOnService();
        setActiveStep(2);
      }
    }

    if (activeStep === 2) {
      setActiveStep(3);
    }
  };

  const steps: any = [
    { icon: RecordVoiceOver, label: "Informacion asistente" },
    { icon: RecordVoiceOver, label: "Nivel de IDIOMA" },
    { icon: Event, label: "Agendar ENTREVISTA" },
    { icon: Done, label: "Listo RESUMEN" },
  ];

  const getInterviewsOnService = async () => {

    let campoJson = serviceDetail?.campoJson;

    if (campoJson) {

      let codigoPeriodo = campoJson?.codigoPeriodo;
      let codigoFacultad = campoJson?.codigoFacultad;
      let codigoSede = campoJson?.codigoSede;

      const interviewsRequest: InterviewRequest = {
        codigoPeriodo: codigoPeriodo,
        codigoFacultad: codigoFacultad,
        codigoSede: codigoSede
      };



      const interviews = await getInterviewsEdu(interviewsRequest);

      const days: string[] = [];

      if (!interviews || interviews.entrevistas == null) {
        setAvailableDays(days);
        // Ordenar por hora
        setAvailableInterviews([]);

        setValue("selectedDate", null);

        return;
      }

      const availableInterviewsArray = interviews?.entrevistas?.flatMap(
        (interview) =>
          interview.fechasEntrevista!.map((dateInterview) => {
            const date = new Date(dateInterview.fecha!);
            if (!days.includes(moment(date).format("DD/MM/YYYY"))) {
              days.push(moment(date).format("DD/MM/YYYY"));
            }
            const [hour, minute] = dateInterview.hora!.split(":");
            const hourPeriod = Number(hour) < 12 ? "AM" : "PM";
            const formattedHour = `${Number(hour) % 12}:${minute} ${hourPeriod}`;
            return {
              codigo: dateInterview.codigo,
              codigoPsicologo: interview.codigoPsicologo,
              fecha: date,
              hora: formattedHour,
              nombrePsicologo: interview.nombrePsicologo,
            } as InterviewSelectedEduDTO;
          })
      );

      setAvailableDays(days);

      availableInterviewsArray?.sort(SortArray); // Ordenar por hora
      setAvailableInterviews(availableInterviewsArray);

      const sortedByDate = [...availableInterviewsArray!]?.sort(SortArrayByDate); // Ordenar por fecha
      // Aquí puedes acceder a la primera fecha disponible
      const firstAvailableDate = sortedByDate[0].fecha;

      setValue("selectedDate", firstAvailableDate);
    }
  };

  const getStepContent = (activeStep: number) => {

    let content = <></>;

    switch (activeStep) {
      case 0: {
        content = stepInfoUserForm();
        break;
      }
      case 1: {
        content = stepContentNivelIdioma();
        break;
      }
      case 2: {
        content = stepContentEntrevista();
        break;
      }
      case 3: {
        content = stepContentResumen();
        break;
      }
    }

    return content;
  };

  const disabledButtonNext = (activeStep: number) => {
    const atLeastRadioSelected = (
      values: PurchaseForm["benefeciariesSelected"]
    ) => {
      return values ? true : false;
    };
    switch (activeStep) {
      case 1: {
        return !atLeastRadioSelected(formValues.nivelIngles);
      }
      case 2: {
        return !atLeastRadioSelected(formValues.interviewSelected);
      }
    }
    return false;
  };

  const stepInfoUserForm = () => {

    return (
      <>
        <ParticularUserFormEdu setInfoUserShoppingData={setInfoUserShoppingData} admissionData={admissionData}
          serviceDetail={serviceDetail} setRateSelected={setRateSelected} cotizante={cotizante} setCotizante={setCotizante} setStep={setActiveStep} />
      </>
    )
  }

  const stepContentNivelIdioma = () => {
    const nivelIngles = formValues?.nivelIngles;
    const atLeastRadioSelected = (values: PurchaseForm["nivelIngles"]) => {
      return values ? true : false;
    };

    return (
      <>
        <form onSubmit={handleSubmit(handleNext)}>
          <div className="w-full">
            <Typography className="font-bold text-2xl pt-8" color="primary">
              Información importante
            </Typography>
            <Divider orientation="horizontal" className="pb-2" flexItem />

            <Typography className="font-bold text-lg pt-2 text-principal-650 text-center">
              ¿El aspirante posee nivel de inglés oral conversacional?
            </Typography>

            <FormControl className="flex items-center w-full">
              <RadioGroup row name="row-radio-buttons-group">
                <FormControlLabel
                  value="S"
                  control={
                    <Radio
                      {...register("nivelIngles", {
                        validate: atLeastRadioSelected,
                      })}
                      value={"S"}
                      checked={nivelIngles === "S"}
                    />
                  }
                  label="Sí"
                />
                <FormControlLabel
                  value="N"
                  control={
                    <Radio
                      {...register("nivelIngles", {
                        validate: atLeastRadioSelected,
                      })}
                      value={"N"}
                      checked={nivelIngles === "N"}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="flex w-fxull justify-between pt-8 items-center">
            {activeStep !== 0 && <Button
              color="primary"
              className="py-2 w-1/2 mr-2"
              onClick={handleBack}
            >
              Atrás
            </Button>
            }
            <Button
              type="submit"
              variant="contained"
              className="py-2 w-1/2"
              disabled={disabledButtonNext(activeStep)}
            >
              Siguiente
            </Button>
          </div>
        </form>
      </>
    );
  };

  const stepContentEntrevista = () => {

    if (isLoadingInterviews) {
      return <Loading />;
    }

    if (availableInterviews?.length === 0) {
      return (
        <>
          <div className="flex flex-col items-center w-full py-4">
            <Warning color="warning"></Warning>
            <p className="font-bold">
              No se encontraron entrevistas disponibles en la fecha seleccionada
            </p>
          </div>
          <div className="flex w-fxull justify-between pt-8 items-center">
            <Button
              color="primary"
              className="py-2 w-1/2 mr-2"
              onClick={() => {
                setValue("interviewSelected", undefined);
                handleBack();
              }}
            >
              Atrás
            </Button>
          </div>
        </>
      );
    }

    const atLeastRadioSelected = (
      values: PurchaseForm["interviewSelected"]
    ) => {
      return values ? true : false;
    };

    const shouldDisableDate = (date: Date) => {
      const dateParse = moment(date).format("DD/MM/YYYY");
      return !availableDays!.includes(dateParse);
    };

    return (
      <form onSubmit={handleSubmit(handleNext)}>
        <div className="w-full">
          <Typography className="font-bold text-2xl pt-8 pb-4" color="primary">
            Horarios de entrevista
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <DatePicker
              className="w-full"
              label="Fecha"
              format="dd/MM/yyyy"
              shouldDisableDate={shouldDisableDate}
              slots={{
                ...register("selectedDate", {
                  required: "Este campo es requerido",
                }),
              }}
              defaultValue={formValues.selectedDate}
              disablePast={true}
              onChange={(date: Date | null) => {
                setValue("selectedDate", date);
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="w-full">
          {availableInterviewsByDay?.length === 0 ? (
            <div className="">
              <Warning color="warning"></Warning>
              <p className="font-bold">
                No se encontraron entrevistas disponibles en la fecha
                seleccionada
              </p>
            </div>
          ) : (
            availableInterviewsByDay?.map((interview) => (
              <div
                key={interview.codigo}
                className="flex flex-wrap w-full py-3 border-b"
              >
                <div className="w-3/4">
                  <div>
                    <ClockIcon color="primary"></ClockIcon>
                    <span className="text-lg pl-4 text-principal-650">
                      {interview.hora}
                    </span>
                  </div>
                  <div>
                    <Person color="primary"></Person>
                    <span className="text-lg pt-8 pl-4 text-principal-650">
                      {interview.nombrePsicologo}
                    </span>
                  </div>
                </div>
                <div className="w-1/4 flex items-center">
                  <FormControl className="ml-auto">
                    <RadioGroup row name="row-radio-buttons-group">
                      <FormControlLabel
                        value={interview.codigo}
                        control={
                          <Radio
                            {...register("interviewSelected", {
                              validate: atLeastRadioSelected,
                            })}
                            value={interview.codigo}
                            checked={
                              formValues.interviewSelected === interview.codigo
                            }
                          />
                        }
                        label=""
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="flex w-fxull justify-between pt-8 items-center">
          <Button
            color="primary"
            className="py-2 w-1/2 mr-2"
            onClick={() => {
              setValue("interviewSelected", undefined);
              handleBack();
            }}
          >
            Atrás
          </Button>
          <Button
            type="submit"
            variant="contained"
            className="py-2 w-1/2"
            disabled={disabledButtonNext(activeStep)}
          >
            Siguiente
          </Button>
        </div>
      </form>
    );
  };

  const stepContentResumen = () => {

    let beneficiarios = [];

    if (formValues?.benefeciariesSelected.constructor === String) {
      if (formValues?.aditionalForm !== undefined) {
        beneficiarios.push({
          nombreUsuario:
            formValues?.aditionalForm![0].firstName +
            " " +
            formValues?.aditionalForm![0].secondName
        });
      }
    }

    let entrevista: InterviewSelectedEduDTO | null | undefined = null;

    if (formValues.nivelIngles !== "N") {
      entrevista = availableInterviewsByDay?.filter(
        (interview) => interview.codigo == formValues.interviewSelected
      )[0];
    }
    ////console.log('cotizante', cotizante)

    return (
      <>
        <form onSubmit={handleSubmit(handleFormSubmitV2)}>
          <div className="flex flex-col w-full items-center">
            <Typography className="font-bold text-lg pt-8 text-principal-250 ">
              Resumen
            </Typography>
            <Typography className="font-bold text-lg  text-principal-650">
              Resumen del agendamiento
            </Typography>

            <Typography className="font-bold text-lg pt-8 text-principal-250">
              Usuario
            </Typography>
            <Typography className="text-lg">
              {admissionData?.nombre}
            </Typography>

            <Typography className="font-bold text-lg pt-8 text-principal-250">
              Total programa
            </Typography>
            {
              rateSelected?.valor &&
              <p className='text-principal-650 font-bold text-end'>{rateSelected?.valor ? Number(rateSelected?.valor).toLocaleString('es-CO', {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 0,
              }) : ''}</p>
            }


            {entrevista != null && (
              <>
                <Typography className="font-bold text-lg pt-8 text-principal-650">
                  Entrevista
                </Typography>
                <Typography className="text-lg" color="primary">
                  <span className="font-bold">Fecha: </span>
                  {moment(entrevista.fecha).format("DD/MM/YYYY")}{" "}
                  <span className="font-bold">Hora: </span> {entrevista.hora}{" "}
                </Typography>
                <p className="text-lg text-principal-650">
                  {entrevista.nombrePsicologo}
                </p>
              </>
            )}
          </div>

          <div className="flex w-fxull justify-between pt-8 items-center">
            <Button
              color="primary"
              className="py-2 w-1/2 mr-2"
              onClick={handleBack}
            >
              Atrás
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              className="py-2 w-1/2"
              disabled={disabledButtonNext(activeStep)}
              loading={isLoading}
            >
              {entrevista != null ? "Agendar" : "Comprar"}
            </LoadingButton>

            <ServiceConfirmationDialog
              open={openDialog}
              onClose={handleCloseDialog}
              onConfirm={() => formSubmit(formData!)}
            />
          </div>
        </form>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
              component: 'form',
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries((formData as any).entries());
                  const email = formJson.email;
                  console.log('email', email);
                  
                  if (cotizante) {
                      cotizante.email = email;
                      console.log('cotizante', cotizante);
                      
                  }
                  handleClose();
              },
          }}
        >
          <DialogContent>
              <DialogContentText>
                  Señor usuario, no tenemos registrado tu correo electronico en nuestro sistema, te invitamos a digitarlo para efectos de factura electronica.
              </DialogContentText>
              <TextField
                  autoFocus
                  required
                  margin="dense"
                  id="name"
                  name="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="standard"
              />
          </DialogContent>
          <DialogActions>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button type="submit">Continuar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };


  return (
    <div className="w-full center">
      <Stepper
        activeStep={activeStep}
        className="w-full center"
        alternativeLabel={!mobile}
        orientation={mobile ? "vertical" : "horizontal"}
      >
        {steps.map((step: any, index: any) => {
          return (
            <Step key={step.label}>
              <StepLabel
                color={index <= activeStep ? "primary" : "disabled"}
                StepIconProps={{
                  color: index <= activeStep ? "primary" : "disabled",
                }}
                StepIconComponent={step.icon}
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {getStepContent(activeStep)}


    </div>
  );

  function SortArray(x: InterviewSelectedEduDTO, y: InterviewSelectedEduDTO) {
    if (x.hora < y.hora) {
      return -1;
    }
    if (x.hora > y.hora) {
      return 1;
    }
    return 0;
  }

  function SortArrayByDate(
    x: InterviewSelectedEduDTO,
    y: InterviewSelectedEduDTO
  ) {
    if (x.fecha < y.fecha) {
      return -1;
    }
    if (x.fecha > y.fecha) {
      return 1;
    }
    return 0;
  }
};
