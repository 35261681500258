import { Business } from 'models/business'
import React from 'react'
import { Link } from "react-router-dom";


interface BussinesProps {
  business: Business,
  setSelectedBusiness?: any,
  selectedBusiness?: Business | null,
  selectedCategory?: string;
}

export const BusinessCard = ({ business, setSelectedBusiness, selectedBusiness, selectedCategory }: BussinesProps) => {

  return (
    <Link to={selectedCategory ? `/business/${business.idNegocio}/${selectedCategory}` : `/business/${business.idNegocio}`} onClick={() => setSelectedBusiness && setSelectedBusiness(business)}>
      <div className='w-[140px] md:w-[170px] mr-10 md:mr-14 border-2 rounded-lg pb-2 shadow-md shadow-slate-400'
        style={selectedBusiness == business ? { backgroundColor: `${selectedBusiness?.color}`, borderColor: 'white' } : { backgroundColor: 'white', borderColor: `${business?.color}` }}>
        <div
          style={selectedBusiness ? { fill: 'white' } : { fill: `${business?.color}` }}
          className="flex justify-center items-center h-[60px] md:h-[70px]"
          dangerouslySetInnerHTML={{ __html: business?.icono ? business?.icono : "" }}
        />
        <p className='text-center text-sm' style={selectedBusiness ? { color: 'white' } : { color: `${business?.color}` }}>{business?.nombre}</p>
      </div>
    </Link>
  )
}
