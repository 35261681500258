import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  space?: string;
  value?: string | number;
}

export const ReservationConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  space,
  value,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color="primary" className="font-bold">
        Cotización de reserva
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Se va a reservar el espacio <strong>{space}</strong> con valor{" "}
          <strong>
            {value?.toLocaleString("es-CO", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
            })}
          </strong>{" "}
          <br />
          ¿Estás seguro de continuar?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Reservar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
