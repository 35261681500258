import React, { useRef } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { ServiceImages } from 'models/serviceImages';

interface CarouselDialogProps {
    open: boolean;
    serviceCenterImages: ServiceImages[];
    onClose: () => void;
}

export const CarouselDialog = ({ open, onClose, serviceCenterImages }: CarouselDialogProps) => {

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = (scrollOffset: number) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += scrollOffset;
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth='md'>
            <div className='flex w-full flex-row items-center px-3'>
                <ExpandCircleDownOutlined
                    className="h-10 w-10 mr-2 cursor-pointer text-principal-450 rotate-90"
                    onClick={() => handleScroll(-320)}
                />
                <div
                    className="flex w-full px-2 py-6 scroll-container overflow-hidden"
                    ref={scrollContainerRef}
                >
                    {serviceCenterImages.map((image, index) => (
                        <img className='rounded-lg mx-2 w-80' key={index} src={image?.dataImagen} alt="" />
                    ))}
                </div>
                <ExpandCircleDownOutlined
                    className="h-10 w-10 ml-2 cursor-pointer text-principal-450 -rotate-90"
                    onClick={() => handleScroll(320)}
                />
            </div>
        </Dialog>
    );
};