import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ServiceConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle color="primary" className="font-bold">Agendar entrevista</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Estas seguro que deseas agendar la entrevista?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Agendar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
