import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import QrImage from "../../assets/images/qr_icon.png";
import QrImageMini from "../../assets/images/qr_icon_mini.png";
import manosComfenalco from "../../assets/images/manosComfenalco.png";
import { usePurchaseHistory } from "hooks/usePurchaseHistory";
import CloseIcon from "@mui/icons-material/Close";
import QRCode from "react-qr-code";
import { QrInfo } from "models/qrInfo";
import moment from "moment";
import { SmallLoading } from "components/SmallLoading";

interface QrDialogProps {
  idCompra: number;
  color: string;
}

export const QrPurchaseHistory = ({ idCompra, color }: QrDialogProps) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { getQrsSale } = usePurchaseHistory();
  const [saleInfo, setsaleInfo] = useState(null);
  const [listQr, setListQr] = useState<QrInfo[]>([]);
  const [pdfInfo, setPdfInfo] = useState<string>("");

  const [qrSelected, setQrSelected] = useState<QrInfo>();
  const [idSelected, setIdSelected] = useState<number>(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dateFormat = (date: string) => {
    return moment(date).locale("es").format("DD/MM/YYYY");
  };

  useEffect(() => {
    const onGetServicedata = async () => {
      if (idCompra) {
        const infoSale = await getQrsSale(Number(idCompra));
        setsaleInfo(infoSale);
        setListQr(infoSale.listQr);
        setPdfInfo(infoSale.archivoPdf);
        setQrSelected(infoSale.listQr[0]);
      }
      setIsLoading(false);
    };

    if (open) {
      onGetServicedata();
    }
  }, [open]);

  const handleDownloadFileClick = () => {
    const anchorLink = document.createElement("a");
    anchorLink.href = `data:application/pdf;base64,${pdfInfo}`;
    anchorLink.download = `documento.pdf`;
    anchorLink.click();
  };

  const changeQrSelected = (data: QrInfo, index: number) => {
    setQrSelected(data);
    setIdSelected(index);
  };

  const colorTheme = createTheme({
    palette: {
      primary: {
        main: color,
      },
    },
  });

  return (
    <div>
      <div
        className="flex flex-wrap justify-center items-center content-center text-center center"
        onClick={handleClickOpen}
      >
        <img alt="user icon" src={QrImage} className="w-1/5" />
        <p className="w-full text-principal-250 font-bold">Descargar </p>
        <p className="w-full text-principal-250 font-bold">Boleta</p>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{qrSelected?.nombreCentroServicio}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="flex flex-nowrap">
          {isLoading ? (
            <SmallLoading />
          ) : (
            <div className="flex flex-nowrap">
              <div className="flex flex-wrap w-1/2 justify-center items-center content-center text-center px-5">
                <div className="w-full">
                  <QRCode
                    value={qrSelected?.codeValue ? qrSelected?.codeValue : ""}
                    className="p-5 w-40 h-40 bg-white"
                  />
                </div>
                <p>{qrSelected?.nombreCliente}</p>
                <p>
                  {dateFormat(qrSelected?.fechaUso ? qrSelected?.fechaUso : "")}
                </p>
              </div>
              <div className="w-1/2 px-5 justify-center items-center content-center">
                <div
                  className="w-full rounded-md  p-2 flex flex-wrap justify-center items-center content-center"
                  style={{ backgroundColor: color }}
                >
                  <p className="text-principal-0 w-full">Asistentes</p>
                  {listQr.map((data: QrInfo, index: number) => (
                    <div
                      className="p-2"
                      onClick={() => {
                        changeQrSelected(data, index);
                      }}
                    >
                      <img
                        alt="user icon"
                        src={QrImageMini}
                        className=""
                        style={{
                          backgroundColor:
                            index === idSelected ? "rgb(255,255,255,0.5)" : "",
                          cursor: "pointer",
                        }}
                      />
                      <div
                        className="rounded-xl text-center mt-1 "
                        style={{
                          color,
                          width: 22,
                          height: 22,
                          backgroundColor:
                            index !== idSelected
                              ? "rgb(255,255,255,0.5)"
                              : "#ffffff",
                          cursor: "pointer",
                        }}
                      >
                        {index + 1}
                      </div>
                    </div>
                  ))}
                </div>
                <ThemeProvider theme={colorTheme}>
                  <div className="pt-2 flex justify-center items-center content-center">
                    <Button
                      variant="outlined"
                      onClick={handleDownloadFileClick}
                    >
                      Descargar
                    </Button>
                  </div>
                </ThemeProvider>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
