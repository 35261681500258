import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Button, IconButton, Typography } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form';
import { HotelForm } from 'models/purchaseForm';
import manosComfenalco from '../assets/images/manosComfenalco.png';
import { ServiceCenter } from 'models/serviceCenter';
import { FormDateRangePicker } from './DateRangePicker';
import { ArrowBackIos, ExpandCircleDownOutlined, Hotel } from '@mui/icons-material';
import { Beneficiaries } from './Beneficiaries';
import { StaticCounter } from './StaticCounter';
import { useNavigate } from 'react-router-dom';
import { useBoxValidator } from 'hooks/useBoxValidator';
import { ValidadorCaja } from 'models/boxValidatorModel';
import { SmallLoading } from './SmallLoading';
import { TIPO_USUARIO } from 'utils/constants';


interface ServiceCenterForm {
    serviceCenterData?: ServiceCenter,
    color?: string
}

export const ServiceCenterForm = ({ serviceCenterData, color }: ServiceCenterForm) => {
    const { authData, isLogged } = useSelector((state: RootState) => state.auth);
    const { documentNumber, documentType } = useSelector((state: RootState) => state.individualSale.individualUserData);
    const credentials = useSelector(
        (state: RootState) => state.credentials.credentials
    );

    const { getBoxValidator, isLoadingBoxValidator } = useBoxValidator();

    const navigate = useNavigate();


    const { handleSubmit, formState: { errors }, setValue, register, watch } = useForm<HotelForm>({
        defaultValues: {
            benefeciariesSelected: [],
        },
    });

    const [aditionalsCounter, setAditionalsCounter] = useState<{ adultsCounter: number, childCounter: number }>({
        adultsCounter: 0,
        childCounter: 0
    });

    const [boxValidator, setBoxValidator] = useState<ValidadorCaja>();
    const [emailMessage, setEmailMessage] = useState("");

    useEffect(() => {
        if (documentType != "" && documentNumber != "") {
            const onGetBoxValidator = async () => {
                const boxValidatorResp = await getBoxValidator(
                    documentType,
                    documentNumber
                );

                if (credentials) {
                    if (boxValidatorResp && boxValidatorResp.afiliado) {
                        let mail = boxValidatorResp.afiliado.email;
                        let mailTrans =
                            mail?.slice(0, 3) +
                            "***" +
                            mail?.slice(mail.indexOf("@"), mail.indexOf("@") + 4) +
                            "***";
                        setEmailMessage(
                            credentials.mensajeCorreoAfiliado!.replace("$CORREO", mailTrans)
                        );
                    }
                }

                setBoxValidator(boxValidatorResp);
            };
            onGetBoxValidator();
        }
    }, [documentType, documentNumber]);



    // Realiza el envio del formulario
    const handleFormSubmit: SubmitHandler<HotelForm> = (data: HotelForm) => {

        const hotelData: HotelForm = {
            ...data,
            adults: aditionalsCounter.adultsCounter > 0 ? aditionalsCounter.adultsCounter : 0,
            childs: aditionalsCounter.childCounter > 0 ? aditionalsCounter.childCounter : 0,
            hotelName: serviceCenterData?.nombre,
            idCentroServicio: serviceCenterData?.idCentroServicio,
            color: color,
            boxValidator: boxValidator,
            codZeus: serviceCenterData?.codigoZeus
        }

        navigate('/room-list', { state: { data: JSON.stringify(hotelData) } })
    };

    return (
        <div className='w-full' >
            {
                !isLogged ?
                    <div className='flex flex-col w-full items-center pt-4'>
                        <div className='flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2'>
                            <img src={manosComfenalco} className="h-16 w-16" />
                            <p className='text-principal-600 font-bold'>Por favor, inicia sesión para realizar tu compra.</p>
                        </div>
                    </div> :
                    <>
                        {
                            isLoadingBoxValidator ? <SmallLoading /> :
                                <>
                                    {((authData?.tipoUsuario === TIPO_USUARIO && documentNumber && documentType) || (isLogged)) ?
                                        (
                                            <>
                                                <div className='flex items-center px-4 py-2 bg-principal-350 -mt-4 boorder rounded-t-lg'>
                                                    <IconButton
                                                        size="large"
                                                        edge="start"
                                                        aria-label="menu"
                                                        onClick={() => navigate(-1)}
                                                    >
                                                        <ExpandCircleDownOutlined className='rotate-90' fontSize='large' color='primary' />
                                                    </IconButton>
                                                    <div className='w-full'>
                                                        <Typography color='primary' className='font-bold text-xl text-center'>Información de la reserva</Typography>
                                                    </div>
                                                </div>
                                                {(authData?.tipoUsuario === TIPO_USUARIO) && <p className="text-principal-600 p-4">{emailMessage}</p>}
                                                <form onSubmit={handleSubmit(handleFormSubmit)}>

                                                    <div className='flex flex-wrap px-4 border-b py-4'>
                                                        <div className='flex flex-wrap w-full lg:w-2/5 items-center'>
                                                            <div className='w-1/5 lg:w-1/4'>
                                                                <Hotel className='w-14 h-14' color='primary' />
                                                            </div>
                                                            <div className='flex flex-col px-2 w-4/5 lg:w-3/4'>
                                                                <p className='font-bold text-principal-600 px-2'>¿A dónde vas?</p>
                                                                <p className='font-bold text-principal-600 px-2'>{serviceCenterData?.nombre}</p>
                                                            </div>

                                                        </div>
                                                        <div className='flex items-center w-full lg:w-3/5'>
                                                            <FormDateRangePicker register={register} setValue={setValue} errors={errors} watch={watch} />
                                                        </div>
                                                    </div>

                                                    <div className='w-full py-4 border-b'>
                                                        <Beneficiaries
                                                            errors={errors}
                                                            register={register}
                                                            setValue={setValue}
                                                            watch={watch}
                                                            beneficiaries={authData?.tipoUsuario === TIPO_USUARIO ? boxValidator?.grupoFamiliar?.informacionGrupo : authData?.beneficiarioVOs}
                                                        />
                                                    </div>

                                                    <div className='w-full py-4'>
                                                        <StaticCounter aditionalsCounter={aditionalsCounter} setAditionalsCounter={setAditionalsCounter} />
                                                    </div>


                                                    <div className='flex w-full justify-end p-4 items-center'>
                                                        <Button type='submit' variant="contained" className='w-1/3'>
                                                            buscar
                                                        </Button>
                                                    </div>

                                                </form>
                                            </>
                                        ) : (
                                            <div className='flex flex-col w-full items-center pt-4'>
                                                <div className='flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2'>
                                                    <img src={manosComfenalco} className="h-16 w-16" />
                                                    <p className='text-principal-600 font-bold'>Por favor, inicia sesión para realizar tu reserva.</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                        }
                    </>
            }
        </div >
    )
}
