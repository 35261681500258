import { Dispatch } from "redux";
import { setToken } from "./jwtSlice";
import requestApi from '../../../api/cartApi'
import { JWT } from "models/jwt";

export const getJWT = () => {
    return async (dispatch: Dispatch) => {

        const resp = await requestApi.get<JWT>(`auth/loginInit`);

        const jwtResp = await Promise.resolve(resp);

        dispatch(setToken(jwtResp?.data));
    }
};
