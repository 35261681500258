import QRCode from "react-qr-code";
import React from "react";
import { useRef } from "react";

interface CarnetInfoProps {
  nombre: string;
  activo: string;
  categoria: string;
  abreviatura: string;
  identificacion: string;
  idPers: string;
}
export const CarnetInfo = ({
  nombre,
  activo,
  categoria,
  abreviatura,
  identificacion,
  idPers,
}: CarnetInfoProps) => {
  const QRCoderef = useRef(null);

  return (
    <div className=" col-lg-8 col-md-12 col-sm-12 d-flex justify-content-center">
      <div className="bg-carnet-pattern  align-items-end bg-no-repeat w-560px h-360px min-w-560px min-h-360px">
        <div className=" flex justify-content-between w-full">
          <div className="px-0 flex flex-col bd-highlight w-full font-bold">
            <p className="mt-146px pl-6 w-full ">
              {nombre}
              <br />
              Categoría: {(activo === "N" && "Retirado") || categoria}
              <br />
              <span className="pl-6">
                {abreviatura}: <span>{identificacion}</span>
              </span>
              <br />
            </p>
            <p className=" text-center pt-14">
              <span className="idPers">{idPers}</span>
            </p>
          </div>
          <div className="pr-7 pt-36 ">
            <QRCode value={idPers} className="p-5 w-40 h-40 bg-white" />
          </div>
        </div>
      </div>
    </div>
  );
};
