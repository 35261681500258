import React, { useEffect, useState } from 'react'
import { UseFormSetValue, UseFormRegister, FieldErrors, UseFormWatch } from 'react-hook-form';
import { HotelForm } from 'models/purchaseForm';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import 'moment/locale/es';

interface DatePickerProps {
    setValue: UseFormSetValue<HotelForm>,
    register: UseFormRegister<HotelForm>,
    errors: FieldErrors<HotelForm>,
    watch: UseFormWatch<HotelForm>
}

export const FormDateRangePicker = ({ setValue, register, errors, watch }: DatePickerProps) => {

    moment.locale('es-co');

    const [minDate, setMinDate] = useState<any>();

    const initialDate = watch('initialDate')!;

    useEffect(() => {
        if (initialDate) {
            const finishDate = initialDate;
            setMinDate(finishDate)
        }
    }, [initialDate])

    const handleInitialDateChange = (date: Date | null) => {
        // Con setValue, actualizamos el valor del campo 'selectedDate'
        setValue('initialDate', date);
    };

    const handleFinishDateChange = (date: Date | null) => {
        // Con setValue, actualizamos el valor del campo 'selectedDate'
        setValue('finishDate', date);
    };

    return (
        <div className='flex flex-col'>
            <div className='flex flex-wrap flex-row justify-between items-center w-full'>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div className='w-1/2 pr-2'>
                        <DatePicker
                            className='w-full'
                            disablePast={true}
                            label='Fecha entrada'
                            format="DD/MM/YYYY"
                            slots={
                                {
                                    ...register('initialDate', {
                                        required: 'Este campo es requerido',
                                        validate: (value) => {
                                            const currentDate = new Date();
                                            const selectedDate = new Date(value || '');
                                            if (selectedDate.getDate() < currentDate.getDate() && selectedDate.getMonth() <= currentDate.getMonth() && selectedDate.getFullYear() && selectedDate.getFullYear() <= currentDate.getFullYear()) {
                                                return "La fecha seleccionada no puede ser una fecha pasada";
                                            }
                                            return true;
                                        },
                                    })
                                }
                            }
                            onChange={handleInitialDateChange}
                        />
                        {errors.initialDate && <p className='text-red-500 text-end'>{String(errors.initialDate.message)}</p>}
                    </div>
                    <div className='w-1/2'>
                        <DatePicker
                            className='w-full'
                            disablePast={true}
                            label='Fecha salida'
                            format="DD/MM/YYYY"
                            disabled={!initialDate}
                            minDate={(minDate)}
                            slots={
                                {
                                    ...register('finishDate', {
                                        required: 'Este campo es requerido',
                                        validate: (value) => {
                                            const selectedDate = new Date(value || '');
                                            const minDateFormat = new Date(minDate)
                                            if (selectedDate.getDate() <= minDateFormat.getDate() && selectedDate.getMonth() <= minDateFormat.getMonth() && selectedDate.getFullYear() && selectedDate.getFullYear() <= minDateFormat.getFullYear()) {
                                                return "La fecha de salida no puede ser la misma o menor a la fecha de entrada";
                                            }
                                            return true;
                                        },
                                    })
                                }
                            }
                            slotProps={
                                {
                                    textField: {
                                        style: { padding: '0px' }
                                    }
                                }
                            }
                            onChange={handleFinishDateChange}
                        />
                        {errors.finishDate && <p className='text-red-500 text-end'>{String(errors.finishDate.message)}</p>}
                    </div>
                </LocalizationProvider>
            </div>
        </div>
    )
}
