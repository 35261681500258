import { useState } from "react";
import { Link } from "react-router-dom";
import LogoComfenalco from "assets/images/logoComfenalco.svg";
import { Menu } from "@mui/icons-material";
import { AppBar, Toolbar, IconButton, createTheme } from "@mui/material";
import { Aside } from "./Aside";
import { LocationSelect } from "./LocationSelect";
import { SearchTextField } from "./SearchTextField";
import { SesionButtons } from "./SesionButtons";
import React from "react";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ThemeProvider } from "@emotion/react";

export const NavBar = () => {
  const isLogged = useSelector((state: RootState) => state.auth.isLogged);
  const { token } = useSelector((state: RootState) => state.jwt);

  const [openModal, setOpenModal] = useState(false);

  const greenTheme = createTheme({
    palette: {
      primary: {
        main: "#135F2C",
      },
    },
  });

  const handleToggle = () => {
    setOpenModal(!openModal);
  };
  const salesCount = useSelector(
    (state: RootState) => state.salesCount.salesCount
  );

  return (
    <ThemeProvider theme={greenTheme}>
      <div>
        <AppBar position="static" className="!shadow">
          <Toolbar className="flex justify-between bg-principal-50 py-3">
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              className="w-1/3 sm:w-auto mr-2 color-principal-150"
              onClick={() => handleToggle()}
            >
              <Menu className="justify-self-start" />
            </IconButton>

            <Link to="/home" className="w-1/3 sm:w-1/4 md:w-1/6 lg:w-1/10 ">
              <img src={LogoComfenalco} />
            </Link>

            {token?.token && (
              <>
                <LocationSelect styles={"w-1/6 hidden md:inline"} />

                <SearchTextField styles={"w-1/3 hidden sm:inline"} />
              </>
            )}

            {isLogged && token?.token && (
              <IconButton
                component={Link}
                to={`/shopping-car`}
                size="large"
                aria-label="menu"
                className="flex justify-center w-1/3 sm:w-auto color-principal-150"
              >
                <div
                  style={{
                    position: "absolute",
                    marginBottom: 25,
                    fontSize: 14,
                    backgroundColor: "#FFFFFF",
                    width: 25,
                    height: 25,
                    borderRadius: 13,
                    borderWidth: 1,
                    borderColor: "#ED8534",
                    flex: 1,
                    justifyContent: "center",
                  }}
                  className="text-[#ED8534] "
                >
                  {salesCount}
                </div>
                <ShoppingCartOutlined
                  className="text-[#ED8534]"
                  fontSize="large"
                />
              </IconButton>
            )}

            <SesionButtons styles={"w-1/3 sm:w-auto flex justify-end"} />
          </Toolbar>
        </AppBar>
      </div>
      <Aside openModal={openModal} handleToggle={handleToggle} />
    </ThemeProvider>
  );
};
