import { Person, PersonAdd } from "@mui/icons-material";
import { MRoom } from "models/room";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

interface RoomCardProps {
  index: number;
  room: MRoom;
  color?: string;
  hotelName?: string;
  guestsAges: {
    adults: any;
    children: any;
  };
  selectedRoom: {
    room: MRoom | null;
    index: number | null;
  };
  setSelectedRoom: React.Dispatch<
    React.SetStateAction<{
      room: MRoom | null;
      index: number | null;
    }>
  >;
}

export const RoomCard = ({
  room,
  selectedRoom,
  setSelectedRoom,
  index,
  color,
  hotelName,
  guestsAges,
}: RoomCardProps) => {
  const navigate = useNavigate();
  console.log(room)
  const handleNavigate = () => {
    navigate("/room-list/room-detail", {
      state: { data: JSON.stringify(room), color: color, hotelName: hotelName },
    });
  };

  return (
    <div className="flex flex-wrap justify-between w-full mt-4 text-principal-600">
      <div className="w-full md:w-[25%] md:mt-0 border rounded-lg">
        <div className="flex justify-center py-2 items-center border-b bg-principal-350/30">
          <p className="text-center font-bold text-principal-600">
            Información Habitación
          </p>
        </div>
        <div className="p-2 border-b">
          <p className="font-bold text-principal-600">Tipo de habitación:</p>
          <p className="cursor-pointer" onClick={() => handleNavigate()}>
            {room?.hotelTarifa?.nametyperoom}
          </p>
        </div>
        <div className="p-2 border-b">
          <p>{room?.hotelTarifa?.descriptionroom}</p>
        </div>
        <div className="p-2 border-b">
          <p>{room?.hotelTarifa?.nameplan}</p>
        </div>
        {room?.iconos.length > 0 && (
          <div className="flex flex-row p-2 border-b min-h-20 max-h-25 overflow-y-scroll">
            {room?.iconos?.map((icon, index) => (
              <div
                className="flex flex-row justify-center items-center p-2"
                key={index}
              >
                <img src={icon?.icono} className="w-4 h-4" />
                <p className="pl-2">{icon?.descripcion}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-wrap w-full md:w-[73%] mt-2 md:mt-0 border rounded-lg">
        <div className="w-full sm:w-1/2 md:w-1/3  border">
          <div className="flex justify-center py-2 items-center border-b bg-principal-350/30">
            <p className="text-center font-bold text-principal-600">
              Capacidad
            </p>
          </div>
          <div className="flex flex-wrap flex-row p-2">
            {Array.from(
              {
                length: JSON.parse(room?.servicio?.campoJson)?.capacidadAdultos,
              },
              (_, i) => (
                <div key={i}>
                  <Person />
                </div>
              )
            )}
            {Array.from(
              {
                length: JSON.parse(room?.servicio?.campoJson)?.capacidadNinos,
              },
              (_, i) => (
                <div key={i}>
                  <PersonAdd fontSize="small" />
                </div>
              )
            )}
          </div>
          <div className="flex flex-wrap pb-2 px-2">
            <p className="pr-1">Capacidad para: </p>
            {JSON.parse(room.servicio.campoJson)?.capacidadAdultos + JSON.parse(room.servicio.campoJson)?.capacidadNinos} {' personas'}
            

          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 border">
          <div className="flex justify-center py-2 items-center border-b bg-principal-350/30">
            <p className="text-center font-bold text-principal-600">
              Tus opciones
            </p>
          </div>
          <div className="p-2">
            <span>
              {room?.servicio?.descripcionHabitacion &&
                room?.servicio?.descripcionHabitacion
                  .split("\n")
                  .map((linea: string, i: number) => (
                    <React.Fragment key={i}>
                      {linea}
                      <br />
                    </React.Fragment>
                  ))}
            </span>
          </div>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 border">
          <div className="flex justify-center py-2 items-center border-b bg-principal-350/30">
            <p className="text-center font-bold text-principal-600">Cotizar</p>
          </div>
          <div className="p-2">
            {Number(room?.hotelTarifa?.total_original) > 0 ? (
              <p className="font-bold text-principal-600">
                {Number(room?.hotelTarifa?.total_original).toLocaleString(
                  "es-CO",
                  {
                    style: "currency",
                    currency: "COP",
                    minimumFractionDigits: 0,
                  }
                )}
              </p>
            ) : (
              <p>Para:</p>
            )}
            <div className="flex">
              {guestsAges?.adults > 1 ? (
                <p>{guestsAges?.adults} adultos</p>
              ) : (
                <>
                  {guestsAges?.adults > 0 && <p>{guestsAges?.adults} adulto</p>}
                </>
              )}
  
              {guestsAges?.children > 1 ? (
                <>
                  <p className="px-1">y</p>
                  <p>{guestsAges?.children} niños</p>
                </>
              ) : (
                <>
                  {guestsAges?.children > 0 && (
                    <p>{guestsAges?.children} niño</p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="flex justify-center p-2 mt-4">
            <Button
              variant={index === selectedRoom?.index ? "contained" : "outlined"}
              onClick={() => setSelectedRoom({ room: room, index: index })}
            >
              {index === selectedRoom?.index ? "Seleccionado" : "Seleccionar"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
