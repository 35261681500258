import { Category } from 'models/category';
import moment from 'moment';

export const IsCategoryAvailable = (selectedCategory?: Category): boolean => {

    if (!selectedCategory) {
        return false;
    }
    // Obtén la fecha actual
    const currentDate = moment();

    // Define el rango de fechas
    const startDate = moment(selectedCategory?.fechaInicialMensaje);
    const endDate = moment(selectedCategory?.fechaFinalMensaje);

    return currentDate.isBetween(startDate, endDate, null, '[]');
}
