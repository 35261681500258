import { Loading } from 'components/Loading';
import { useSubsidyMonetary } from 'hooks/useMonetarySubsidy';
import { useParam } from 'hooks/useParam';
import { MSubsidyMonetaryRequest, MSubsidyMonetaryResponse, SubsidiosFamiliare } from 'models/subsidyMonetaryModel';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { PARAM_RANGO_FECHA } from 'utils/constants';
import manosComfenalco from "../../../assets/images/manosComfenalco.png";
import { PeriodsSlider } from 'components/PeriodSlider/PeriodsSlider';
import { Business } from 'models/business';
import { MonetarySubsidyInformation } from 'components/MonetarySubsidyInformation';
import { Category } from 'models/category';
import { IsCategoryAvailable } from 'utils/CategoryAvailable';

interface MonetarySubsidyProps {
  selectedBusiness?: Business;
  selectedCategory?: Category;
}

export const MonetarySubsidy = ({ selectedBusiness, selectedCategory }: MonetarySubsidyProps) => {
  const { isLogged, authData } = useSelector((state: RootState) => state.auth);
  const { getMonetarySubsidyInfo } = useSubsidyMonetary();
  const [isLoading, setIsLoading] = useState(false);
  const { getParamData } = useParam();
  const [monetarySubsidyData, setMonetarySubsidyData] =
    useState<MSubsidyMonetaryResponse>();
  const [selectedPeriod, setSelectedPeriod] = useState<SubsidiosFamiliare>();

  const isWithinRange = selectedCategory && IsCategoryAvailable(selectedCategory)

  useEffect(() => {
    const getSubsidy = async () => {
      setIsLoading(true);

      const dateRangerParam = await getParamData(PARAM_RANGO_FECHA);
      const currentRanger = Number(dateRangerParam?.data.valorParametro);
      const initialDate = new Date();

      initialDate.setMonth(initialDate.getMonth() - currentRanger);
      initialDate.setDate(1);
      const isoDate = initialDate.toISOString();

      if (isLogged) {
        const subsidyMonetaryRequest: MSubsidyMonetaryRequest = {
          tipoIdentificacion: authData.afiliado.abreviatura!,
          identificacion: authData.afiliado.identificacion!,
          fechaInicio: isoDate,
          fechaFin: new Date().toISOString(),
        };

        const subsidyMonetaryResponse = await getMonetarySubsidyInfo(
          subsidyMonetaryRequest,
        );

        if (subsidyMonetaryResponse) {
          setMonetarySubsidyData(subsidyMonetaryResponse);
        };
      }
      setIsLoading(false);
    };

    getSubsidy();
  }, []);

  useEffect(() => {
    if (monetarySubsidyData) {

      setSelectedPeriod(monetarySubsidyData?.subsidiosFamiliares[0]);
    }

  }, [monetarySubsidyData]);


  return (

    <>
      {!isWithinRange ?
        <>
          {
            isLogged ? (
              <>
                {isLoading ? (
                  <Loading />
                ) : (
                  <div>
                    {monetarySubsidyData ? (
                      <>
                        <PeriodsSlider
                          monetarySubsidyData={monetarySubsidyData}
                          isLoading={isLoading}
                          color={selectedBusiness?.color!}
                          selectedPeriod={selectedPeriod}
                          setSelectedPeriod={setSelectedPeriod}
                        />
                        <MonetarySubsidyInformation
                          color={selectedBusiness?.color!}
                          selectedPeriod={selectedPeriod}
                          message={monetarySubsidyData?.mensajeFechaPago!}
                        />
                      </>
                    ) : (
                      <div className="flex flex-col w-full items-center pt-4">
                        <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                          <img src={manosComfenalco} className="h-16 w-16" />
                          <p className="text-principal-600 font-bold">
                            No se encontraron datos.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            ) :
              (
                <div className="flex flex-col w-full items-center pt-4">
                  <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                    <img src={manosComfenalco} className="h-16 w-16" />
                    <p className="text-principal-600 font-bold">
                      No hay información para mostrar. Si deseas consultar tu subsidio
                      monetario por favor inicia sesión.
                    </p>
                  </div>
                </div>
              )
          }
        </> :
        <div className="flex flex-col w-full items-center pt-4">
          <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
            <img src={manosComfenalco} className="h-16 w-16" />
            <p className="text-principal-600 font-bold">
              {selectedCategory?.mensajeInactividad && selectedCategory?.mensajeInactividad}
            </p>
          </div>
        </div>
      }
    </>
  )
}
