import React, { useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import manosComfenalco from '../../../assets/images/manosComfenalco.png';
import { useQuotas } from 'hooks/useQuotas';
import { MQuotas } from 'models/quotas';
import { SmallLoading } from 'components/SmallLoading';
import { Category } from 'models/category';
import { IsCategoryAvailable } from 'utils/CategoryAvailable';
import { CustomTabPanel } from 'components/Quotas/CustomTabPanel';
import { SubsidyCreditCard } from 'components/Quotas/SubsidyCreditCard';
import { GoodwillCreditCard } from 'components/Quotas/GoodwillCreditCard';
import { useCreditOffersParams } from 'hooks/useCreditOffersParams';

interface QuotasProps {
    selectedCategory?: Category;
}

export const Quotas = ({ selectedCategory }: QuotasProps) => {
    const { isLogged, authData } = useSelector((state: RootState) => state.auth);
    const credentials = useSelector((state: RootState) => state.credentials.credentials);
    const { getQuotes, isLoadingQuotes } = useQuotas();
    const [quotas, setQuotas] = useState<MQuotas>();
    const { isLoadingService, goodwillCreditParams, subsidyCreditParams, getCreditOffers } = useCreditOffersParams();
    const [infoCredit, setInfoCredit] = useState({
        montoLibreInversion: '',
        montoCompraCartera: '',
        tasaLibreInversion: '',
        tasaCompraCartera: ''
    })

    const [asesorInfo, setasesorInfo] = useState({
        correoAsesor: '',
        celularAsesor: '',
        asesorFinal: ''
    });

    const [value, setValue] = React.useState(0);
    const isWithinRange = selectedCategory && IsCategoryAvailable(selectedCategory);


    useEffect(() => {
        const getQuotas = async () => {

            const quotas = await getQuotes(authData?.afiliado?.abreviatura, authData?.afiliado?.identificacion);

            setQuotas(quotas)
            getOffers();

        }


        isLogged && getQuotas();
    }, [])

    const getOffers = async () => {
        const creditResponse = await getCreditOffers();

        if (creditResponse) {

            if (creditResponse.length > 0) {

                const credito = creditResponse[0].credito;
                const montoLibreInversion = credito?.montoMaximoLi;
                const montoCompraCartera = credito?.montoMaximoCC;
                const tasaLibreInversion = credito?.tasaMesVencidaLi;
                const tasaCompraCartera = credito?.tasaMesVencidaCC;

                setasesorInfo({
                    correoAsesor: credito?.correoAsesor,
                    celularAsesor: credito?.celularAsesor,
                    asesorFinal: credito?.asesorFinal
                })
                setInfoCredit({
                    montoLibreInversion,
                    montoCompraCartera,
                    tasaLibreInversion,
                    tasaCompraCartera
                })
            }
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            {
                isLogged ?
                    <>
                        {
                            !isWithinRange ?
                                <>
                                    {isLoadingQuotes || isLoadingService ? <SmallLoading /> :
                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                                    <Tab label="Crédito anticipo de subsidio" {...a11yProps(0)} />
                                                    <Tab label="Crédito de libranza" {...a11yProps(1)} />
                                                </Tabs>
                                            </Box>
                                            <CustomTabPanel value={value} index={0}>
                                                <div className='flex flex-wrap w-full justify-around pt-8 rounded-xl'>
                                                    <SubsidyCreditCard
                                                        quotas={quotas}
                                                        title='COMPRA DE SERVICIOS'
                                                        firstParam={subsidyCreditParams.creditBuyFirstParagraph}
                                                        secondParam={subsidyCreditParams.creditBuySecondParagraph}
                                                        value1Param={subsidyCreditParams.compraServicioMv}
                                                    />
                                                    <SubsidyCreditCard
                                                        quotas={quotas}
                                                        title='CUPO DE CREDITO'
                                                        firstParam={subsidyCreditParams.quotaFirstParagraph}
                                                        secondParam={subsidyCreditParams.quotaSecondParagraph}
                                                        value1Param={subsidyCreditParams.cupoCreditoMv}

                                                    />
                                                </div>

                                            </CustomTabPanel>
                                            <CustomTabPanel value={value} index={1}>
                                                <div className='flex flex-wrap w-full justify-around pt-8 rounded-xl'>
                                                    <GoodwillCreditCard quotas={quotas} title='LIBRE INVERSION' text={goodwillCreditParams.freeInvestment} monto={infoCredit?.montoLibreInversion} tasa={infoCredit?.tasaLibreInversion} asesorInfo={asesorInfo} />
                                                    <GoodwillCreditCard quotas={quotas} title='COMPRA DE CARTERA' text={goodwillCreditParams.portfolioPurchase} monto={infoCredit?.montoCompraCartera} tasa={infoCredit?.tasaCompraCartera} asesorInfo={asesorInfo} />
                                                </div>
                                            </CustomTabPanel>
                                        </Box>
                                    }
                                </> :
                                <div className="flex flex-col w-full items-center pt-4">
                                    <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                                        <img
                                            src={manosComfenalco}
                                            className="h-16 w-16"
                                        />
                                        <p className="text-principal-600 font-bold">
                                            {selectedCategory?.mensajeInactividad && selectedCategory?.mensajeInactividad}
                                        </p>
                                    </div>
                                </div>
                        }

                    </>
                    :
                    <div className='flex flex-col w-full items-center pt-4'>
                        <div className='flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2'>
                            <img src={manosComfenalco} className="h-16 w-16" />
                            <p className='text-principal-600 font-bold'>{credentials?.inicioSesionRequerido}</p>
                        </div>
                    </div>
            }
        </>
    )
}
