
import { useState } from 'react';
import integrationApi from '../api/integrationApi';
import {
    MHousingSubsidy,
    MHousingSubsidyRequest,
} from '../models/housingSubsidyModel';
import {
    MPostulationHousingSubsidyRequest,
    OpportunitiesHousingSubsidy,
} from '../models/postulationModel';

export const useHousingSubsidy = () => {
    const [isLoadingHousingSubsidy, setIsLoadingHousingSubsidy] = useState(false);
    const [
        isLoadingOpportunitiesPostulation,
        setIsLoadingOpportunitiesPostulation,
    ] = useState(false);
    const urlHousingSubsidy = 'subsidioVivienda';

    const getHousingSubsidy = async (
        housingSubsidyRequest: MHousingSubsidyRequest,
    ) => {
        setIsLoadingHousingSubsidy(true);

        const housingSubsidy = await integrationApi.post<any>(
            `${urlHousingSubsidy}/getSubsidio`,
            housingSubsidyRequest,
        );

        setIsLoadingHousingSubsidy(false);

        return housingSubsidy?.data;
    };

    const getOpportunitiesPostulation = async (
        postulationRequest: MPostulationHousingSubsidyRequest,
    ) => {
        setIsLoadingOpportunitiesPostulation(true);
        try {
            const applicationResponse =
                await integrationApi.post<OpportunitiesHousingSubsidy>(
                    `${urlHousingSubsidy}/postulacion`,
                    postulationRequest,
                );
            setIsLoadingOpportunitiesPostulation(false);
            return applicationResponse?.data.oportunidad;
        } catch (error) {
            setIsLoadingOpportunitiesPostulation(false);
            return null;
        }
    };

    return {
        isLoadingHousingSubsidy,
        isLoadingOpportunitiesPostulation,
        getHousingSubsidy,
        getOpportunitiesPostulation,
    };
};