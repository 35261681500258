import { LoadingButton } from "@mui/lab";
import { InterviewsCol } from "components/InterviewsCol";
import { InterviewsEdu } from "components/InterviewsEdu";
import { Loading } from "components/Loading";
import { useInterviewsEdu } from "hooks/useInterviewEdu";
import { InterviewRequest } from "models/interviewRequestModel";
import { InterviewSelectedColDTO, InterviewSelectedEduDTO } from "models/interviewSelected";
import { AdmissionData, ServiceDetailLight } from "models/lightForm";
import { PurchaseForm } from "models/purchaseForm";

import moment from 'moment';
import { ServiceConfirmationDialog } from "pages/Services/ServiceConfirmationDialog";
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from "react-hook-form";
import { COMPRA_INDIVIDUAL_ACTIVA, IND_ORIGEN_COLEGIO } from "utils/constants";


interface InterviewFormProps {
    setStep: React.Dispatch<React.SetStateAction<number>>,
    admissionData?: AdmissionData,
    serviceDetail?: ServiceDetailLight,
    setRateSelected?: React.Dispatch<React.SetStateAction<any>>,
    setPreviousstep: React.Dispatch<React.SetStateAction<number>>

}


const InterviewForm = ({ setStep, admissionData, serviceDetail, setRateSelected, setPreviousstep }: InterviewFormProps) => {




    const { getInterviewsEdu, isLoadingInterviews, getInterviewsColegio } =
        useInterviewsEdu();

    const [availableInterviewsEdu, setAvailableInterviewsEdu] =
        useState<InterviewSelectedEduDTO[]>();
    const [availableInterviewsEduByDay, setAvailableInterviewsEduByDay] =
        useState<InterviewSelectedEduDTO[]>();

    const [availableInterviewsCol, setAvailableInterviewsCol] =
        useState<InterviewSelectedColDTO[]>();
    const [availableInterviewsColByDay, setAvailableInterviewsColByDay] =
        useState<InterviewSelectedColDTO[]>();

    const [availableDays, setAvailableDays] = useState<string[]>();
    const [isLoadingPurchase, setIsLoadingPurchase] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [formData, setFormData] = useState<PurchaseForm>();
    const [totalAmount, setTotalAmount] = useState(0);

    const {
        handleSubmit,
        formState: { errors },
        setValue,
        register,
        watch,
        unregister,
        getValues,
    } = useForm<PurchaseForm>({
        defaultValues: {
            benefeciariesSelected:
                serviceDetail?.campoJson.compraIndividual === COMPRA_INDIVIDUAL_ACTIVA
                    ? null
                    : [],
        },
    });

    const formValues = watch();

    useEffect(() => {

        if (
            serviceDetail?.campoJson.tieneEntrevista &&
            serviceDetail?.campoJson.tieneEntrevista === "S" &&
            serviceDetail?.indOrigen !== IND_ORIGEN_COLEGIO
        ) {
            const getInterviewsEducacion = async () => {
                const interviewsRequest: InterviewRequest = {
                    codigoPeriodo: serviceDetail?.campoJson.codigoPeriodo,
                    codigoFacultad: serviceDetail?.campoJson.codigoFacultad,
                    codigoSede: serviceDetail?.campoJson.codigoSede,
                };

                const interviews = await getInterviewsEdu(interviewsRequest);
                const days: string[] = [];

                if (!interviews || interviews.entrevistas == null) {
                    setAvailableDays(days);
                    // Ordenar por hora
                    setAvailableInterviewsEdu([]);

                    setValue("selectedDate", null);
                    return;
                }

                const availableInterviewsArray = interviews?.entrevistas?.flatMap(
                    (interview) =>
                        interview.fechasEntrevista!.map((dateInterview) => {
                            const date = new Date(dateInterview.fecha!);
                            if (!days.includes(moment(date).format("DD/MM/YYYY"))) {
                                days.push(moment(date).format("DD/MM/YYYY"));
                            }
                            const [hour, minute] = dateInterview.hora!.split(":");
                            const hourPeriod = Number(hour) < 12 ? "AM" : "PM";
                            const formattedHour =
                                Number(hour) % 12 != 0
                                    ? `${Number(hour) % 12}:${minute} ${hourPeriod}`
                                    : `12:${minute} ${hourPeriod}`;
                            return {
                                codigo: dateInterview.codigo,
                                codigoPsicologo: interview.codigoPsicologo,
                                fecha: date,
                                hora: formattedHour,
                                nombrePsicologo: interview.nombrePsicologo,
                            } as InterviewSelectedEduDTO;
                        })
                );

                setAvailableDays(days);

                availableInterviewsArray?.sort(SortArray); // Ordenar por hora
                setAvailableInterviewsEdu(availableInterviewsArray);

                const sortedByDate = [...availableInterviewsArray!]?.sort(
                    SortArrayByDate
                ); // Ordenar por fecha
                // Aquí puedes acceder a la primera fecha disponible
                const firstAvailableDate = sortedByDate[0].fecha;

                setValue("selectedDate", firstAvailableDate);
            };

            getInterviewsEducacion();
        }

        if (
            serviceDetail?.campoJson.tieneEntrevista &&
            serviceDetail?.campoJson.tieneEntrevista === "S" &&
            serviceDetail?.indOrigen === IND_ORIGEN_COLEGIO
        ) {
            const getInterviewsEducacion = async () => {
                const interviews = await getInterviewsColegio(serviceDetail?.idServicio);
                const days: string[] = [];

                if (!interviews || interviews.entrevistas == null) {
                    setAvailableDays(days);
                    // Ordenar por hora
                    setAvailableInterviewsCol([]);

                    setValue("selectedDate", null);
                    return;
                }

                const availableInterviewsArray = interviews?.entrevistas?.flatMap(
                    (interview) => {
                        const date = moment(interview.fecha!).toDate();
                        if (!days.includes(moment(date).format("DD/MM/YYYY"))) {
                            days.push(moment(date).format("DD/MM/YYYY"));
                        }
                        const hourPeriod = Number(interview.hora) < 12 ? "AM" : "PM";
                        const formattedHour =
                            Number(interview.hora) % 12 != 0
                                ? `${Number(interview.hora) % 12}:${interview.minuto.padStart(
                                    2,
                                    "0"
                                )} ${hourPeriod}`
                                : `12:${interview.minuto.padStart(2, "0")} ${hourPeriod}`;
                        return {
                            codigo: interview.codigo,
                            fecha: date,
                            hora: formattedHour,
                        } as InterviewSelectedColDTO;
                    }
                );

                setAvailableDays(days);

                availableInterviewsArray?.sort(SortArray); // Ordenar por hora
                setAvailableInterviewsCol(availableInterviewsArray);

                const sortedByDate = [...availableInterviewsArray!]?.sort(
                    SortArrayByDate
                ); // Ordenar por fecha
                // Aquí puedes acceder a la primera fecha disponible
                const firstAvailableDate = sortedByDate[0].fecha;

                setValue("selectedDate", firstAvailableDate);
            };

            getInterviewsEducacion();
        }


    }, []);

    const SortArray = (x: any, y: any) => {
        if (x?.hora < y?.hora) {
            return -1;
        }
        if (x?.hora > y?.hora) {
            return 1;
        }
        return 0;
    };

    const SortArrayByDate = (x: any, y: any) => {
        if (x?.fecha < y?.fecha) {
            return -1;
        }
        if (x?.fecha > y?.fecha) {
            return 1;
        }
        return 0;
    };

    useEffect(() => {
        if (
            serviceDetail?.campoJson.tieneEntrevista &&
            serviceDetail?.campoJson.tieneEntrevista === "S" &&
            serviceDetail?.indOrigen !== IND_ORIGEN_COLEGIO
        ) {
            if (!formValues.selectedDate) {
                return;
            }

            setValue("interviewSelected", undefined);
            setAvailableInterviewsEduByDay([]);

            const interviewsDay = availableInterviewsEdu?.filter(
                (interview) =>
                    interview.fecha.getTime() === formValues.selectedDate!.getTime()
            );

            if (interviewsDay!.length > 0) {
                setAvailableInterviewsEduByDay(interviewsDay);
            }
        }

        if (
            serviceDetail?.campoJson.tieneEntrevista &&
            serviceDetail?.campoJson.tieneEntrevista === "S" &&
            serviceDetail?.indOrigen === IND_ORIGEN_COLEGIO
        ) {
            if (!formValues.selectedDate) {
                return;
            }

            setValue("interviewSelected", undefined);
            setAvailableInterviewsColByDay([]);

            const interviewsDay = availableInterviewsCol?.filter(
                (interview) =>
                    interview.fecha.getTime() === formValues.selectedDate!.getTime()
            );

            if (interviewsDay!.length > 0) {
                setAvailableInterviewsColByDay(interviewsDay);
            }
        }
    }, [formValues.selectedDate]);

    const formSubmit: SubmitHandler<PurchaseForm> = async (
        data: PurchaseForm
    ) => {
        console.log('formSubmit', data)
        setOpenDialog(false);
    }

    const handleFormSubmit: SubmitHandler<PurchaseForm> = (
        data: PurchaseForm
    ) => {
        if (data?.interviewSelected) {
            setFormData(data);
            setOpenDialog(true);
        } else {
            formSubmit(data);
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    return (
        <div>
            {isLoadingPurchase && <Loading />}
            <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full">
                {serviceDetail?.campoJson.tieneEntrevista &&
                    serviceDetail?.campoJson.tieneEntrevista === "S" &&
                    serviceDetail?.indOrigen !== IND_ORIGEN_COLEGIO && (
                        <InterviewsEdu
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            availableInterviewsByDay={availableInterviewsEduByDay}
                            availableDays={availableDays}
                            availableInterviews={availableInterviewsEdu}
                            isLoadingInterviews={isLoadingInterviews}
                        />
                    )}

                {serviceDetail?.campoJson.tieneEntrevista &&
                    serviceDetail?.campoJson.tieneEntrevista === "S" &&
                    serviceDetail?.indOrigen === IND_ORIGEN_COLEGIO && (
                        <InterviewsCol
                            register={register}
                            setValue={setValue}
                            watch={watch}
                            availableInterviewsByDay={availableInterviewsColByDay}
                            availableDays={availableDays}
                            availableInterviews={availableInterviewsCol}
                            isLoadingInterviews={isLoadingInterviews}
                        />
                    )}

                <div
                    className="flex w-full pt-8 items-center"
                    style={
                        !serviceDetail?.campoJson.tieneEntrevista ||
                            serviceDetail?.campoJson.tieneEntrevista !== "S"
                            ? { justifyContent: "space-between" }
                            : { justifyContent: "end" }
                    }
                >
                    {(!serviceDetail?.campoJson.tieneEntrevista ||
                        serviceDetail?.campoJson.tieneEntrevista !== "S") && (
                            <p className="text-lg text-principal-600">
                                Total a pagar:{" "}
                                <b>
                                    {totalAmount
                                        ? totalAmount.toLocaleString("es-CO", {
                                            style: "currency",
                                            currency: "COP",
                                            minimumFractionDigits: 0,
                                        })
                                        : "$0"}
                                </b>
                            </p>
                        )}

                    <ServiceConfirmationDialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        onConfirm={() => formSubmit(formData!)}
                    />

                    <LoadingButton
                        type="submit"
                        variant="contained"
                        className="w-1/2"
                        loading={isLoadingPurchase}
                        disabled={formValues.interviewSelected === undefined ||
                            formValues.interviewSelected === ""
                        }
                    >
                        {totalAmount > 0
                            ? "Comprar"
                            : serviceDetail?.campoJson.tieneEntrevista &&
                                serviceDetail?.campoJson.tieneEntrevista === "S"
                                ? "Agendar entrevista"
                                : "Comprar"}
                    </LoadingButton>
                </div>
            </form>

        </div>
    )
}


export default InterviewForm;