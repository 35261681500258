import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface PublicRouteProps {
  children: any;
}

export const PublicRoute = ({ children }: PublicRouteProps) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const lastPath = pathname + search;
    // localStorage.setItem("lastPath", lastPath);
  }, [pathname, search]);

  return children;
};
