import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LastLocation = () => {
  const location = useLocation();

  useEffect(() => {
    const lastPathStorage = localStorage.getItem("nowPath");

    if (lastPathStorage && lastPathStorage.includes("business")) {
      localStorage.setItem("lastBusinessPath", lastPathStorage);
    }

    if (lastPathStorage !== location.pathname) {
      localStorage.setItem("lastPath", String(lastPathStorage));
      localStorage.setItem("nowPath", location.pathname);
    }
  }, [location.pathname]);

  return <div></div>;
};

export default LastLocation;
