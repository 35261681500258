import { useState } from 'react'
import { RatesRequest } from 'models/ratesRequest';
import integrationApi from 'api/integrationApi';
import { ServiceRates } from 'models/servicesRates';
import { RatesFormLightRequest } from './ratesFormLightRequest';


export const useRates = () => {
    const [isLoadingRates, setIsLoadingRates] = useState(false);

    const urlRates: string = "tarifas";

    const getRates = async (ratesRequest: RatesRequest) => {

        setIsLoadingRates(true);

        const rates = integrationApi.post<ServiceRates>(`${urlRates}/getTarifas`, ratesRequest);

        const ratesResp = await Promise.resolve(
            rates
        );


        setIsLoadingRates(false);
        return ratesResp?.data;

    }


    const getRatesFormLight = async (ratesRequest: RatesFormLightRequest) => {

        const rates = integrationApi.post<any>(`${urlRates}/getTarifasFormLight`, ratesRequest);

        const ratesResp = await Promise.resolve(
            rates
        );
        return ratesResp?.data;

    }

    return {
        isLoadingRates,
        getRates,
        getRatesFormLight
    }
}
