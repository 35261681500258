import React from 'react'
import manosComfenalco from '../assets/images/manosComfenalco.png';

export const SmallLoading = () => {
    return (
        <div className="flex justify-center items-center flex-col w-full py-2">
            <img src={manosComfenalco} alt="Icono de carga" className="animate-spin h-16 w-16" />
        </div>
    )
}
