import { ShoppingCartOutlined } from '@mui/icons-material'
import { Button, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { IndividualSaleDialog } from './IndividualSaleDialog';

export const IndividualSaleForm = () => {
    const [openDialog, setOpenDialog] = useState(false);
    const { authData, isLogged } = useSelector((state: RootState) => state.auth);
    const { documentNumber, documentType } = useSelector(
        (state: RootState) => state.individualSale.individualUserData
    );


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div className="flex items-center justify-center w-full py-4">
            <Button
                className="bg-principal-250 flex flex-col"
                onClick={() => handleOpenDialog()}
                variant="contained"
            >
                <ShoppingCartOutlined />
                Venta individual
            </Button>

            <Divider orientation="vertical" className="mx-2" />

            <div>
                <p className="font-bold text-principal-650">
                    <span className="text-principal-250">Asesor:</span>{" "}
                    {authData?.afiliado?.primerNombre}{" "}
                    {authData?.afiliado?.primerApellido}
                </p>
                <p className="font-bold text-principal-650">
                    <span className="text-principal-550">cliente: </span>
                    {documentType} - {documentNumber}
                </p>
            </div>
            <IndividualSaleDialog open={openDialog} onClose={handleCloseDialog} />
        </div>
    )
}
