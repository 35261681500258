import { ThemeProvider } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  createTheme
} from "@mui/material";
import { Loading } from "components/Loading";
import { useGenericData } from "hooks/useGenericData";
import { useUser } from "hooks/useUser";
import { UpdateDateRequest, UpdateProfileForm } from "models/UpdateProfileForm";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const Profile = () => {
  const { afiliado } = useSelector((state: RootState) => state.auth.authData);

  const {
    getDeparmentList,
    getAddressesList,
    getMunicipalitiesList,
    getNeighborhoodsList,
  } = useGenericData();

  const { updateUserData, isLoadingUpdateData } = useUser();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<UpdateProfileForm>();

  const formValues = watch();
  const departmentSelected = watch("department");
  const citySelected = watch("city");

  const [municipalitiesList, setMunicipalitiesList] = useState<any[]>([]);
  const [neighborHoodsList, setNeighborhoodsList] = useState<any[]>([]);
  const [addressList, setAddressList] = useState<any[]>([]);
  const [departmentsList, setDepartmentsList] = useState<any[]>([]);

  const [completeaddress, setCompleteaddress] = useState<string[]>([]);
  const [selectedVia, setselectedVia] = useState();
  const [isLoadingData, setIsLoadingData] = useState({
    afiliado: true,
    address: true,
    departmentList: true,
    addressesList: true,
  });

  const greenTheme = createTheme({
    palette: {
      primary: {
        main: "#135F2C",
      },
    },
  });

  useEffect(() => {
    const onGetAddressList = async () => {
      const addressListResp = await getAddressesList();
      setAddressList(
        addressListResp?.addressList ? addressListResp?.addressList : []
      );
      setIsLoadingData({
        ...isLoadingData,
        addressesList: false,
      });
    };
    onGetAddressList();
  }, []);

  useEffect(() => {
    const onGetDepartmentList = async () => {
      const deparmentListResp = await getDeparmentList();
      setDepartmentsList(
        deparmentListResp?.departmentsList
          ? deparmentListResp?.departmentsList
          : []
      );
      setIsLoadingData({
        ...isLoadingData,
        departmentList: false,
      });
    };
    onGetDepartmentList();
  }, []);

  useEffect(() => {
    const onGetMunicipalitiesList = async () => {
      if (!departmentSelected) return;
      const municipalitiesList = await getMunicipalitiesList(
        departmentSelected
      );
      setMunicipalitiesList(municipalitiesList);
    };

    onGetMunicipalitiesList();
  }, [departmentSelected]);

  useEffect(() => {
    const onGetNeighborhoodsList = async () => {
      if (!citySelected) return;
      const neighborHoodsList = await getNeighborhoodsList(citySelected);
      setNeighborhoodsList(neighborHoodsList);
    };

    onGetNeighborhoodsList();
  }, [citySelected]);

  useEffect(() => {
    setValue(
      "department",
      afiliado?.idDepaResidencia ? afiliado?.idDepaResidencia : undefined
    );
    setValue(
      "city",
      afiliado?.idMuniResidencia ? afiliado?.idMuniResidencia : undefined
    );
    setValue(
      "neighborhood",
      afiliado?.idBarrResidencia ? afiliado?.idBarrResidencia : undefined
    );
    setIsLoadingData({
      ...isLoadingData,
      afiliado: false,
    });
  }, [afiliado]);

  useEffect(() => {
    const address = afiliado?.direccionResidencia.split(" ");

    setValue("viaType", address && address[0] ? address[0] : " ");
    setValue("viaNumber", address && address[1] ? address[1] : " ");
    setValue("aditionalNumber", address && address[2] ? address[2] : " ");
    setValue("aditionalNumberTwo", address && address[3] ? address[3] : " ");
    setValue(
      "aditionalInfo",
      address?.length >= 5 ? address?.slice(4).join(" ") : ""
    );

    setselectedVia(address && address[0] ? address[0] : " ");
    setCompleteaddress(address);
    setIsLoadingData({
      ...isLoadingData,
      address: false,
    });
  }, [addressList]);

  const handleUpdateUserData = async (data: UpdateProfileForm) => {
    const request: UpdateDateRequest = {
      barrio: data?.neighborhood,
      tipoIdentificacion: afiliado?.abreviatura,
      celular: data?.cellphone,
      correo: data?.email,
      direccion: data?.fullAddress,
      identificacion: afiliado?.identificacion,
      telefono: data?.phoneNumber,
    };

    const respuesta = await updateUserData(request);
  };

  return (
    <ThemeProvider theme={greenTheme}>
      <div className="p-8" onSubmit={handleSubmit(handleUpdateUserData)}>
        {!afiliado ||
          !municipalitiesList ||
          !neighborHoodsList ||
          !addressList ||
          !departmentsList ||
          !completeaddress ? (
          <Loading />
        ) : (
          <form className="flex flex-wrap w-full">
            <div className="flex flex-wrap w-full">
              <div className="w-full sm:w-1/2 px-1 py-3">
                <TextField
                  defaultValue={
                    afiliado?.telefonoResidencia
                      ? afiliado?.telefonoResidencia
                      : undefined
                  }
                  className="w-full"
                  label="Télefono"
                  {...register(`phoneNumber`, {
                    required: "El número de telefono es obligatorio",
                  })}
                />
                {errors?.phoneNumber && (
                  <p className="text-red-500">
                    {String(errors?.phoneNumber?.message)}
                  </p>
                )}
              </div>
              <div className="w-full sm:w-1/2 px-1 py-3">
                <TextField
                  className="w-full"
                  label="Celular"
                  defaultValue={
                    afiliado?.celular ? afiliado?.celular : undefined
                  }
                  {...register(`cellphone`, {
                    required: "El número de celular es obligatorio",
                  })}
                />
                {errors?.cellphone && (
                  <p className="text-red-500">
                    {String(errors?.cellphone?.message)}
                  </p>
                )}
              </div>
              <div className="w-full sm:w-1/2 px-1 py-3">
                <TextField
                  className="w-full"
                  label="Correo"
                  defaultValue={afiliado?.email ? afiliado?.email : undefined}
                  {...register(`email`, {
                    required: "El correo es obligatorio",
                  })}
                />
                {errors?.email && (
                  <p className="text-red-500">
                    {String(errors?.email?.message)}
                  </p>
                )}
              </div>
            </div>

            <div className="flex flex-wrap w-full">
              <span className="w-full font-bold text-lg text-principal-600 py-4">
                Actializar ubicación
              </span>
              <div className="w-full sm:w-1/2 px-1 py-3">
                <FormControl className="w-full">
                  <InputLabel id="departamento">Departamento</InputLabel>
                  <Select
                    labelId="departamento"
                    label="Departamento"
                    defaultValue={
                      afiliado?.idDepaResidencia
                        ? afiliado?.idDepaResidencia
                        : undefined
                    }
                    {...register(`department`, {
                      required: "El departamento es obligatorio",
                    })}
                  >
                    {departmentsList?.map((departament, index) => (
                      <MenuItem key={index} value={departament?.ID_DEPA}>
                        {departament?.DESCRIPCION}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors?.department && (
                  <p className="text-red-500">
                    {String(errors?.department?.message)}
                  </p>
                )}
              </div>
              <div className="w-full sm:w-1/2 px-1 py-3">
                <FormControl className="w-full">
                  <InputLabel id="Ciudad">Ciudad</InputLabel>
                  <Select
                    labelId="Ciudad"
                    label="Ciudad"
                    {...register(`city`, {
                      required: "La ciudad es obligatorio",
                    })}
                    defaultValue={
                      afiliado?.idMuniResidencia
                        ? afiliado?.idMuniResidencia
                        : undefined
                    }
                    disabled={municipalitiesList?.length <= 0}
                  >
                    {municipalitiesList?.map((city, index) => (
                      <MenuItem key={index} value={city?.ID_MUNI}>
                        {city?.DESCRIPCION}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors?.city && (
                  <p className="text-red-500">
                    {String(errors?.city?.message)}
                  </p>
                )}
              </div>
              <div className="w-full sm:w-1/2 px-1 py-3">
                <FormControl className="w-full">
                  <InputLabel id="Barrio">Barrio</InputLabel>
                  <Select
                    labelId="Barrio"
                    label="Barrio"
                    defaultValue={
                      afiliado?.idBarrResidencia
                        ? afiliado?.idBarrResidencia
                        : undefined
                    }
                    {...register(`neighborhood`, {
                      required: "El barrio es obligatorio",
                    })}
                    disabled={neighborHoodsList?.length <= 0}
                  >
                    {neighborHoodsList?.map((neightborHood, index) => (
                      <MenuItem key={index} value={neightborHood?.ID_BARR}>
                        {neightborHood?.DESCRIPCION}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors?.neighborhood && (
                  <p className="text-red-500">
                    {String(errors?.neighborhood?.message)}
                  </p>
                )}
              </div>

              <div className="flex flex-wrap w-full">
                <div className="flex flex-wrap w-full sm:w-1/2">
                  <div className="w-full sm:w-1/2 px-1 py-3">
                    <FormControl className="w-full">
                      <InputLabel id="tipo-via">Tipo de vía</InputLabel>
                      <Select
                        labelId="tipo-via"
                        label="Tipo de vía"
                        {...register(`viaType`, {
                          required: "El tipo de vía es obligatoria",
                        })}
                        defaultValue={selectedVia}
                      >
                        {addressList?.map((address, index) => (
                          <MenuItem key={index} value={address?.ABREVIATURA}>
                            {address?.DESCRIPCION}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors?.viaType && (
                      <p className="text-red-500">
                        {String(errors?.viaType?.message)}
                      </p>
                    )}
                  </div>
                  <div className="w-full sm:w-1/2 px-1 py-3">
                    <TextField
                      defaultValue={completeaddress[1]}
                      className="w-full"
                      label="Número de vía"
                      {...register(`viaNumber`, {
                        required: "El número de vía es obligatorio",
                      })}
                    />
                    {errors.viaNumber && (
                      <p className="text-red-500">
                        {String(errors?.viaNumber?.message)}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex flex-wrap w-full sm:w-1/2">
                  <div className="w-full sm:w-1/4 px-1 py-3">
                    <TextField
                      defaultValue={completeaddress[2]}
                      className="w-full"
                      label="Número adicional"
                      {...register(`aditionalNumber`, {
                        required: "El número de vía es obligatorio",
                      })}
                    />
                    {errors.aditionalNumber && (
                      <p className="text-red-500">
                        {String(errors?.aditionalNumber?.message)}
                      </p>
                    )}
                  </div>
                  <div className="w-full sm:w-1/4 px-1 py-3">
                    <TextField
                      defaultValue={completeaddress[3]}
                      className="w-full"
                      {...register(`aditionalNumberTwo`, {
                        required: "El número de vía es obligatorio",
                      })}
                    />
                    {errors.aditionalNumberTwo && (
                      <p className="text-red-500">
                        {String(errors?.aditionalNumberTwo?.message)}
                      </p>
                    )}
                  </div>
                  <div className="w-full sm:w-2/4 px-1 py-3">
                    <TextField
                      defaultValue={completeaddress[4]}
                      className="w-full"
                      label="Info adicional"
                      {...register(`aditionalInfo`, {
                        required: "La información adicional es obligatoria",
                      })}
                    />
                    {errors.aditionalInfo && (
                      <p className="text-red-500">
                        {String(errors?.aditionalInfo?.message)}
                      </p>
                    )}
                  </div>
                </div>

                <div className="w-full px-1 py-3">
                  <TextField
                    value={
                      formValues.viaType +
                      " " +
                      formValues.viaNumber +
                      " " +
                      formValues.aditionalNumber +
                      " " +
                      formValues.aditionalNumberTwo +
                      " " +
                      formValues.aditionalInfo
                    }
                    className="w-full"
                    label="Dirección"
                    InputProps={{
                      readOnly: true,
                    }}
                    {...register(`fullAddress`)}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-wrap w-full">
              <LoadingButton
                className="w-full sm:w-1/3"
                type="submit"
                variant="contained"
                disabled={true}
                loading={isLoadingUpdateData}
              >
                Guardar
              </LoadingButton>
            </div>
          </form>
        )}
      </div>
    </ThemeProvider>
  );
};
