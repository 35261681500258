import { useState } from "react";
import cartApi from "../api/cartApi";
import integrationApi from "../api/integrationApi";
import { ServiceData } from "models/serviceData";
import { ServiceImages } from "models/serviceImages";
import { CampoJson } from "models/campoJson";
import { MFile } from "models/MFile";
import { UnavailableDates } from "models/uavailableDate";
import { SearchServiceRequest } from "models/searchServices";
import { ValidationChannel } from "models/validationChannel";

export const useService = () => {

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const services: string = "servicios";

  const searchServices = async (serviceRequest: SearchServiceRequest) => {
    setIsLoadingSearch(true);
    const serviceReq = await integrationApi.post<any>(`${services}/serviciosBuscador`, serviceRequest);
    setIsLoadingSearch(false);
    return serviceReq?.data
  }

  const getServiceData = async (idServicio: number) => {
    const serviceDataReques = cartApi.post<any>(`${services}/servicioDetalle`, {
      idServicio: idServicio,
    });
    const serviceDataResp = await Promise.resolve(serviceDataReques);

    const serviceDataTemporal = serviceDataResp?.data;

    const campoJson: CampoJson = JSON.parse(serviceDataTemporal.campoJson);

    const serviceData: ServiceData = serviceDataTemporal;

    serviceData.campoJson = campoJson;

    const serviceImagesRequest = cartApi.post<ServiceImages[]>(
      `imagen/getImagenesServicio`,
      {
        idServicio: serviceData?.idServicio,
        idCentroServicio: serviceData?.idCentroServicioCdaCentroServicio,
      }
    );

    const serviceImagesResp = await Promise.resolve(serviceImagesRequest);

    const serviceImages: ServiceImages[] = serviceImagesResp?.data;

    return {
      serviceData,
      serviceImages,
    };
  };

  const getFileData = async (idServicio: number, indOrigen: string) => {
    const FileDataReques = cartApi.post<MFile[]>(
      `archivo/getArchivosServicio`,
      {
        idServicio: idServicio,
        indOrigen: indOrigen,
      }
    );

    const FileDataResp = await Promise.resolve(FileDataReques);
    const fileData: MFile[] = FileDataResp?.data;

    return {
      fileData,
    };
  };

  const getUnavailableDates = async (idServicio: number) => {
    const unavailableDatesRequest = cartApi.post<UnavailableDates[]>(
      `fechasNoDis/getFechasNoDisServicio`,
      {
        idServicio: idServicio,
      }
    );

    const unavailableDatesResp = await Promise.resolve(unavailableDatesRequest);

    const unavailableDates: UnavailableDates[] = unavailableDatesResp?.data;

    return unavailableDates;
  };

  const getValidationChannel = async (validationChannel: ValidationChannel) => {

    try {
      const validationChannelRequest = cartApi.post<any>(
        `${services}/validacionCanal`,
        validationChannel
      );
      const validationChannelResp = await Promise.resolve(validationChannelRequest);
      const unavailableDates: any = validationChannelResp.data;
      return unavailableDates;

    } catch (error) {

    }

  };

  const getServiceFormLight = async (idServicio: number) => {
    try {

      const request = {
        idServicio: idServicio
      }
      const getServiceForm = cartApi.post<any>(
        `${services}/servicioFormLight`,
        request
      );
      const getServiceFormResp = await Promise.resolve(getServiceForm);

      const responseServiceData: any = getServiceFormResp.data;
      responseServiceData.campoJson = JSON.parse(responseServiceData.campoJson);
      return responseServiceData;

    } catch (error) {

    }

  };

  return {
    getServiceData,
    getFileData,
    getUnavailableDates,
    searchServices,
    isLoadingSearch,
    getValidationChannel,
    getServiceFormLight
  };
};
