import React, { useRef, useState, useEffect } from 'react'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { MSubsidyMonetaryResponse, SubsidiosFamiliare } from 'models/subsidyMonetaryModel';
import { SmallLoading } from '../SmallLoading';
import { PeriodCard } from './PeriodCard';

interface PeriodsSliderProps {
    monetarySubsidyData: MSubsidyMonetaryResponse;
    isLoading: boolean;
    color: string;
    selectedPeriod: SubsidiosFamiliare | undefined;
    setSelectedPeriod: React.Dispatch<React.SetStateAction<SubsidiosFamiliare | undefined>>;
}

export const PeriodsSlider = (props: PeriodsSliderProps) => {
    const { monetarySubsidyData, isLoading, color, selectedPeriod, setSelectedPeriod } =
        props;
    const [mobile, setMobile] = useState(window.innerWidth <= 500);

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 500);
    }

    // useEffect that detects changes resize from window
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = (scrollOffset: number) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += scrollOffset;
        }
    };
    return (
        <>
            {
                <div className="flex w-full flex-row items-center px-3 shadow border-t-2"
                >
                    <ExpandCircleDownOutlinedIcon
                        className="h-10 w-10 mr-2 cursor-pointer text-principal-450 rotate-90"
                        style={selectedPeriod && { color: 'white' }}
                        onClick={() => handleScroll(-200)}
                    />
                    {
                        isLoading ? (<SmallLoading />) : (
                            <div
                                className="flex w-full px-4 scroll-container overflow-hidden"
                                ref={scrollContainerRef}
                                style={{ justifyContent: monetarySubsidyData?.subsidiosFamiliares.length <= 4 && !mobile ? 'center' : '', }}
                            >

                                {monetarySubsidyData?.subsidiosFamiliares?.map((period, index) => (
                                    <PeriodCard
                                        key={index}
                                        color={color}
                                        selectedPeriod={selectedPeriod}
                                        setSelectedPeriod={setSelectedPeriod}
                                        item={period}
                                    />
                                ))}

                            </div>
                        )
                    }

                    <ExpandCircleDownOutlinedIcon
                        className="h-10 w-10 ml-2 cursor-pointer text-principal-450 -rotate-90"
                        onClick={() => handleScroll(200)}
                    />
                </div>
            }
        </>

    );
}
