import React, { useRef, useState, useEffect } from 'react'
import { BusinessCard } from './BusinessCard';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Business } from 'models/business';
import { Category } from 'models/category';
import { CategoryCard } from './CategoryCard';
import { Loading } from './Loading';
import { SmallLoading } from './SmallLoading';

interface CategorySliderProps {
    selectedBusiness?: Business;
    activeCategories?: Category[];
    selectedCategoryId?: string;
    selectedCategory?: Category;
    setSelectedCategory?: any;
    isLoadingCategories?: boolean;
}

export const CategorySlider = ({ activeCategories, selectedBusiness, selectedCategory, setSelectedCategory, isLoadingCategories }: CategorySliderProps) => {


    const [mobile, setMobile] = useState(window.innerWidth <= 500);

    const handleWindowSizeChange = () => {
      setMobile(window.innerWidth <= 500);
    }
    
    // useEffect that detects changes resize from window
    useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }, []);

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = (scrollOffset: number) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += scrollOffset;
        }
    };

    return (
        <>
            {
                <div className="flex w-full flex-row items-center px-3 shadow-md shadow-black/10"
                // style={selectedBusiness && { backgroundColor: selectedBusiness.color }}
                >
                    <ExpandCircleDownOutlinedIcon
                        className="h-10 w-10 mr-2 cursor-pointer text-principal-450 rotate-90"
                        // style={selectedBusiness && { color: 'white' }}
                        onClick={() => handleScroll(-200)}
                    />
                    {
                        !activeCategories || isLoadingCategories || !selectedBusiness?.idNegocio ? (<SmallLoading />) : (
                            <div
                                className="flex w-full px-4 scroll-container overflow-hidden"
                                ref={scrollContainerRef}
                                style={{justifyContent:activeCategories.length<=4 && !mobile ?'center':'',}}
                            >

                                {activeCategories?.map((category) => (
                                    <CategoryCard
                                        key={category.idCategoria}
                                        category={category}
                                        setSelectedCategory={setSelectedCategory}
                                        selectedCategory={selectedCategory === category ? true : false}
                                        selectedBusiness={selectedBusiness}
                                    />

                                ))}

                            </div>
                        )
                    }

                    <ExpandCircleDownOutlinedIcon
                        className="h-10 w-10 ml-2 cursor-pointer text-principal-450 -rotate-90"
                        // style={selectedBusiness && { color: 'white' }}
                        onClick={() => handleScroll(200)}
                    />
                </div>
            }
        </>

    );
}
