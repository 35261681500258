import { SubsidiosFamiliare } from 'models/subsidyMonetaryModel';
import React, { useState, useEffect } from 'react'

interface PeriodCardProps {
    item: SubsidiosFamiliare;
    setSelectedPeriod: React.Dispatch<React.SetStateAction<SubsidiosFamiliare | undefined>>;
    color: string;
    selectedPeriod: SubsidiosFamiliare | undefined;
}

export const PeriodCard = ({
    item,
    setSelectedPeriod,
    selectedPeriod,
    color
}: PeriodCardProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <button onClick={() => setSelectedPeriod(item)}>
            <div
                className='flex flex-row items-center justify-center h-12 w-60 border-x bg-white'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={selectedPeriod == item ? { borderBottom: `2px solid ${color}` } : {}}

            >
                <p className='text-center text-sm'
                    style={{
                        color: ((selectedPeriod && isHovered) || selectedPeriod === item) ? color : '',
                        fontWeight: ((selectedPeriod && isHovered) || selectedPeriod === item) ? 'bold' : 'normal'
                    }}
                >
                    {item?.periodoSubsidio}
                </p>
            </div>
        </button>

    )
}
