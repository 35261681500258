
import { useEffect, useState } from 'react'
import integrationApi from 'api/integrationApi';
import cartApi from 'api/cartApi';
import { ServiceRequest } from 'models/serviceRequest';

interface GenericData {
    identificationTypes?: any[],
    isLoadingGeneric?: boolean
    departmentsList?: any[];
    municipalityList?: any[];
    neighborhoodList?: any[];
    addressList?: any[];
    isLoadingDocumentType?: boolean;
    isLoadingDepartmentList?: boolean;
    isLoadingMunicipalityList?: boolean;
    isLoadingNeighborhoodsList?: boolean;
    isLoadingAddressesList?: boolean;
}

export const useGenericData = () => {


    const urlGeneric: string = "generico";

    const getIdentificationsTypes = async () => {

        const services = integrationApi.get(`${urlGeneric}/getListIdentificaciones`);

        const servicesResp = await Promise.resolve(
            services
        );

        return { identificationTypes: servicesResp?.data, isLoadingGeneric: false };

    }

    const getActiveMunicipalities = async () => {

        try {
            const municipalities = await cartApi.get(
                `municipio/municipioBuscador`
            );

            const municipalitiesResp = await Promise.resolve(municipalities);

            return { municipalityList: municipalitiesResp?.data, isLoadingMunicipalityList: false };
        } catch (error) {
            console.error('Error en la solicitud de red municipios', error);
        }

    };

    const getDeparmentList = async () => {
        try {
            const department = await integrationApi.get<any[]>(
                `${urlGeneric}/getListDepartamento`,
            );
            const departmentResp = await Promise.resolve(department);

            return {
                departmentsList: departmentResp?.data,
                isLoadingDepartmentList: false,
            };
        } catch (error) {
            console.error('Error en la solicitud de red departamentos', error);
        }
    };

    const getAddressesList = async () => {
        try {
            const address = await integrationApi.get<any[]>(
                `${urlGeneric}/getListDirecciones`,
            );
            const addressResp = await Promise.resolve(address);

            return {
                addressList: addressResp?.data,
                isLoadingAddressesList: false,
            };
        } catch (error) {
            console.error('Error en la solicitud de red direcciones', error);
        }
    };

    const getMunicipalitiesList = async (condicion?: string) => {
        if (condicion) {
            try {
                const municipalities = await integrationApi.post(
                    `${urlGeneric}/getListMunicipios`,
                    { condicion: condicion },
                );
                const municipalitiesResp = await Promise.resolve(municipalities);

                return municipalitiesResp?.data;
            } catch (error) {
                console.error('Error en la solicitud de red municipios', error);
            }
        }
    };

    const getNeighborhoodsList = async (condicion?: string) => {
        if (condicion) {
            try {
                const neighborhoods = await integrationApi.post(
                    `${urlGeneric}/getListBarrios`,
                    {
                        condicion: condicion,
                    },
                );
                const neighborhoodResp = await Promise.resolve(neighborhoods);

                return neighborhoodResp?.data;
            } catch (error) {
                console.error('Error en la solicitud de red barrios', error);
            }
        }
    };

    return {
        getIdentificationsTypes,
        getDeparmentList,
        getActiveMunicipalities,
        getAddressesList,
        getNeighborhoodsList,
        getMunicipalitiesList,
    }
}
