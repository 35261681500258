import React from "react";
import { Service } from "models/service";
import { Link } from "react-router-dom";
import { Category } from "models/category";
import { Divider } from "@mui/material";
interface CardProps {
  service: Service;
  selectedCategory?: Category;
  isLogged: boolean;
  showValue?: boolean;
  color?: string;
  screenType?: string
}

export const ServiceCard = ({
  service,
  selectedCategory,
  isLogged,
  showValue = false,
  color = "#000",
  screenType
}: CardProps) => {
  const textStyle = {
    color: color,
    fontWeight: "bold",
  };

  return (
    <Link id={`servicio-${service.idServicio}`} to={`/${screenType ? screenType : selectedCategory?.tipoPantalla}/${service?.idServicio}`}>
      <div className="w-72 md:w-[26rem] mx-4 rounded-lg pb-2 bg-white shadow-md shadow-black/10 border border-principal-350">
        <div
          className="w-full h-40 md:h-60 rounded-t-lg bg-cover"
          style={{ backgroundImage: `url(${service?.imagen})` }}
        />
        <div className="flex flex-wrap p-4 items-center h-26 md:h-[9rem]">
          <p className="w-full text-md font-bold text-principal-300">
            {service?.nombre}
          </p>
          <p className="w-full text-principal-300">
            {service?.nombreMunicipio}
          </p>
          {showValue && (
            <div className="w-full">
              <Divider orientation="horizontal" className="pb-2" flexItem />
            </div>
          )}
          <div className="w-full flex justify-end">
            {showValue && (
              <div>
                <p className="text-right" style={textStyle}>
                  {isLogged ? "Para ti" : "Desde"}
                </p>
                <p style={{ ...textStyle, direction: "rtl" }}>
                  $
                  {service?.valorTarifa
                    ? Number(service?.valorTarifa).toLocaleString("es-CO", {
                      currency: "COP",
                      minimumFractionDigits: 0,
                    })
                    : "0"}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};
