import React, { useCallback, useEffect, useState } from "react";
import { YOUTUBE_URL_FORMATS } from "utils/constants";

interface PostulationInfoProps {
  postulationVideoUrl: string;
  message: string;
}

export const PostulationInfo = ({
  postulationVideoUrl,
  message,
}: PostulationInfoProps) => {
  const extractVideoIdFromUrl = (url: string): string => {
    // List of supported YouTube URL formats
    const youtubeUrlFormats = YOUTUBE_URL_FORMATS;

    // Find the matching URL format
    const matchingFormat = youtubeUrlFormats.find((format) =>
      url?.includes(format)
    );

    if (matchingFormat) {
      // Extract the video ID from the URL
      const videoId = url.split(matchingFormat)[1].split("&")[0];
      return videoId;
    }

    // Return an empty string if no valid format is found
    return "";
  };

  const embededUrl = (url: string) => {
    const idVideo = extractVideoIdFromUrl(postulationVideoUrl);
    const embeded = "https://www.youtube.com/embed/" + idVideo;
    return embeded;
  };
  const VideoPlayer = () => {
    const videoUrl = embededUrl(postulationVideoUrl);

    return (
      <iframe
        className="w-full h-full"
        src={videoUrl}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    );
  };
  const [mobile, setMobile] = useState(window.innerWidth <= 500);
  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };
  // useEffect that detects changes resize from window
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div style={{ alignItems: "center" }} className="flex w-full">
      <div className="w-full flex items-center flex-wrap">
        <div className="p-5 w-full  md:w-1/2 lg:w-1/2 text-justify">
          <p>{message}</p>
        </div>
        <div
          className="p-5 w-full  md:w-1/2 lg:w-1/2 "
          style={{ height: !mobile ? "315px" : "100%" }}
        >
          <VideoPlayer></VideoPlayer>
        </div>
      </div>
    </div>
  );
};
