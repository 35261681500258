import { useState } from 'react';
import integrationApi from '../api/integrationApi';
import { MSchoolKitRequest, MSchoolKitResponse } from '../models/schoolKitModel';

export const useSchoolarKit = () => {
    const [isLoadingSchoolKit, setIsLoadinSchoolKit] = useState(true);

    const urlSchoolKit: string = "kitescolar";

    const getSchoolKitInfo = async (schoolKitRequest: MSchoolKitRequest) => {

        setIsLoadinSchoolKit(true);

        try {
            const schoolKit = await integrationApi.post<MSchoolKitResponse>(`${urlSchoolKit}/getInfoKit`, schoolKitRequest);

            setIsLoadinSchoolKit(false);

            return schoolKit?.data;

        } catch (error) {
            setIsLoadinSchoolKit(false);
            return null;
        }

    }

    return {
        isLoadingSchoolKit,
        getSchoolKitInfo
    }
}