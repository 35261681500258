import { HotelCard } from "components/HotelCard";
import { Paginatior } from "components/Paginatior";
import { SmallLoading } from "components/SmallLoading";
import { SpacesCard } from "components/SpacesCard";
import { useHotels } from "hooks/useHotels";
import { useSpaces } from "hooks/useSpaces";
import { Business } from "models/business";
import { Category } from "models/category";
import { MHotel } from "models/hotelsResponse";
import { MSpace, MSpacesResponse } from "models/spacesRequest";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "store";
import { IsCategoryAvailable } from "utils/CategoryAvailable";
import manosComfenalco from "../../../assets/images/manosComfenalco.png";


interface SpacesProps {
  selectedBusiness?: Business;
  selectedCategory?: Category;
  pageableParam: number;
}

export const Spaces = ({ selectedBusiness, selectedCategory, pageableParam }: SpacesProps) => {
  // const { bannerImage, isLoadingBanner } = useBanner();
  const { authData } = useSelector((state: RootState) => state.auth);

  const { getSpaces, isLoadingSpaces } = useSpaces();

  const [spaces, setSpaces] = useState<MSpace[]>([]);

  const [page, setPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);

  const isWithinRange = selectedCategory && IsCategoryAvailable(selectedCategory)

  useEffect(() => {
    const getSpacessList = async () => {
      const spaces = await getSpaces(page - 1, pageableParam);

      setTotalPages(spaces?.totalPages);
      setSpaces(spaces?.content);
    };

    getSpacessList();
  }, [page]);

  return (
    <div className="w-full px-4">
      {
        !isWithinRange ?
          <>
            {isLoadingSpaces ? (
              <div className="h-80 w-full">
                <SmallLoading />
              </div>
            ) : (
              <>
                {spaces?.length <= 0 ? (
                  <div className="flex justify-center h-80">
                    No hay espacios para mostrar.
                  </div>
                ) : (
                  <div className="flex flex-wrap justify-around py-6 ">
                    {spaces?.map((space) => (
                      <div className="py-3" key={space.idServicio}>
                        <SpacesCard
                          space={space}
                          screenType="spaces"
                          selectedBusiness={selectedBusiness}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </> :
          <div className="flex flex-col w-full items-center pt-4">
            <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
              <img
                src={manosComfenalco}
                className="h-16 w-16"
              />
              <p className="text-principal-600 font-bold">
                {selectedCategory?.mensajeInactividad && selectedCategory?.mensajeInactividad}
              </p>
            </div>
          </div>
      }
      {(spaces?.length > 0 && !isWithinRange) && (
        <div className="flex justify-center pb-4">
          <Paginatior totalPages={totalPages} page={page} setPage={setPage} />
        </div>
      )}
    </div>
  );
};
