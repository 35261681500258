
import { useEffect, useState } from 'react'
import { CampoJson, Service } from 'models/service';
import integrationApi from 'api/integrationApi';
import { useSelector } from 'react-redux';
import { RootState } from 'store';


interface ServicesState {
    upcomingEvents: Service[],
    bestsellersProducts: Service[],
    newsService: Service[],
    interestingProducts: Service[],
    isLoadingService: boolean
}

export const useService = () => {

    const { isLogged } = useSelector((state: RootState) => state.auth);

    const urlService: string = "servicios";

    const [servicesState, setServicesState] = useState<ServicesState>({
        upcomingEvents: [],
        bestsellersProducts: [],
        newsService: [],
        interestingProducts: [],
        isLoadingService: true
    });

    const getServices = async () => {
        
        setServicesState(prevState => ({
            ...prevState,
            isLoadingService: true
        }));

        const upComingPromise = integrationApi.post<Service[]>(`${urlService}/proximosEventos`, { idMunicipio: null });
        const bestsellersPromise = integrationApi.post<Service[]>(`${urlService}/productosMasVendidos`, { idMunicipio: null });
        const newsPromise = integrationApi.post<Service[]>(`${urlService}/serviciosNovedades`, { idMunicipio: null });

        const responses = await Promise.all([upComingPromise, bestsellersPromise, newsPromise])

        let interestingProducts: Service[] = [];

        if (isLogged) {

            const interestingPromise = await integrationApi.post<Service[]>(`${urlService}/productosDeInteres`, { idMunicipio: null });

            interestingProducts = interestingPromise?.data.map(service => {
                const campoJson: CampoJson = JSON.parse("" + service.campoJson);
                let eventService = service;
                eventService.campoJson = campoJson;
                return eventService;
            })
        }

        const upcomingEvents = responses[0]?.data.map(service => {
            const campoJson: CampoJson = JSON.parse("" + service.campoJson);
            let eventService = service;
            eventService.campoJson = campoJson;
            return eventService;
        })

        const bestsellers = responses[1]?.data.map(service => {
            const campoJson: CampoJson = JSON.parse("" + service.campoJson);
            let eventService = service;
            eventService.campoJson = campoJson;
            return eventService;
        })

        const news = responses[2]?.data.map(service => {
            const campoJson: CampoJson = JSON.parse("" + service.campoJson);
            let eventService = service;
            eventService.campoJson = campoJson;
            return eventService;
        })

        setServicesState({
            upcomingEvents: upcomingEvents,
            bestsellersProducts: bestsellers,
            newsService: news,
            interestingProducts: interestingProducts,
            isLoadingService: false
        })
    }

    useEffect(() => {
        getServices();
    }, [isLogged]);

    return {
        ...servicesState
    }
}
