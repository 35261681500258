import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import BookOnlineOutlinedIcon from "@mui/icons-material/BookOnlineOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import "./styles/aside.css";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { TIPO_USUARIO } from "utils/constants";

interface AsideProps {
  openModal: boolean;
  handleToggle: Function;
}

export const Aside = ({ openModal, handleToggle }: AsideProps) => {
  const { authData, isLogged } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();

  const handleNavigate = (rute: string) => {
    navigate(rute);
  };

  return (
    <Drawer anchor="left" open={openModal} onClose={() => handleToggle()}>
      <Box
        sx={{ width: 250, height: "100%" }}
        role="presentation"
        onClick={() => handleToggle()}
        onKeyDown={() => handleToggle()}
      >
        <List className="flex flex-col justify-center pr-4 h-[70%]">
          {/* Inicio */}
          <ListItem disablePadding className="mb-2 pr-4">
            <ListItemButton
              onClick={() => handleNavigate("/home")}
              className="rounded-r-full hover:rounded-r-full"
            >
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItemButton>
          </ListItem>

          {/* Tarjeta virtual */}
          {isLogged &&
            <ListItem
              disablePadding
              className="mb-2 pr-4 rounded-r-full"
            >
              <ListItemButton
                onClick={() => handleNavigate("/carnet")}
                className="rounded-r-full hover:rounded-r-full"
              >
                <ListItemIcon>
                  <CreditCardIcon />
                </ListItemIcon>
                <ListItemText primary="Tarjeta virtual Delagente" />
              </ListItemButton>
            </ListItem>
          }

          {/* PQR's */}
          <ListItem disablePadding className="mb-2 pr-4 rounded-r-full">
            <ListItemButton
              onClick={() => handleNavigate("/tickets")}
              className="rounded-r-full hover:rounded-r-full"
            >
              <ListItemIcon>
                <SupportAgentIcon />
              </ListItemIcon>
              <ListItemText primary="Consulta de PQRs" />
            </ListItemButton>
          </ListItem>

          {/* Historial de compra */}
          {
            (isLogged && authData?.tipoUsuario !== TIPO_USUARIO) &&
            <ListItem
              disablePadding
              className="mb-2 pr-4 rounded-r-full"
            >
              <ListItemButton
                onClick={() => handleNavigate("/historial-compras")}
                className="rounded-r-full hover:rounded-r-full"
              >
                <ListItemIcon>
                  <ReceiptLongIcon />
                </ListItemIcon>
                <ListItemText primary="Historial de compras" />
              </ListItemButton>
            </ListItem>
          }

          {/* Agenda tu cita */}
          <ListItem disablePadding className="mb-2 pr-4 rounded-r-full">
            <ListItemButton
              onClick={() => handleNavigate("/schedule")}
              className="rounded-r-full hover:rounded-r-full"
            >
              <ListItemIcon>
                <BookOnlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Agenda tu cita" />
            </ListItemButton>
          </ListItem>
        </List>

        <List className="flex flex-col justify-center pr-4 h-[30%]">
          {/* Politica de tratamientos */}
          <ListItem disablePadding className="mb-1 pr-4 rounded-r-full">
            <a href="https://www.comfenalcovalle.com.co/wp-content/uploads/2020/03/Politica-Tratamiento-Informacion-ComfenalcoValle-25-Feb-2020.pdf" target="_blank" rel="noopener noreferrer">
              <ListItemButton
                className="rounded-r-full hover:rounded-r-full"
              >
                <ListItemText primary="Política de tratamiento" />
              </ListItemButton>
            </a>
          </ListItem>

          {/* Condiciones */}
          <ListItem disablePadding className="mb-1 pr-4 rounded-r-full">
            <a href="https://www.comfenalcovalle.com.co/wp-content/uploads/2020/08/Condiciones-devoluciones-ventas-App-Comfenalco-Valle-delagente-V3-06-agosto-2020.pdf" target="_blank" rel="noopener noreferrer">
              <ListItemButton
                onClick={() => handleNavigate("/tickets")}
                className="rounded-r-full hover:rounded-r-full"
              >
                <ListItemText primary="Condiciones y devoluciones" />
              </ListItemButton>
            </a>
          </ListItem>

          {/* Terminos*/}
          <ListItem disablePadding className="mb-1 pr-4 rounded-r-full text-sm">
            <a href="https://www.comfenalcovalle.com.co/wp-content/uploads/2022/03/Terminos-y-condiciones-uso-de-plataforma-Comfenalco-Valle-delagente-V2-06-agosto-2020.pdf" target="_blank" rel="noopener noreferrer">
              <ListItemButton
                onClick={() => handleNavigate("/tickets")}
                className="rounded-r-full hover:rounded-r-full"
              >
                <ListItemText
                  style={{ fontSize: "0.8rem" }}
                  primary="Términos y condiciones"
                />
              </ListItemButton>
            </a>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};
