import { useState } from "react";
import carApi from "../api/cartApi";
import integrationApi from "../api/integrationApi";
import { ShoppingCarResponse } from "models/salesShoppingCar";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { setSuccess } from "store/slices/status";
import { HotelReservationResponse } from "models/hotelReservationsResponse";
import { ABREVIACION_ORIGEN_WEB } from "utils/constants";
import { setCountSales } from "store/slices/salesCount";
import { ShoppingCartWithValue } from "models/lightForm";

export const useShoppingCar = () => {
  const dispatch = useDispatch<AppDispatch>();

  const urlShoppingCar: string = "compra";

  const [isLoadingShoppingCar, setIsLoadingShoppingCar] = useState(true);

  const salesShoppingCart = async (pageNumber: number, pageSize: number) => {
    setIsLoadingShoppingCar(true);

    const salesShoppingCar = carApi.post<ShoppingCarResponse>(
      `${urlShoppingCar}/getSalesShoppingCart`,
      { pageNumber: pageNumber, pageSize: pageSize }
    );

    const salesShoppingCarResp = await Promise.resolve(salesShoppingCar);

    setIsLoadingShoppingCar(false);
    return salesShoppingCarResp?.data;
  };

  const hotelReservations = async (pageNumber: number, pageSize: number) => {
    setIsLoadingShoppingCar(true);

    const salesShoppingCar = await carApi.post<HotelReservationResponse>(
      `${urlShoppingCar}/getReservasHoteles`,
      { pageNumber: pageNumber, pageSize: pageSize }
    );

    setIsLoadingShoppingCar(false);
    return salesShoppingCar?.data;
  };

  const deleteSaleShoppingCart = async (idSale: number) => {
    setIsLoadingShoppingCar(true);

    const salesShoppingCar = integrationApi.post<any>(
      `${urlShoppingCar}/eliminarCompraCarrito`,
      { idCompra: idSale }
    );

    const salesShoppingCarResp = await Promise.resolve(salesShoppingCar);

    setIsLoadingShoppingCar(false);

    if (salesShoppingCarResp?.status === 200) {
      dispatch(setSuccess("Se ha eliminado el item correctamente"));
      setTimeout(() => {
        dispatch(setSuccess(null));
      }, 6000);
      return true;
    }
  };

  const paySale = async (idCompra: number) => {
    const dataSend = {
      comprasDtos: [
        {
          idCompra: idCompra,
        },
      ],
      afiliadoDTO: {
        abreviatura: "",
        identificacion: "",
        primerNombre: "",
        segundoNombre: "",
        primerApellido: "",
        segundoApellido: "",
        fechaNacimiento: "",
        email: "",
        categoria: "",
        celular: "",
        telefonoResidencia: "",
        sexo: "",
      },
      origenCompra: ABREVIACION_ORIGEN_WEB,
    };

    const paySaleResult = integrationApi.post<any>(
      `${urlShoppingCar}/paySale`,
      dataSend
    );

    const salesShoppingCarResp = await Promise.resolve(paySaleResult);
    if (salesShoppingCarResp?.status === 200) {
      dispatch(setSuccess("Redireccionando a la pasarela"));
      setTimeout(() => {
        dispatch(setSuccess(null));
      }, 6000);
      return salesShoppingCarResp?.data;
    }
  };

  const getTotalCarritoCompras = async () => {
    const paySaleResult = await carApi.get<any>(
      `${urlShoppingCar}/getTotalCarritoCompras`
    );
    if (paySaleResult) {
      dispatch(setCountSales(paySaleResult.data.totalItems));
    }
  };


  const shoppingCartWithValueLightForm = async (shoppingCartRequest: ShoppingCartWithValue) => {
    console.log("shoppingCartRequest",shoppingCartRequest);
    
    try {
      const salesShoppingCar = integrationApi.post<any>(
        `${urlShoppingCar}/shoppingCarEducacionRecreacion`,
        shoppingCartRequest
      );

      const salesShoppingCarResp = await Promise.resolve(salesShoppingCar);
      return salesShoppingCarResp.data

    } catch (error) {

    }

  }





  return {
    isLoadingShoppingCar,
    salesShoppingCart,
    deleteSaleShoppingCart,
    hotelReservations,
    paySale,
    getTotalCarritoCompras,
    shoppingCartWithValueLightForm
  };
};
