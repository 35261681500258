import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ReactElement, useState } from 'react';
import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import clsx from 'clsx';
import { BannerData } from "models/banner";

interface BannerProps {
  bannerImage: any[];
}

export const Banner = ({ bannerImage }: BannerProps) => {

  const [isArrowHovered, setIsArrowHovered] = useState<'prev' | 'next' | null>(null);

  const handleArrowMouseEnter = (arrow: 'prev' | 'next') => {
    setIsArrowHovered(arrow);
  };

  const handleArrowMouseLeave = () => {
    setIsArrowHovered(null);
  };

  const renderCustomPrevArrow = (
    onClickHandler: () => void,
    hasPrev: boolean,
    label: string
  ): ReactElement | null => {
    if (!hasPrev) {
      return null;
    }

    return (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        onMouseEnter={() => handleArrowMouseEnter('prev')}
        onMouseLeave={handleArrowMouseLeave}
        className={clsx(
          'absolute top-1/2 left-4 transform -translate-y-1/2 z-10 cursor-pointer bg-transparent border-none outline-none transition-opacity',
          {
            'opacity-50': !isArrowHovered || isArrowHovered === 'next',
            'opacity-100': isArrowHovered === 'prev',
          }
        )}
      >
        <ArrowBackIosIcon className="w-6 h-6 text-white" />
      </button>
    );
  };

  const renderCustomNextArrow = (
    onClickHandler: () => void,
    hasNext: boolean,
    label: string
  ): ReactElement | null => {
    if (!hasNext) {
      return null;
    }

    return (
      <button
        type="button"
        onClick={onClickHandler}
        title={label}
        onMouseEnter={() => handleArrowMouseEnter('next')}
        onMouseLeave={handleArrowMouseLeave}
        className={clsx(
          'absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer bg-transparent border-none outline-none transition-opacity',
          {
            'opacity-50': !isArrowHovered || isArrowHovered === 'prev',
            'opacity-100': isArrowHovered === 'next',
          }
        )}
      >
        <ArrowForwardIosIcon className="w-6 h-6 text-white" />
      </button>
    );
  };

  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      showThumbs={false}
      className="h-fit"
      renderArrowPrev={renderCustomPrevArrow}
      renderArrowNext={renderCustomNextArrow}
    >
      {bannerImage?.map((images, id) => (
        <div key={id}>
          <img src={images.dataImagen} alt={`Banner ${id}`} />
        </div>
      ))}
    </Carousel>
  );
};
