import { Loading } from 'components/Loading';
import { useParam } from 'hooks/useParam';
import { MHousingSubsidy, MHousingSubsidyRequest } from 'models/housingSubsidyModel';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { PARAM_LINK_NO_AGENDAR_CITA } from 'utils/constants';
import manosComfenalco from "../../../assets/images/manosComfenalco.png";

export const ScheduleAppointment = () => {

    const [urlParam, setUrlParam] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const { getParamData } = useParam();

    useEffect(() => {

        const getInfo = async () => {
            const info = await getParamData(PARAM_LINK_NO_AGENDAR_CITA);
            let url = info?.data.valorParametro!;
            setUrlParam(url);
            setIsLoading(false);
        }

        getInfo();

    }, [])

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    {urlParam != "" ?
                        (
                            <div className='w-full h-full'>
                                <iframe src={urlParam} 
                            className='w-full '
                            height={'850px'}
                            allowFullScreen
                                title={'Turnero'}>
                            </iframe>
                            </div>
                        ) :
                        (
                            <div className='flex flex-col w-full items-center pt-4'>
                    <div className='flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2'>
                        <img src={manosComfenalco} className="h-16 w-16" />
                        <p className='text-principal-650 font-bold' >Error al consultar la información para agendamiento. </p>
                    </div>
                </div>
                        )
                    }
                </>
            )

            }
        </>
    );
}