import React from "react";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@mui/material";

interface RatesModalProps {
    open: boolean;
    onClose: () => void;
    infoAsesor: any
}

export const DetailModal = ({
    open,
    onClose,
    infoAsesor
}: RatesModalProps) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth={true}>
            <DialogTitle className="flex items-center font-bold text-principal-550">
                <LocalOfferOutlinedIcon className="mr-2" />
                Información Consultor Financiero
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            ></IconButton>
            <DialogContent dividers>
                <div className="w-full mx-auto mt-2 px-4 py-1">
                    <p className="py-2">Comunícate con tu Consultor Financiero quien te brindara asesoría</p>
                    <div className="relative flex flex-col break-words bg-white w-full shadow-lg rounded ">
                        <div className="block w-full max-h-72 overflow-auto">
                            <table className="items-center bg-transparent w-full">
                                <tr>
                                    <th className={`align-middle border border-solid py-3 text-principal-250 border-l-0 border-r-0 whitespace-nowrap font-semibold text-center`}>
                                        Nombre
                                    </th>
                                    <td className={`align-middle border border-solid py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-center`}>
                                        {infoAsesor?.asesorFinal}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={`align-middle border border-solid py-3 text-principal-250 border-l-0 border-r-0 whitespace-nowrap font-semibold text-center`}>
                                        Celular
                                    </th>
                                    <td className={`align-middle border border-solid py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-center`}>
                                        {infoAsesor?.celularAsesor}
                                    </td>
                                </tr>
                                <tr>
                                    <th className={`align-middle border border-solid py-3 text-principal-250 border-l-0 border-r-0 whitespace-nowrap font-semibold text-center`}>
                                        Correo
                                    </th>
                                    <td className={`align-middle border border-solid py-3 border-l-0 border-r-0 whitespace-nowrap font-semibold text-center`}>
                                        {infoAsesor?.correoAsesor}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};
