import { LocationOn } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useGenericData } from "hooks/useGenericData";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "store";
import { setCity } from "store/slices/searchServices";

interface LocationProps {
  styles: string;
}

export const LocationSelect = ({ styles }: LocationProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const city = useSelector((state: RootState) => state.searchServices.city);

  const navigate = useNavigate();

  const [municipalityList, setMunicipalityList] = useState<any>([]);

  const { getActiveMunicipalities } = useGenericData();

  useEffect(() => {
    const getMunicipalities = async () => {
      const activeMunicipalities = await getActiveMunicipalities();
      setMunicipalityList(activeMunicipalities?.municipalityList);
    };

    getMunicipalities();
  }, []);

  const onChange = (data: any) => {
    navigate("/search");
    dispatch(setCity(data?.target?.value));
  };

  return (
    <div className={styles}>
      <FormControl className="w-full" size="small">
        <InputLabel id="Elige-tu-ciudad">
          <LocationOn /> Elige tu ciudad
        </InputLabel>
        <Select
          labelId="Elige-tu-ciudad"
          label={`LogoElige tu ciudad`}
          defaultValue={city ? city : ""}
          onChange={onChange}
        >
          <MenuItem value={""}>Elige tu ciudad</MenuItem>
          {municipalityList?.map((city: any, index: any) => (
            <MenuItem key={index} value={city?.idMunicipio}>
              {city?.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
