import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface statusState {
    successMessage: string | null
    errorMessage: string | null,
}

const initialState: statusState = {
    successMessage: null,
    errorMessage: null,
}

export const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        setError: (state, action: PayloadAction<string | null>) => {
            state.errorMessage = action.payload
        },
        setSuccess: (state, action: PayloadAction<string | null>) => {
            state.successMessage = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setError, setSuccess } = statusSlice.actions