

// obtener la edad a partir de una fecha de nacimiento
export const getAgeFromDateString = (date: string) => {
    // param format
    // anio-mes-dia

    // get edad
    const today = new Date();
    const birthDate = new Date(date);
    let age: number = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}


export const getAgeFromDate = (date: Date) => {

    // get edad
    const today = new Date();
    const birthDate = date;
    let age: number = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;

}
