import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import manosComfenalco from '../../../assets/images/manosComfenalco.png';
import { useBalances } from 'hooks/useBalance';
import { MBalances } from 'models/balances';
import { SmallLoading } from 'components/SmallLoading';
import { Category } from 'models/category';
import { IsCategoryAvailable } from 'utils/CategoryAvailable';

interface BalancesProps {
    selectedCategory?: Category;
}

export const Balances = ({ selectedCategory }: BalancesProps) => {
    const credentials = useSelector((state: RootState) => state.credentials.credentials);

    const { isLogged, authData } = useSelector((state: RootState) => state.auth);

    const { getBalances, isLoadingBalances } = useBalances();
    const [balances, setBalances] = useState<MBalances[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const isWithinRange = selectedCategory && IsCategoryAvailable(selectedCategory)


    useEffect(() => {
        const getLocalBalances = async () => {

            const balances = await getBalances(authData?.afiliado?.abreviatura, authData?.afiliado?.identificacion);

            setBalances(balances?.creditos?.credito)
        }

        isLogged ? getLocalBalances() : setIsLoading(false);
    }, [])

    return (
        <>
            {isLogged ?
                <>
                    {
                        !isWithinRange ?
                            <>
                                {isLoadingBalances ? <SmallLoading /> :
                                    <div className='flex flex-wrap justify-around pt-8 text-2xl'>
                                        {
                                            balances?.length > 0 ? <>
                                                {balances?.map((balance, index) => (
                                                    <div className='flex flex-col justify-center items-center w-3/10 bg-white border shadow p-8' key={index}>
                                                        <p className='font-bold text-principal-600'>{balance.nombreTipoCredito}</p>
                                                        <Typography className='text-2xl' color='primary'>Saldo</Typography>
                                                        {
                                                            balances &&
                                                            <Typography className='text-2xl' color='primary'>{Number(balance.saldo).toLocaleString('es-CO', {
                                                                style: 'currency',
                                                                currency: 'COP',
                                                                minimumFractionDigits: 0,
                                                            })}</Typography>
                                                        }
                                                    </div>
                                                ))}
                                            </> :
                                                <div className='flex flex-col w-full items-center pt-4'>
                                                    <div className='flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2'>
                                                        <img src={manosComfenalco} className="h-16 w-16" />
                                                        <p className='text-principal-600 font-bold'>{credentials?.noExistenCreditos}</p>
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                }
                            </> :
                            <div className="flex flex-col w-full items-center pt-4">
                                <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                                    <img
                                        src={manosComfenalco}
                                        className="h-16 w-16"
                                    />
                                    <p className="text-principal-600 font-bold">
                                        {selectedCategory?.mensajeInactividad && selectedCategory?.mensajeInactividad}
                                    </p>
                                </div>
                            </div>
                    }
                </>
                :
                <div className='flex flex-col w-full items-center pt-4'>
                    <div className='flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2'>
                        <img src={manosComfenalco} className="h-16 w-16" />
                        <p className='text-principal-600 font-bold'>{credentials?.inicioSesionRequerido}</p>
                    </div>
                </div>}
        </>
    )
}
