import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useGenericData } from "hooks/useGenericData";
import { useRates } from "hooks/useRates";
import {
  ReservationForm,
  MSaleWithOutLoginForm,
  PurchaseForm,
} from "models/purchaseForm";
import { ServiceData } from "models/serviceData";
import { ServiceRates } from "models/servicesRates";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { setIndividualUserData } from "store/slices/individualSale";

export const SaleWithOutLoginForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoadingRates } = useRates();

  const [identificationTypes, setIdentificationTypes] = useState<any[]>([]);

  const { getIdentificationsTypes } = useGenericData();

  useEffect(() => {
    const onGetGetIdentificationsTypes = async () => {
      const identificationTypesResp = await getIdentificationsTypes();
      setIdentificationTypes(
        identificationTypesResp?.identificationTypes
          ? identificationTypesResp?.identificationTypes
          : []
      );
    };
    onGetGetIdentificationsTypes();
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<MSaleWithOutLoginForm>();

  const handleFormSubmit: SubmitHandler<MSaleWithOutLoginForm> = async (
    data: MSaleWithOutLoginForm
  ) => {
    dispatch(setIndividualUserData(data));
  };

  return (
    <div className="w-full">
      <Typography className="font-bold text-xl" color="primary">
        Identificate para acceder al servicio
      </Typography>
      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="flex flex-wrap"
      >
        <div className="w-full sm:w-1/2 px-1 py-3">
          <FormControl className="w-full">
            <InputLabel id="tipo-identificacion">
              Tipo de identificación
            </InputLabel>
            <Select
              labelId="tipo-identificacion"
              label="Tipo de identificación"
              defaultValue={""}
              {...register(`documentType`, {
                required: "El tipo de identificación es obligatorio",
              })}
            >
              {identificationTypes?.map((id, index) => (
                <MenuItem key={index} value={id?.ABREVIATURA}>
                  {id?.DESCRIPCION}
                </MenuItem>
              ))}
            </Select>
            {errors?.documentType && (
              <p className="text-red-500">
                {String(errors?.documentType?.message)}
              </p>
            )}
          </FormControl>
        </div>
        <div className="w-full sm:w-1/2 px-1 py-3">
          <TextField
            defaultValue={""}
            className="w-full"
            label="Número de documento"
            {...register(`documentNumber`, {
              required: "El número de documento es obligatorio",
            })}
          />
          {errors?.documentNumber && (
            <p className="text-red-500">
              {String(errors?.documentNumber?.message)}
            </p>
          )}
        </div>

        <LoadingButton
          type="submit"
          variant="contained"
          className="w-1/2"
          loading={isLoadingRates}
        >
          Traer grupo
        </LoadingButton>
      </form>
    </div>
  );
};
