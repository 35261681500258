import { CustomDialog } from 'components/Dialog';
import React, { useCallback, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import postulationBackground from "../../../assets/images/postulation.png";
import { Card } from '@mui/material';

interface FormCardPostulationProps {
    canApply: boolean;
    postulationMessage: string;
    postulationShortMessage: string;
    color: string;
    formHousingUrl: string;
}

export const FormCardPostulation = ({
    canApply,
    postulationMessage,
    postulationShortMessage,
    color,
    formHousingUrl,
}: FormCardPostulationProps) => {

    const [openModal, setOpenModal] = useState(false);
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');


    const handlePress = useCallback(async () => {
        if (canApply) {
            // Checking if the link is supported for links with custom URL scheme.
            const supported = regex.test(formHousingUrl!);
            if (supported) {
                // Opening the link with some app, if the URL scheme is "http" the web link should be opened
                // by some browser in the mobile
                window.open(formHousingUrl!, "_blank", "noreferrer");
            } else {
                CustomDialog({
                    open: true,
                    onClose: () => { },
                    onConfirm: () => { },
                    title: "Error",
                    message: "La url que estás intentando abrir no es válida",
                    messageOnClose: "Cerrar",
                    messageOnConfirm: "",
                    onlyClose: true
                })
            }
        } else {
            setOpenModal(true);
        }
    }, [formHousingUrl]);

    return (
        <Card style={{ alignItems: 'center' }} className='flex m-5 w-full flex-wrap' >
            <div className='flex w-full flex-wrap divide-y '>
                <img src={postulationBackground} className='rounded-t-md flex w-full' />
                <div className='flex flex-nowrap py-5 w-full justify-center items-center '>
                    <InfoIcon sx={{ fontSize: 30, color: "#e17000" }} />
                    <p className='flex px-2'>{postulationShortMessage}</p>
                </div>
                <div className='flex w-full justify-center items-center bg-principal-550'>
                    <button
                        color={color}
                        onClick={handlePress}
                        className='flex font-semibold text-white w-full justify-center items-center py-2'>
                        Postulate al subsidio de vivienda
                    </button>
                </div>
            </div>
            {openModal && (
                CustomDialog({
                    open: true,
                    onClose: () => setOpenModal(false),
                    onConfirm: () => setOpenModal(false),
                    title: postulationShortMessage,
                    message: postulationMessage,
                    messageOnClose: "Aceptar",
                    messageOnConfirm: "",
                    onlyClose: true
                })
            )}
        </Card>
    );
};