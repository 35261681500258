import { useState } from 'react';
import integrationApi from '../api/integrationApi';
import { RoomsRequest } from 'models/roomsRequest';
import { MRoom } from 'models/room';
import { LiquidationPlanRequest } from 'models/liquidationPlanRequest';

export const useRoomsAvailable = () => {

    const [isLoadingRooms, setIsLoadingRooms] = useState(true);
    const [isLoadingLiquidation, setIsLoadingLiquidation] = useState(true);

    const urlHotel: string = "hotel";

    const getRoomsAvailable = async (roomsRequest: RoomsRequest) => {

        setIsLoadingRooms(true);

        const rooms = await integrationApi.post<MRoom[]>(`${urlHotel}/getHabitacionesDisponibles`, roomsRequest);

        setIsLoadingRooms(false);

        return rooms?.data;
    }

    const getLiquidationPlan = async (liquidationPlanRequest: LiquidationPlanRequest) => {

        setIsLoadingLiquidation(true);

        const liquidation = await integrationApi.post<any>(`${urlHotel}/getLiquidationPlan`, liquidationPlanRequest);

        setIsLoadingRooms(false);

        return liquidation?.data;
    }

    return {
        isLoadingRooms,
        getRoomsAvailable,
        getLiquidationPlan,
        isLoadingLiquidation
    }
}