import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { MHotel } from 'models/hotelsResponse';
import { Category } from 'models/category';
import { Business } from 'models/business';
interface CardProps {
    hotel: MHotel
    screenType?: string
    selectedBusiness?: Business;
}

export const HotelCard = ({ hotel, screenType, selectedBusiness }: CardProps) => {
    const navigate = useNavigate();

    const handdleNavigate = () => {
        navigate(`/${screenType}/${hotel?.idCentroServicio}`, { state: { color: selectedBusiness?.color } })
    }
    return (
        <div className='w-72 md:w-[26rem] mx-4 rounded-lg pb-2 bg-white shadow-md shadow-black/10 border border-principal-350 cursor-pointer' onClick={() => handdleNavigate()}>
            <div className='w-full h-40 md:h-60 rounded-t-lg bg-cover' style={{ backgroundImage: `url(${hotel?.imagen})` }} />
            <div
                className="flex flex-wrap p-4 items-center h-24 md:h-20"
            >
                <p className='w-full text-md font-bold text-principal-300'>{hotel?.nombre}</p>
                <p className='w-full text-principal-300'>{hotel?.nombreMunicipio}</p>
            </div>
        </div>
    )
}
