import React, { useEffect, useState } from 'react';
import integrationApi from '../api/integrationApi';
import { ValidadorCaja } from '../models/boxValidatorModel';

export const useBoxValidator = () => {

    const [isLoadingBoxValidator, setIsLoadingBoxValidator] = useState(false);
    const urlBoxValidator = 'auth';

    const getBoxValidator = async (tipoIdentificacion: string, identificacion: string) => {

        setIsLoadingBoxValidator(true);

        const boxValidator = await integrationApi.post<ValidadorCaja>(`${urlBoxValidator}/consultaValidadorDerechos`, { tipoIdentificacion: tipoIdentificacion, identificacion: identificacion });

        setIsLoadingBoxValidator(false);

        return boxValidator?.data;
    }

    return {
        getBoxValidator,
        isLoadingBoxValidator
    };
};