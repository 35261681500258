import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useGenericData } from "hooks/useGenericData";
import { PurchaseForm } from "models/purchaseForm";
import React, { useEffect, useState } from "react";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import moment from 'moment';
import 'moment/locale/es';

interface AditionalFormProps {
  register: UseFormRegister<PurchaseForm>;
  formIndex: number;
  errors: FieldErrors<PurchaseForm>;
  setValue: UseFormSetValue<PurchaseForm>;
  idTarifa: number;
}

export const AditionalFormService = ({
  register,
  formIndex,
  errors,
  setValue,
  idTarifa,
}: AditionalFormProps) => {
  moment.locale('es-co');

  const [identificationTypes, setIdentificationTypes] = useState<any[]>([]);

  const { getIdentificationsTypes } = useGenericData();

  useEffect(() => {
    const onGetGetIdentificationsTypes = async () => {
      const identificationTypesResp = await getIdentificationsTypes();
      setIdentificationTypes(
        identificationTypesResp?.identificationTypes
          ? identificationTypesResp?.identificationTypes
          : []
      );
    };
    onGetGetIdentificationsTypes();
  }, []);

  const handleDateChange = (date: Date | null) => {
    // Con setValue, actualizamos el valor del campo 'selectedDate'
    setValue(`aditionalForm.${formIndex}.birthDate`, date);
  };

  useEffect(() => {
    setValue(`aditionalForm.${formIndex}.idTarifa`, idTarifa);
  }, []);

  return (
    <div className="flex flex-wrap w-full">
      <div className="w-full w-full sm:w-1/2 px-1 py-3">
        <FormControl className="w-full">
          <InputLabel id="tipo-identificacion">
            Tipo de identificación
          </InputLabel>
          <Select
            labelId="tipo-identificacion"
            label={`Tipo de identificación`}
            defaultValue={undefined}
            {...register(`aditionalForm.${formIndex}.documentType`, {
              required: "El tipo de identificación es obligatorio",
            })}
          >
            {identificationTypes?.map((id, index) => (
              <MenuItem key={index} value={id?.ABREVIATURA}>
                {id?.DESCRIPCION}
              </MenuItem>
            ))}
          </Select>
          {errors.aditionalForm &&
            errors.aditionalForm[formIndex]?.documentType && (
              <p className="text-red-500">
                {String(
                  errors?.aditionalForm[formIndex]?.documentType?.message
                )}
              </p>
            )}
        </FormControl>
      </div>
      <div className="w-full w-full sm:w-1/2 px-1 py-3">
        <TextField
          className="w-full"
          label="Número de documento"
          {...register(`aditionalForm.${formIndex}.documentNumber`, {
            required: "El número de documento es obligatorio",
          })}
        />
        {errors.aditionalForm &&
          errors?.aditionalForm[formIndex]?.documentNumber && (
            <p className="text-red-500">
              {String(
                errors?.aditionalForm[formIndex]?.documentNumber?.message
              )}
            </p>
          )}
      </div>
      <div className="w-full sm:w-1/2 px-1 py-3">
        <TextField
          defaultValue={undefined}
          className="w-full"
          label="Nombre del particular"
          {...register(`aditionalForm.${formIndex}.firstName`, {
            required: "El nombre es obligatorio",
          })}
        />
        {errors.aditionalForm &&
          errors?.aditionalForm[formIndex]?.firstName && (
            <p className="text-red-500">
              {String(errors?.aditionalForm[formIndex]?.firstName?.message)}
            </p>
          )}
      </div>
      <div className="w-full sm:w-1/2 px-1 py-3">
        <TextField
          defaultValue={undefined}
          className="w-full"
          label="Apellido del particular"
          {...register(`aditionalForm.${formIndex}.secondName`, {
            required: "El apellido es obligatorio",
          })}
        />
        {errors.aditionalForm &&
          errors?.aditionalForm[formIndex]?.secondName && (
            <p className="text-red-500">
              {String(errors?.aditionalForm[formIndex]?.secondName?.message)}
            </p>
          )}
      </div>
      <div className="w-full w-full sm:w-1/2 px-1 py-3">
        <FormControl className="w-full">
          <InputLabel id="genero">Género</InputLabel>
          <Select
            labelId="genero"
            label="Género"
            defaultValue={undefined}
            {...register(`aditionalForm.${formIndex}.gender`, {
              required: "El género es obligatorio",
            })}
          >
            <MenuItem value="M">Masculino</MenuItem>
            <MenuItem value="F">Femenino</MenuItem>
          </Select>
        </FormControl>
        {errors.aditionalForm && errors?.aditionalForm[formIndex]?.gender && (
          <p className="text-red-500">
            {String(errors?.aditionalForm[formIndex]?.gender?.message)}
          </p>
        )}
      </div>
      <div className="w-full sm:w-1/2 mt-3 px-1">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            defaultValue={null}
            className="w-full"
            format="DD/MM/YYYY"
            disableFuture={true}
            label="Fecha de nacimiento"
            slots={{
              ...register(`aditionalForm.${formIndex}.birthDate`, {
                required: "La fecha de nacimiento es obligatoria",
              }),
            }}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
        {errors.aditionalForm &&
          errors?.aditionalForm[formIndex]?.birthDate && (
            <p className="text-red-500">
              {String(errors?.aditionalForm[formIndex]?.birthDate?.message)}
            </p>
          )}
      </div>
      <div className="w-full sm:w-1/2 px-1 py-3">
        <TextField
          defaultValue={undefined}
          className="w-full"
          label="Correo electrónico"
          {...register(`aditionalForm.${formIndex}.email`, {
            required: "El correo electrónico es obligatorio",
          })}
        />
        {errors.aditionalForm && errors?.aditionalForm[formIndex]?.email && (
          <p className="text-red-500">
            {String(errors?.aditionalForm[formIndex]?.email?.message)}
          </p>
        )}
      </div>
      <div className="w-full sm:w-1/2 px-1 py-3">
        <TextField
          defaultValue={undefined}
          className="w-full"
          label="Celular"
          {...register(`aditionalForm.${formIndex}.cellPhone`, {
            required: "El número de celular es obligatorio",
          })}
        />
        {errors.aditionalForm &&
          errors?.aditionalForm[formIndex]?.cellPhone && (
            <p className="text-red-500">
              {String(errors?.aditionalForm[formIndex]?.cellPhone?.message)}
            </p>
          )}
      </div>
    </div>
  );
};
