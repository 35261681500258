import React from "react";
import User from "../../assets/images/iconUser.svg";
import { Tab, Tabs, ThemeProvider, createTheme } from "@mui/material";
import { Beneficiary } from "models/Beneficiary";

interface ListBeneficiaryProps {
  list: Beneficiary[];
  onChangeBenficiary: (i: number) => void;
  valueSelected: string;
}

const orangeTheme = createTheme({
  palette: {
    primary: {
      main: "#e17000",
    },
  },
});
const ListAffiliates = ({
  list,
  onChangeBenficiary,
  valueSelected,
}: ListBeneficiaryProps) => {
  return (
    <ThemeProvider theme={orangeTheme}>
      <Tabs
        className="w-full justify-center"
        value={valueSelected}
        orientation="vertical"
        sx={{ borderRight: 1, borderColor: "divider" }}
        variant="scrollable"
        onChange={(event: React.SyntheticEvent, newValue: number) =>
          onChangeBenficiary(newValue)
        }
      >
        {list.map((data: any, index: number) => (
          <Tab
            key={index}
            value={index + ""}
            className="flex justify-start w-full"
            iconPosition="start"
            icon={<img alt="user icon" src={User} className="mr-6" />}
            label={data.nombre}
          ></Tab>
        ))}
      </Tabs>
    </ThemeProvider>
  );
};

export default ListAffiliates;
