import React, { useEffect, useState } from "react";
import { Rates } from "./Rates";
import { Beneficiary } from "models/Beneficiary";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { ServiceData } from "models/serviceData";
import {
  ABREVIACION_ORIGEN_WEB,
  COMPRA_INDIVIDUAL_ACTIVA,
  OPORTUNIDAD,
  ORIGEN_WEB,
  PARAM_MENSAJE_CUOTAS_EDUCACION,
  TIPO_USUARIO,
} from "utils/constants";
import { ServiceRates } from "models/servicesRates";
import { UnavailableDates } from "models/uavailableDate";
import { useForm, SubmitHandler } from "react-hook-form";
import { PurchaseForm } from "models/purchaseForm";
import { CompraRequest, UsuarioCompra } from "models/compraCero";
import { InfoRate } from "models/infoRate";
import moment from "moment";
import { AditionalCounter } from "models/aditionalCounter";
import manosComfenalco from "../assets/images/manosComfenalco.png";
import Stepper from "@mui/material/Stepper";
import {
  Done,
  Event,
  Person,
  RecordVoiceOver,
  SupervisedUserCircle,
  Warning,
} from "@mui/icons-material";
import {
  ClockIcon,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { useInterviewsEdu } from "hooks/useInterviewEdu";
import { InterviewRequest } from "models/interviewRequestModel";
import { InterviewSelectedEduDTO } from "models/interviewSelected";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Loading } from "./Loading";
import { useNavigate } from "react-router-dom";
import { usePurchase } from "hooks/usePurchase";
import { setSuccess } from "store/slices/status";
import { LoadingButton } from "@mui/lab";
import { ValidadorCaja } from "models/boxValidatorModel";
import { ServiceConfirmationDialog } from "pages/Services/ServiceConfirmationDialog";
import { useParam } from "hooks/useParam";
import es from 'date-fns/locale/es';
import { useShoppingCar } from "hooks/useShoppingCar";


interface ServiceForm {
  serviceData?: ServiceData;
  rates?: ServiceRates;
  unavailableDates?: UnavailableDates[];
  firstAvailableDate?: string;
  boxValidator?: ValidadorCaja;
}
export const ServiceTypeInterviewForm = ({
  serviceData,
  rates,
  unavailableDates = [],
  firstAvailableDate,
  boxValidator,
}: ServiceForm) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLogged, authData } = useSelector((state: RootState) => state.auth);
  const { getParamData } = useParam();
  const { getInterviewsEdu, isLoadingInterviews } = useInterviewsEdu();
  const { zeroPay, compraValor } = usePurchase();
  const { documentNumber, documentType } = useSelector(
    (state: RootState) => state.individualSale.individualUserData
  );

  const [mensajeCuotas, setMensajeCuotas] = useState('');

  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [availableInterviews, setAvailableInterviews] =
    useState<InterviewSelectedEduDTO[]>();
  const [availableInterviewsByDay, setAvailableInterviewsByDay] =
    useState<InterviewSelectedEduDTO[]>();
  const [availableDays, setAvailableDays] = useState<string[]>();

  const [mobile, setMobile] = useState(window.innerWidth <= 500);

  const { beneficiarioVOs, afiliado } = useSelector(
    (state: RootState) => state.auth.authData
  );

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
    unregister,
    getValues,
  } = useForm<PurchaseForm>({
    defaultValues: {
      benefeciariesSelected:
        serviceData?.campoJson.compraIndividual === COMPRA_INDIVIDUAL_ACTIVA
          ? null
          : [],
    },
  });

  const formValues = watch();

  const navigate = useNavigate();

  const [counterRate, setCounterRate] = useState<AditionalCounter[]>(
    rates?.tarifasCategoriaD?.map((rate) => {
      return { counter: 0, rate: rate };
    })!
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState<PurchaseForm>();

  const handleFormSubmit: SubmitHandler<PurchaseForm> = (
    data: PurchaseForm
  ) => {
    if (data?.interviewSelected) {
      setFormData(data);
      setOpenDialog(true);
    } else {
      formSubmit(data);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const getParam = async () => {
      const mensajeCuotasParam = await getParamData(PARAM_MENSAJE_CUOTAS_EDUCACION);
      mensajeCuotasParam?.data?.valorParametro &&
        setMensajeCuotas(mensajeCuotasParam?.data?.valorParametro);
    }

    getParam();
  }, [])

  useEffect(() => {
    if (!formValues.selectedDate) {
      return;
    }

    setValue("interviewSelected", undefined);
    setAvailableInterviewsByDay([]);

    const interviewsDay = availableInterviews?.filter(
      (interview) =>
        interview.fecha.getTime() === formValues.selectedDate!.getTime()
    );

    if (interviewsDay!.length > 0) {
      setAvailableInterviewsByDay(interviewsDay);
    }
  }, [formValues.selectedDate]);
  // Asigna la oportunidad al usuario mayor
  const updateOportunidad = (users: UsuarioCompra[]) => {
    if (isLogged && authData?.tipoUsuario !== TIPO_USUARIO) {
      const userIndex = users.findIndex(
        (user) => user.numIdentificacion === afiliado.identificacion
      );

      if (userIndex !== -1) {
        users[userIndex].oportunidad = OPORTUNIDAD;
      } else {
        const olderUser = users.reduce(
          (max, user) => (user?.edad! > max?.edad! ? user : max),
          { edad: 0 }
        );

        const olderUserIndex = users.indexOf(olderUser);

        if (olderUserIndex !== -1) {
          users[olderUserIndex].oportunidad = OPORTUNIDAD;
        } else {
          const olderAditionalUser = users.reduce(
            (max, user) => (user?.edadMaxima! > max?.edadMaxima! ? user : max),
            { edadMaxima: 0 }
          );

          const olderAditionalUserIndex = users.indexOf(olderAditionalUser);

          users[olderAditionalUserIndex].oportunidad = OPORTUNIDAD;
        }
      }
    } else {
      const userIndex = users.findIndex(
        (user) => user.numIdentificacion === documentNumber
      );

      if (userIndex !== -1) {
        users[userIndex].oportunidad = OPORTUNIDAD;
      } else {
        const olderUser = users.reduce(
          (max, user) => (user?.edad! > max?.edad! ? user : max),
          { edad: 0 }
        );

        const olderUserIndex = users.indexOf(olderUser);

        if (olderUserIndex !== -1) {
          users[olderUserIndex].oportunidad = OPORTUNIDAD;
        } else {
          const olderAditionalUser = users.reduce(
            (max, user) => (user?.edadMaxima! > max?.edadMaxima! ? user : max),
            { edadMaxima: 0 }
          );

          const olderAditionalUserIndex = users.indexOf(olderAditionalUser);

          users[olderAditionalUserIndex].oportunidad = OPORTUNIDAD;
        }
      }
    }
    return users;
  };

  // Obtiene los usuarios seleccionados para la compra
  const getPurchaseUser = (data: PurchaseForm) => {
    const interview: InterviewSelectedEduDTO = availableInterviews?.find(
      (interview) => interview.codigo === data?.interviewSelected
    )!;

    const userRates: InfoRate[] = data?.aditionalForm
      ? [
        rates?.tarifasCategoriaD.find(
          (beneficiary: InfoRate) =>
            beneficiary?.tarifa?.idTarifa ===
            Number(data.benefeciariesSelected)
        )!,
      ]
      : [
        rates?.tarifasUsuarios.find(
          (beneficiary: InfoRate) =>
            beneficiary.identificacionUsuario === data.benefeciariesSelected
        )!,
      ];

    const purchaseUser: UsuarioCompra[] = userRates?.map(
      (userRate: InfoRate) => {
        if (isLogged && authData?.tipoUsuario !== TIPO_USUARIO) {
          const beneficiary = beneficiarioVOs.find(
            (beneficiary: Beneficiary) =>
              beneficiary?.identificacion === userRate?.identificacionUsuario
          );

          if (beneficiary) {
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion: beneficiary?.abreviatura,
              numIdentificacion: beneficiary?.identificacion,
              nombre: beneficiary?.nombre,
              correo: "",
              fechaNacimiento: moment(beneficiary?.fechaNacimiento).format(
                "YYYY/MM/DD"
              ),
              genero: beneficiary?.sexo,
              edad: Number(userRate?.edadUsuario),
              valor: interview
                ? userRate.tarifa?.total
                : userRate.tarifa?.total - userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: Number(
                userRate?.tarifa?.idCategoriaUsuarioCdaCategoriaUsuario
              ),
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          } else {
            const birthDate =
              data?.aditionalForm && data?.aditionalForm[0]?.birthDate;
            const fechaNac = moment(birthDate, "YYYY-MM-DD");
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentType,
              numIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentNumber,
              nombre:
                data?.aditionalForm &&
                data?.aditionalForm[0]?.firstName +
                " " +
                data?.aditionalForm[0]?.secondName,
              correo: "",
              fechaNacimiento:
                data?.aditionalForm &&
                moment(data?.aditionalForm[0]?.birthDate).format("YYYY/MM/DD"),
              genero: data?.aditionalForm && data?.aditionalForm[0]?.gender,
              edad: moment().diff(fechaNac, "years"),
              valor: interview
                ? userRate.tarifa?.total
                : userRate.tarifa?.total - userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: 4,
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          }
        } else {
          const beneficiary =
            boxValidator?.grupoFamiliar?.informacionGrupo?.find(
              (beneficiary: Beneficiary) =>
                beneficiary?.identificacion === userRate?.identificacionUsuario
            );
          if (beneficiary) {
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion: userRate?.abreviaturaUsuario,
              numIdentificacion: userRate?.identificacionUsuario,
              nombre: userRate?.nombreUsuario,
              correo: "",
              fechaNacimiento: moment(
                boxValidator?.afiliado.fechaNacimiento
              ).format("YYYY/MM/DD"),
              genero: boxValidator?.afiliado.sexo!,
              edad: Number(userRate?.edadUsuario),
              valor: interview
                ? userRate.tarifa?.total
                : userRate.tarifa?.total - userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: Number(boxValidator?.afiliado.idCate),
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };
            return usuarioCompra;
          } else {
            const birthDate =
              data?.aditionalForm && data?.aditionalForm[0]?.birthDate;
            const fechaNac = moment(birthDate, "YYYY-MM-DD");
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentType,
              numIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentNumber,
              nombre:
                data?.aditionalForm &&
                data?.aditionalForm[0]?.firstName +
                " " +
                data?.aditionalForm[0]?.secondName,
              correo: "",
              fechaNacimiento:
                data?.aditionalForm &&
                moment(data?.aditionalForm[0]?.birthDate).format("YYYY/MM/DD"),
              genero: data?.aditionalForm && data?.aditionalForm[0]?.gender,
              edad: moment().diff(fechaNac, "years"),
              valor: interview
                ? userRate.tarifa?.total
                : userRate.tarifa?.total - userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: 4,
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          }
        }
      }
    );

    return purchaseUser;
  };

  //INICIA

  const [urlPasarela, seturlPasarela] = useState("");
  useEffect(() => {
    const getParamUrlPasarela = async () => {
      const getParamUrlPasarela = await getParamData("URL_PASARELA");
      // Get url pasarela param
      if (getParamUrlPasarela?.data) {
        const dataParameter = getParamUrlPasarela?.data;
        seturlPasarela(dataParameter.valorParametro);
      }
    }
    getParamUrlPasarela();
  }, [urlPasarela]);

  const {
    paySale,
  } = useShoppingCar();

  const handlePaySale = async (sale: any) => {
    const paySaleResultInfo = await paySale(sale.idCompra);
    setIsLoadingPurchase(false);
    dispatch(setSuccess(null));
    window.location.replace(urlPasarela + paySaleResultInfo?.infoPago);
  };

  // Realiza el envio del formulario
  const formSubmit: SubmitHandler<PurchaseForm> = async (
    data: PurchaseForm
  ) => {
    openDialog && setOpenDialog((prev) => !prev);
    setIsLoadingPurchase(true);

    const purchaseUser: UsuarioCompra[] = getPurchaseUser(data);

    const interview: InterviewSelectedEduDTO = availableInterviews?.find(
      (interview) => interview.codigo === data?.interviewSelected
    )!;

    const compraRequest: CompraRequest = {
      valorTotal: purchaseUser[0].valor,
      porcentajeDcto: 0,
      origenCompra: ABREVIACION_ORIGEN_WEB,
      fechaInicioServicio: serviceData?.campoJson?.servFechaInicial
        ? moment(serviceData?.campoJson?.servFechaInicial).format("YYYY/MM/DD")
        : moment(data?.selectedDate).format("YYYY/MM/DD"),
      fechaFinServicio: serviceData?.campoJson?.servFechaFinal
        ? moment(serviceData?.campoJson?.servFechaFinal).format("YYYY/MM/DD")
        : null,
      fechaServicio: moment(data?.selectedDate).format("YYYY/MM/DD"),
      idServicio: serviceData?.idServicio,
      cantidad: purchaseUser?.length,
      medioCompra: ORIGEN_WEB,
      centroServicio: "" + serviceData?.idCentroServicioCdaCentroServicio,
      compraUsuarios: updateOportunidad(purchaseUser),
      entrevista: interview ? interview : null,
      facturaUsuarios: [],
      afiliadoIdentificacion:
        !isLogged || (isLogged && authData?.tipoUsuario === TIPO_USUARIO)
          ? documentNumber
          : undefined,
      afiliadoTipoIden:
        !isLogged || (isLogged && authData?.tipoUsuario === TIPO_USUARIO)
          ? documentType
          : undefined,
      propietario:
        authData?.empleadoCRM && authData?.empleadoCRM.idEmpleado
          ? authData.empleadoCRM.idEmpleado
          : "",
    };

    if (compraRequest?.valorTotal! > 0) {
      const response = await compraValor(compraRequest);
      if (response) {
        response?.mensaje &&
          dispatch(
            setSuccess(
              "La compra se realizó exitosamente, serás redirigido a la pasarela de pagos"
            )
          );
        setTimeout(() => {
          setIsLoadingPurchase(false);
          dispatch(setSuccess(null));
          handlePaySale(response);
        }, 5000);
      } else {
        setIsLoadingPurchase(false);
      }
    } else {
      const response = await zeroPay(compraRequest);
      if (response) {
        response?.mensaje &&
          dispatch(
            interview
              ? setSuccess(
                "La entrevista se agendo exitosamente, serás redirigido al historial de compras"
              )
              : setSuccess(
                "La compra se realizó exitosamente, serás redirigido al historial de compras"
              )
          );
        setIsLoadingPurchase(false);
        navigate("/historial-compras");
      } else {
        setIsLoadingPurchase(false);
      }
    }
  };

  //INICIA

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  // useEffect that detects changes resize from window
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleNext = () => {
    if (activeStep === 1) {
      if (formValues.nivelIngles === "N") {
        return handleSkip();
      } else {
        getInterviewsOnService();
      }
    }

    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
      setSkipped(newSkipped);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (isStepSkipped(prevActiveStep - 2)) {
        return prevActiveStep - 2;
      }
      return prevActiveStep - 1;
    });
  };

  const steps: any = [
    { icon: SupervisedUserCircle, label: "Seleccionar ASISTENTES" },
    { icon: RecordVoiceOver, label: "Nivel de IDIOMA" },
    { icon: Event, label: "Agendar ENTREVISTA" },
    { icon: Done, label: "Listo RESUMEN" },
  ];

  const getInterviewsOnService = async () => {
    const interviewsRequest: InterviewRequest = {
      codigoPeriodo: serviceData?.campoJson.codigoPeriodo,
      codigoFacultad: serviceData?.campoJson.codigoFacultad,
      codigoSede: serviceData?.campoJson.codigoSede,
    };

    const interviews = await getInterviewsEdu(interviewsRequest);
    const days: string[] = [];

    if (!interviews || interviews.entrevistas == null) {
      setAvailableDays(days);
      // Ordenar por hora
      setAvailableInterviews([]);

      setValue("selectedDate", null);
      return;
    }

    const availableInterviewsArray = interviews?.entrevistas?.flatMap(
      (interview) =>
        interview.fechasEntrevista!.map((dateInterview) => {
          const date = new Date(dateInterview.fecha!);
          if (!days.includes(moment(date).format("DD/MM/YYYY"))) {
            days.push(moment(date).format("DD/MM/YYYY"));
          }
          const [hour, minute] = dateInterview.hora!.split(":");
          const hourPeriod = Number(hour) < 12 ? "AM" : "PM";
          const formattedHour = `${Number(hour) % 12}:${minute} ${hourPeriod}`;
          return {
            codigo: dateInterview.codigo,
            codigoPsicologo: interview.codigoPsicologo,
            fecha: date,
            hora: formattedHour,
            nombrePsicologo: interview.nombrePsicologo,
          } as InterviewSelectedEduDTO;
        })
    );

    setAvailableDays(days);

    availableInterviewsArray?.sort(SortArray); // Ordenar por hora
    setAvailableInterviews(availableInterviewsArray);

    const sortedByDate = [...availableInterviewsArray!]?.sort(SortArrayByDate); // Ordenar por fecha
    // Aquí puedes acceder a la primera fecha disponible
    const firstAvailableDate = sortedByDate[0].fecha;

    setValue("selectedDate", firstAvailableDate);
  };

  const getStepContent = (activeStep: number) => {
    let content = <></>;

    switch (activeStep) {
      case 0: {
        content = stepContentAsistentes();
        break;
      }
      case 1: {
        content = stepContentNivelIdioma();
        break;
      }
      case 2: {
        content = stepContentEntrevista();
        break;
      }
      case 3: {
        content = stepContentResumen();
        break;
      }
    }

    return content;
  };

  const disabledButtonNext = (activeStep: number) => {
    const atLeastRadioSelected = (
      values: PurchaseForm["benefeciariesSelected"]
    ) => {
      return values ? true : false;
    };
    switch (activeStep) {
      case 0: {
        return !atLeastRadioSelected(formValues?.benefeciariesSelected);
      }
      case 1: {
        return !atLeastRadioSelected(formValues.nivelIngles);
      }
      case 2: {
        return !atLeastRadioSelected(formValues.interviewSelected);
      }
    }
    return false;
  };

  const stepContentAsistentes = () => {
    return (
      <form onSubmit={handleSubmit(handleNext)}>
        <div className="w-full">
          <Typography className="font-bold text-2xl pt-8" color="primary">
            {serviceData?.campoJson?.compraIndividual ===
              COMPRA_INDIVIDUAL_ACTIVA
              ? "¿Quién será el asistente?"
              : "¿Quiénes serán los asistentes?"}
          </Typography>

          <div className="w-full pt-4">
            <Rates
              indOrigen={serviceData?.indOrigen}
              compraIndividual={serviceData?.campoJson?.compraIndividual}
              showPriceInline={false}
              rates={rates}
              errors={errors}
              register={register}
              setValue={setValue}
              watch={watch}
              counterRate={counterRate}
              unregister={unregister}
              getValues={getValues}
              serviceData={serviceData}
            />
          </div>
        </div>

        <div className="flex w-full pt-8 justify-end">
          <Button
            type="submit"
            variant="contained"
            className="py-2 w-1/2"
            disabled={disabledButtonNext(activeStep)}
          >
            Siguiente
          </Button>
        </div>
      </form>
    );
  };

  const stepContentNivelIdioma = () => {
    const nivelIngles = formValues?.nivelIngles;
    const atLeastRadioSelected = (values: PurchaseForm["nivelIngles"]) => {
      return values ? true : false;
    };

    return (
      <>
        <form onSubmit={handleSubmit(handleNext)}>
          <div className="w-full">
            <Typography className="font-bold text-2xl pt-8" color="primary">
              Información importante
            </Typography>
            <Divider orientation="horizontal" className="pb-2" flexItem />

            <Typography className="font-bold text-lg pt-2 text-principal-650 text-center">
              ¿El aspirante posee nivel de inglés oral conversacional?
            </Typography>

            <FormControl className="flex items-center w-full">
              <RadioGroup row name="row-radio-buttons-group">
                <FormControlLabel
                  value="S"
                  control={
                    <Radio
                      {...register("nivelIngles", {
                        validate: atLeastRadioSelected,
                      })}
                      value={"S"}
                      checked={nivelIngles === "S"}
                    />
                  }
                  label="Sí"
                />
                <FormControlLabel
                  value="N"
                  control={
                    <Radio
                      {...register("nivelIngles", {
                        validate: atLeastRadioSelected,
                      })}
                      value={"N"}
                      checked={nivelIngles === "N"}
                    />
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="flex w-fxull justify-between pt-8 items-center">
            <Button
              color="primary"
              className="py-2 w-1/2 mr-2"
              onClick={handleBack}
            >
              Atrás
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="py-2 w-1/2"
              disabled={disabledButtonNext(activeStep)}
            >
              Siguiente
            </Button>
          </div>
        </form>
      </>
    );
  };

  const stepContentEntrevista = () => {
    if (isLoadingInterviews) {
      return <Loading />;
    }

    if (availableInterviews?.length === 0) {
      return (
        <>
          <div className="flex flex-col items-center w-full py-4">
            <Warning color="warning"></Warning>
            <p className="font-bold">
              No se encontraron entrevistas disponibles en la fecha seleccionada
            </p>
          </div>
          <div className="flex w-fxull justify-between pt-8 items-center">
            <Button
              color="primary"
              className="py-2 w-1/2 mr-2"
              onClick={() => {
                setValue("interviewSelected", undefined);
                handleBack();
              }}
            >
              Atrás
            </Button>
          </div>
        </>
      );
    }

    const atLeastRadioSelected = (
      values: PurchaseForm["interviewSelected"]
    ) => {
      return values ? true : false;
    };

    const shouldDisableDate = (date: Date) => {
      const dateParse = moment(date).format("DD/MM/YYYY");
      return !availableDays!.includes(dateParse);
    };

    return (
      <form onSubmit={handleSubmit(handleNext)}>
        <div className="w-full">
          <Typography className="font-bold text-2xl pt-8 pb-4" color="primary">
            Horarios de entrevista
          </Typography>

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
            <DatePicker
              className="w-full"
              label="Fecha"
              format="dd/MM/yyyy"
              shouldDisableDate={shouldDisableDate}
              slots={{
                ...register("selectedDate", {
                  required: "Este campo es requerido",
                }),
              }}
              defaultValue={formValues.selectedDate}
              disablePast={true}
              onChange={(date: Date | null) => {
                setValue("selectedDate", date);
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="w-full">
          {availableInterviewsByDay?.length === 0 ? (
            <div className="">
              <Warning color="warning"></Warning>
              <p className="font-bold">
                No se encontraron entrevistas disponibles en la fecha
                seleccionada
              </p>
            </div>
          ) : (
            availableInterviewsByDay?.map((interview) => (
              <div
                key={interview.codigo}
                className="flex flex-wrap w-full py-3 border-b"
              >
                <div className="w-3/4">
                  <div>
                    <ClockIcon color="primary"></ClockIcon>
                    <span className="text-lg pl-4 text-principal-650">
                      {interview.hora}
                    </span>
                  </div>
                  <div>
                    <Person color="primary"></Person>
                    <span className="text-lg pt-8 pl-4 text-principal-650">
                      {interview.nombrePsicologo}
                    </span>
                  </div>
                </div>
                <div className="w-1/4 flex items-center">
                  <FormControl className="ml-auto">
                    <RadioGroup row name="row-radio-buttons-group">
                      <FormControlLabel
                        value={interview.codigo}
                        control={
                          <Radio
                            {...register("interviewSelected", {
                              validate: atLeastRadioSelected,
                            })}
                            value={interview.codigo}
                            checked={
                              formValues.interviewSelected === interview.codigo
                            }
                          />
                        }
                        label=""
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="flex w-fxull justify-between pt-8 items-center">
          <Button
            color="primary"
            className="py-2 w-1/2 mr-2"
            onClick={() => {
              setValue("interviewSelected", undefined);
              handleBack();
            }}
          >
            Atrás
          </Button>
          <Button
            type="submit"
            variant="contained"
            className="py-2 w-1/2"
            disabled={disabledButtonNext(activeStep)}
          >
            Siguiente
          </Button>
        </div>
      </form>
    );
  };

  const stepContentResumen = () => {


    let beneficiarios = [];

    if (formValues?.benefeciariesSelected.constructor === String) {
      if (formValues?.aditionalForm !== undefined) {
        beneficiarios.push({
          nombreUsuario:
            formValues?.aditionalForm![0].firstName +
            " " +
            formValues?.aditionalForm![0].secondName,
          categoriaUsuario: rates?.tarifasCategoriaD[0].categoriaUsuario,
          tarifa: rates?.tarifasCategoriaD[0].tarifa,
        });
      } else {
        beneficiarios.push(
          rates?.tarifasUsuarios.find(
            (beneficiary: InfoRate) =>
              beneficiary.identificacionUsuario ===
              formValues?.benefeciariesSelected
          )!
        );
      }
    } else {
      beneficiarios = formValues?.benefeciariesSelected?.map(
        (beneficiaryId: string) =>
          rates?.tarifasUsuarios.find(
            (beneficiary: InfoRate) =>
              beneficiary.identificacionUsuario === beneficiaryId
          )!
      );
    }

    let entrevista: InterviewSelectedEduDTO | null | undefined = null;

    if (formValues.nivelIngles !== "N") {
      entrevista = availableInterviewsByDay?.filter(
        (interview) => interview.codigo == formValues.interviewSelected
      )[0];
    }

    return (
      <>
        {isLoadingPurchase && <Loading />}
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="flex flex-col w-full items-center">
            <Typography className="font-bold text-2xl pt-8" color="primary">
              Resumen
            </Typography>
            <Typography className="text-lg text-principal-650">
              Resumen del agendamiento
            </Typography>

            <Typography className="font-bold text-lg pt-8 text-principal-650">
              Usuario
            </Typography>

            {beneficiarios.map((beneficiario: any) => (
              <div key={beneficiario?.identificacionUsuario}>
                <Typography
                  className="text-lg text-center pb-2"
                  color="primary"
                >
                  {beneficiario.nombreUsuario}
                </Typography>
                {entrevista == null ? (
                  <>
                    <p className="font-bold text-principal-650">
                      Categoria {beneficiario.categoriaUsuario}
                    </p>
                    <div className="w-full bg-principal-400/10 overflow-x-scroll">
                      <div
                        className="max-w-full"
                        dangerouslySetInnerHTML={{
                          __html: beneficiario.tarifa.descripcion,
                        }}
                      ></div>
                      < div className="py-2 px-2">
                        <span className="text-lg text-principal-650">
                          {mensajeCuotas} {(beneficiario.tarifa?.total - beneficiario.tarifa?.valor).toLocaleString("es-CO", {
                            style: "currency",
                            currency: "COP",
                            minimumFractionDigits: 0,
                          })}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="font-bold text-principal-650 text-center">
                    Total del programa: $ {beneficiario.tarifa.total}
                  </p>
                )}
              </div>
            ))}
            {entrevista != null && (
              <>
                <Typography className="font-bold text-lg pt-8 text-principal-650">
                  Entrevista
                </Typography>
                <Typography className="text-lg" color="primary">
                  <span className="font-bold">Fecha: </span>
                  {moment(entrevista.fecha).format("DD/MM/YYYY")}{" "}
                  <span className="font-bold">Hora: </span> {entrevista.hora}{" "}
                </Typography>
                <p className="text-lg text-principal-650">
                  {entrevista.nombrePsicologo}
                </p>
              </>
            )}
          </div>

          <div className="flex w-fxull justify-between pt-8 items-center">
            <Button
              color="primary"
              className="py-2 w-1/2 mr-2"
              onClick={handleBack}
            >
              Atrás
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              className="py-2 w-1/2"
              disabled={disabledButtonNext(activeStep)}
              loading={isLoadingPurchase}
            >
              {entrevista != null ? "Agendar" : "Comprar"}
            </LoadingButton>

            <ServiceConfirmationDialog
              open={openDialog}
              onClose={handleCloseDialog}
              onConfirm={() => formSubmit(formData!)}
            />
          </div>
        </form>
      </>
    );
  };

  if (!isLogged) {
    return (
      <div className="flex flex-col w-full items-center pt-4">
        <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
          <img src={manosComfenalco} className="h-16 w-16" />
          <p className="text-principal-600 font-bold">
            Por favor, inicia sesión para realizar tu compra.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full center">
      <Stepper
        activeStep={activeStep}
        className="w-full center"
        alternativeLabel={!mobile}
        orientation={mobile ? "vertical" : "horizontal"}
      >
        {steps.map((step: any, index: any) => {
          return (
            <Step key={step.label}>
              <StepLabel
                color={index <= activeStep ? "primary" : "disabled"}
                StepIconProps={{
                  color: index <= activeStep ? "primary" : "disabled",
                }}
                StepIconComponent={step.icon}
              >
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {getStepContent(activeStep)}
    </div>
  );

  function SortArray(x: InterviewSelectedEduDTO, y: InterviewSelectedEduDTO) {
    if (x.hora < y.hora) {
      return -1;
    }
    if (x.hora > y.hora) {
      return 1;
    }
    return 0;
  }

  function SortArrayByDate(
    x: InterviewSelectedEduDTO,
    y: InterviewSelectedEduDTO
  ) {
    if (x.fecha < y.fecha) {
      return -1;
    }
    if (x.fecha > y.fecha) {
      return 1;
    }
    return 0;
  }
};
