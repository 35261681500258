import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { Loading } from "components/Loading";
import { SpaceDescription } from "components/SpaceDescription";
import { SpaceForm } from "components/SpaceForm";
import { useService } from "hooks/useService";
import { useSpaces } from "hooks/useSpaces";
import { ServiceImages } from "models/serviceImages";
import { MSpaceDetail } from "models/spaceDetailModel";
import { UnavailableDates } from "models/uavailableDate";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store";
import manosComfenalco from "../../assets/images/manosComfenalco.png";
import { SaleWithOutLoginForm } from "components/SaleWithOutLoginForm";
import { IndividualSaleForm } from "components/IndividualSaleForm";
import {
  OPCION_VENTA_INDIVIDUAL,
  TIPO_USUARIO,
} from "utils/constants";
import { useExcludeService } from "hooks/useExcludeService";

export const SportsCenters = () => {
  const { isLogged, authData } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const { getIsIncludedUser } = useExcludeService();

  const { documentNumber, documentType } = useSelector(
    (state: RootState) => state.individualSale.individualUserData
  );

  const { idService } = useParams();

  const { getSpaceDetail, isLoadingDetail } = useSpaces();
  const { getServiceData, getUnavailableDates } = useService();

  const [bannerImages, setBannerImages] = useState<ServiceImages[]>([]);
  const [spaceDetail, setSpaceDetail] = useState<MSpaceDetail>();
  const [unavailableDates, setUnavailableDates] =
    useState<UnavailableDates[]>();
  const [isLoading, setIsLoading] = useState(true);

  const purpleTheme = createTheme({
    palette: {
      primary: {
        main: spaceDetail?.detalleServicio?.colorNegocio || "#000",
      },
    },
  });

  useEffect(() => {
    const isExcludedUser = async () => {
      const isExcluded = isLogged && idService && await getIsIncludedUser(authData?.afiliado?.identificacion, idService);

      isExcluded && navigate('/home');
    }

    if (isLogged) {
      isExcludedUser();
    }
  }, [])

  useEffect(() => {
    const onGetSpaceData = async () => {
      setIsLoading(true);

      const spaceDetail = await getSpaceDetail(Number(idService));

      const { serviceImages } = await getServiceData(Number(idService));

      const unavailableDates = await getUnavailableDates(Number(idService));

      setBannerImages(serviceImages);
      setSpaceDetail(spaceDetail);
      setUnavailableDates(unavailableDates);

      setIsLoading(false);
    };

    onGetSpaceData();
  }, []);

  return (
    <ThemeProvider theme={purpleTheme}>
      {isLoadingDetail || isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-wrap justify-around py-8 px-8 md:px-0 w-full bg-principal-0">
          {authData?.tipoUsuario == TIPO_USUARIO &&
            authData?.cdaRolCdaOpcions?.some(
              (option: any) =>
                option?.idOpcionCdaOpcion == OPCION_VENTA_INDIVIDUAL
            ) && <IndividualSaleForm />}
          <div className="flex flex-col w-full md:w-[48%] rounded-lg border border-principal-350 bg-white">
            <SpaceDescription
              bannerImages={bannerImages}
              spaceDetail={spaceDetail}
            />
          </div>
          <div className="flex  w-full md:w-[48%] mt-4 md:mt-0 px-4 py-8 rounded-lg border border-principal-350 bg-white">
            {!isLogged &&
            spaceDetail?.detalleServicio?.valorCero &&
            !documentNumber &&
            !documentType ? (
              <SaleWithOutLoginForm />
            ) : (
              <>
                {isLogged || (documentNumber && documentType) ? (
                  <>
                    {authData?.tipoUsuario !== TIPO_USUARIO ||
                    (authData?.tipoUsuario === TIPO_USUARIO &&
                      documentNumber &&
                      documentType) ||
                    (!isLogged &&
                      spaceDetail?.detalleServicio?.valorCero &&
                      documentNumber &&
                      documentType) ? (
                      <SpaceForm
                        spaceDetail={spaceDetail}
                        unavailableDates={unavailableDates}
                      />
                    ) : (
                      <div className="flex flex-col w-full items-center pt-4">
                        <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                          <img src={manosComfenalco} className="h-16 w-16" />
                          <p className="text-principal-600 font-bold">
                            Por favor, diligencia la información del usuario al
                            que le vas a vender.
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex flex-col w-full items-center pt-4">
                    <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                      <img src={manosComfenalco} className="h-16 w-16" />
                      <p className="text-principal-600 font-bold">
                        Por favor, inicia sesión para realizar tu reserva.
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};
