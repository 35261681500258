import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface authState {
  authData: any;
  isLogged: boolean;
  errorMessage: "";
}

const initialState: authState = {
  authData: {},
  isLogged: false,
  errorMessage: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action: PayloadAction<any>) => {
      state.authData = action.payload;
      state.isLogged = true;
    },
    onLogout: (state) => {
      state.authData = {};
      state.isLogged = false;
      localStorage.removeItem("userData");
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAuthData, onLogout } = authSlice.actions;
