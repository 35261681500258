import React, { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { UseFormSetValue, UseFormRegister, FieldErrors } from "react-hook-form";
import { PurchaseForm } from "models/purchaseForm";
import { UnavailableDates } from "models/uavailableDate";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import es from 'date-fns/locale/es';

interface DatePickerProps {
  setValue: UseFormSetValue<PurchaseForm>;
  register: UseFormRegister<PurchaseForm>;
  unavailableDates?: UnavailableDates[];
  errors: FieldErrors<PurchaseForm>;
  firstAvailableDate: any;
  disabled?: boolean;
}

export const FormDatePicker = ({
  setValue,
  register,
  unavailableDates = [],
  firstAvailableDate,
  errors,
  disabled,
}: DatePickerProps) => {
  const [dateFirstAvailableParsed, setDateFirstAvailableParsed] = useState(
    new Date()
  );

  useEffect(() => {
    if (firstAvailableDate) {
      // Crear una instancia de moment con el formato especificado
      const firstDate = moment(firstAvailableDate, "DD/MM/YYYY");

      // Obtener una cadena con el formato deseado
      const formatted = firstDate.format("MM/DD/YYYY");

      const dateFirstAvailable = new Date(formatted);

      firstAvailableDate = new Date(
        `${dateFirstAvailable.getMonth() + 1
        }/${dateFirstAvailable.getDate()}/${dateFirstAvailable.getFullYear()}`
      );

      setDateFirstAvailableParsed(firstAvailableDate);
      setValue("selectedDate", firstAvailableDate);
    }
  }, []);

  const handleDateChange = (date: Date | null) => {
    // Con setValue, actualizamos el valor del campo 'selectedDate'
    setValue("selectedDate", date);
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <p className="font-bold text-principal-600 text-lg pr-4">
          ¿Qué días deseas visitarnos?
        </p>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
          <DatePicker
            disabled={disabled == undefined ? false : disabled}
            value={dateFirstAvailableParsed}
            disablePast={true}
            format="dd/MM/yyyy"
            slots={{
              ...register("selectedDate", {
                required: "Este campo es requerido",
                validate: (value) => {
                  const selectedDate = new Date(value || '');
                  const currentDate = new Date();
                  if (selectedDate.getDate() < currentDate.getDate() && selectedDate.getMonth() <= currentDate.getMonth() && selectedDate.getFullYear() && selectedDate.getFullYear() <= currentDate.getFullYear()) {
                    return "La fecha seleccionada no puede ser una fecha pasada";
                  }
                  return true;
                },
              }),
            }}
            onChange={handleDateChange}
            shouldDisableDate={(date: Date) => {
              const formattedDate = moment(date).format("DD/MM/YYYY"); // Convertimos la fecha a formato 'YYYY-MM-DD'
              return unavailableDates.some((unavailableDate) => {
                const formattedUnavailableDate = moment
                  .utc(unavailableDate.fechaNoDisponible)
                  .format("DD/MM/YYYY");
                return formattedUnavailableDate === formattedDate;
              });
            }}
          />
        </LocalizationProvider>
      </div>
      {errors.selectedDate && (
        <p className="text-red-500 text-end">
          {String(errors.selectedDate.message)}
        </p>
      )}
    </>
  );
};
