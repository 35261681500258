import { useAuth0 } from "@auth0/auth0-react";
import { Banner } from "components/Banner";
import { BusinessSlider } from "components/BussinesSlider";
import { EventSlider } from "components/EventSlider";
import { IndividualSaleForm } from "components/IndividualSaleForm";
import { Loading } from "components/Loading";
import { ServiceSlider } from "components/ServiceSlider";
import { useBanner } from "hooks/useBanner";
import { useBusiness } from "hooks/useBusiness";
import { useService } from "hooks/useServices";
import { useShoppingCar } from "hooks/useShoppingCar";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { AppDispatch, RootState } from "store";
import { loginAuthAdmin } from "store/slices/auth";
import {
  COMPRA_INDIVIDUAL_ACTIVA,
  OPCION_VENTA_INDIVIDUAL,
  TIPO_USUARIO,
} from "utils/constants";
import { decrypt, encrypt } from "utils/security/AesUtil";
import "./home.css";

export const Home = () => {
  const { bannerImage, isLoadingBanner } = useBanner();
  const { activeBusiness, isLoadingBusiness } = useBusiness();
  const {
    upcomingEvents,
    bestsellersProducts,
    newsService,
    interestingProducts,
    isLoadingService,
  } = useService();

  const { user, isAuthenticated } = useAuth0();

  const { getTotalCarritoCompras } = useShoppingCar();

  const { authData, isLogged } = useSelector((state: RootState) => state.auth);
  const { token } = useSelector((state: RootState) => state.jwt);

  const dispatch = useDispatch<AppDispatch>();
  

  useEffect(() => {
    /*
      si llega info de auth0 despues del login y no está autenticado, 
      realiza la funcion de login de colaborador
    */
    const userInfoLogged =
      localStorage.getItem("userData") &&
      decrypt(localStorage.getItem("userData")!);
    if (user && isAuthenticated && !userInfoLogged) {
      doLoginAdmin();
      getSalesCount();
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (isLogged && token?.token) {
      getSalesCount();
    }
  }, [isLogged, token]);

  const getSalesCount = () => {
    if (isLogged && token?.token) {
      getTotalCarritoCompras();
    }
  };

  const doLoginAdmin = async () => {
    if (user) {
      let userToLogin = {
        idPers: null,
        tipoIdentificacion:
          user[`https://test-empresas-comf.com/employ/tipo_identificacion`],
        numeroIdentificacion:
          user[`https://test-empresas-comf.com/employ/rest_id`],
        tipoUsuario: user[`https://test-empresas-comf.com/employ/tipo`],
      };

      dispatch(loginAuthAdmin(userToLogin));

      getSalesCount();
    }
  };

  return (
    <div className="flex flex-wrap  w-full">
      {isLoadingBanner || isLoadingBusiness || isLoadingService ? (
        // {isLoadingBanner || isLoadingBusiness ? (
        <Loading />
      ) : (
        <>
          <div className="w-full">
            <Banner bannerImage={bannerImage} />
          </div>
          <div className="flex justify-center w-full -mt-14 z-10">
            <BusinessSlider
              activeBusiness={
                authData && authData?.tipoUsuario === TIPO_USUARIO
                  ? activeBusiness.filter(
                    (business) => business.ventaind === "S"
                  )
                  : activeBusiness
              }
            />
          </div>

          {authData?.tipoUsuario === TIPO_USUARIO &&
            authData?.cdaRolCdaOpcions?.some(
              (option: any) =>
                option?.idOpcionCdaOpcion === OPCION_VENTA_INDIVIDUAL
            ) && <IndividualSaleForm />}

          {((isLogged && interestingProducts?.length > 0) ||
            (authData?.tipoUsuario === TIPO_USUARIO &&
              interestingProducts?.length > 0)) && (
              <div className="w-full">
                <ServiceSlider
                  services={interestingProducts}
                  name={"TE PUEDE INTERESAR"}
                  color={"#135F2C"}
                  isLogged={isLogged}
                />
              </div>
            )}
          {newsService?.length > 0 && (
            <div className="w-full">
              <ServiceSlider
                services={newsService}
                name={"NOVEDADES"}
                color={"#CCD532"}
                isLogged={isLogged}
              />
            </div>
          )}

          {upcomingEvents?.length > 0 && (
            <div className="w-full">
              <EventSlider services={upcomingEvents} />
            </div>
          )}

          {bestsellersProducts?.length > 0 && (
            <div className="w-full">
              <ServiceSlider
                services={bestsellersProducts}
                name={"PRODUCTOS MÁS VENDIDOS"}
                isLogged={isLogged}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
