import { Button } from '@mui/material';
import { AditionalCounter } from 'models/aditionalCounter';
import { InfoRate } from 'models/infoRate';
import React, { useState } from 'react'

interface CounterProps {
    counterRate: AditionalCounter[]
    setCounterRate: React.Dispatch<React.SetStateAction<AditionalCounter[]>>
    index: number
    aditionalsCounter: number,
    setAditionalsCounter: React.Dispatch<React.SetStateAction<number>>
}

export const DynamicCounter = ({ counterRate, setCounterRate, index, aditionalsCounter, setAditionalsCounter }: CounterProps) => {

    const [counter, setCounter] = useState(0);


    const handleCounterChange = (value: number) => {
        setCounter(value)
        setAditionalsCounter(aditionalsCounter + 1);
        let newCounters = counterRate;

        newCounters[index] = { ...newCounters[index], counter: value }

        setCounterRate(newCounters);
    };

    return (
        <div className='flex flex-row rounded-lg items-center'>
            <Button variant="contained" onClick={() => counterRate[index].counter > 0 && handleCounterChange(counterRate[index].counter - 1)}>-</Button>

            {
                <p className='px-6'>{counter}</p>
            }

            <Button variant="contained" onClick={() => handleCounterChange(counterRate[index]?.counter + 1)}>+</Button>
        </div >
    )
}
