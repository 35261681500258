import { useState } from 'react';
import integrationApi from '../api/integrationApi';

export const useExcludeService = () => {
    const [isLoadingExclude, setIsLoadingExclude] = useState(false);
    const urlIsExclude = 'empresasServicio';

    const getIsIncludedUser = async (numeroIdentificacion: string, idServicioCdaServicio: string) => {
        setIsLoadingExclude(true);

        if (numeroIdentificacion && idServicioCdaServicio) {

            const isExclude = await integrationApi.post<number>(
                `${urlIsExclude}/consultaEmpresaServicio`,
                { numeroIdentificacion, idServicioCdaServicio },
            );

            setIsLoadingExclude(false);

            return isExclude?.data === 0;
        }

        return false;
    };
    return {
        isLoadingExclude,
        getIsIncludedUser,
    };
};