import { LocalPhone, LocationOn } from '@mui/icons-material'
import React from 'react'

interface LocationProps {
    location?: string;
    phoneNumer?: number;
}

export const Location = ({ location, phoneNumer }: LocationProps) => {
    return (
        <>
            {
                location &&
                <div className='flex flex-row pb-4 items-center'>
                    <LocationOn color='primary' />
                    <p className='pl-2'>{location}</p>
                </div>
            }
            {
                phoneNumer &&
                <div className='flex flex-row items-center'>
                    <LocalPhone color='primary' />
                    <p className='pl-2'>{phoneNumer}</p>
                </div>
            }
        </>
    )
}
