import { LoadingButton } from "@mui/lab";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useBoxValidator } from "hooks/useBoxValidator";
import { usePurchase } from "hooks/usePurchase";
import { useRates } from "hooks/useRates";
import { useSpaces } from "hooks/useSpaces";
import { Beneficiary } from "models/Beneficiary";
import { ValidadorCaja } from "models/boxValidatorModel";
import { CompraRequest, UsuarioCompra } from "models/compraCero";
import { InfoRate } from "models/infoRate";
import { PurchaseForm } from "models/purchaseForm";
import { ServiceRates } from "models/servicesRates";
import { AvailableSchedule, MSpaceDetail } from "models/spaceDetailModel";
import { MReserveResponse, MReserveSpace } from "models/spacesRequest";
import { UnavailableDates } from "models/uavailableDate";
import moment from "moment";
import { ReservationConfirmationDialog } from "pages/SportsCenters/ReservationConfirmationDialog";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "store";
import { setSuccess } from "store/slices/status";
import {
  ABREVIACION_ORIGEN_WEB,
  COMPRA_INDIVIDUAL_ACTIVA,
  ORIGEN_WEB,
  TIPO_USUARIO,
} from "utils/constants";
import { AvailableSchedules } from "./AvailableSchedules";
import { Loading } from "./Loading";
import { Rates } from "./Rates";
import { SmallLoading } from "./SmallLoading";
import { FormDatePicker } from "./datePicker";
import { useShoppingCar } from "hooks/useShoppingCar";
import { useParam } from "hooks/useParam";

interface SpaceFormProps {
  unavailableDates: UnavailableDates[] | undefined;
  spaceDetail: MSpaceDetail | undefined;
}

export const SpaceForm = ({
  unavailableDates,
  spaceDetail,
}: SpaceFormProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const { isLogged, authData } = useSelector((state: RootState) => state.auth);
  const { documentNumber, documentType } = useSelector(
    (state: RootState) => state.individualSale.individualUserData
  );
  const credentials = useSelector(
    (state: RootState) => state.credentials.credentials
  );

  const { beneficiarioVOs } = useSelector(
    (state: RootState) => state.auth.authData
  );

  const {
    getAvailableSchedules,
    isLoadingSchedules,
    onReserveSpace,
    isLoadingReserve,
  } = useSpaces();

  const { getRates, isLoadingRates } = useRates();

  const { getBoxValidator } = useBoxValidator();

  const { zeroPay, compraValor } = usePurchase();

  const navigate = useNavigate();



  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
    unregister,
    getValues,
  } = useForm<PurchaseForm>({
    defaultValues: {
      selectedDate: new Date(),
      availableSchedules: [],
      benefeciariesSelected:
        spaceDetail?.detalleServicio?.campoJson?.compraIndividual ===
          COMPRA_INDIVIDUAL_ACTIVA
          ? null
          : [],
    },
  });

  const [rates, setRates] = useState<ServiceRates>();
  const [availableSchedules, setAvailableSchedules] = useState<
    AvailableSchedule[]
  >([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState<PurchaseForm>();

  const [boxValidator, setBoxValidator] = useState<ValidadorCaja>();
  const [emailMessage, setEmailMessage] = useState("");
  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false);

  const [reservation, setReservation] = useState<MReserveResponse>();

  const [isValidDate, setIsValidDate] = useState(false);

  const [paxSelected, setPaxSelected] = useState(0);

  const sede = watch("lugar");
  const selectedDate = watch("selectedDate");
  const selectedSchedules = watch("availableSchedules");
  const benefeciariesSelected = watch("benefeciariesSelected");

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    const currentDate = new Date();
    const validationSelectedDate = selectedDate;
    currentDate.setHours(0, 0, 0, 0);
    validationSelectedDate?.setHours(0, 0, 0, 0);

    validationSelectedDate && validationSelectedDate >= currentDate ? setIsValidDate(true) : setIsValidDate(false)
  }, [selectedDate])


  useEffect(() => {
    if (documentType !== "" && documentNumber !== "") {
      const onGetBoxValidator = async () => {
        const boxValidatorResp = await getBoxValidator(
          documentType,
          documentNumber
        );

        if (credentials) {
          if (boxValidatorResp && boxValidatorResp.afiliado) {
            let mail = boxValidatorResp.afiliado.email;
            let mailTrans =
              mail?.slice(0, 3) +
              "***" +
              mail?.slice(mail.indexOf("@"), mail.indexOf("@") + 4) +
              "***";
            setEmailMessage(
              credentials.mensajeCorreoAfiliado!.replace("$CORREO", mailTrans)
            );
          }
        }

        setBoxValidator(boxValidatorResp);
      };
      onGetBoxValidator();
    }
  }, [documentType, documentNumber]);

  useEffect(() => {
    const onSearchSchedules = async () => {
      setValue("availableSchedules", []);
      if (selectedDate && sede) {
        const selectedSede = spaceDetail?.sedes.find(
          (actualSede) => actualSede?.idServicio === sede
        )!;

        const schedulesData = await getAvailableSchedules(
          sede,
          selectedDate,
          selectedSede.codigoSede
        );
        const availableSchedules = schedulesData?.disponibilidadArea.filter(
          (schedule) =>
            schedule?.horainicial >= schedulesData?.rangominhorainicialventa &&
            schedule?.horafinal <= schedulesData?.rangomaxhorafinalventa
        );

        if (Number(schedulesData?.duracionventaminima) > 1) {
          const AvailableSchedulesPair = formHourBlocks(
            availableSchedules,
            Number(schedulesData?.duracionventaminima)
          );
          setAvailableSchedules(AvailableSchedulesPair);
        } else {
          availableSchedules && setAvailableSchedules(availableSchedules);
        }
      }
    };

    const currentDate = new Date();
    const validationSelectedDate = selectedDate;
    currentDate.setHours(0, 0, 0, 0);
    validationSelectedDate?.setHours(0, 0, 0, 0);

    if (validationSelectedDate && validationSelectedDate >= currentDate) {
      onSearchSchedules();

      const intervalId = setInterval(onSearchSchedules, 300000)

      return () => clearInterval(intervalId);
    }
  }, [selectedDate, sede]);

  useEffect(() => {
    setValue("availableSchedules", []);

    const onSetRates = async () => {
      if (sede && isLogged && authData?.tipoUsuario !== TIPO_USUARIO) {
        const rates: ServiceRates = await getRates({
          idServicio: sede,
          ventaIndividual: "N",
          fechaServicio: moment(selectedDate).format("DD/MM/YYYY"),
        });

        setRates(rates);
      }

      if (
        (sede &&
          isLogged &&
          authData?.tipoUsuario === TIPO_USUARIO &&
          documentNumber) ||
        (sede &&
          !isLogged &&
          spaceDetail?.detalleServicio.valorCero === "S" &&
          documentNumber &&
          documentType)
      ) {
        const rates: ServiceRates = await getRates({
          idServicio: sede,
          ventaIndividual: "S",
          fechaServicio: moment(selectedDate).format("DD/MM/YYYY"),
          abreviatura: documentType,
          identificacion: documentNumber,
        });

        setRates(rates);
      }
    };

    const currentDate = new Date();
    const validationSelectedDate = selectedDate;
    currentDate.setHours(0, 0, 0, 0);
    validationSelectedDate?.setHours(0, 0, 0, 0);

    if (validationSelectedDate && validationSelectedDate >= currentDate) {
      sede && onSetRates();
    }

  }, [sede, selectedDate, documentNumber]);

  const formHourBlocks = (
    availableSchedules: AvailableSchedule[],
    duracionventaminima: number
  ): AvailableSchedule[] => {
    const hourBlocks: AvailableSchedule[] = [];

    availableSchedules.reduce((currentBlock, schedule) => {
      if (!currentBlock) {
        return schedule;
      }

      if (currentBlock.horafinal === schedule.horainicial) {
        currentBlock.horafinal = schedule.horafinal;
      } else {
        hourBlocks.push({ ...currentBlock });
        currentBlock = schedule;
      }

      if (
        currentBlock.horafinal === schedule.horafinal &&
        parseInt(currentBlock.horafinal) - parseInt(currentBlock.horainicial) >=
        duracionventaminima
      ) {
        hourBlocks.push({ ...currentBlock });
        currentBlock = null;
      }

      return currentBlock;
    }, null as AvailableSchedule | null);

    return hourBlocks;
  };

  const getPurchaseUser = (data: PurchaseForm) => {
    const userRates: InfoRate[] =
      spaceDetail?.detalleServicio.campoJson.compraIndividual ===
        COMPRA_INDIVIDUAL_ACTIVA
        ? [
          rates?.tarifasUsuarios.find(
            (beneficiary: InfoRate) =>
              beneficiary.identificacionUsuario === data.benefeciariesSelected
          )!,
        ]
        : data.benefeciariesSelected?.map(
          (beneficiaryId: string) =>
            rates?.tarifasUsuarios.find(
              (beneficiary: InfoRate) =>
                beneficiary.identificacionUsuario === beneficiaryId
            )!
        );

    const purchaseUser: UsuarioCompra[] = userRates?.map(
      (userRate: InfoRate) => {
        if (isLogged && authData?.tipoUsuario !== TIPO_USUARIO) {
          const beneficiary = beneficiarioVOs.find(
            (beneficiary: Beneficiary) =>
              beneficiary?.identificacion === userRate?.identificacionUsuario
          );

          if (beneficiary) {
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion: beneficiary?.abreviatura,
              numIdentificacion: beneficiary?.identificacion,
              nombre: beneficiary?.nombre,
              correo: "",
              fechaNacimiento: moment(beneficiary?.fechaNacimiento).format(
                "YYYY/MM/DD"
              ),
              genero: beneficiary?.sexo,
              edad: Number(userRate?.edadUsuario),
              valor: userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: Number(
                userRate?.tarifa?.idCategoriaUsuarioCdaCategoriaUsuario
              ),
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          } else {
            const birthDate =
              data?.aditionalForm && data?.aditionalForm[0]?.birthDate;
            const fechaNac = moment(birthDate, "YYYY-MM-DD");
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentType,
              numIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentNumber,
              nombre:
                data?.aditionalForm &&
                data?.aditionalForm[0]?.firstName +
                " " +
                data?.aditionalForm[0]?.secondName,
              correo: "",
              fechaNacimiento:
                data?.aditionalForm &&
                moment(data?.aditionalForm[0]?.birthDate).format("YYYY/MM/DD"),
              genero: data?.aditionalForm && data?.aditionalForm[0]?.gender,
              edad: moment().diff(fechaNac, "years"),
              valor: userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: 4,
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          }
        } else {
          const beneficiary =
            boxValidator?.grupoFamiliar?.informacionGrupo?.find(
              (beneficiary: Beneficiary) =>
                beneficiary?.identificacion === userRate?.identificacionUsuario
            );
          if (beneficiary) {
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion: userRate?.abreviaturaUsuario,
              numIdentificacion: userRate?.identificacionUsuario,
              nombre: userRate?.nombreUsuario,
              correo: "",
              fechaNacimiento: moment(
                boxValidator?.afiliado.fechaNacimiento
              ).format("YYYY/MM/DD"),
              genero: boxValidator?.afiliado.sexo!,
              edad: Number(userRate?.edadUsuario),
              valor: userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: Number(boxValidator?.afiliado.idCate),
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };
            return usuarioCompra;
          } else {
            const birthDate =
              data?.aditionalForm && data?.aditionalForm[0]?.birthDate;
            const fechaNac = moment(birthDate, "YYYY-MM-DD");
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentType,
              numIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentNumber,
              nombre:
                data?.aditionalForm &&
                data?.aditionalForm[0]?.firstName +
                " " +
                data?.aditionalForm[0]?.secondName,
              correo: "",
              fechaNacimiento:
                data?.aditionalForm &&
                moment(data?.aditionalForm[0]?.birthDate).format("YYYY/MM/DD"),
              genero: data?.aditionalForm && data?.aditionalForm[0]?.gender,
              edad: moment().diff(fechaNac, "years"),
              valor: userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: 4,
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          }
        }
      }
    );

    return purchaseUser;
  };

  const handleFormSubmit: SubmitHandler<PurchaseForm> = async (
    data: PurchaseForm
  ) => {
    const userSelected =
      spaceDetail?.detalleServicio?.campoJson?.compraIndividual ===
        COMPRA_INDIVIDUAL_ACTIVA
        ? [
          rates?.tarifasUsuarios.find(
            (beneficiary: InfoRate) =>
              beneficiary.identificacionUsuario === data.benefeciariesSelected
          )!,
        ]
        : data.benefeciariesSelected?.map(
          (beneficiaryId: string) =>
            rates?.tarifasUsuarios.find(
              (beneficiary: InfoRate) =>
                beneficiary.identificacionUsuario === beneficiaryId
            )!
        );

    let startDate = "";
    let endDate = "";
    let pax = data.availableSchedules[0]?.pax;
    setPaxSelected(pax);
    if (data.availableSchedules.length > 1) {
      startDate = data.availableSchedules[0].horainicial;
      endDate = data.availableSchedules[1].horafinal;
    } else {
      startDate = data.availableSchedules[0].horainicial;
      endDate = data.availableSchedules[0].horafinal;
    }

    let reserveArea: MReserveSpace = {
      fechaServicio: moment(data.selectedDate).toDate().toISOString(),
      idServicio: data.lugar!,
      pax: Number(pax),
      celular: "",
      codSede: data.availableSchedules[0].codsede,
      email: "",
      horaFinal: endDate,
      horaInicial: startDate,
      identificacion: userSelected[0].identificacionUsuario,
      medioCompra: ABREVIACION_ORIGEN_WEB,
      nombre: userSelected[0].nombreUsuario,
      telefono: "",
      tipoDocumento: userSelected[0].abreviaturaUsuario,
      nombreServicio: spaceDetail?.detalleServicio?.nombre
        ? spaceDetail?.detalleServicio?.nombre
        : "",
      propietario:
        authData?.empleadoCRM && authData?.empleadoCRM.idEmpleado
          ? authData.empleadoCRM.idEmpleado
          : "",
    };

    let reservation = await onReserveSpace(reserveArea);
    reservation.fechaIngreso = startDate;
    reservation.fechaSalida = endDate;

    if (reservation) {
      setReservation(reservation);
      setFormData(data);
      handleOpenDialog();
    } else {
      setIsLoadingPurchase(false);
    }
  };


  const [urlPasarela, seturlPasarela] = useState("");
  const { getParamData } = useParam();

  useEffect(() => {
    const getParamUrlPasarela = async () => {
      const getParamUrlPasarela = await getParamData("URL_PASARELA");
      // Get url pasarela param
      if (getParamUrlPasarela?.data) {
        const dataParameter = getParamUrlPasarela?.data;
        seturlPasarela(dataParameter.valorParametro);
      }
    }
    getParamUrlPasarela();
  }, [urlPasarela]);

  const {
    paySale,
  } = useShoppingCar();

  const handlePaySale = async (sale: any) => {
    const paySaleResultInfo = await paySale(sale.idCompra);
    setIsLoadingPurchase(false);
    dispatch(setSuccess(null));
    window.location.replace(urlPasarela + paySaleResultInfo?.infoPago);
  };

  const onReserveArea = async (
    reservation: MReserveResponse,
    data: PurchaseForm
  ) => {
    handleCloseDialog();
    if (reservation) {
      setIsLoadingPurchase(true);

      const cotizacion = {
        numeroReserva: Number(reservation.numeroReserva),
        codigoEspacioSalon: reservation.codigoEspacioSalon,
        valorReserva: reservation.valorReserva,
        oportunidad: reservation.oportunidad,
        numTransaccion: reservation.numTransaccion,
      };

      const purchaseUser: UsuarioCompra[] = getPurchaseUser(data);

      const compraRequest: CompraRequest = {
        valorTotal: Number(cotizacion.valorReserva),
        porcentajeDcto: 0,
        origenCompra: ABREVIACION_ORIGEN_WEB,
        fechaInicioServicio: moment(data.selectedDate).format("YYYY/MM/DD"),
        fechaFinServicio: moment(data.selectedDate).format("YYYY/MM/DD"),
        idServicio: data.lugar,
        //cantidad: data.benefeciariesSelected.length,
        cantidad: paxSelected,
        medioCompra: ORIGEN_WEB,
        centroServicio: data.availableSchedules[0].codsede,
        compraUsuarios: purchaseUser,
        facturaUsuarios: null,
        entrevista: null,
        reserva: reservation,
        listTiempoEspacio: data.availableSchedules,
        afiliadoIdentificacion:
          !isLogged || (isLogged && authData?.tipoUsuario === TIPO_USUARIO)
            ? documentNumber
            : undefined,
        afiliadoTipoIden:
          !isLogged || (isLogged && authData?.tipoUsuario === TIPO_USUARIO)
            ? documentType
            : undefined,
      };

      if (Number(cotizacion.valorReserva) > 0) {
        const response = await compraValor(compraRequest);

        if (response) {
          response?.mensaje && dispatch(setSuccess(response?.mensaje));
          setTimeout(() => {
            setIsLoadingPurchase(false);
            dispatch(setSuccess(null));
            handlePaySale(response);
          }, 5000);
        } else {
          setIsLoadingPurchase(false);
        }
      } else {
        const response = await zeroPay(compraRequest);
        if (response) {
          setIsLoadingPurchase(false);
          navigate("/historial-compras");
        } else {
          setIsLoadingPurchase(false);
        }
      }
    } else {
      setIsLoadingPurchase(false);
    }
  };

  return (
    <div className="w-full">
      {(isLoadingReserve || isLoadingPurchase) && <Loading />}
      {((isLogged && authData?.tipoUsuario === TIPO_USUARIO) ||
        (!isLogged && spaceDetail?.detalleServicio.valorCero === "S")) && (
          <p className="text-principal-600 pb-4">{emailMessage}</p>
        )}
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormDatePicker
          disabled={isLoadingSchedules}
          register={register}
          setValue={setValue}
          unavailableDates={unavailableDates}
          errors={errors}
          firstAvailableDate={null}
        />

        <div className="py-3 border-b">
          <p className="font-bold text-principal-600 py-2">¿En que lugar?</p>
          <FormControl className="w-full">
            <InputLabel id="lugar">Lugar</InputLabel>
            <Select
              labelId="lugar"
              label="lugar"
              defaultValue={""}
              {...register(`lugar`, { required: "El lugar es obligatorio" })}
            >
              {spaceDetail?.sedes?.map((sede, index) => (
                <MenuItem key={index} value={sede?.idServicio}>
                  {sede?.nombreCentroServicio} {sede?.nombreMunicipio}
                </MenuItem>
              ))}
            </Select>
            {errors.lugar && errors.lugar && (
              <p className="text-red-500">{String(errors?.lugar?.message)}</p>
            )}
          </FormControl>
        </div>

        <div className="py-3">
          <p className="font-bold text-principal-600 py-2">
            Horarios de reserva
          </p>
          <>
            {sede && selectedDate ? (
              <>
                {
                  <>
                    {isLoadingSchedules ? (
                      <SmallLoading />
                    ) : (
                      <>
                        {availableSchedules.length > 0 && isValidDate ? (
                          <AvailableSchedules
                            availableSchedules={availableSchedules}
                            errors={errors}
                            register={register}
                            setValue={setValue}
                            unregister={unregister}
                            watch={watch}
                          />
                        ) : (
                          <p>No hay horarios disponibles para este dia</p>
                        )}
                      </>
                    )}
                  </>
                }
              </>
            ) : (
              <p>Primero debes seleccionar un dia y un centro de servicio</p>
            )}
          </>
        </div>

        <div className="py-3">
          <p className="font-bold text-principal-600 py-2">
            Encargado de la reserva
          </p>
          <>
            {sede && selectedDate ? (
              <>
                {
                  <>
                    {isLoadingRates ? (
                      <SmallLoading />
                    ) : (
                      <>
                        {rates && (
                          <div className="w-full pt-8">
                            <Rates
                              indOrigen={
                                spaceDetail?.detalleServicio?.indOrigen
                              }
                              compraIndividual={
                                spaceDetail?.detalleServicio.campoJson
                                  ?.compraIndividual
                              }
                              showPriceInline={true}
                              rates={rates}
                              errors={errors}
                              register={register}
                              setValue={setValue}
                              watch={watch}
                              unregister={unregister}
                              getValues={getValues}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                }
              </>
            ) : (
              <p>Recuerda que al ser encargado debes asistir</p>
            )}
          </>
        </div>

        <div className="flex justify-end">
          <LoadingButton
            type="submit"
            variant="contained"
            className="w-1/2"
            disabled={
              !selectedDate ||
              !sede ||
              selectedSchedules?.length <= 0 ||
              !benefeciariesSelected
            }
            loading={isLoadingReserve}
          >
            Comprar
          </LoadingButton>
        </div>

        <ReservationConfirmationDialog
          open={openDialog}
          onClose={handleCloseDialog}
          onConfirm={() => onReserveArea(reservation!, formData!)}
          value={reservation?.valorReserva}
          space={spaceDetail?.detalleServicio?.nombre}
        />
      </form>
    </div>
  );
};
