import { Checkbox } from '@mui/material';
import { PurchaseForm, ReservationForm } from 'models/purchaseForm';
import { AvailableSchedule } from 'models/spaceDetailModel';
import React from 'react'
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormUnregister, UseFormWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { setError } from 'store/slices/status';

interface AvailableSchedulesProps {
    availableSchedules: AvailableSchedule[];
    register: UseFormRegister<PurchaseForm>;
    errors: FieldErrors<PurchaseForm>;
    setValue: UseFormSetValue<PurchaseForm>;
    watch: UseFormWatch<PurchaseForm>;
    unregister: UseFormUnregister<PurchaseForm>;
}

export const AvailableSchedules = ({ availableSchedules, errors, register, setValue, unregister, watch }: AvailableSchedulesProps) => {

    const dispatch = useDispatch<AppDispatch>();

    const atLeastOneCheckBoxSelected = (
        values: PurchaseForm["availableSchedules"]
    ) => values?.some(Boolean) && values.length > 2;

    const selectedSchedules: AvailableSchedule[] = watch("availableSchedules") || [];


    const handleCheckBoxChange = (selectedSchedule: AvailableSchedule) => {
        const parsedSelectedSchedule = selectedSchedule;

        // Verificar si el horario ya está seleccionado
        const isAlreadySelected = selectedSchedules.some(schedule => schedule.horainicial === parsedSelectedSchedule.horainicial);

        if (isAlreadySelected) {
            // Si ya está seleccionado, quitarlo de la lista de seleccionados
            const updatedSelectedSchedules = selectedSchedules.filter(schedule => schedule.horainicial !== parsedSelectedSchedule.horainicial);
            setValue("availableSchedules", updatedSelectedSchedules);
        } else {
            const newSelectedSchedules = [...selectedSchedules, parsedSelectedSchedule];

            // Verificar si se han seleccionado más de 2 horarios
            if (newSelectedSchedules.length > 2) {
                // Mostrar un mensaje de error o aviso
                dispatch(setError('No se pueden seleccionar más de 2 horarios'));
                setTimeout(() => {
                    dispatch(setError(null));
                }, 3000);

            } else {
                // Verificar si los horarios seleccionados son consecutivos
                if (newSelectedSchedules.length === 2) {
                    const [firstSchedule, secondSchedule] = newSelectedSchedules;
                    if (firstSchedule.horafinal === secondSchedule.horainicial || firstSchedule.horainicial === secondSchedule.horafinal) {
                        setValue("availableSchedules", newSelectedSchedules);
                    } else {
                        // Mostrar un mensaje de error o aviso
                        dispatch(setError('Los horarios no son consecutivos'));
                        setTimeout(() => {
                            dispatch(setError(null));
                        }, 3000);
                    }
                } else {
                    setValue("availableSchedules", newSelectedSchedules);
                }
            }
        }
    };

    return (
        <div className='flex flex-row p-2 overflow-x-scroll'>
            {
                availableSchedules?.map((schedule, i) => (
                    <div className='flex border rounded p-1 mr-2' key={i}>
                        <div>
                            <p>{schedule.horainicial}</p>
                            <p>{schedule.horafinal}</p>
                        </div>
                        <div>
                            <Checkbox
                                checked={selectedSchedules?.some((selectedSchedule: AvailableSchedule) => selectedSchedule?.horainicial === schedule.horainicial)}
                                onChange={() => handleCheckBoxChange(schedule)} // Llamamos a la función personalizada
                            />
                        </div>
                    </div>
                ))
            }
            {errors.availableSchedules && (
                <p className="text-red-500">Selecciona al menos .</p>
            )}
        </div>
    )
}
