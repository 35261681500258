import { Assignment, CheckBox, HourglassTopOutlined } from '@mui/icons-material';
import { Step, StepLabel, Stepper } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'

interface TicketsStepperProps {
    currentStep: number;
    resolutionDueTimePoint: string;
    requestInProcessAtTimePoint: string
}

export const TicketsStepper = ({ currentStep, resolutionDueTimePoint, requestInProcessAtTimePoint }: TicketsStepperProps) => {
    const steps: any = [
        {
            icon: Assignment, label: moment(requestInProcessAtTimePoint).format('DD MMMM YYYY') ===
                'Invalid date'
                ? 'Pendiente No registra'
                : `${'Pendiente '}${moment(requestInProcessAtTimePoint).format(
                    'DD MMMM YYYY',
                )}`
        },
        { icon: HourglassTopOutlined, label: 'En proceso' },
        {
            icon: CheckBox, label: moment(resolutionDueTimePoint).format('DD MMMM YYYY') === 'Invalid date'
                ? 'Completado No registra'
                : `${'Completado'} ${moment(resolutionDueTimePoint).format(
                    'DD MMMM YYYY',
                )}`
        },
    ];

    const [mobile, setMobile] = useState(window.innerWidth <= 1023);

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 1023);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return (
        <Stepper activeStep={currentStep} className='w-full center' orientation={mobile ? 'horizontal' : 'vertical'} >
            {steps.map((step: any, index: any) => {
                return (
                    <Step key={step.label}>
                        <StepLabel color={index <= currentStep ? 'primary' : 'disabled'} StepIconProps={{ color: index <= currentStep ? 'primary' : 'disabled' }} StepIconComponent={step.icon}
                        >{step.label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    )
}
