import React, { useEffect, useState } from "react";
import { Business } from "models/business";
import { Category } from "models/category";
import { ServiceType } from "./ScreenTypes/ServiceType";
import { Quotas } from "./ScreenTypes/Quotas";
import { Balances } from "./ScreenTypes/Balances";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { HotelsList } from "./ScreenTypes/HotelsList";
import { Spaces } from "./ScreenTypes/Spaces";
import { HousingSubsidy } from './ScreenTypes/HousingSubsidy';
import {
  ST_CUPOS,
  ST_ESPACIOS,
  ST_HOTEL,
  ST_KIT_ESCOLAR,
  ST_SALDOS,
  ST_SERVICIO,
  ST_SUB_MONETARIO,
  ST_SUB_VIVIENDA,
  ST_POSTULACION,
  PARAM_CANTIDAD_SERVICIOS_PAGINADO,
  ST_RE_CAPTCHA
} from "utils/constants";
import { MonetarySubsidy } from "./ScreenTypes/MonetarySubsidy";
import { HousingApplication } from './ScreenTypes/HousingApplication';
import { SchoolKit } from "./ScreenTypes/SchoolKit";
import { useParam } from "hooks/useParam";
import { ServiceTypeRecaptcha } from "./ScreenTypes/ServiceTypeRecaptcha";

interface ScreenTypeProps {
  selectedCategory?: Category;
  selectedBusiness?: Business;
  isLoadingCategories?: any;
}

export const ScreenType = ({
  selectedCategory,
  selectedBusiness,
  isLoadingCategories,
}: ScreenTypeProps) => {

  
  const { getParamData } = useParam();
  const [pageableParam, setpageableParam] = useState(5);
  

  useEffect(() => {
    const getPageableParam = async () => {
      const param = await getParamData(PARAM_CANTIDAD_SERVICIOS_PAGINADO);
      const pageSize: number = param?.data?.valorParametro && (typeof parseInt(param?.data?.valorParametro) === 'number') ? parseInt(param?.data?.valorParametro) : 5
      setpageableParam(pageSize);
    }

    getPageableParam();

  }, [])


  const purpleTheme = createTheme({
    palette: {
      primary: {
        main: selectedBusiness?.color || "#000",
      },
    },
  });

  let mapScreenType: Map<string, JSX.Element> = new Map<string, JSX.Element>();

  mapScreenType.set(
    ST_SERVICIO,
    <ServiceType
      selectedCategory={selectedCategory}
      selectedBusiness={selectedBusiness}
      isLoadingCategories={isLoadingCategories}
      pageableParam={pageableParam}
    />
  );

  mapScreenType.set(
    ST_RE_CAPTCHA,
    <ServiceTypeRecaptcha
      selectedCategory={selectedCategory}
      selectedBusiness={selectedBusiness}
      isLoadingCategories={isLoadingCategories}
      pageableParam={pageableParam}
      validateRecaptcha={true}
    />
  );

  mapScreenType.set(ST_SALDOS, <Balances selectedCategory={selectedCategory} />);

  mapScreenType.set(ST_CUPOS, <Quotas selectedCategory={selectedCategory} />);

  mapScreenType.set(
    ST_HOTEL,
    <HotelsList
      pageableParam={pageableParam}
      selectedBusiness={selectedBusiness}
      selectedCategory={selectedCategory} 
    />
  );

  mapScreenType.set(ST_ESPACIOS, <Spaces selectedCategory={selectedCategory} pageableParam={pageableParam}/>);

  mapScreenType.set(ST_SUB_VIVIENDA, <HousingSubsidy selectedCategory={selectedCategory} />);

  mapScreenType.set(ST_SUB_MONETARIO, <MonetarySubsidy selectedBusiness={selectedBusiness} selectedCategory={selectedCategory} />);

  mapScreenType.set(
    ST_POSTULACION,
    <HousingApplication
      color={selectedBusiness?.color!}
      selectedCategory={selectedCategory}
    />,
  );
  mapScreenType.set(ST_KIT_ESCOLAR, <SchoolKit selectedCategory={selectedCategory} />);

  return (
    <ThemeProvider theme={purpleTheme}>
      {selectedCategory && mapScreenType.get(selectedCategory.tipoPantalla)}
    </ThemeProvider>
  );
};
