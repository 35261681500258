import { ArrowBackIos, CalendarToday, Hotel, LocationOn, Person } from '@mui/icons-material'
import { Button, Divider, IconButton } from '@mui/material'
import { HotelForm } from 'models/purchaseForm'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'

interface InformationBarProps {
    hotelData: HotelForm;
    totalPersons: number
}

export const HoltelInformationBar = ({ hotelData, totalPersons }: InformationBarProps) => {

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(-1);
    }

    return (
        <div className='flex flex-wrap flex-row justify-between w-full bg-principal-0 py-4 px-8 bg-white border rounded-md font-bold text-principal-600'>
            <div className='flex items-center px-2'>
                <IconButton
                    size="large"
                    edge="start"
                    aria-label="menu"
                    onClick={() => handleNavigate()}
                >
                    <ArrowBackIos />
                </IconButton>
                <p className='px-1'>Volver</p>
            </div>

            <Divider orientation="vertical" flexItem />

            <div className='flex items-center px-2'>
                <LocationOn />
                <p className='px-1'>{hotelData.hotelName}</p>
            </div>

            <Divider orientation="vertical" flexItem />

            <div className='flex items-center px-2'>
                <CalendarToday />
                <p className='px-1'>{moment(hotelData?.initialDate).format('dddd, D MMMM YYYY')} - {moment(hotelData?.finishDate).format('dddd, D MMMM YYYY')}</p>
            </div>

            <Divider orientation="vertical" flexItem />

            <div className='flex items-center px-2'>
                <Hotel />
                <p className='px-1'>1 Habitación</p>
            </div>

            <Divider orientation="vertical" flexItem />

            <div className='flex items-center px-2'>
                <Person />
                <p className='px-1'>{totalPersons} {totalPersons > 1 ? 'Personas' : 'Persona'}</p>
            </div>
        </div>
    )
}
