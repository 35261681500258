import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { useGenericData } from "hooks/useGenericData";
import { SubmitHandler, useForm } from "react-hook-form";
import { AccountCircle } from "@mui/icons-material";
import { MSaleWithOutLoginForm } from "models/purchaseForm";
import { setIndividualUserData } from "store/slices/individualSale";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
}

export const IndividualSaleDialog = ({
  open,
  onClose,
}: ConfirmationDialogProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const { authData } = useSelector((state: RootState) => state.auth);

  const [identificationTypes, setIdentificationTypes] = useState<any[]>([]);

  const { getIdentificationsTypes } = useGenericData();

  useEffect(() => {
    const onGetGetIdentificationsTypes = async () => {
      const identificationTypesResp = await getIdentificationsTypes();
      setIdentificationTypes(
        identificationTypesResp?.identificationTypes
          ? identificationTypesResp?.identificationTypes
          : []
      );
    };
    onGetGetIdentificationsTypes();
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<MSaleWithOutLoginForm>();

  const handleFormSubmit: SubmitHandler<MSaleWithOutLoginForm> = async (
    data: MSaleWithOutLoginForm
  ) => {
    dispatch(setIndividualUserData(data));
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText className="flex items-center justify-center py-2 text-principal-250">
          <AccountCircle className="mr-1" />
          Asesor:{" "}
          <span className="font-bold text-principal-600">
            {authData?.afiliado?.primerNombre}{" "}
            {authData?.afiliado?.primerApellido}
          </span>
        </DialogContentText>
        <div className="w-full border rounded-lg">
          <div className="w-full bg-principal-350 py-2 rounded-t-lg text-center font-bold text-principal-250 ">
            Venta individual
          </div>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="p-4">
              <FormControl className="w-full mb-2">
                <InputLabel id="tipo-identificacion">
                  Tipo de identificación
                </InputLabel>
                <Select
                  labelId="tipo-identificacion"
                  label="Tipo de identificación"
                  defaultValue={""}
                  {...register(`documentType`, {
                    required: "El tipo de identificación es obligatorio",
                  })}
                >
                  {identificationTypes?.map((id, index) => (
                    <MenuItem key={index} value={id?.ABREVIATURA}>
                      {id?.DESCRIPCION}
                    </MenuItem>
                  ))}
                </Select>
                {/* {errors[formIndex]?.documentType && <p className='text-red-500'>{String(errors[formIndex]?.documentType?.message)}</p>} */}
              </FormControl>

              <TextField
                defaultValue={""}
                className="w-full"
                label="Número de documento"
                {...register(`documentNumber`, {
                  required: "El número de documento es obligatorio",
                })}
              />
            </div>
            {/* {errors[formIndex]?.documentNumber && <p className='text-red-500'>{String(errors[formIndex]?.documentNumber?.message)}</p>} */}
            <Button
              type="submit"
              className="w-full py-2 bg-principal-550"
              variant="contained"
            >
              Aceptar
            </Button>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};
