import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, IconButton } from "@mui/material";
import { useParam } from "hooks/useParam";
import { MShoppingCar } from "models/salesShoppingCar";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "store";
import { setError } from "store/slices/status";
import { IND_ORIGEN_ESPACIOS, PARAM_MENSAJE_FECHA_PAGO_RESERVA, TIPO_USUARIO } from "utils/constants";

interface ShoppingCarSale {
  sale: MShoppingCar;
  handleOpenDialog: any;
  handlePay: any;
}

export const ShoppingCard = ({
  sale,
  handleOpenDialog,
  handlePay,
}: ShoppingCarSale) => {

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const { authData } = useSelector((state: RootState) => state.auth);


  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [finishDate, setFinishDate] = useState('');
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('')

  const { getParamData } = useParam()

  const onPay = () => {
    try {
      const startDateFormat = moment.utc(sale?.fechaInicioCompra);
      const currentDate = moment.utc();

      if (currentDate.isAfter(startDateFormat) && authData.tipoUsuario !== TIPO_USUARIO && sale.indOrigen === IND_ORIGEN_ESPACIOS) {
        dispatch(setError(paymentErrorMessage));
        setTimeout(() => {
          dispatch(setError(null));
        }, 3000);
      } else {
        setIsLoading(prev => !prev)
        handlePay(sale.idCompra)
      }

    } catch {
      setIsLoading(prev => !prev)
    }
  }

  useEffect(() => {
    const getPaymentErrorMessage = async () => {
      const errorMessage = await getParamData(PARAM_MENSAJE_FECHA_PAGO_RESERVA)
      errorMessage?.data?.valorParametro && setPaymentErrorMessage(errorMessage?.data?.valorParametro);
    }

    getPaymentErrorMessage();
  }, [])

  useEffect(() => {
    sale?.fechaInicioCompra && setStartDate(moment(sale?.fechaInicioCompra).format('hh:mm A'));
    sale?.fechaFinCompra && setFinishDate(moment(sale?.fechaFinCompra).format('hh:mm A'));
  }, [])

  return (
    <div
      className="flex flex-wrap w-full rounded-lg border p-4 border-s-8 items-center justify-center"
      style={{ borderLeftColor: sale.color }}
    >
      <div className="flex flex-col w-full sm:w-2/3 m-0 text-principal-650 ">
        <p className="font-bold " style={{ color: sale.color }}>
          {sale?.nombreNegCat}
        </p>
        <div
          className="cursor-pointer"
          onClick={() =>
            navigate(`/detalle-servicio/${sale.idServicio}/${sale.idCompra}`)
          }
        >
          {sale?.nombreServCentro}
        </div>
        <div className="flex flex-row py-1">
          <p className="pr-2 font-bold">Valor:</p>
          <p>
            {sale?.valorTotal.toLocaleString("es-CO", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
            })}
          </p>
        </div>
        <div className="flex flex-row py-1">
          <p className="pr-2 font-bold">Fecha de inicio:</p>
          <p>{moment.utc(sale?.fechaServicio).format("MMMM DD [de] YYYY")}</p>
        </div>
        {
          sale.indOrigen === IND_ORIGEN_ESPACIOS &&
          <div className="flex flex-row py-1">
            <p className="pr-2 font-bold">Hora reserva:</p>
            <p>{startDate} - {finishDate}</p>
          </div>
        }
        {/*<div className="flex flex-row py-1">
          <p className="pr-2 font-bold">Fecha límite de pago:</p>
          <p>{
            sale.indOrigen === IND_ORIGEN_ESPACIOS ?
              moment.utc(sale?.fechaFinCompra).format("MMMM DD [de] YYYY") :
              moment.utc(sale?.fechaVigencia).format("MMMM DD [de] YYYY")
          }</p>
        </div>*/}
        {/*
          sale.indOrigen === IND_ORIGEN_ESPACIOS &&
          <div className="flex flex-row py-1">
            <p className="pr-2 font-bold">Hora límite de pago:</p>
            <p>{startDate}</p>
          </div>
        */}
        <div className="flex flex-row py-1">
          <p className="pr-2 font-bold">Estado de la compra:</p>
          <p className="font-bold" style={{ color: sale.color }}>
            {sale?.nombreEstado}
          </p>
        </div>
        <div className="flex flex-row py-1">
          <p className="pr-2 font-bold">Oportunidad:</p>
          <p>{sale?.idOportunidad}</p>
        </div>
      </div>
      <div className="flex justify-center items-center w-full sm:w-1/3 m-0 px-2 h-fit py-2">
        <LoadingButton
          variant="contained"
          className="h-min px-6 mr-2"
          onClick={onPay}
          loading={isLoading}
        >
          Continuar compra
        </LoadingButton>
        <IconButton
          aria-label="Eliminar"
          onClick={() => handleOpenDialog(sale.idCompra)}
        >
          <Delete color="error" />
        </IconButton>
      </div>
    </div>
  );
};
