import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const Admin = () => {
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    doLogin();
  }, []);

  const doLogin = async () => {
    const login = await loginWithRedirect();
  };

  return <div></div>;
};
