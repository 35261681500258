import { PopularService } from 'models/popularService'
import React from 'react'

interface PopularServicesProps {
    popularServices?: PopularService[];
}

export const PopularServices = ({ popularServices }: PopularServicesProps) => {
    return (
        <div className='flex flex-wrap justify-between py-2'>
            {
                popularServices?.map((service, index) => (
                    <div
                        className="flex flex-row items-center py-2"
                        key={index}
                    >
                        <img
                            src={service?.icono}
                            style={{ width: "25px" }}
                        />
                        <p className="my-0 mx-2">
                            {service?.descripcion}
                        </p>
                    </div>
                ))
            }
        </div>
    )
}
