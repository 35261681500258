import React from "react";
import manosComfenalco from "../assets/images/manosComfenalco.png";

export const Loading = () => {
  return (
    <div className="fixed h-full top-0 left-0 z-50 flex justify-center items-center flex-col w-full bg-black bg-opacity-50">
      <img
        src={manosComfenalco}
        alt="Icono de carga"
        className="animate-spin h-16 w-16"
      />
      <p className="text-principal-50 font-semibold text-xl mt-4">
        Estamos preparando todo para ti...
      </p>
    </div>
  );
};
