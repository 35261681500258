import React from "react";
import { Search } from "@mui/icons-material";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppDispatch, RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { setService } from "store/slices/searchServices";

interface SearchTextField {
  styles: string
}

export const SearchTextField = ({ styles }: SearchTextField) => {

  const dispatch = useDispatch<AppDispatch>();

  const service = useSelector(
    (state: RootState) => state.searchServices.service);

  const navigate = useNavigate();

  const { handleSubmit, register } = useForm({
    defaultValues: {
      search: service,
    },
  })

  const onChange = (data: any) => {
    dispatch(setService(data?.search));
    navigate('/search')
  }

  return (
    <div className={styles}>
      <form onSubmit={handleSubmit(onChange)}>
        <TextField
          className="w-full"
          size="small"
          label="Buscar servicio"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  type="submit"
                  aria-label="toggle password visibility"
                  edge="end"
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          {...register(`search`)}
        />
      </form>
    </div>
  );
};
