import { Person, Warning } from '@mui/icons-material';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { ClockIcon, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { InterviewSelectedColDTO } from 'models/interviewSelected';
import { PurchaseForm } from 'models/purchaseForm';
import moment from 'moment';
import React from 'react'
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { SmallLoading } from './SmallLoading';
import es from 'date-fns/locale/es';

interface InterviewsProps {
    register: UseFormRegister<PurchaseForm>
    setValue: UseFormSetValue<PurchaseForm>
    watch: UseFormWatch<PurchaseForm>
    availableInterviewsByDay: InterviewSelectedColDTO[] | undefined
    availableDays: string[] | undefined
    availableInterviews: InterviewSelectedColDTO[] | undefined
    isLoadingInterviews: boolean
}
export const InterviewsCol = ({ availableInterviews, register, setValue, watch, availableInterviewsByDay, availableDays, isLoadingInterviews }: InterviewsProps) => {

    const formValues = watch();

    const atLeastRadioSelected = (values: PurchaseForm['interviewSelected']) => {
        return values ? true : false;
    };

    const shouldDisableDate = (date: Date) => {
        const dateParse = moment(date).format('DD/MM/YYYY');
        return !availableDays!.includes(dateParse);
    }

    return (
        <>
            {
                isLoadingInterviews ? <SmallLoading /> :
                    <>
                        {
                            availableInterviews?.length === 0 ?
                                <>
                                    <div className='flex flex-col items-center w-full py-4'>
                                        <Warning color='warning'></Warning>
                                        <p className='font-bold'>No se encontraron entrevistas disponibles en la fecha seleccionada</p>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='w-full' >

                                        <Typography className='font-bold text-2xl pt-8 pb-4' color='primary'>Horarios de entrevista</Typography>

                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                                            <DatePicker
                                                className='w-full'
                                                label='Fecha'
                                                format="dd/MM/yyyy"
                                                shouldDisableDate={shouldDisableDate}
                                                slots={
                                                    { ...register('selectedDate', { required: 'Este campo es requerido' }) }
                                                }
                                                defaultValue={formValues.selectedDate}
                                                disablePast={true}
                                                onChange={(date: Date | null) => {
                                                    setValue("selectedDate", date);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div >
                                    <div className='w-full pt-2'>
                                        {availableInterviewsByDay?.length === 0 ?
                                            <div className=''>
                                                <Warning color='warning'></Warning>
                                                <p className='font-bold'>No se encontraron entrevistas disponibles en la fecha seleccionada</p>
                                            </div>
                                            : availableInterviewsByDay?.map(interview => <div key={interview.codigo} className="w-full py-2 border-b">
                                                <div>
                                                    <div className='w-full flex items-center'>
                                                        <ClockIcon color='primary'></ClockIcon>
                                                        <span className='text-lg pl-4 text-principal-650'>{interview.hora}</span>
                                                        <FormControl className='ml-auto'>
                                                            <RadioGroup row name="row-radio-buttons-group">
                                                                <FormControlLabel value={interview.codigo} control={
                                                                    <Radio {...register('interviewSelected', {
                                                                        validate: atLeastRadioSelected,
                                                                    })}
                                                                        value={interview.codigo}
                                                                        checked={formValues.interviewSelected == interview?.codigo} />} label="" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>)}
                                    </div>
                                </>
                        }
                    </>
            }
        </>
    )
}
