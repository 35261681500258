import { Button, Typography } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react'


interface staticCounterProps {
    aditionalsCounter: {
        adultsCounter: number;
        childCounter: number;
    }
    setAditionalsCounter: Dispatch<SetStateAction<{
        adultsCounter: number;
        childCounter: number;
    }>>
}

export const StaticCounter = ({ aditionalsCounter, setAditionalsCounter }: staticCounterProps) => {
    return (
        <div>
            <div className='p-4 pt-0 border-b'>
                <Typography className='font-bold text-lg' color='primary'>INVITADO ADICIONAL AL GRUPO FAMILIAR</Typography>
            </div>
            <div className='flex flex-row justify-between w-full items-center py-4 border-b border-principal-350 px-4'>

                <div className='w-1/4'>
                    <p className='text-principal-650 font-bold'>Adultos</p>
                </div>
                <div className='flex flex-row rounded-lg items-center'>
                    <Button variant="contained" onClick={() => aditionalsCounter.adultsCounter > 0 && setAditionalsCounter({ ...aditionalsCounter, adultsCounter: aditionalsCounter.adultsCounter - 1 })}>-</Button>

                    {
                        <p className='px-6'>{aditionalsCounter.adultsCounter}</p>
                    }

                    <Button variant="contained" onClick={() => setAditionalsCounter({ ...aditionalsCounter, adultsCounter: aditionalsCounter.adultsCounter + 1 })}>+</Button>
                </div >
            </div>
            <div className='flex flex-row justify-between w-full items-center py-4 border-b border-principal-350 px-4'>
                <div className='w-1/4'>
                    <p className='text-principal-650 font-bold'>Niños</p>
                    <p className='text-principal-650'>De 0 a 6 años</p>
                </div>
                <div className='flex flex-row rounded-lg items-center'>

                    <Button variant="contained" onClick={() => aditionalsCounter.childCounter > 0 && setAditionalsCounter({ ...aditionalsCounter, childCounter: aditionalsCounter.childCounter - 1 })}>-</Button>

                    {
                        <p className='px-6'>{aditionalsCounter.childCounter}</p>
                    }

                    <Button variant="contained" onClick={() => setAditionalsCounter({ ...aditionalsCounter, childCounter: aditionalsCounter.childCounter + 1 })}>+</Button>
                </div >
            </div>
        </div>
    )
}
