import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface formLightState {
    formLightData: any;
    errorMessage: "";
    interviewData: any;
}

const initialState: formLightState = {
    formLightData: {},
    errorMessage: "",
    interviewData: {},
};

export const formLightSlice = createSlice({
    name: "formLight",
    initialState,
    reducers: {
        setFormLightData: (state, action: PayloadAction<any>) => {
            state.formLightData = action.payload;
        },
        setInterviewData: (state, action: PayloadAction<any>) => {
            state.interviewData = action.payload;
        }
        
    },
});

// Action creators are generated for each case reducer function
export const { setFormLightData, setInterviewData } = formLightSlice.actions;
