import { useState } from 'react';
import integrationApi from '../api/integrationApi';
import { MSubsidyMonetaryRequest, MSubsidyMonetaryResponse } from '../models/subsidyMonetaryModel';

export const useSubsidyMonetary = () => {
    const [isLoadingMonetarySubsidy, setIsLoadinMonetarySubsidy] = useState(true);

    const urlMonetarySubsidy: string = "subsidioMonetario";

    const getMonetarySubsidyInfo = async (subsidyMonetaryRequest: MSubsidyMonetaryRequest) => {

        setIsLoadinMonetarySubsidy(true);

        try {
            const monetarySubsidy = await integrationApi.post<MSubsidyMonetaryResponse>(`${urlMonetarySubsidy}/getSubsidio`, subsidyMonetaryRequest);

            setIsLoadinMonetarySubsidy(false);

            return monetarySubsidy?.data;

        } catch (error) {
            setIsLoadinMonetarySubsidy(false);
            return null;
        }

    }

    return {
        isLoadingMonetarySubsidy,
        getMonetarySubsidyInfo
    }
}
