import { useEffect, useState } from "react";
import requestApi from '../api/cartApi'
import { Parameter } from "models/parameter";
import { COMPRA_DE_CARTERA_PARRAFO, COMPRA_SERVICIOS_PRIMER_PARRAFO, COMPRA_SERVICIOS_SEGUNDO_PARRAFO, COMPRA_SERVICIO_MV, CUPO_CREDITO_MV, CUPO_CREDITO_PRIMER_PARRAFO, CUPO_CREDITO_SEGUNDO_PARRAFO, LIBRE_INVERSION_PARRAFO } from "utils/constants";
import integrationApi from 'api/integrationApi';


export const useCreditOffersParams = () => {

    const [isLoadingService, setIsLoadingService] = useState(true);
    const [subsidyCreditParams, setSubsidyCreditParams] = useState({
        creditBuyFirstParagraph: '',
        creditBuySecondParagraph: '',
        quotaFirstParagraph: '',
        quotaSecondParagraph: '',
        cupoCreditoMv: '',
        compraServicioMv: ''

    });

    const [goodwillCreditParams, setGoodwillCreditParams] = useState({
        freeInvestment: '',
        portfolioPurchase: '',
    });

    const urlParam = 'parametro';

    const urlCredit = 'credit'

    const getParamData = async () => {
        try {
            const creditBuyFirstParagraphRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${COMPRA_SERVICIOS_PRIMER_PARRAFO}`);
            const creditBuySecondParagraphRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${COMPRA_SERVICIOS_SEGUNDO_PARRAFO}`);
            const quotaFirstParagraphRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${CUPO_CREDITO_PRIMER_PARRAFO}`);
            const quotaSecondParagraphRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${CUPO_CREDITO_SEGUNDO_PARRAFO}`);
            const freeInvestmentRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${LIBRE_INVERSION_PARRAFO}`);
            const portfolioPurchaseRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${COMPRA_DE_CARTERA_PARRAFO}`);
            const cupoCreditoMvRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${CUPO_CREDITO_MV}`);
            const compraServicioMvRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${COMPRA_SERVICIO_MV}`);

            const creditBuyFirstParagraph = creditBuyFirstParagraphRequest?.data?.valorParametro;
            const creditBuySecondParagraph = creditBuySecondParagraphRequest?.data?.valorParametro;
            const quotaFirstParagraph = quotaFirstParagraphRequest?.data?.valorParametro;
            const quotaSecondParagraph = quotaSecondParagraphRequest?.data?.valorParametro;
            const freeInvestment = freeInvestmentRequest?.data?.valorParametro;
            const portfolioPurchase = portfolioPurchaseRequest?.data?.valorParametro;
            const cupoCreditoMv = cupoCreditoMvRequest?.data?.valorParametro;
            const compraServicioMv = compraServicioMvRequest?.data?.valorParametro;


            setSubsidyCreditParams({
                creditBuyFirstParagraph,
                creditBuySecondParagraph,
                quotaFirstParagraph,
                quotaSecondParagraph,
                cupoCreditoMv,
                compraServicioMv
            })

            setGoodwillCreditParams({
                freeInvestment,
                portfolioPurchase
            })

            setIsLoadingService(false);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getParamData();
    }, [])


    const getCreditOffers = async () => {
        try {

            const requestCreditOffer = integrationApi.get<any>(`${urlCredit}/consultaCreditoUsuario`);

            const creditResponse = await Promise.resolve(requestCreditOffer);

            return creditResponse.data;

        } catch (error) {
        }
    }


    return {
        goodwillCreditParams,
        subsidyCreditParams,
        isLoadingService,
        getCreditOffers
    }
}