import React, { useEffect, useState } from "react";

import { ServiceTypeForm } from "components/ServiceTypeForm";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { useService } from "hooks/useService";
import { ServiceImages } from "models/serviceImages";
import { ServiceData } from "models/serviceData";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "components/Loading";
import { ServiceDescription } from "components/ServiceDescription";
import { Embedded } from "components/Embedded";
import { MFile } from "models/MFile";
import {
  ES_EMBEBIDO,
  IND_ORIGEN_EDUCACION,
  OPCION_VENTA_INDIVIDUAL,
  TIPO_USUARIO,
} from "utils/constants";
import { useRates } from "hooks/useRates";
import moment from "moment";
import { ServiceRates } from "models/servicesRates";
import { UnavailableDates } from "models/uavailableDate";
import { ServiceTypeInterviewForm } from "components/ServiceTypeInterviewForm";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useBoxValidator } from "hooks/useBoxValidator";
import { ValidadorCaja } from "models/boxValidatorModel";
import manosComfenalco from "../../assets/images/manosComfenalco.png";
import { SaleWithOutLoginForm } from "components/SaleWithOutLoginForm";
import { IndividualSaleForm } from "components/IndividualSaleForm";
import { useExcludeService } from "hooks/useExcludeService";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { setIndividualUserData } from "store/slices/individualSale";
import { MSaleWithOutLoginForm } from "models/purchaseForm";


export const ServiceType = () => {
  const navigate = useNavigate();

  const { isLogged, authData } = useSelector((state: RootState) => state.auth);
  const { documentNumber, documentType } = useSelector(
    (state: RootState) => state.individualSale.individualUserData
  );


  const credentials = useSelector(
    (state: RootState) => state.credentials.credentials
  );

  const { getIsIncludedUser } = useExcludeService();

  const { getRates, isLoadingRates } = useRates();
  const { getServiceData, getFileData, getUnavailableDates } = useService();
  const { getBoxValidator } = useBoxValidator();

  const [isLoading, setIsLoading] = useState(true);
  const [rates, setRates] = useState<ServiceRates>();
  const [bannerImages, setBannerImages] = useState<ServiceImages[]>([]);
  const [serviceData, setServiceData] = useState<ServiceData>();
  const [fileData, setFileData] = useState<MFile[]>();
  const [unavailableDates, setUnavailableDates] =
    useState<UnavailableDates[]>();

  const [boxValidator, setBoxValidator] = useState<ValidadorCaja>();
  const [emailMessage, setEmailMessage] = useState("");

  const [firstAvailableDate, setFirstAvailableDate] = useState<string>();
  const dispatch = useDispatch<AppDispatch>();

  const { idService } = useParams();

  const purpleTheme = createTheme({
    palette: {
      primary: {
        main: serviceData?.colorNegocio || "#000",
      },
    },
  });

  // Creamos una función para verificar si una fecha está disponible
  const isDateAvailable = (
    dateToCheck: moment.Moment,
    unavailableDates: UnavailableDates[]
  ) => {
    const formattedDateToCheck = dateToCheck.format("DD/MM/YYYY");
    return !unavailableDates.some((unavailableDate) => {
      const formattedUnavailableDate = moment(
        unavailableDate.fechaNoDisponible
      ).format("DD/MM/YYYY");
      return formattedDateToCheck === formattedUnavailableDate;
    });
  };

  const getFirstAvailableDate = (
    unavailableDates: UnavailableDates[]
  ): string => {
    // Obtenemos la fecha de hoy como un objeto Moment
    let currentDate = moment();

    // Función para verificar si una fecha está disponible
    const isDateAvailable = (date: moment.Moment): boolean => {
      const dateStr = date.format("YYYY-MM-DD"); // Formato para comparación
      return !unavailableDates.some(
        (unavailableDate) =>
          moment
            .utc(unavailableDate.fechaNoDisponible)
            .add(5, "hours")
            .format("YYYY-MM-DD") === dateStr
      );
    };

    // Iteramos para encontrar la primera fecha disponible
    while (!isDateAvailable(currentDate)) {
      // Avanzamos al siguiente día
      currentDate = currentDate.add(1, "days");
    }

    return currentDate.format("DD/MM/YYYY");
  };

  useEffect(() => {
    const isExcludedUser = async () => {
      const isExcluded = isLogged && idService && await getIsIncludedUser(authData?.empresas?.informacionEmpresa[0]?.identificacion, idService);

      isExcluded && navigate('/home');
    }

    if (isLogged) {
      isExcludedUser();
    }
  }, [isLogged])

  useEffect(() => {
    const onGetRates = async () => {
      if (
        (isLogged && authData?.tipoUsuario === TIPO_USUARIO && authData?.cdaRolCdaOpcions?.some(
          (option: any) =>
            option?.idOpcionCdaOpcion == OPCION_VENTA_INDIVIDUAL
        ) && documentNumber) ||
        (!isLogged && serviceData?.valorCero === "S")
      ) {
        const rates: ServiceRates = await getRates({
          idServicio: Number(idService),
          ventaIndividual: "S",
          fechaServicio:
            firstAvailableDate != null
              ? firstAvailableDate
              : moment(new Date()).format("DD/MM/YYYY"),
          abreviatura: documentType,
          identificacion: documentNumber,
        });

        setRates(rates);
      }
    };

    const onGetBoxValidator = async () => {
      const boxValidatorResp = await getBoxValidator(
        documentType,
        documentNumber
      );

      if (credentials) {
        if (boxValidatorResp && boxValidatorResp.afiliado) {
          let mail = boxValidatorResp.afiliado.email;
          let mailTrans =
            mail?.slice(0, 3) +
            "***" +
            mail?.slice(mail.indexOf("@"), mail.indexOf("@") + 4) +
            "***";
          setEmailMessage(
            credentials.mensajeCorreoAfiliado!.replace("$CORREO", mailTrans)
          );
        }
      }

      setBoxValidator(boxValidatorResp);
    };

    if (documentType && documentNumber) {
      onGetRates();
      onGetBoxValidator();
    }
  }, [documentType, documentNumber, isLoading]);

  useEffect(() => {
    const onGetServicedata = async () => {
      setIsLoading(true);

      const { serviceData, serviceImages } = await getServiceData(
        Number(idService)
      );

      setServiceData(serviceData);
      setBannerImages(serviceImages);

      if (serviceData.indOrigen == IND_ORIGEN_EDUCACION) {
        if (idService && serviceData?.indOrigen) {
          const { fileData } = await getFileData(
            Number(idService),
            serviceData?.indOrigen
          );
          setFileData(fileData);
        }
      }

      const unavailableDates = await getUnavailableDates(Number(idService));

      setUnavailableDates(unavailableDates);

      const firstAvailableDate = getFirstAvailableDate(unavailableDates);

      setFirstAvailableDate(firstAvailableDate);

      if (isLogged && authData?.tipoUsuario !== TIPO_USUARIO) {
        const rates: ServiceRates = await getRates({
          idServicio: Number(idService),
          ventaIndividual: "N",
          fechaServicio: firstAvailableDate,
        });

        setRates(rates);
      }

      setIsLoading(false);
    };

    onGetServicedata();
  }, [isLogged]);

  useEffect(() => {
    if (!isLogged) {
      let data: MSaleWithOutLoginForm = { documentNumber: '', documentType: '' };
      dispatch(setIndividualUserData(data));
    }

  }, []);

  return (
    <ThemeProvider theme={purpleTheme}>
      {isLoading || isLoadingRates ? (
        <div className="h-full">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-wrap justify-around py-8 px-8 md:px-0 w-full bg-principal-0">
          {serviceData?.campoJson?.embebido === ES_EMBEBIDO ? (
            <div className="w-full p-4 ">
              <Embedded link={serviceData?.campoJson?.link} />
            </div>
          ) : (
            <>
              {authData?.tipoUsuario == TIPO_USUARIO &&
                authData?.cdaRolCdaOpcions?.some(
                  (option: any) =>
                    option?.idOpcionCdaOpcion == OPCION_VENTA_INDIVIDUAL
                ) && <IndividualSaleForm />}
              <div className="flex flex-col w-full md:w-[48%] rounded-lg border border-principal-350 bg-white">
                <ServiceDescription
                  bannerImages={bannerImages}
                  serviceData={serviceData}
                  fileData={fileData}
                />
              </div>

              <div className="flex flex-wrap w-full md:w-[48%] mt-4 md:mt-0 px-4 py-8 rounded-lg border border-principal-350 bg-white h-fit">
                {!isLogged &&
                  serviceData?.valorCero === "S" &&
                  !documentNumber &&
                  !documentType &&
                  (!serviceData?.campoJson.tieneEntrevista ||
                    serviceData?.campoJson.tieneEntrevista !== "S") &&
                  (!serviceData.campoJson.servicioIngles ||
                    serviceData.campoJson.servicioIngles !== "S")
                  ? (
                    <SaleWithOutLoginForm />
                  ) : (
                    <>
                      {isLogged || rates ? (
                        <>
                          {isLogged || rates ? (
                            <>
                              {isLogged &&
                                authData?.tipoUsuario === TIPO_USUARIO &&
                                !documentNumber &&
                                !documentType ? (
                                <div className="flex flex-col w-full items-center pt-4">
                                  <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                                    <img
                                      src={manosComfenalco}
                                      className="h-16 w-16"
                                    />
                                    <p className="text-principal-600 font-bold">
                                      Por favor, diligencia la información del
                                      usuario al que le vas a vender.
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {authData?.tipoUsuario === TIPO_USUARIO && (
                                    <p className="text-principal-600 w-full py-4 h-fit">
                                      {emailMessage}
                                    </p>
                                  )}
                                  {/* If the service  */}
                                  {serviceData?.campoJson.tieneEntrevista &&
                                    serviceData?.campoJson.tieneEntrevista ===
                                    "S" &&
                                    serviceData.campoJson.servicioIngles &&
                                    serviceData.campoJson.servicioIngles === "S" ? (
                                    <ServiceTypeInterviewForm
                                      serviceData={serviceData}
                                      rates={rates}
                                      unavailableDates={unavailableDates}
                                      firstAvailableDate={firstAvailableDate}
                                      boxValidator={boxValidator}
                                    />
                                  ) : (
                                    <ServiceTypeForm
                                      serviceData={serviceData}
                                      rates={rates}
                                      isLoadingRates={isLoadingRates}
                                      unavailableDates={unavailableDates}
                                      firstAvailableDate={firstAvailableDate}
                                      boxValidator={boxValidator}
                                      emailMessage={emailMessage}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {!isLogged && serviceData?.valorCero !== "S" && (
                                <div className="flex flex-col w-full items-center pt-4">
                                  <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                                    <img
                                      src={manosComfenalco}
                                      className="h-16 w-16"
                                    />
                                    <p className="text-principal-600 font-bold">
                                      Por favor, inicia sesión para realizar tu
                                      compra.
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {!isLogged && serviceData?.valorCero !== "S" && (
                            <div className="flex flex-col w-full items-center pt-4">
                              <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                                <img
                                  src={manosComfenalco}
                                  className="h-16 w-16"
                                />
                                <p className="text-principal-600 font-bold">
                                  Por favor, inicia sesión para realizar tu
                                  compra.
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
              </div>
            </>
          )}
        </div>
      )}
    </ThemeProvider>
  );
};
