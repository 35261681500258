import React, { useEffect, useState } from "react";
import logoMessenger from '../../assets/images/messenger.png'
import logoSuperSubsidio from '../../assets/images/supersubsidio.png'

export const Footer = () => {

    const [urlContactenos, setUrlContactenos] = useState<string>("");
    const [urlFacebook, setUrlFacebook] = useState<string>("");
    const [urlTerminCondi, setUrlTerminCondi] = useState<string>("");

    return (
        <div className=" text-sm flex mt-2 h-full w-full items-end " >
            <div className="bg-principal-500 text-white  color p-2 text-sm  flex mt-2 w-full">
                <div className="flex justify-between content-between w-full flex-wrap">
                    <div className="flex flex-wrap w-full lg:w-3/4">
                        <span className="w-full">
                            <a href={urlContactenos}>
                                Contáctenos
                            </a>
                        </span>
                        <span className=" flex flex-wrap w-full">Cualquier inconveniente con esta aplicación por favor comunicarse al
                            <a href="tel:+570328862727">(602) 8862727</a> - <a href="tel:018000938585">018000938585.</a>
                            o escribenos al chat.
                            <a href={urlFacebook} target="_blank">
                                <img src={logoMessenger} width="30" className="ml-2" />
                            </a>
                        </span>
                        <span className="w-full">
                            <a target="_blank" rel="noopener noreferrer"
                                href={urlTerminCondi} >
                                Términos y condiciones
                            </a>
                        </span>
                    </div>
                    <div className="flex w-full lg:w-1/4 pr-20">
                        <div className="flex w-full content-center  justify-end pr-5">
                            <img src={logoSuperSubsidio} className="w-full " />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}