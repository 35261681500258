import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Business } from 'models/business';
import { MSpace } from 'models/spacesRequest';
interface CardProps {
    space: MSpace
    screenType?: string
    selectedBusiness?: Business;
}

export const SpacesCard = ({ space, screenType, selectedBusiness }: CardProps) => {
    const navigate = useNavigate();

    const handdleNavigate = () => {
        navigate(`/${screenType}/${space?.idServicio}`, { state: { color: selectedBusiness?.color } })
    }
    return (
        <div className='w-72 md:w-[26rem] mx-4 rounded-lg pb-2 bg-white shadow-md shadow-black/10 border border-principal-350 cursor-pointer' onClick={() => handdleNavigate()}>
            <div className='w-full h-40 md:h-60 rounded-t-lg bg-cover' style={{ backgroundImage: `url(${space?.imagen})` }} />
            <div
                className="flex flex-wrap p-4 items-center h-24 md:h-20"
            >
                <p className='w-full text-md font-bold text-principal-300'>{space?.nombre}</p>
                <p className='w-full text-principal-300'>{space?.nombreMunicipio}</p>
            </div>
        </div>
    )
}
