import { Paginatior } from "components/Paginatior";
import { ServiceCard } from "components/ServiceCard";
import { SmallLoading } from "components/SmallLoading";
import { useCategoryService } from "hooks/useCategoryService";
import { Business } from "models/business";
import { Category } from "models/category";
import { Service } from "models/service";
import { ServiceRequest } from "models/serviceRequest";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import manosComfenalco from "../../../assets/images/manosComfenalco.png";
import { IsCategoryAvailable } from "utils/CategoryAvailable";
import { useReCaptcha } from "hooks/useReCaptcha";
import { } from 'utils/constants'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface ServiceTypeProps {
  selectedCategory?: Category;
  selectedBusiness?: Business;
  isLoadingCategories?: any;
  pageableParam: number;
  validateRecaptcha?: boolean;
}

export const ServiceTypeRecaptcha = ({
  selectedCategory,
  selectedBusiness,
  isLoadingCategories,
  pageableParam,
  validateRecaptcha
}: ServiceTypeProps) => {

  console.log('servicetype recaptcha entered')

  const { executeRecaptcha } = useGoogleReCaptcha();
  const { authData, isLogged } = useSelector((state: RootState) => state.auth);

  const { getCategoryServices, isLoadingService } = useCategoryService();

  const [services, setServices] = useState<Service[]>([]);

  const [page, setPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);

  const { validateTokenRecaptcha } = useReCaptcha();

  const [recaptchaAlreadyValidated, setRecaptchaAlreadyValidated] = useState(false);
  const [errorRecaptcha, setErrorRecaptcha] = useState(false);
  // Define el rango de fechas
  const isWithinRange = selectedCategory && IsCategoryAvailable(selectedCategory)

  useEffect(() => {
    console.log('recaptcha already validated')
    console.log('recaptcha validated')
    if (recaptchaAlreadyValidated) {
      getServices();
    }


  }, [selectedCategory, page]);

  const getServices = async () => {

    const requestServices: ServiceRequest = {
      idCategoria: selectedCategory?.idCategoria,
      pageNumber: page - 1,
      pageSize: pageableParam,
      consulta: null,
      idMunicipio: null,
    };

    const services = await getCategoryServices(requestServices);



    setTotalPages(services?.totalPages);

    setServices(services?.content);
  };

  useEffect(() => {

    doValidateRecaptcha();

  }, []);

  const doValidateRecaptcha = async () => {
    if (executeRecaptcha) {
      let token = await executeRecaptcha('submit');

      const validateTokenResponse = await validateTokenRecaptcha(token);
      console.log('validate token recaptcha response')
      console.log(validateTokenResponse)
      if (validateTokenResponse?.success) {
        setRecaptchaAlreadyValidated(true);
        getServices();
      } else {
        setRecaptchaAlreadyValidated(false);
        setErrorRecaptcha(true);
      }

    }

  }

  return (
    <div className="w-full px-4">

      {isLoadingService || isLoadingCategories && recaptchaAlreadyValidated ? (

        <>
          {/* {
            errorRecaptcha && <div className="flex justify-center w-full   h-10 content-center items-center align-center">
              <div className="bg-principal-700 text-principal-0 rounded-b-2xl w-1/2 flex justify-center items-center h-10">No se validó correctamente el captcha </div> </div>
          } */}

          <div className="h-80 w-full">
            {!errorRecaptcha && <SmallLoading />}
          </div>
        </>

      ) : (
        <> {
          !isWithinRange ?
            (<>
              {services?.length <= 0 ? (
                <div className="flex justify-center h-80">
                  No hay servicios para mostrar.
                </div>
              ) : (
                <div className="flex flex-wrap justify-around py-6 ">
                  {services?.map((service) => (
                    <div className="py-3" key={service.idServicio}>
                      <ServiceCard
                        color={selectedBusiness?.color!}
                        key={service.idServicio}
                        service={service}
                        selectedCategory={selectedCategory}
                        isLogged={isLogged}
                        showValue={true}
                        screenType={service?.tipoPantalla}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>) :
            <div className="flex flex-col w-full items-center pt-4">
              <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                <img
                  src={manosComfenalco}
                  className="h-16 w-16"
                />
                <p className="text-principal-600 font-bold">
                  {selectedCategory?.mensajeInactividad && selectedCategory?.mensajeInactividad}
                </p>
              </div>
            </div>
        }
        </>
      )}
      {(services?.length > 0 && !isWithinRange) && (
        <div className="flex justify-center pb-4">
          <Paginatior totalPages={totalPages} page={page} setPage={setPage} />
        </div>
      )}
    </div>
  );
};
