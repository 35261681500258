import { MTicketPqrResponse } from 'models/ticketPqrModel';
import React from 'react'
import emptyState from "../assets/images/emptyState.png";
import { TicketsStepper } from './TicketsStepper';
import { TicketsSummary } from './TicketsSummary';

interface TicketInformationProps {
    ticketData: MTicketPqrResponse | null;
    currentStep: number;
    hasError: boolean;
}
export const TicketInformation = ({ ticketData, currentStep, hasError }: TicketInformationProps) => {
    return (
        <div className='py-8'>
            {hasError ? (
                <div className='flex flex-col w-full items-center pt-4'>
                    <div className='flex flex-col w-2/3 items-center rounded-lg text-center p-2'>
                        <img src={emptyState} className="h-60 w-60" />
                        <p className='text-principal-650 font-bold' >Hubo un error con tu consulta, por favor vuelve a intentarlo</p>
                    </div>
                </div>
            ) : (
                <>
                    <div className='flex flex-wrap w-full'>

                        <div className='flex justify-center w-full lg:w-1/4 pb-6 lg:pb-0'>
                            <TicketsStepper
                                currentStep={currentStep}
                                resolutionDueTimePoint={ticketData?.resolutionDueTimePoint ?? ''}
                                requestInProcessAtTimePoint={ticketData?.requestInProcessAtTimePoint ?? ''}
                            />
                        </div>
                        <div className='w-full lg:w-3/4 '>
                            <div>
                                <TicketsSummary
                                    ticketData={ticketData}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
