import React, { useEffect, useState } from 'react'
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment';
import 'moment/locale/es';
import { useGenericData } from 'hooks/useGenericData';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AdmissionData, CompraUsuario, EnvioCrmLeadRequest, ParticularForm, RateFormLight, ServiceDetailLight, ShoppingCartWithValue } from 'models/lightForm';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { RatesFormLightRequest } from 'hooks/ratesFormLightRequest';
import { useRates } from 'hooks/useRates';
import { getAgeFromDate } from 'utils/FunctionUtilities';
import { useShoppingCar } from 'hooks/useShoppingCar';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { setSuccess } from 'store/slices/status';
import { useUser } from 'hooks/useUser';
import { useParam } from 'hooks/useParam';
import { get } from 'http';
import { InfoCotizante, InfoSolicitante } from 'models/infoCotizante';
import useInfoSolicitante from 'hooks/useInfoSolicitante';

interface ParticularFormEduProps {
    setInfoUserShoppingData: React.Dispatch<React.SetStateAction<any>>,
    admissionData?: AdmissionData,
    serviceDetail?: ServiceDetailLight,
    setRateSelected?: React.Dispatch<React.SetStateAction<any>>,
    cotizante?: InfoSolicitante,
    setCotizante?: React.Dispatch<React.SetStateAction<any>>,
    setStep: React.Dispatch<React.SetStateAction<number>>,

}

export const ParticularUserFormEdu = ({ setInfoUserShoppingData, admissionData, serviceDetail, setRateSelected, cotizante, setCotizante, setStep }: ParticularFormEduProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { handleSubmit, register, formState: { errors }, setValue, getValues } = useForm<ParticularForm>()

    const { getIdentificationsTypes } = useGenericData();

    const [identificationTypes, setIdentificationTypes] = useState<any[]>([]);

    const [personAge, setPersonAge] = useState<number>();

    const [rateFound, setrateFound] = useState<RateFormLight>();

    const { getRatesFormLight } = useRates();

    const { shoppingCartWithValueLightForm, paySale } = useShoppingCar();

    const { getParamData } = useParam();

    const { getInfoSolicitante } = useInfoSolicitante();



    const findRate = async () => {

        if (serviceDetail?.fechaServicio && admissionData) {


            let fechaServicio: Date = serviceDetail.fechaServicio;
            // fecha a string dia/mes/año
            let fechaServicioString: string = fechaServicio.getDate() + '/' + (fechaServicio.getMonth() + 1) + '/' + fechaServicio.getFullYear();

            const birthDate = getValues('birthDate')

            // moment to date
            const dateConverted: Date = new Date(birthDate)
            // fecha a string dia/mes/año
            let birthDayString = admissionData.categoria == "D" ? dateConverted?.getFullYear() + "-" + (dateConverted?.getMonth() + 1) + '-' + +dateConverted?.getDate() : admissionData.fechaNacimiento;
            console.log('fecha nacimiento')
            console.log(fechaServicioString)
            let rateRequest: RatesFormLightRequest = {
                idServicio: serviceDetail?.idServicio,
                numeroIdentificacion: admissionData?.numeroDocumento,
                tipoIdentificacion: admissionData?.tipoDocumento,
                fechaNacimiento: birthDayString,
                fechaServicio: fechaServicioString
            }

            const getRatesFound: RateFormLight = await getRatesFormLight(rateRequest);

            if (getRatesFound) {
                console.log(getRatesFound)
                setrateFound(getRatesFound);
            }
            console.log('rates found')
            console.log(getRatesFound)
        }

    }


    useEffect(() => {

        const onGetGetIdentificationsTypes = async () => {
            const identificationTypesResp = await getIdentificationsTypes();
            setIdentificationTypes(
                identificationTypesResp?.identificationTypes
                    ? identificationTypesResp?.identificationTypes
                    : []
            );
        };
        onGetGetIdentificationsTypes();
    }, []);

    const handleFormSubmitParticular: SubmitHandler<ParticularForm> = async (
        data: ParticularForm
    ) => {

        // validate if form is valid
        if (Object.keys(errors).length === 0 && rateFound && serviceDetail && admissionData) {

            //let serviceDetail.campoJson = JSON.parse(serviceDetail.campoJson);

            let servFechaInicial = serviceDetail.campoJson.servFechaInicial;
            let servFechaFinal = serviceDetail.campoJson.servFechaFinal;

            // convertir fechas a formato anioo/mes/dia
            let fechaInicialDate = servFechaInicial ? new Date(servFechaInicial) : new Date();
            let fechaFinalDate = servFechaFinal ? new Date(servFechaFinal) : new Date();

            let fechaInicialString = "";
            let fechaFinalString = "";
            if (fechaInicialDate && fechaFinalDate) {

                fechaInicialString = fechaInicialDate.getFullYear() + '/' + (fechaInicialDate.getMonth() + 1).toString().padStart(2, '0') + '/' + fechaInicialDate.getDate().toString().padStart(2, '0');
                fechaFinalString = fechaFinalDate.getFullYear() + '/' + (fechaFinalDate.getMonth() + 1).toString().padStart(2, '0') + '/' + fechaFinalDate.getDate().toString().padStart(2, '0');
            }
            if (setRateSelected) {
                setRateSelected(rateFound);
            }


            const edad = getAgeFromDate(new Date(data.birthDate))

            let infoCotizante: InfoSolicitante = {
                primerNombre: getValues('fullName'),
                segundoNombre: getValues('secondName'),
                primerApellido: getValues('surname'),
                segundoApellido: getValues('secondSurname'),
                abreviatura: getValues('documentType') ? getValues('documentType') : admissionData?.tipoDocumento,
                identificacion: getValues('documentNumber') ? getValues('documentNumber') : admissionData?.numeroDocumento,
                calidadAfiliado: "D",
                categoria: "D",
                fechaNacimiento: moment(getValues('birthDate')).format('YYYY/MM/DD'),
                direccionResidencia: getValues('direccion'),
                celular: getValues('cellPhone'),
                sexo: getValues('sex'),
                email: getValues('email'),
                edad: edad,
                fechaExpedicion: getValues('expDate') ? moment(getValues('expDate')).format('YYYY/MM/DD') : ''
            };

            setCotizante && setCotizante(infoCotizante);

            setStep(1);

        }

    };

    const handleDateChange = (date: Moment | null) => {
        setValue(`birthDate`, date);


        if (date) {
            const currentDate = moment();
            const age = currentDate.diff(date, 'years');

            if (age > 18) {

                // set document type and document number null
                setValue(`documentType`, '');
                setValue(`documentNumber`, '');
            }

            setPersonAge(age);

            findRate();
        }

    }

    const handleExpDateChange = (date: Moment | null) => {
        setValue(`expDate`, date);


        if (date) {
            const currentDate = moment();
            const age = currentDate.diff(date, 'years');

            //setPersonAge(age);

            findRate();
        }

    }

    const handleDateChangePayer = (date: Moment | null) => {
        setValue(`birthDatePayer`, date);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Permitir solo números
        if (event.key !== 'Backspace' && isNaN(Number(event.key))) {
            event.preventDefault();
        }
    };

    const handleFormSubmitV2: SubmitHandler<any> = async (
    ) => {
        setStep(1);
    };

    const getPaymentResponsible = async () => {
        if (admissionData) {
            const infoSolicitanteRequest: InfoSolicitante = await getInfoSolicitante(
                {
                    tipoIdentificacion: admissionData?.tipoDocumento,
                    numeroIdentificacion: admissionData?.numeroDocumento
                }
            );

            if (setCotizante) {
                setCotizante(infoSolicitanteRequest);
            }
        }

    }


    useEffect(() => {

        if (admissionData && admissionData.categoria !== "D") {

            getPaymentResponsible();

            findRate();
        }

    }, []);

    useEffect(() => {
        console.log(admissionData);
        console.log(rateFound)
    }, [rateFound, admissionData])

    return (
        <>
            {admissionData && admissionData.categoria !== "D" && (
                <>

                    <div className="flex flex-wrap w-full">
                        <span className="flex w-full font-bold text-xl text-principal-250 pb-2 align-center justify-center items-center content-center">
                            Detalles de la compra
                        </span>
                        <form
                            onSubmit={handleSubmit(handleFormSubmitV2)}
                            className="w-full justify-center"
                        >
                            <div className='flex flex-row'>

                                <div className='flex flex-col w-full md:w-1/2 sm:w-full'>
                                    <span className="w-full font-regular text-md text-principal-250 pb-2">
                                        Datos del solicitante del servicio
                                    </span>

                                    <div className='flex flex-col'>
                                        <span>
                                            Nombre
                                        </span>

                                        <span className='text-principal-100 mt-2'>
                                            {admissionData?.nombre}
                                        </span>
                                    </div>

                                    <div className='flex flex-col mt-4'>
                                        <span>
                                            Tipo de documento
                                        </span>

                                        <span className='text-principal-100 mt-2'>
                                            {admissionData?.tipoDocumento}
                                        </span>
                                    </div>

                                    <div className='flex flex-col mt-4'>
                                        <span>
                                            Número de identificación
                                        </span>

                                        <span className='text-principal-100 mt-2'>
                                            {admissionData?.numeroDocumento}
                                        </span>
                                    </div>
                                </div>

                            </div>


                            {/* <div className='w-full sm:w-full px-1 py-3'>
                                <div className="w-full px-1 py-2">
                                    <FormControlLabel required control={<div className='flex flex-wrap items-center'>
                                        <Checkbox required />Acepto el <div className='text-principal-250 ml-1'>
                                            {' '} tratamiento de datos personales
                                        </div></div>} label="" />
                                </div>

                                <hr></hr>
                            </div> */}

                            <div className='flex flex-wrap w-full pt-3'>
                                <span className="w-full font-regular text-md text-principal-250 pb-2">
                                    Datos del pago
                                </span>
                            </div>


                            {rateFound?.valor && (
                                <div className="w-full flex flex-col">
                                    <div className="flex justify-between w-full my-5">
                                    <span className="font-regular text-md text-principal-100">Total</span>
                                    {rateFound?.valor && (
                                        <p className="text-principal-100 font-bold text-end">
                                        {Number(rateFound?.valor).toLocaleString('es-CO', {
                                            style: 'currency',
                                            currency: 'COP',
                                            minimumFractionDigits: 0,
                                        })}
                                        </p>
                                    )}
                                    </div>

                                    <div className="w-full bg-principal-400/10">
                                    <div
                                        className="flex flex-col max-w-full justify-center text-sm md:text-base"
                                    >
                                        <div
                                        dangerouslySetInnerHTML={{ __html: rateFound.descripcion }}
                                        className=" flex flex-col scale-90 md:scale-100"
                                        ></div>
                                    </div>
                                    <div className="py-2 px-2">
                                        <span className="text-lg text-principal-650">
                                        {rateFound.nombreConcepto}{' '}
                                        {(rateFound?.total - rateFound?.valor).toLocaleString('es-CO', {
                                            style: 'currency',
                                            currency: 'COP',
                                            minimumFractionDigits: 0,
                                        })}
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                )}





                            {
                                !rateFound?.valor &&
                                <div className='flex justify-center w-full my-5'>
                                    <p className='text-principal-100 font-bold text-end'>No se encontraron tarifas para este usuario</p>
                                </div>
                            }

                            {
                                rateFound?.valor &&
                                <div className='flex justify-center w-full '>
                                    <button type="submit" className="bg-principal-250 text-white py-4 w-full rounded-md" disabled={!rateFound?.valor}>Continuar con la compra</button>
                                </div>
                            }

                        </form>
                    </div>
                </>
            )
            }
            {admissionData && admissionData.categoria === "D" && (
                <div className="flex flex-wrap w-full">
                    <span className="flex w-full font-bold text-xl text-principal-250 pb-2 align-center justify-center items-center content-center">
                        Detalles de la compra
                    </span>
                    <form
                        onSubmit={handleSubmit(handleFormSubmitParticular)}
                        className="flex flex-wrap"
                    >
                        <span className="w-full font-regular text-md text-principal-250 pb-2">
                            Datos del solicitante del servicio
                        </span>
                        <div className="flex flex-row w-full">
                            <div className="flex flex-col w-1/2">
                                <span>Tipo de documento</span>
                                <span className="font-bold">{admissionData?.tipoDocumento}</span>

                            </div>

                            <div className="flex flex-col w-1/2">
                                <span>Número de identificación</span>
                                <span className="font-bold">{admissionData?.numeroDocumento}</span>

                            </div>
                        </div>
                        <div className='flex flex-wrap w-full mt-5'>
                            <div className="w-full sm:w-1/2 px-1 py-3">
                                <TextField
                                    defaultValue={""}
                                    className="w-full"
                                    label="Primer Nombre"
                                    {...register(`fullName`, {
                                        required: "El primer nombre es obligatorio"
                                    })}
                                />
                                {errors.fullName && (
                                    <p className="text-red-500">
                                        {String(errors?.fullName?.message)}
                                    </p>
                                )}
                            </div>
                            <div className="w-full sm:w-1/2 px-1 py-3">
                                <TextField
                                    defaultValue={""}
                                    className="w-full"
                                    label="Segundo Nombre"
                                    {...register(`secondName`, {
                                        //required: "El segundo nombre es obligatorio"
                                    })}
                                />
                                {errors.secondName && (
                                    <p className="text-red-500">
                                        {String(errors?.secondName?.message)}
                                    </p>
                                )}
                            </div>
                            <div className="w-full sm:w-1/2 px-1 py-3">
                                <TextField
                                    defaultValue={""}
                                    className="w-full"
                                    label="Primer apellido"
                                    {...register(`surname`, {
                                        required: "El primer apellido es obligatorio"
                                    })}
                                />
                                {errors.surname && (
                                    <p className="text-red-500">
                                        {String(errors?.surname?.message)}
                                    </p>
                                )}
                            </div>
                            <div className="w-full sm:w-1/2 px-1 py-3">
                                <TextField
                                    defaultValue={""}
                                    className="w-full"
                                    label="Segundo apellido"
                                    {...register(`secondSurname`, {
                                        required: "El segundo apellido es obligatorio"
                                    })}
                                />
                                {errors.secondSurname && (
                                    <p className="text-red-500">
                                        {String(errors?.secondSurname?.message)}
                                    </p>
                                )}
                            </div>

                            <div className="w-full sm:w-1/2 mt-3 px-1">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        className="w-full"
                                        format="DD/MM/YYYY"
                                        disableFuture={true}
                                        label="Fecha de nacimiento"
                                        slots={{
                                            ...register(`birthDate`, {
                                                required: "La fecha de nacimiento es obligatoria",
                                            }),
                                        }}
                                        onChange={handleDateChange}
                                    />
                                </LocalizationProvider>
                                {errors?.birthDate && (
                                    <p className="text-red-500">
                                        {String(errors?.birthDate?.message)}
                                    </p>
                                )}
                            </div>

                            <div className="w-full sm:w-1/2 mt-3 px-1">
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                        className="w-full"
                                        format="DD/MM/YYYY"
                                        disableFuture={true}
                                        label="Fecha de expedicion documento"
                                        slots={{
                                            ...register(`expDate`, {
                                                required: "La fecha de expedicion es obligatoria",
                                            }),
                                        }}
                                        onChange={handleExpDateChange}
                                    />
                                </LocalizationProvider>
                                {errors?.expDate && (
                                    <p className="text-red-500">
                                        {String(errors?.expDate?.message)}
                                    </p>
                                )}
                            </div>

                            <div className="w-full sm:w-1/2 px-1 py-3">
                                <TextField
                                    defaultValue={""}
                                    className="w-full"
                                    label="Correo electronico"
                                    {...register(`email`, {
                                        required: "El correo electronico es obligatorio",
                                        pattern: {
                                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                            message: "Ingrese un correo electrónico válido"
                                        }
                                    })}
                                />
                                {errors?.email && (
                                    <p className="text-red-500">
                                        {String(errors?.email?.message)}
                                    </p>
                                )}
                            </div>

                            <div className="w-full sm:w-1/2 px-1 py-3">
                                <TextField
                                    defaultValue={""}
                                    className="w-full"
                                    label="Número de celular"
                                    {...register(`cellPhone`, {
                                        required: "El número de celular es obligatorio"
                                    })}
                                    onKeyDown={handleKeyDown}
                                />
                                {errors?.cellPhone && (
                                    <p className="text-red-500">
                                        {String(errors?.cellPhone?.message)}
                                    </p>
                                )}
                            </div>

                            <div className="w-full sm:w-1/2 px-1 py-3">

                                <FormControl className="w-full">
                                    <InputLabel id="genero">Género</InputLabel>
                                    <Select
                                        labelId="sexo"
                                        label="Sexo"
                                        defaultValue={undefined}
                                        {...register(`sex`, {
                                            required: "El sexo es obligatorio",
                                        })}
                                    >
                                        <MenuItem value="M">Masculino</MenuItem>
                                        <MenuItem value="F">Femenino</MenuItem>
                                    </Select>
                                    {errors?.sex && (
                                        <p className="text-red-500">
                                            {String(errors?.sex?.message)}
                                        </p>
                                    )}
                                </FormControl>

                            </div>

                            <div className="w-full sm:w-1/2 px-1 py-3">
                                <TextField
                                    defaultValue={""}
                                    className="w-full"
                                    label="Direccion"
                                    {...register(`direccion`, {
                                        required: "La direccion es obligatoria"
                                    })}
                                />
                                {errors?.direccion && (
                                    <p className="text-red-500">
                                        {String(errors?.direccion?.message)}
                                    </p>
                                )}
                            </div>


                        </div>

                        {
                            (personAge != undefined && personAge < 18) &&
                            (<div className='flex flex-wrap w-full pt-3'>
                                <span className="w-full font-regular text-md text-principal-250 pb-2">
                                    Datos del responsable de pago
                                </span>
                                <div className="w-full sm:w-1/2 px-1 py-3">
                                    <FormControl className="w-full">
                                        <InputLabel id="tipo-identificacion">
                                            Tipo de identificación
                                        </InputLabel>
                                        <Select
                                            labelId="tipo-identificacion"
                                            label="Tipo de identificación"
                                            defaultValue={"-1"}
                                            {...register(`documentType`, {
                                                required: "El tipo de identificación es obligatorio",
                                            })}
                                        >
                                            <MenuItem value={'-1'}>
                                                Selecciona
                                            </MenuItem>
                                            {identificationTypes?.map((id, index) => (
                                                <MenuItem key={index} value={id?.ABREVIATURA}>
                                                    {id?.DESCRIPCION}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                        {errors?.documentType && (
                                            <p className="text-red-500">
                                                {String(errors?.documentType?.message)}
                                            </p>
                                        )}
                                    </FormControl>
                                </div>
                                <div className="w-full sm:w-1/2 px-1 py-3">
                                    <TextField
                                        defaultValue={""}
                                        className="w-full"
                                        label="Número de identificación"
                                        {...register(`documentNumber`, {
                                            required: "El número de documento es obligatorio",
                                        })}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {errors?.documentNumber && (
                                        <p className="text-red-500">
                                            {String(errors?.documentNumber?.message)}
                                        </p>
                                    )}
                                </div>

                                <div className="w-full sm:w-1/2 px-1 py-3">
                                    <TextField
                                        defaultValue={""}
                                        className="w-full"
                                        label="Nombre"
                                        {...register(`namePayer`, {
                                            required: "El nombre es obligatorio",
                                        })}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {errors?.namePayer && (
                                        <p className="text-red-500">
                                            {String(errors?.namePayer?.message)}
                                        </p>
                                    )}
                                </div>

                                <div className="w-full sm:w-1/2 mt-3 px-1">
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            className="w-full"
                                            format="DD/MM/YYYY"
                                            disableFuture={true}
                                            label="Fecha de nacimiento"
                                            slots={{
                                                ...register(`birthDatePayer`, {
                                                    required: "La fecha de nacimiento es obligatoria",
                                                }),
                                            }}
                                            onChange={handleDateChangePayer}
                                        />
                                    </LocalizationProvider>
                                    {errors?.birthDatePayer && (
                                        <p className="text-red-500">
                                            {String(errors?.birthDatePayer?.message)}
                                        </p>
                                    )}
                                </div>

                                <div className="w-full sm:w-1/2 px-1 py-3">
                                    <TextField
                                        defaultValue={""}
                                        className="w-full"
                                        label="Correo electronico"
                                        {...register(`emailPayer`, {
                                            required: "El correo electronico es obligatorio",
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                message: "Ingrese un correo electrónico válido"
                                            }
                                        })}
                                    />
                                    {errors?.emailPayer && (
                                        <p className="text-red-500">
                                            {String(errors?.emailPayer?.message)}
                                        </p>
                                    )}
                                </div>

                                <div className="w-full sm:w-1/2 px-1 py-3">
                                    <TextField
                                        defaultValue={""}
                                        className="w-full"
                                        label="Número de celular"
                                        {...register(`cellPhonePayer`, {
                                            required: "El número de celular es obligatorio"
                                        })}
                                        onKeyDown={handleKeyDown}
                                    />
                                    {errors?.cellPhonePayer && (
                                        <p className="text-red-500">
                                            {String(errors?.cellPhonePayer?.message)}
                                        </p>
                                    )}
                                </div>

                                <div className="w-full sm:w-1/2 px-1 py-3">
                                    <FormControl className="w-full">
                                        <InputLabel id="genero">Género</InputLabel>
                                        <Select
                                            labelId="sexo"
                                            label="sexo"
                                            defaultValue={undefined}
                                            {...register(`sexPayer`, {
                                                required: "El sexo es obligatorio",
                                            })}
                                        >
                                            <MenuItem value="M">Masculino</MenuItem>
                                            <MenuItem value="F">Femenino</MenuItem>
                                        </Select>
                                        {errors?.sexPayer && (
                                            <p className="text-red-500">
                                                {String(errors?.sexPayer?.message)}
                                            </p>
                                        )}
                                    </FormControl>

                                </div>
                            </div>)
                        }

                        {/* <div className='w-full sm:w-full px-1 py-3'>
                            <div className="w-full px-1 py-2">
                                <FormControlLabel required control={<div className='flex flex-wrap items-center'>
                                    <Checkbox required />Acepto el <div className='text-principal-250 ml-1'>
                                        {' '} tratamiento de datos personales
                                    </div></div>} label="" />
                            </div>

                            <hr></hr>
                        </div> */}

                        <div className='flex flex-wrap w-full pt-3'>
                            <span className="w-full font-regular text-md text-principal-250 pb-2">
                                Datos del pago
                            </span>
                        </div>

                        
                        {rateFound?.valor &&
                            <div className="w-full flex-col">
                                <div className='flex justify-between w-full my-5'>
                                    <span className="font-regular text-md text-principal-100 ">
                                        Total
                                    </span>

                                    {
                                        rateFound?.valor &&
                                        <p className='text-principal-100 font-bold text-end'>{rateFound?.valor ? Number(rateFound?.valor).toLocaleString('es-CO', {
                                            style: 'currency',
                                            currency: 'COP',
                                            minimumFractionDigits: 0,
                                        }) : ''}</p>
                                    }
                                </div>

                                <div className="w-full bg-principal-400/10 overflow-x-scroll">
                                    <div
                                        className=" flex flex-col scale-90 md:scale-100"
                                        dangerouslySetInnerHTML={{ __html: rateFound.descripcion }}
                                    ></div>
                                    {
                                        < div className="py-2 px-2">
                                            <span className="text-lg text-principal-650">
                                                {rateFound.nombreConcepto} {(rateFound?.total - rateFound?.valor).toLocaleString("es-CO", {
                                                    style: "currency",
                                                    currency: "COP",
                                                    minimumFractionDigits: 0,
                                                })}
                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                        

                        {
                            rateFound?.valor &&
                            <div className='flex justify-center w-full '>
                                <button type="submit" className="bg-principal-250 text-white py-4 w-full rounded-md" disabled={!rateFound?.valor}>Continuar con la compra</button>
                            </div>
                        }
                    </form>
                </div>
            )}

        </>
    )
}
