import { Divider, Typography } from '@mui/material';
import { Cotizante, Solicitud } from 'models/schoolKitModel';
import React from 'react';

interface SchoolKitSummaryProps {
    beneficiaries: Cotizante[] | undefined;
    request: Solicitud | undefined;
    adjudicationMessage: string | undefined;
    postulationMessage: string | undefined;
}

export const SchoolKitSummary = (props: SchoolKitSummaryProps) => {
    const {
        beneficiaries,
        request,
        adjudicationMessage,
        postulationMessage,
    } = props;

    return (
        <div className='flex flex-col border rounded-lg items-center'>
            <div className='flex bg-principal-350 w-full rounded-t-lg'>
                <div className='w-1/2 text-center p-2'>
                    <Typography color='primary' className='font-bold text-xl'>Número solicitud</Typography>
                    <p className='text-xl font-bold text-principal-600'>{request?.numeroSolicitud}</p>
                </div>
                <Divider orientation="vertical" className='bg-principal-600' />
                <div className='w-1/2 text-center p-2'>
                    <Typography color='primary' className='font-bold text-xl'>Fecha postulación</Typography>
                    <p className='text-xl font-bold text-principal-600'>{request?.fechaPostulacion}</p>
                </div>
            </div>
            <Typography color='primary' className='font-bold text-lg py-2 mt-4'>Beneficiarios</Typography>
            <div className='text-center w-full md:w-1/2 border rounded-lg'>
                <div className='flex w-full border-b py-2'>
                    <div className='w-1/3 text-center px-2'><Typography color='primary'>Nombre beneficiario</Typography></div>
                    <div className='w-1/3 text-center px-2'><Typography color='primary'>Tipo de documento</Typography></div>
                    <div className='w-1/3 text-center px-2'><Typography color='primary'>Número de documento</Typography></div>
                </div>
                {beneficiaries && beneficiaries.length > 0 && (
                    <div className='w-full'>
                        {beneficiaries.map((beneficiary: Cotizante, i: number) => (
                            <div key={beneficiary.identificacion} className='flex w-full py-2 border-b'>
                                <div className='flex items-center justify-center w-1/3'>
                                    {beneficiary.nombre}
                                </div>
                                <div className='flex items-center justify-center w-1/3'>
                                    {beneficiary.abreviatura}
                                </div>
                                <div className='flex items-center justify-center w-1/3'>
                                    {beneficiary.identificacion}
                                </div>
                                {i !== beneficiaries.length - 1 && (
                                    <Divider />
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <Typography color='primary' className='font-bold text-lg py-2 mt-4'>Comentarios</Typography>
            <div className='w-full px-4 pb-4'>
                <div className='border rounded-lg p-2'>
                    {(request?.estado == 'GENERADO' ||
                        request?.estado == 'RECHAZADO' ||
                        request?.estado == 'ADJUDICADO') &&
                        request.mostrarAdjudicacionEcommerce == 'S' ? (
                        <p>{postulationMessage}</p>
                    ) : (
                        <p>{adjudicationMessage}</p>
                    )}
                </div>
            </div>
        </div>
    );
};