
import { useState } from 'react'
import integrationApi from 'api/integrationApi';
import { ServiceRequest } from 'models/serviceRequest';
import { CampoJson, Service, categoryService } from 'models/service';


export const useCategoryService = () => {

    const urlService: string = "servicios";

    const [isLoadingService, setIsLoadingService] = useState(true);

    const getCategoryServices = async (serviceRequest: ServiceRequest) => {
        setIsLoadingService(true);

        const services = await integrationApi.post<categoryService>(`${urlService}/serviciosCategoria`, serviceRequest);

        const serviceResp = services?.data;

        serviceResp.content = serviceResp?.content?.map(service => {
            const campoJson: CampoJson = JSON.parse("" + service?.campoJson);
            let categoryService = service;
            categoryService.campoJson = campoJson;
            return categoryService;
        })

        setIsLoadingService(false);
        return serviceResp;

    }

    return {
        isLoadingService,
        getCategoryServices
    }
}
