import { Service } from "models/service";
import React, { useRef } from "react";
import { ServiceCard } from "./ServiceCard";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

interface SliderProps {
  services: Service[];
  name: string;
  color?: string;
  isLogged: boolean;
}

export const ServiceSlider = ({
  services = [],
  name,
  color = "#135F2C",
  isLogged,
}: SliderProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = (scrollOffset: number) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += scrollOffset;
    }
  };
  return (
    <div className="w-full flex flex-wrap my-8">
      <div
        className="flex mb-6 py-3 pr-4 rounded-r-full text-white items-center"
        style={{ backgroundColor: color }}
      >
        <span className="text-lg font-bold ml-14 mr-4">{name}</span>
      </div>

      <div className="flex w-full flex-row items-center px-3">
        <ArrowBackIosNewOutlinedIcon
          className="h-8 w-8 mr-2 cursor-pointer text-principal-450"
          onClick={() => handleScroll(-400)}
        />
        <div
          className="flex w-full px-2 py-2 scroll-container overflow-hidden"
          ref={scrollContainerRef}
        >
          {services?.map((service, index) => (
            <ServiceCard service={service} key={index} isLogged={isLogged} screenType={service?.tipoPantalla}/>
          ))}
        </div>
        <ArrowForwardIosOutlinedIcon
          className="h-8 w-8 ml-2 cursor-pointer text-principal-450"
          onClick={() => handleScroll(400)}
        />
      </div>
    </div>
  );
};
