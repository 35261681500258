import { ThemeProvider } from "@emotion/react";
import { Tab, Tabs, createTheme } from "@mui/material";
import { Loading } from "components/Loading";
import { Paginatior } from "components/Paginatior";
import { ReservationCard } from "components/ReservationCard";
import { ShoppingCard } from "components/ShoppingCard";
import { useParam } from "hooks/useParam";
import { useShoppingCar } from "hooks/useShoppingCar";
import { MHotelReservation } from "models/hotelReservationsResponse";
import { MShoppingCar } from "models/salesShoppingCar";
import { ConfirmationDialog } from "pages/history/ConfirmationDialog";
import React, { useEffect, useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const ShoppingCar = () => {
  const {
    salesShoppingCart,
    hotelReservations,
    deleteSaleShoppingCart,
    paySale,
  } = useShoppingCar();

  const { getTotalCarritoCompras } = useShoppingCar();

  
  const { isLogged } = useSelector((state: RootState) => state.auth);
  const { token } = useSelector((state: RootState) => state.jwt);

  const { getParamData } = useParam();
  const [salesShoppingCar, setSalesShoppingCar] = useState<MShoppingCar[]>();
  const [hotelsReservations, setHotelsReservations] =
    useState<MHotelReservation[]>();
  const [totalPagesShopping, setTotalPagesShopping] = useState(0);
  const [totalPagesReservation, setTotalPagesReservation] = useState(0);
  const [shoppingPage, setShoppingPage] = useState(1);
  const [reservationPage, setReservationPage] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  const [selectedTab, setSelectedTab] = useState(0);

  const [openDialog, setOpenDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number>(0);

  const [urlPasarela, seturlPasarela] = useState("");

  const handleOpenDialog = (idCompra: number) => {
    setIdToDelete(idCompra);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDelete = async (idCompra: number) => {
    const deleteResponse = await deleteSaleShoppingCart(idCompra);

    if (deleteResponse) {
      setSalesShoppingCar(
        salesShoppingCar?.filter((sale) => sale.idCompra !== idCompra)
      );
      setHotelsReservations(
        hotelsReservations?.filter((sale) => sale.idCompra !== idCompra)
      );
    }

    handleCloseDialog();
  };

  const greenTheme = createTheme({
    palette: {
      primary: {
        main: "#135f2c",
      },
    },
  });

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handlePaySale = async (sale: any) => {
    const paySaleResultInfo = await paySale(sale.idCompra);

    window.location.replace(urlPasarela + paySaleResultInfo?.infoPago);
  };

  useEffect(() => {
    const getShoppinCartItems = async () => {
      setIsLoading(true);

      const salesShoppingCar = await salesShoppingCart(shoppingPage - 1, 5);

      setTotalPagesShopping(salesShoppingCar?.totalPages);

      setSalesShoppingCar(salesShoppingCar?.content);

      // Get url pasarela param
      const getParamUrlPasarela = await getParamData("URL_PASARELA");

      if (getParamUrlPasarela?.data) {
        const dataParameter = getParamUrlPasarela?.data;
        seturlPasarela(dataParameter.valorParametro);
      }

      setIsLoading(false);
    };

    getShoppinCartItems();
  }, [shoppingPage]);

  useEffect(() => {
    const getShoppinCartItems = async () => {
      setIsLoading(true);

      const hotelReservationsResponse = await hotelReservations(
        reservationPage - 1,
        5
      );

      setTotalPagesReservation(hotelReservationsResponse?.totalPages);

      setHotelsReservations(hotelReservationsResponse?.content);

      setIsLoading(false);
    };
    getShoppinCartItems();
  }, [reservationPage]);

  useEffect(() => {
    isLogged && token?.token && getTotalCarritoCompras();
  }, []);

  return (
    <ThemeProvider theme={greenTheme}>
      <div className="w-full px-4">
        {isLoading ? (
          <div className="h-80 w-full">
            <Loading />
          </div>
        ) : (
          <>
            <div className="flex justify-center items-center pt-8">
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab
                  label="Carrito de compras"
                  className="text-bold font-bold"
                />
                <Tab
                  label="Reservas hoteleras"
                  className="text-bold font-bold"
                />
              </Tabs>
            </div>
            {selectedTab === 0 && (
              <>
                {salesShoppingCar && salesShoppingCar?.length <= 0 ? (
                  <div className="flex justify-center h-80">
                    No hay compras para mostrar.
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center w-full pt-2">
                    {salesShoppingCar?.map((sale) => (
                      <div className="py-3 w-full lg:w-1/2" key={sale.idCompra}>
                        <ShoppingCard
                          sale={sale}
                          handleOpenDialog={handleOpenDialog}
                          handlePay={() => handlePaySale(sale)}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {salesShoppingCar && salesShoppingCar?.length > 0 && (
                  <div className="flex justify-center pb-4">
                    <Paginatior
                      totalPages={totalPagesShopping}
                      page={shoppingPage}
                      setPage={setShoppingPage}
                    />
                  </div>
                )}
              </>
            )}
            {selectedTab === 1 && (
              <>
                {hotelsReservations && hotelsReservations?.length <= 0 ? (
                  <div className="flex justify-center h-80">
                    No hay reservas para mostrar.
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center w-full pt-2">
                    {hotelsReservations?.map((sale) => (
                      <div className="py-3 w-full lg:w-1/2" key={sale.idCompra}>
                        <ReservationCard
                          sale={sale}
                          handleOpenDialog={handleOpenDialog}
                          handlePay={() => handlePaySale(sale)}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {hotelsReservations && hotelsReservations?.length > 0 && (
                  <div className="flex justify-center pb-4">
                    <Paginatior
                      totalPages={totalPagesReservation}
                      page={reservationPage}
                      setPage={setReservationPage}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      <ConfirmationDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={() => handleDelete(idToDelete)}
      />
    </ThemeProvider>
  );
};
