import { Service } from 'models/service'
import React, { useRef } from 'react'
import { ServiceCard } from './ServiceCard'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Link } from 'react-router-dom';

interface SliderProps {
    services: Service[]
}

export const EventSlider = ({ services }: SliderProps) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const currentDate = new Date();
    const month = currentDate.toLocaleString('es-ES', { month: 'long' });

    const handleScroll = (scrollOffset: number) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += scrollOffset;
        }
    };
    return (
        <div className='w-full flex flex-wrap my-2'>

            <div className='flex flex-wrap w-[25%] items-center px-2 justify-center bg-principal-400/10'>
                <div className='text-center'>
                    <p className='w-full text-xl font-bold text-principal-250'>EVENTOS</p>
                    <p className='w-full text-3xl font-bold text-principal-200'>{month.toUpperCase()}</p>
                </div>
            </div>

            <div className="flex w-[75%] flex-row items-center py-10 px-3 bg-principal-400/10">
                <ArrowBackIosNewOutlinedIcon
                    className="h-8 w-8 mr-2 cursor-pointer text-principal-450"
                    onClick={() => handleScroll(-400)}
                />
                <div
                    className="flex w-full px-2 py-2 scroll-container overflow-hidden"
                    ref={scrollContainerRef}
                >

                    {
                        services?.map((service, index) => (
                            <Link to={`/${service?.tipoPantalla}/${service?.idServicio}`} key={index}>
                                <div className='h-40 sm:h-48 md:h-60 w-60 sm:w-72 md:w-[26rem] mr-8 rounded-lg bg-cover' style={{ backgroundImage: `url(${service?.imagen})` }} />
                            </Link>
                        ))
                    }

                </div>
                <ArrowForwardIosOutlinedIcon
                    className="h-8 w-8 ml-2 cursor-pointer text-principal-450"
                    onClick={() => handleScroll(400)}
                />
            </div>
        </div>

    )
}
