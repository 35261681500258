import { ThemeProvider } from '@emotion/react'
import { Typography, createTheme } from '@mui/material'
import { TicketInformation } from 'components/TicketInformation'
import { TicketsForm } from 'components/TicketsForm'
import { getStepTicket } from 'helpers/getStepTickets'
import { useTickets } from 'hooks/useTickets'
import { MTicketPqrRequest, MTicketPqrResponse } from 'models/ticketPqrModel'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import emptyState from "../../assets/images/emptyState.png";

export const Tickets = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [ticketData, setTicketData] = useState<MTicketPqrResponse | null>();
  const { getTicketPqr, isLoadingTicketPqr } = useTickets();
  const strongOrange = createTheme({
    palette: {
      primary: {
        main: '#D07700',
      },
    },
  });

  //strongOrange: '#D07700'

  const getTicket = async (ticketRequest: MTicketPqrRequest) => {
    setIsLoading(true);
    const ticketResponse = await getTicketPqr(ticketRequest);
    if (ticketResponse) {
      const actualStep = getStepTicket(ticketResponse.lifeCycleStatus ?? '');

      setHasError(false);
      setTicketData(ticketResponse);
      setCurrentStep(actualStep);
      setIsLoading(false);
    } else {
      setHasError(true);
      setTicketData(null);
      setCurrentStep(0);
      setIsLoading(false);
    }
  };

  const { register, handleSubmit, formState: { errors } } = useForm<MTicketPqrRequest>();

  return (
    <ThemeProvider theme={strongOrange}>
      <div className='w-full p-8'>
        <Typography className='font-bold text-xl pb-4' color={'primary'}>Consulta tus peticiones</Typography>
        <TicketsForm register={register} handleSubmit={handleSubmit} errors={errors} getTicket={getTicket} isLoadingTicketPqr={isLoadingTicketPqr} />
        {ticketData ?
          <TicketInformation
            ticketData={ticketData}
            currentStep={currentStep}
            hasError={hasError}
          /> : <div className='flex flex-col w-full items-center pt-4'>
            <div className='flex flex-col w-2/3 items-center rounded-lg text-center p-2'>
              <img src={emptyState} className="h-60 w-60" />
              <p className='text-principal-650 font-bold' >Realiza la consulta correspondiente</p>
            </div>
          </div>
        }

      </div>
    </ThemeProvider>
  )
}
