import { Dispatch } from "redux";
import { setCredentials } from "./credentialsSlice";
import requestApi from "../../../api/cartApi";

export const getCredentials = () => {
  return async (dispatch: Dispatch) => {
    try {
      const credentials: Record<string, string> = {};

      const resp = await requestApi.get<Record<string, string>>(
        `auth/paramsInicio`
      );

      const credentialsResp = await Promise.resolve(resp);

      const credentialList = credentialsResp?.data;
      for (const credential in credentialList) {
        credentials[credential] = credentialList[credential];
      }

      dispatch(setCredentials(credentials));
    } catch (error) {
      console.log(error);
    }
  };
};
