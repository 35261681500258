import { Typography } from '@mui/material'
import React from 'react'

interface EmbeddedPops {
    link: string
}
export const Embedded = ({ link }: EmbeddedPops) => {
    return (
        <div>
            <Typography className='font-bold text-2xl pb-2' color='primary'>Aquí podrás encontrar información relacionada con el servicio</Typography>
            <div className='w-full rounded border border-principal-350 shadow'>
                <iframe
                    src={link}
                    title={"Embedded service"}
                    width={"100%"}
                    height={"850px"}
                />
            </div>
        </div>
    )
}
