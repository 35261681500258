
import { useState } from 'react';
import cartApi from '../api/cartApi';
import integrationApi from '../api/integrationApi';
import { MReserveResponse, MReserveSpace, MSpacesResponse } from '../models/spacesRequest';
import { AvailableSchedule, MSpaceDetail, SchedulesData } from '../models/spaceDetailModel';
import { CampoJson } from 'models/campoJson';

export const useSpaces = () => {

    const [isLoadingSpaces, setIsLoadingSpaces] = useState(true);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const [isLoadingSchedules, setIsLoadingSchedules] = useState(false);
    const [isLoadingReserve, setIsLoadingReserve] = useState(false);

    const urlSpaces: string = "servicios";

    const getSpaces = async (pageNumber: number, pageSize: number) => {

        setIsLoadingSpaces(true);

        //const spaces = await cartApi.post<MSpacesResponse>(`${urlSpaces}/getEspaciosDeportivos`, { pageNumber: pageNumber, pageSize: pageSize });
        const spaces = await integrationApi.post<MSpacesResponse>(`${urlSpaces}/serviciosEspacios`, { pageNumber: pageNumber, pageSize: pageSize });

        const spacesResp = spaces?.data;

        spacesResp.content = spacesResp?.content?.map(service => {
            const campoJson: CampoJson = JSON.parse("" + service.campoJson);
            let spaceService = service;
            //spaceService.campoJson = campoJson;
            return service;
        })

        setIsLoadingSpaces(false);

        return spacesResp;
    }

    const getSpaceDetail = async (idServicio: number) => {

        setIsLoadingDetail(true);

        const spaceDetailResp = await cartApi.post(`${urlSpaces}/espacioDeportivoDetalle`, idServicio);
        const spacesTemporal = spaceDetailResp?.data;
        const campoJson: CampoJson = JSON.parse(spacesTemporal.detalleServicio.campoJson);
        const spaceDetail: MSpaceDetail = spacesTemporal;
        spaceDetail.detalleServicio.campoJson = campoJson;
        setIsLoadingDetail(false);

        return spaceDetailResp?.data;
    }

    const getAvailableSchedules = async (idServicio: number, fechaConsultaServicio: Date, codSede: string) => {

        setIsLoadingSchedules(true);

        const availableSchedules = await integrationApi.post<SchedulesData>(`espacios/disponibilidadEspacio`, { idServicio: idServicio, fechaConsultaServicio: fechaConsultaServicio, codSede: codSede });

        setIsLoadingSchedules(false);

        return availableSchedules?.data;
    }

    const onReserveSpace = async (reserve: MReserveSpace) => {

        setIsLoadingReserve(true);

        const reserveSpace = await integrationApi.post<MReserveResponse>(`espacios/reservarArea`, reserve);
        setIsLoadingReserve(false);

        return reserveSpace?.data;
    }

    return {
        isLoadingSpaces,
        getSpaces,
        getSpaceDetail,
        isLoadingDetail,
        getAvailableSchedules,
        isLoadingSchedules,
        onReserveSpace,
        isLoadingReserve
    }
}