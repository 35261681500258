import { Box, Typography } from '@mui/material';
import React from 'react'

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const CustomTabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className='flex w-full'
        >
            {value === index && (
                <div className='flex w-full'>
                    {children}
                </div>
            )}
        </div>
    )
}
