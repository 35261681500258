import { MHousingSubsidy } from 'models/housingSubsidyModel';
import manosComfenalco from "../assets/images/manosComfenalco.png";
import React from 'react'
import { HousingSubsidyStepper } from './HousingSubsidyStepper';
import { HousingSubsidySummary } from './HousingSubsidySummary';
import { Typography } from '@mui/material';

interface HousingSubsidyInformationProps {
    housingSubsidy: MHousingSubsidy | undefined;
    currentStep: number;
    error: string | undefined;
    linkOnError: string | undefined;
    hasError: boolean;
}

export const HousingSubsidyInformation = ({
    housingSubsidy,
    currentStep,
    error,
    linkOnError,
    hasError
}: HousingSubsidyInformationProps) => {
    return (
        <div>
            {hasError ? (
                <div className='flex flex-col w-full items-center pt-4'>
                    <div className='flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2'>
                        <img src={manosComfenalco} className="h-16 w-16" />
                        <p className='text-principal-650 font-bold' >{error}</p>
                        <a className='text-principal-600 font-bold' href={linkOnError}>
                            ¡Para más información consulta aquí!</a>
                    </div>
                </div>
            ) : (
                <>
                    <div className='flex flex-wrap w-full'>
                        <Typography color='primary' className='text-2xl font-bold w-full pb-2'>Subsidio de vivienda</Typography>

                        <div className='flex justify-center w-full lg:w-1/4 pb-6 lg:pb-0'>
                            <HousingSubsidyStepper
                                currentStep={currentStep}
                            />
                        </div>
                        <div className='w-full lg:w-3/4 '>
                            <div>
                                <HousingSubsidySummary
                                    housingSubsidy={housingSubsidy}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
