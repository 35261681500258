import { useState } from 'react'
import cartApi from '../api/cartApi'
import integrationApi from '../api/integrationApi'
import { HotelsResponse } from 'models/hotelsResponse';
import { ReservationRequest } from 'models/reservationRequest';
import { ReservaCompra } from 'models/compraCero';

export const useHotels = () => {

    const [isLoadingHotels, setIsLoadingHotels] = useState(true);

    const urlHotels: string = "centroServicio";
    const hotel: string = "hotel";

    const getHotels = async (pageNumber: number, pageSize: number) => {

        setIsLoadingHotels(true);

        const hotels = await cartApi.post<HotelsResponse>(`${urlHotels}/centrosHoteles`, { pageNumber: pageNumber, pageSize: pageSize });

        setIsLoadingHotels(false);

        return hotels?.data;
    }

    const hotelReservation = async (reservationRequest: ReservationRequest) => {

        setIsLoadingHotels(true);

        const reservation = await integrationApi.post<ReservaCompra>(`${hotel}/reservarHotel`, reservationRequest);

        setIsLoadingHotels(false);

        return reservation?.data;
    }


    return {
        isLoadingHotels,
        getHotels,
        hotelReservation
    }
}