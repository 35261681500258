import { Dispatch } from "redux";
import integrationApi from "../../../api/integrationApi";
import cartApi from "../../../api/cartApi";
import { AuthRequest } from "models/authRequest";
import { setAuthData } from "./authSlice";
import { ValidateAdminRequest } from "models/validateAdminRequest";
import { encrypt } from "utils/security/AesUtil";

export const getAuthData = (dataSend: any) => {
  return async (dispatch: Dispatch) => {
    const dataLoginRequest = integrationApi.post<any>("auth/decrypt", dataSend);

    const dataLoginResp = await Promise.resolve(dataLoginRequest);

    const dataAuth = dataLoginResp?.data.idToken;

    const authRequest: AuthRequest = {
      idPers: dataAuth["comfenApp/idPers"],
      tipoIdentificacion: dataAuth["comfenApp/tipo_identificacion"],
      numeroIdentificacion: dataAuth["comfenApp/rest_id"],
      tipoUsuario: dataAuth["comfenApp/tipo"],
    };

    const resp = await integrationApi.post<any>(
      `auth/InfoUsuario`,
      authRequest
    );

    const authResp = await Promise.resolve(resp);

    localStorage.setItem("userData", encrypt(JSON.stringify(authResp?.data)));

    dispatch(setAuthData(authResp?.data));
  };
};

export const loginAuthAdmin = (dataSend: any) => {
  return async (dispatch: Dispatch) => {
    const authRequest: AuthRequest = {
      idPers: dataSend.idPers,
      tipoIdentificacion: dataSend.tipoIdentificacion,
      numeroIdentificacion: dataSend.numeroIdentificacion,
      tipoUsuario: dataSend?.tipoUsuario,
    };

    const resp = await integrationApi.post<any>(
      `auth/InfoUsuario`,
      authRequest
    );

    const authRespInfoUsuario = await Promise.resolve(resp);

    if (authRespInfoUsuario) {
      if (authRespInfoUsuario.data) {
        const infoUserLogin = authRespInfoUsuario.data;



        const authRequest: ValidateAdminRequest = {
          tipoIdentificacion: dataSend.tipoIdentificacion,
          numeroIdentificacion: dataSend.numeroIdentificacion,
        };

        const respValidateAdmin = await cartApi.post<any>(
          `usuario/validateAdmin`,
          authRequest
        );

        if (respValidateAdmin && respValidateAdmin.data) {

          const empleadoCRM = await integrationApi.post<any>(`auth/consultaEmpleado`, {
            tipoIdentificacion: dataSend.tipoIdentificacion,
            identificacion: dataSend.numeroIdentificacion,
          });

          empleadoCRM?.data ?
            //Setting data to localStorage and store
            localStorage.setItem("userData", encrypt(JSON.stringify({ ...infoUserLogin, empleadoCRM: empleadoCRM?.data }))) :
            localStorage.setItem("userData", encrypt(JSON.stringify(infoUserLogin)));

          empleadoCRM?.data ? dispatch(setAuthData({ ...infoUserLogin, empleadoCRM: empleadoCRM?.data })) : dispatch(setAuthData(infoUserLogin));
        }
      }
    }
  };
};
