import { Typography } from '@mui/material';
import { ServiceData } from 'models/serviceData'
import React from 'react'
import moment from 'moment';
import 'moment/locale/es';
import { PUBLICO_AFILIADOS, PUBLICO_AFILIADOS_EMPRESA_COMFENALCO, PUBLICO_AFILIADOS_MENOS_EMPLEADOS, PUBLICO_TODOS } from 'utils/constants';

interface AditionalInformationProps {
    serviceData?: ServiceData
}

export const AditionalInformation = ({ serviceData }: AditionalInformationProps) => {
    let mapPublic: Map<string, string> = new Map<string, string>();

    mapPublic.set('1', PUBLICO_TODOS);
    mapPublic.set('2', PUBLICO_AFILIADOS);
    mapPublic.set('3', PUBLICO_AFILIADOS_EMPRESA_COMFENALCO);
    mapPublic.set('4', PUBLICO_AFILIADOS_MENOS_EMPLEADOS);

    const dateFormat = (date: string) => {
        return moment(date).locale('es').format('DD [de] MMMM [de] YYYY hh:mm a');
    };


    return (
        <div className='w-full'>
            <Typography className='text-xl font-bold pt-2' color='primary'>Información adicional</Typography>

            {
                serviceData?.nombre &&
                <div className='flex flex-row items-baseline'>
                    <Typography className='text-lg text font-bold pt-2 text-principal-650'>Servicio: </Typography>
                    <Typography className='text-principal-650 pl-2'>{serviceData?.nombre}</Typography>
                </div>
            }

            {
                serviceData?.campoJson?.servFechaInicial &&
                <div className='flex flex-row items-baseline'>
                    <Typography className='text-lg text font-bold pt-2 text-principal-650'>Inicia: </Typography>
                    <Typography className='text-principal-650 pl-2'>{dateFormat(serviceData?.campoJson?.servFechaInicial)}</Typography>
                </div>
            }

            {
                serviceData?.campoJson?.servFechaFinal &&
                <div className='flex flex-row items-baseline'>
                    <Typography className='text-lg text font-bold pt-2 text-principal-650'>Finaliza: </Typography>
                    <Typography className='text-principal-650 pl-2'>{dateFormat(serviceData?.campoJson?.servFechaFinal)}</Typography>
                </div>
            }

            {
                serviceData?.campoJson?.publico &&
                <div className='flex flex-row items-baseline'>
                    <Typography className='text-lg text font-bold pt-2 text-principal-650'>Público: </Typography>
                    <Typography className='text-principal-650 pl-2'>{mapPublic.get(serviceData?.campoJson?.publico)}</Typography>
                </div>
            }


        </div>
    )
}
