import { useState } from 'react';
import { MTicketPqrRequest, MTicketPqrResponse } from '../models/ticketPqrModel';
import integrationApi from '../api/integrationApi';

export const useTickets = () => {
    const [isLoadingTicketPqr, setIsLoadingTicketPqr] = useState(false);

    const urlTickets = 'ticket';

    const getTicketPqr = async (ticketRequest: MTicketPqrRequest) => {
        try {
            setIsLoadingTicketPqr(true);
            const ticketPqr = await integrationApi.post<MTicketPqrResponse>(
                `${urlTickets}/getTicket`,
                ticketRequest,
            );
            setIsLoadingTicketPqr(false);

            return ticketPqr.data;
        } catch (error) {
            return null;
        }
    };

    return {
        getTicketPqr,
        isLoadingTicketPqr,
    };
};