import React from 'react';
import { Beneficiario, SubsidiosFamiliare } from 'models/subsidyMonetaryModel';
import { Divider, IconButton, Tooltip, Typography } from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

interface MonetarySubsidyInformationProps {
    color: string;
    selectedPeriod: SubsidiosFamiliare | undefined;
    message: string;
}

export const MonetarySubsidyInformation = ({
    color,
    selectedPeriod,
    message
}: MonetarySubsidyInformationProps) => {
    return (
        <div className='flex flex-wrap justify-evenly px-10 w-full mt-4 text-principal-600'>
            <div className='w-full md:w-[65%] md:mt-0 border rounded-lg'>
                <div className='py-4 px-2 border-b flex justify-center flex-wrap text-center'>
                    <Typography className='font-bold text-xl' color='primary'>{selectedPeriod?.periodoSubsidio}</Typography>
                    <p className='font-bold text-principal-600 w-full'>Periodo liquidado</p>
                </div>
                <div className='flex justify-evenly flex-wrap text-center py-2 items-center border-b bg-principal-350/30'>
                    <p className='text-principal-600'>Valor liquidado</p>
                    <Typography className='font-bold text-xl' color='primary'>
                        {Number(selectedPeriod?.valorLiquidado).toLocaleString('es-CO', {
                            style: 'currency',
                            currency: 'COP',
                            minimumFractionDigits: 0,
                        })}
                    </Typography>
                </div>
                <div className='flex flex-wrap w-ful px-5 py-3 justify-between'>
                    <div className='flex flex-wrap justify-between w-full border-b pb-3'>
                        <div className='flex w-9/20 flex-col'>
                            <div className='flex flex-wrap items-center'>
                                <p className='font-bold text-principal-600'>
                                    Fecha aproximada de pago
                                </p>
                                <Tooltip title={message}>
                                    <IconButton>
                                        <HelpOutlineOutlinedIcon fontSize='small' color='primary'/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <p className='text-principal-600'>{selectedPeriod?.fechaPago}</p>
                        </div>

                        <Divider orientation="vertical" flexItem />

                        <div className='flex w-9/20 flex-col'>
                            <p className='font-bold text-principal-600'>
                                Cantidad de beneficiarios
                            </p>
                            <p className='text-principal-600'>{selectedPeriod?.numeroBeneficiarios}</p>
                        </div>
                    </div>

                    <div className='flex flex-wrap justify-between w-full pt-2'>
                        <div className='flex w-9/20 flex-col'>
                            <p className='font-bold text-principal-600'>
                                Forma de pago
                            </p>
                            <p className='text-principal-600'>{selectedPeriod?.formaPago}</p>
                        </div>

                        <Divider orientation="vertical" flexItem />

                        <div className='flex w-9/20 flex-col'>
                            <p className='text-principal-600'>{message}</p>
                        </div>
                    </div>


                    <Divider orientation="vertical" />
                </div>

                <Divider orientation="horizontal" className='w-full my-2' flexItem />

                <div className='flex flex-wrap w-ful px-5 py-3 justify-between'>
                    <div className='items-center flex justify-center w-full text-center'>
                        <p className='font-bold text-principal-600 w-full'>Persona autorizada</p>
                    </div>
                    <div className='flex flex-wrap pt-4 w-full justify-between'>
                        <p className='font-bold text-principal-600'>
                            {selectedPeriod?.personaAutorizada.primerNombrePerosna}
                            {selectedPeriod?.personaAutorizada.segundoNombrePerosna
                                ? selectedPeriod?.personaAutorizada.segundoNombrePerosna
                                : ''}
                            {selectedPeriod?.personaAutorizada.primerApellidoPerosna}
                            {selectedPeriod?.personaAutorizada.SegundoApellidoPerosna
                                ? selectedPeriod?.personaAutorizada.SegundoApellidoPerosna
                                : ''}
                        </p>
                        <div className='flex w-9/20 flex-col'>
                            <p className='font-bold text-principal-600'>
                                Tipo de documento
                            </p>
                            <p >{selectedPeriod?.personaAutorizada.tipoDocumentoPerosna}</p>
                        </div>

                        <Divider orientation="vertical" />

                        <div className='flex w-9/20 flex-col' >
                            <p className='font-bold text-principal-600'>Documento</p>
                            <p >{selectedPeriod?.personaAutorizada.numeroDocumentoPerosna}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='w-full md:w-[30%] border rounded-lg mt-4 md:mt-0'>
                <div className='py-4 px-2flex justify-center flex-wrap text-center'>
                    <p className='font-bold text-principal-600 text-lg w-full'>Beneficiarios</p>
                    {selectedPeriod?.beneficiarios.beneficiario.map((item: Beneficiario, index: number) => (
                        <div className='pt-3 px-5 flex flex-wrap justify-start' key={index}>
                            <Typography className='font-bold text-base w-full text-start' color='primary'>
                                {item.NombreCompleto}
                            </Typography>
                            <div className='pt-4'>
                                <div className='flex flex-wrap'>
                                    <p className='font-bold text-base'>Valor subsidio: {' '}</p>
                                    <p className='pl-2'>
                                        {Number(item.valorSubsidioBene).toLocaleString('es-CO', {
                                            style: 'currency',
                                            currency: 'COP',
                                            minimumFractionDigits: 0,
                                        })}
                                    </p>
                                </div>
                                <div className='flex flex-wrap'>
                                    <p className='font-bold text-base'>Tipo documento</p>
                                    <p className='pl-2'>
                                        {item.tipoDocumentoBeneficiario}
                                    </p>
                                </div>
                                <div className='flex flex-wrap'>
                                    <p className='font-bold text-base'>Documento</p>
                                    <p className='pl-2'>
                                        {item.numeroDocumentoBeneficiario}
                                    </p>
                                </div>
                            </div>
                            {index !== selectedPeriod.beneficiarios.beneficiario.length - 1 && (
                                <hr className='my-2 w-full border-gray-300' />
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}