import React, { useEffect, useState } from "react";
import { AditionalsForm } from "./AditionalsForm";
import { Rates } from "./Rates";
import { Beneficiary } from "models/Beneficiary";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { Typography } from "@mui/material";
import { ServiceData } from "models/serviceData";
import {
  ABREVIACION_ORIGEN_WEB,
  COMPRA_INDIVIDUAL_ACTIVA,
  IND_ORIGEN_COLEGIO,
  IND_ORIGEN_CONVENIOS,
  IND_ORIGEN_EDUCACION,
  IND_ORIGEN_EVENTOS,
  OPORTUNIDAD,
  ORIGEN_WEB,
  PARTICULAR,
  TIPO_USUARIO,
} from "utils/constants";
import { ServiceRates } from "models/servicesRates";
import { UnavailableDates } from "models/uavailableDate";
import { useForm, SubmitHandler } from "react-hook-form";
import { PurchaseForm } from "models/purchaseForm";
import { FormDatePicker } from "./datePicker";
import { CompraRequest, UsuarioCompra } from "models/compraCero";
import { InfoRate } from "models/infoRate";
import moment from "moment";
import { AditionalCounter } from "models/aditionalCounter";
import { usePurchase } from "hooks/usePurchase";
import { SmallLoading } from "./SmallLoading";
import { setSuccess } from "store/slices/status";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { ValidadorCaja } from "models/boxValidatorModel";
import { InterviewsEdu } from "./InterviewsEdu";
import {
  InterviewSelectedColDTO,
  InterviewSelectedEduDTO,
} from "models/interviewSelected";
import { useInterviewsEdu } from "hooks/useInterviewEdu";
import { InterviewRequest } from "models/interviewRequestModel";
import { InterviewsCol } from "./InterviewsCol";
import { Loading } from "./Loading";
import { ServiceConfirmationDialog } from "pages/Services/ServiceConfirmationDialog";
import { useShoppingCar } from "hooks/useShoppingCar";
import { useParam } from "hooks/useParam";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useReCaptcha } from "hooks/useReCaptcha";

interface ServiceForm {
  serviceData?: ServiceData;
  rates?: ServiceRates;
  unavailableDates?: UnavailableDates[];
  firstAvailableDate?: string;
  boxValidator?: ValidadorCaja;
  emailMessage?: string;
  isLoadingRates?: boolean;
}

export const ServiceTypeForm = ({
  serviceData,
  rates,
  unavailableDates = [],
  firstAvailableDate,
  boxValidator,
  emailMessage,
  isLoadingRates,
}: ServiceForm) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { isLogged, authData } = useSelector((state: RootState) => state.auth);
  const { getParamData } = useParam();
  const { beneficiarioVOs, afiliado } = useSelector(
    (state: RootState) => state.auth.authData
  );
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { validateTokenRecaptcha } = useReCaptcha();
  const [recaptchaAlreadyValidated, setRecaptchaAlreadyValidated] = useState(false);
  const [errorRecaptcha, setErrorRecaptcha] = useState(false);

  const { documentNumber, documentType } = useSelector(
    (state: RootState) => state.individualSale.individualUserData
  );

  const { zeroPay, compraValor } = usePurchase();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
    unregister,
    getValues,
  } = useForm<PurchaseForm>({
    defaultValues: {
      benefeciariesSelected:
        serviceData?.campoJson.compraIndividual === COMPRA_INDIVIDUAL_ACTIVA
          ? null
          : [],
    },
  });

  const [urlPasarela, seturlPasarela] = useState("");

  const formValues = watch();

  const [aditionalsCounter, setAditionalsCounter] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);

  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false);

  const [counterRate, setCounterRate] = useState<AditionalCounter[]>(
    rates?.tarifasCategoriaD?.map((rate) => {
      return { counter: 0, rate: rate };
    })!
  );

  const { getInterviewsEdu, isLoadingInterviews, getInterviewsColegio } =
    useInterviewsEdu();

  const [availableInterviewsEdu, setAvailableInterviewsEdu] =
    useState<InterviewSelectedEduDTO[]>();
  const [availableInterviewsEduByDay, setAvailableInterviewsEduByDay] =
    useState<InterviewSelectedEduDTO[]>();

  const [availableInterviewsCol, setAvailableInterviewsCol] =
    useState<InterviewSelectedColDTO[]>();
  const [availableInterviewsColByDay, setAvailableInterviewsColByDay] =
    useState<InterviewSelectedColDTO[]>();

  const [availableDays, setAvailableDays] = useState<string[]>();

  const doValidateRecaptcha = async () => {
    if (executeRecaptcha) {
      let token = await executeRecaptcha('submit');

      const validateTokenResponse = await validateTokenRecaptcha(token);
      if (validateTokenResponse?.success) {
        setRecaptchaAlreadyValidated(true);
      } else {
        setRecaptchaAlreadyValidated(false);
        setErrorRecaptcha(true);
      }
    }

  }

  useEffect(() => {
    doValidateRecaptcha();
  }, [serviceData?.tieneReglaCine]);

  useEffect(() => {
    if (
      serviceData?.campoJson.tieneEntrevista &&
      serviceData?.campoJson.tieneEntrevista === "S" &&
      serviceData?.indOrigen !== IND_ORIGEN_COLEGIO
    ) {
      if (!formValues.selectedDate) {
        return;
      }

      setValue("interviewSelected", undefined);
      setAvailableInterviewsEduByDay([]);

      const interviewsDay = availableInterviewsEdu?.filter(
        (interview) =>
          interview.fecha.getTime() === formValues.selectedDate!.getTime()
      );

      if (interviewsDay!.length > 0) {
        setAvailableInterviewsEduByDay(interviewsDay);
      }
    }

    if (
      serviceData?.campoJson.tieneEntrevista &&
      serviceData?.campoJson.tieneEntrevista === "S" &&
      serviceData?.indOrigen === IND_ORIGEN_COLEGIO
    ) {
      if (!formValues.selectedDate) {
        return;
      }

      setValue("interviewSelected", undefined);
      setAvailableInterviewsColByDay([]);

      const interviewsDay = availableInterviewsCol?.filter(
        (interview) =>
          interview.fecha.getTime() === formValues.selectedDate!.getTime()
      );

      if (interviewsDay!.length > 0) {
        setAvailableInterviewsColByDay(interviewsDay);
      }
    }
  }, [formValues.selectedDate]);

  useEffect(() => {
    if (
      serviceData?.campoJson.tieneEntrevista &&
      serviceData?.campoJson.tieneEntrevista === "S" &&
      serviceData?.indOrigen !== IND_ORIGEN_COLEGIO
    ) {
      const getInterviewsEducacion = async () => {
        const interviewsRequest: InterviewRequest = {
          codigoPeriodo: serviceData?.campoJson.codigoPeriodo,
          codigoFacultad: serviceData?.campoJson.codigoFacultad,
          codigoSede: serviceData?.campoJson.codigoSede,
        };

        const interviews = await getInterviewsEdu(interviewsRequest);
        const days: string[] = [];

        if (!interviews || interviews.entrevistas == null) {
          setAvailableDays(days);
          // Ordenar por hora
          setAvailableInterviewsEdu([]);

          setValue("selectedDate", null);
          return;
        }

        const availableInterviewsArray = interviews?.entrevistas?.flatMap(
          (interview) =>
            interview.fechasEntrevista!.map((dateInterview) => {
              const date = new Date(dateInterview.fecha!);
              if (!days.includes(moment(date).format("DD/MM/YYYY"))) {
                days.push(moment(date).format("DD/MM/YYYY"));
              }
              const [hour, minute] = dateInterview.hora!.split(":");
              const hourPeriod = Number(hour) < 12 ? "AM" : "PM";
              const formattedHour =
                Number(hour) % 12 != 0
                  ? `${Number(hour) % 12}:${minute} ${hourPeriod}`
                  : `12:${minute} ${hourPeriod}`;
              return {
                codigo: dateInterview.codigo,
                codigoPsicologo: interview.codigoPsicologo,
                fecha: date,
                hora: formattedHour,
                nombrePsicologo: interview.nombrePsicologo,
              } as InterviewSelectedEduDTO;
            })
        );

        setAvailableDays(days);

        availableInterviewsArray?.sort(SortArray); // Ordenar por hora
        setAvailableInterviewsEdu(availableInterviewsArray);

        const sortedByDate = [...availableInterviewsArray!]?.sort(
          SortArrayByDate
        ); // Ordenar por fecha
        // Aquí puedes acceder a la primera fecha disponible
        const firstAvailableDate = sortedByDate[0].fecha;

        setValue("selectedDate", firstAvailableDate);
      };

      getInterviewsEducacion();
    }

    if (
      serviceData?.campoJson.tieneEntrevista &&
      serviceData?.campoJson.tieneEntrevista === "S" &&
      serviceData?.indOrigen === IND_ORIGEN_COLEGIO
    ) {
      const getInterviewsEducacion = async () => {
        const interviews = await getInterviewsColegio(serviceData?.idServicio);
        const days: string[] = [];

        if (!interviews || interviews.entrevistas == null) {
          setAvailableDays(days);
          // Ordenar por hora
          setAvailableInterviewsCol([]);

          setValue("selectedDate", null);
          return;
        }

        const availableInterviewsArray = interviews?.entrevistas?.flatMap(
          (interview) => {
            const date = moment(interview.fecha!).toDate();
            if (!days.includes(moment(date).format("DD/MM/YYYY"))) {
              days.push(moment(date).format("DD/MM/YYYY"));
            }
            const hourPeriod = Number(interview.hora) < 12 ? "AM" : "PM";
            const formattedHour =
              Number(interview.hora) % 12 != 0
                ? `${Number(interview.hora) % 12}:${interview.minuto.padStart(
                  2,
                  "0"
                )} ${hourPeriod}`
                : `12:${interview.minuto.padStart(2, "0")} ${hourPeriod}`;
            return {
              codigo: interview.codigo,
              fecha: date,
              hora: formattedHour,
            } as InterviewSelectedColDTO;
          }
        );

        setAvailableDays(days);

        availableInterviewsArray?.sort(SortArray); // Ordenar por hora
        setAvailableInterviewsCol(availableInterviewsArray);

        const sortedByDate = [...availableInterviewsArray!]?.sort(
          SortArrayByDate
        ); // Ordenar por fecha
        // Aquí puedes acceder a la primera fecha disponible
        const firstAvailableDate = sortedByDate[0].fecha;

        setValue("selectedDate", firstAvailableDate);
      };

      getInterviewsEducacion();
    }


  }, []);

  const SortArray = (x: any, y: any) => {
    if (x?.hora < y?.hora) {
      return -1;
    }
    if (x?.hora > y?.hora) {
      return 1;
    }
    return 0;
  };

  const SortArrayByDate = (x: any, y: any) => {
    if (x?.fecha < y?.fecha) {
      return -1;
    }
    if (x?.fecha > y?.fecha) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    rates &&
      setCounterRate(
        rates?.tarifasCategoriaD?.map((rate) => {
          return { counter: 0, rate: rate };
        })!
      );
  }, [rates]);

  useEffect(() => {
    formValues?.benefeciariesSelected && getTotalAmount();
  }, [formValues.benefeciariesSelected, aditionalsCounter]);

  // Obtiene los usuarios seleccionados para la compra
  const getPurchaseUser = (data: PurchaseForm) => {

    let interview:
      | InterviewSelectedEduDTO
      | InterviewSelectedColDTO
      | undefined;

    if (
      serviceData?.campoJson.tieneEntrevista &&
      serviceData?.campoJson.tieneEntrevista === "S" &&
      serviceData?.indOrigen !== IND_ORIGEN_COLEGIO
    ) {
      interview = availableInterviewsEdu?.find(
        (interview) => interview.codigo === data?.interviewSelected
      )!;
    }

    if (
      serviceData?.campoJson.tieneEntrevista &&
      serviceData?.campoJson.tieneEntrevista === "S" &&
      serviceData?.indOrigen === IND_ORIGEN_COLEGIO
    ) {
      interview = availableInterviewsCol?.find(
        (interview) => interview.codigo === data?.interviewSelected
      )!;
    }

    const userRates: InfoRate[] =
      serviceData?.campoJson.compraIndividual === COMPRA_INDIVIDUAL_ACTIVA
        ? [
          rates?.tarifasUsuarios.find(
            (beneficiary: InfoRate) =>
              beneficiary.identificacionUsuario === data.benefeciariesSelected
          )!,
        ]
        : data.benefeciariesSelected?.map(
          (beneficiaryId: string) =>
            rates?.tarifasUsuarios.find(
              (beneficiary: InfoRate) =>
                beneficiary.identificacionUsuario === beneficiaryId
            )!
        );

    const purchaseUser: UsuarioCompra[] = userRates?.map(
      (userRate: InfoRate) => {
        if (isLogged && authData?.tipoUsuario !== TIPO_USUARIO) {
          const beneficiary = beneficiarioVOs.find(
            (beneficiary: Beneficiary) =>
              beneficiary?.identificacion === userRate?.identificacionUsuario
          );

          if (beneficiary) {
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion: beneficiary?.abreviatura,
              numIdentificacion: beneficiary?.identificacion,
              nombre: beneficiary?.nombre,
              correo: "",
              fechaNacimiento: moment(beneficiary?.fechaNacimiento).format(
                "YYYY/MM/DD"
              ),
              genero: beneficiary?.sexo,
              edad: Number(userRate?.edadUsuario),
              valor: interview ? 0 : userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: Number(
                userRate?.tarifa?.idCategoriaUsuarioCdaCategoriaUsuario
              ),
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          } else {
            const birthDate =
              data?.aditionalForm && data?.aditionalForm[0]?.birthDate;
            const fechaNac = moment(birthDate, "YYYY-MM-DD");
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentType,
              numIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentNumber,
              nombre:
                data?.aditionalForm &&
                data?.aditionalForm[0]?.firstName +
                " " +
                data?.aditionalForm[0]?.secondName,
              correo: "",
              fechaNacimiento:
                data?.aditionalForm &&
                moment(data?.aditionalForm[0]?.birthDate).format("YYYY/MM/DD"),
              genero: data?.aditionalForm && data?.aditionalForm[0]?.gender,
              edad: moment().diff(fechaNac, "years"),
              valor: interview ? 0 : userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: 4,
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          }
        } else {
          const beneficiary =
            boxValidator?.grupoFamiliar?.informacionGrupo?.find(
              (beneficiary: Beneficiary) =>
                beneficiary?.identificacion === userRate?.identificacionUsuario
            );

          if (beneficiary) {
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion: userRate?.abreviaturaUsuario,
              numIdentificacion: userRate?.identificacionUsuario,
              nombre: userRate?.nombreUsuario,
              correo: "",
              fechaNacimiento: moment(
                boxValidator?.afiliado.fechaNacimiento
              ).format("YYYY/MM/DD"),
              genero: boxValidator?.afiliado.sexo!,
              edad: Number(userRate?.edadUsuario),
              valor: interview ? 0 : userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: Number(boxValidator?.afiliado.idCate),
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };
            return usuarioCompra;
          } else {
            const birthDate =
              data?.aditionalForm && data?.aditionalForm[0]?.birthDate;
            const fechaNac = moment(birthDate, "YYYY-MM-DD");
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentType,
              numIdentificacion:
                data?.aditionalForm && data?.aditionalForm[0]?.documentNumber,
              nombre:
                data?.aditionalForm &&
                data?.aditionalForm[0]?.firstName +
                " " +
                data?.aditionalForm[0]?.secondName,
              correo: "",
              fechaNacimiento:
                data?.aditionalForm &&
                moment(data?.aditionalForm[0]?.birthDate).format("YYYY/MM/DD"),
              genero: data?.aditionalForm && data?.aditionalForm[0]?.gender,
              edad: moment().diff(fechaNac, "years"),
              valor: interview ? 0 : userRate.tarifa?.valor,
              porcentajeDcto: userRate?.tarifa?.porcentajeDcto,
              idCate: 4,
              edadMinima: userRate?.tarifa?.edadMinima,
              edadMaxima: userRate?.tarifa?.edadMaxima,
              oportunidad: "",
            };

            return usuarioCompra;
          }
        }
      }
    );

    return purchaseUser;
  };

  // Obtiene los usuarios adicionales de la compra
  const getAditionalUsers = () => {
    let usuariosCompraAdicional: UsuarioCompra[] = [];

    if (counterRate) {
      for (const aditional of counterRate) {
        if (aditional.counter > 0) {
          for (let index = 0; index < aditional.counter; index++) {
            const usuarioCompra: UsuarioCompra = {
              tipoIdentificacion: "CC",
              numIdentificacion: "1111111111",
              nombre: PARTICULAR,
              correo: "",
              fechaNacimiento: moment(new Date()).format("YYYY/MM/DD"),
              genero: "",
              edad: aditional?.rate?.tarifa?.edadMinima,
              valor: aditional?.rate?.tarifa?.valor,
              idCate:
                aditional?.rate?.tarifa.idCategoriaUsuarioCdaCategoriaUsuario,
              edadMinima: aditional?.rate?.tarifa?.edadMinima,
              edadMaxima: aditional?.rate?.tarifa?.edadMaxima,
              oportunidad: "N",
            };

            usuariosCompraAdicional.push(usuarioCompra);
          }
        }
      }
    }

    return usuariosCompraAdicional;
  };

  // Asigna la oportunidad al usuario mayor
  const updateOportunidad = (users: UsuarioCompra[]) => {
    if (isLogged && authData?.tipoUsuario !== TIPO_USUARIO) {
      const userIndex = users.findIndex(
        (user) => user.numIdentificacion === afiliado.identificacion
      );

      if (userIndex !== -1) {
        users[userIndex].oportunidad = OPORTUNIDAD;
      } else {
        const olderUser = users.reduce(
          (max, user) => (user?.edad! > max?.edad! ? user : max),
          { edad: 0 }
        );

        const olderUserIndex = users.indexOf(olderUser);

        if (olderUserIndex !== -1) {
          users[olderUserIndex].oportunidad = OPORTUNIDAD;
        } else {
          const olderAditionalUser = users.reduce(
            (max, user) => (user?.edadMaxima! > max?.edadMaxima! ? user : max),
            { edadMaxima: 0 }
          );

          const olderAditionalUserIndex = users.indexOf(olderAditionalUser);

          users[olderAditionalUserIndex].oportunidad = OPORTUNIDAD;
        }
      }
    } else {
      const userIndex = users.findIndex(
        (user) => user.numIdentificacion === documentNumber
      );

      if (userIndex !== -1) {
        users[userIndex].oportunidad = OPORTUNIDAD;
      } else {
        const olderUser = users.reduce(
          (max, user) => (user?.edad! > max?.edad! ? user : max),
          { edad: 0 }
        );

        const olderUserIndex = users.indexOf(olderUser);

        if (olderUserIndex !== -1) {
          users[olderUserIndex].oportunidad = OPORTUNIDAD;
        } else {
          const olderAditionalUser = users.reduce(
            (max, user) => (user?.edadMaxima! > max?.edadMaxima! ? user : max),
            { edadMaxima: 0 }
          );

          const olderAditionalUserIndex = users.indexOf(olderAditionalUser);

          users[olderAditionalUserIndex].oportunidad = OPORTUNIDAD;
        }
      }
    }
    return users;
  };

  // Calcula el valor total de la compra
  const getTotalAmount = () => {
    if (
      !serviceData?.campoJson.tieneEntrevista ||
      serviceData?.campoJson.tieneEntrevista !== "S"
    ) {
      const userRates: InfoRate[] =
        serviceData?.campoJson.compraIndividual === COMPRA_INDIVIDUAL_ACTIVA
          ? [
            rates?.tarifasUsuarios.find(
              (beneficiary: InfoRate) =>
                beneficiary.identificacionUsuario ===
                formValues?.benefeciariesSelected
            ),
          ]
          : formValues?.benefeciariesSelected?.map((beneficiaryId: string) =>
            rates?.tarifasUsuarios.find(
              (beneficiary: InfoRate) =>
                beneficiary.identificacionUsuario === beneficiaryId
            )
          );

      const userAditionalRates: InfoRate[] =
        serviceData?.campoJson.compraIndividual === COMPRA_INDIVIDUAL_ACTIVA
          ? [
            rates?.tarifasCategoriaD?.find(
              (beneficiary: InfoRate) =>
                beneficiary?.tarifa?.idTarifa ===
                Number(formValues?.benefeciariesSelected)
            ),
          ]
          : formValues?.benefeciariesSelected?.map((beneficiaryId: string) =>
            rates?.tarifasCategoriaD.find(
              (beneficiary: InfoRate) =>
                beneficiary?.tarifa?.idTarifa === Number(beneficiaryId)
            )
          );

      const totalAditionalRatesAmount = userAditionalRates?.reduce(
        (acumulador, usuario) => {
          if (usuario?.tarifa?.valor) {
            return acumulador + usuario?.tarifa?.valor;
          } else {
            return acumulador;
          }
        },
        0
      );

      const totalRatesAmount = userRates?.reduce((acumulador, usuario) => {
        if (usuario?.tarifa?.valor) {
          return acumulador + usuario?.tarifa?.valor;
        } else {
          return acumulador;
        }
      }, 0);

      const totalAditionalsAmount = counterRate
        ? counterRate?.reduce((acumulador, usuario) => {
          return acumulador + usuario?.rate?.tarifa?.valor * usuario?.counter;
        }, 0)
        : 0;

      setTotalAmount(
        totalAditionalRatesAmount + totalRatesAmount + totalAditionalsAmount
      );
    }
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState<PurchaseForm>();

  const handleFormSubmit: SubmitHandler<PurchaseForm> = async (
    data: PurchaseForm
  ) => {
    if (data?.interviewSelected) {
      setFormData(data);
      setOpenDialog(true);
    } else {
      if (serviceData?.tieneReglaCine) {
        await doValidateRecaptcha();
        if (recaptchaAlreadyValidated) {
          formSubmit(data)
        } else setIsLoadingPurchase(false);
      } else {
        formSubmit(data);
      }

    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  //pagar 

  useEffect(() => {
    const getParamUrlPasarela = async () => {
      const getParamUrlPasarela = await getParamData("URL_PASARELA");
      // Get url pasarela param
      if (getParamUrlPasarela?.data) {
        const dataParameter = getParamUrlPasarela?.data;
        seturlPasarela(dataParameter.valorParametro);
      }
    }
    getParamUrlPasarela();
  }, [urlPasarela]);

  const {
    paySale,
  } = useShoppingCar();
  const handlePaySale = async (sale: any) => {
    const paySaleResultInfo = await paySale(sale.idCompra);
    setIsLoadingPurchase(false);
    dispatch(setSuccess(null));
    window.location.replace(urlPasarela + paySaleResultInfo?.infoPago);
  };

  // Realiza el envio del formulario
  const formSubmit: SubmitHandler<PurchaseForm> = async (
    data: PurchaseForm
  ) => {
    openDialog && setOpenDialog((prev) => !prev);
    setIsLoadingPurchase(true);
    const purchaseUser: UsuarioCompra[] = getPurchaseUser(data);
    const aditionalPurchaseUser: UsuarioCompra[] = getAditionalUsers();

    let interview:
      | InterviewSelectedEduDTO
      | InterviewSelectedColDTO
      | undefined;

    if (
      serviceData?.campoJson.tieneEntrevista &&
      serviceData?.campoJson.tieneEntrevista === "S" &&
      serviceData?.indOrigen !== IND_ORIGEN_COLEGIO
    ) {
      interview = availableInterviewsEdu?.find(
        (interview) => interview.codigo === data?.interviewSelected
      )!;
    }

    if (
      serviceData?.campoJson.tieneEntrevista &&
      serviceData?.campoJson.tieneEntrevista === "S" &&
      serviceData?.indOrigen === IND_ORIGEN_COLEGIO
    ) {
      interview = availableInterviewsCol?.find(
        (interview) => interview.codigo === data?.interviewSelected
      )!;
    }

    const compraRequest: CompraRequest = {
      valorTotal:
        serviceData?.campoJson.tieneEntrevista &&
          serviceData?.campoJson.tieneEntrevista === "S"
          ? 0
          : totalAmount,
      porcentajeDcto: 0,
      origenCompra: ABREVIACION_ORIGEN_WEB,
      fechaInicioServicio: serviceData?.campoJson?.servFechaInicial
        ? moment(serviceData?.campoJson?.servFechaInicial).format("YYYY/MM/DD")
        : moment(data?.selectedDate).format("YYYY/MM/DD"),
      fechaFinServicio: serviceData?.campoJson?.servFechaFinal
        ? moment(serviceData?.campoJson?.servFechaFinal).format("YYYY/MM/DD")
        : null,
      fechaServicio: moment(data?.selectedDate).format("YYYY/MM/DD"),
      idServicio: serviceData?.idServicio,
      cantidad: purchaseUser?.length,
      medioCompra: ORIGEN_WEB,
      centroServicio: "" + serviceData?.idCentroServicioCdaCentroServicio,
      compraUsuarios: updateOportunidad([
        ...purchaseUser,
        ...aditionalPurchaseUser,
      ]),
      afiliadoIdentificacion:
        !isLogged || (isLogged && authData?.tipoUsuario === TIPO_USUARIO)
          ? documentNumber
          : undefined,
      afiliadoTipoIden:
        !isLogged || (isLogged && authData?.tipoUsuario === TIPO_USUARIO)
          ? documentType
          : undefined,
      entrevista: interview ? interview : null,
      propietario:
        authData?.empleadoCRM && authData?.empleadoCRM.idEmpleado
          ? authData.empleadoCRM.idEmpleado
          : "",
    };


    if (totalAmount > 0) {
      const response = await compraValor(compraRequest);

      if (response) {
        response?.mensaje &&
          dispatch(
            setSuccess(
              "La compra se realizó exitosamente, serás redirigido a la pasarela de pago"
            )
          );
        setTimeout(() => {
          if (!serviceData?.tieneReglaCine) {
            handlePaySale(response);
          } else {
            setIsLoadingPurchase(false);
            dispatch(setSuccess(null));
          }

        }, 5000);
      } else {
        setIsLoadingPurchase(false);
      }
    } else {
      const response = await zeroPay(compraRequest);
      if (response) {
        response?.mensaje &&
          dispatch(
            interview
              ? setSuccess(
                "La entrevista se agendo exitosamente, serás redirigido al historial de compras"
              )
              : setSuccess(
                "La compra se realizó exitosamente, serás redirigido al historial de compras"
              )
          );
        setIsLoadingPurchase(false);
        navigate("/historial-compras");
      } else {
        setIsLoadingPurchase(false);
      }
    }
  };

  const dateFormat = (date: string) => {
    return moment(date).format("DD/MM/YYYY hh:mm a");
  };

  return (
    <div className="w-full">
      {isLoadingPurchase && <Loading />}
      <form onSubmit={handleSubmit(handleFormSubmit)} className="w-full">
        {serviceData?.indOrigen !== IND_ORIGEN_EDUCACION &&
          serviceData?.indOrigen !== IND_ORIGEN_EVENTOS &&
          serviceData?.indOrigen !== IND_ORIGEN_COLEGIO &&
          serviceData?.campoJson?.tieneEntrevista !== "S" &&
          serviceData?.campoJson.servFechaInicial == null &&
          serviceData?.campoJson.servFechaFinal == null && (
            <FormDatePicker
              register={register}
              setValue={setValue}
              unavailableDates={unavailableDates}
              errors={errors}
              firstAvailableDate={firstAvailableDate}
            />
          )}

        {serviceData?.indOrigen === IND_ORIGEN_EVENTOS && (
          <div className="flex justify-between">
            <p className="font-bold text-lg text-principal-600">
              Fecha y hora del evento:{" "}
            </p>
            <p className="font-bold text-lg text-principal-650">
              {dateFormat(serviceData?.campoJson?.servFechaInicial)}
            </p>
          </div>
        )}

        <Typography className="font-bold text-2xl pt-8" color="primary">
          {serviceData?.campoJson?.compraIndividual === COMPRA_INDIVIDUAL_ACTIVA
            ? "¿Quién será el asistente?"
            : "¿Quiénes serán los asistentes?"}
        </Typography>

        <div className="w-full pt-8">
          {isLoadingRates ? (
            <SmallLoading />
          ) : (
            <Rates
              indOrigen={serviceData?.indOrigen}
              compraIndividual={serviceData?.campoJson?.compraIndividual}
              showPriceInline={
                serviceData?.indOrigen !== IND_ORIGEN_CONVENIOS &&
                serviceData?.campoJson.tieneEntrevista !== "S"
              }
              rates={rates}
              errors={errors}
              register={register}
              setValue={setValue}
              watch={watch}
              counterRate={counterRate}
              unregister={unregister}
              getValues={getValues}
              showDiscount={serviceData?.indOrigen === IND_ORIGEN_CONVENIOS}
              serviceData={serviceData}
            />
          )}
        </div>

        {serviceData?.indOrigen !== IND_ORIGEN_EDUCACION &&
          serviceData?.indOrigen !== IND_ORIGEN_EVENTOS &&
          serviceData?.indOrigen !== IND_ORIGEN_COLEGIO && (
            <div className="w-full pt-8">
              <AditionalsForm
                counterRate={counterRate}
                setCounterRate={setCounterRate}
                rates={rates?.tarifasCategoriaD}
                aditionalsCounter={aditionalsCounter}
                setAditionalsCounter={setAditionalsCounter}
              />
            </div>
          )}

        {serviceData?.campoJson.tieneEntrevista &&
          serviceData?.campoJson.tieneEntrevista === "S" &&
          serviceData?.indOrigen !== IND_ORIGEN_COLEGIO && (
            <InterviewsEdu
              register={register}
              setValue={setValue}
              watch={watch}
              availableInterviewsByDay={availableInterviewsEduByDay}
              availableDays={availableDays}
              availableInterviews={availableInterviewsEdu}
              isLoadingInterviews={isLoadingInterviews}
            />
          )}

        {serviceData?.campoJson.tieneEntrevista &&
          serviceData?.campoJson.tieneEntrevista === "S" &&
          serviceData?.indOrigen === IND_ORIGEN_COLEGIO && (
            <InterviewsCol
              register={register}
              setValue={setValue}
              watch={watch}
              availableInterviewsByDay={availableInterviewsColByDay}
              availableDays={availableDays}
              availableInterviews={availableInterviewsCol}
              isLoadingInterviews={isLoadingInterviews}
            />
          )}

        <div
          className="flex w-full pt-8 items-center"
          style={
            !serviceData?.campoJson.tieneEntrevista ||
              serviceData?.campoJson.tieneEntrevista !== "S"
              ? { justifyContent: "space-between" }
              : { justifyContent: "end" }
          }
        >
          {(!serviceData?.campoJson.tieneEntrevista ||
            serviceData?.campoJson.tieneEntrevista !== "S") && (
              <p className="text-lg text-principal-600">
                Total a pagar:{" "}
                <b>
                  {totalAmount
                    ? totalAmount.toLocaleString("es-CO", {
                      style: "currency",
                      currency: "COP",
                      minimumFractionDigits: 0,
                    })
                    : "$0"}
                </b>
              </p>
            )}

          <ServiceConfirmationDialog
            open={openDialog}
            onClose={handleCloseDialog}
            onConfirm={() => formSubmit(formData!)}
          />

          <LoadingButton
            type="submit"
            variant="contained"
            className="w-1/2"
            loading={isLoadingPurchase}
            disabled={
              (serviceData?.campoJson.tieneEntrevista &&
                serviceData?.campoJson.tieneEntrevista === "S" &&
                !formValues?.interviewSelected) ||
              (formValues.benefeciariesSelected == null || formValues.benefeciariesSelected.length <= 0)
            }
          >
            {
              serviceData?.tieneReglaCine
                ? "Postularse"
                : totalAmount > 0
                  ? "Comprar"
                  : serviceData?.campoJson.tieneEntrevista &&
                    serviceData?.campoJson.tieneEntrevista === "S"
                    ? "Agendar entrevista"
                    : "Comprar"
            }
          </LoadingButton>
        </div>
      </form>
    </div>
  );
};
