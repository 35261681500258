import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { Loading } from "components/Loading";
import { ServiceDescription } from "components/ServiceDescription";
import { usePurchaseHistory } from "hooks/usePurchaseHistory";
import { useService } from "hooks/useService";
import { MFile } from "models/MFile";
import { ServiceData } from "models/serviceData";
import { ServiceImages } from "models/serviceImages";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IND_ORIGEN_EDUCACION } from "utils/constants";
import SaleInfo from "./SaleInfo";

export const DetailHistory = () => {
  const { getServiceData, getFileData, getUnavailableDates } = useService();

  const { getDetailPurchase } = usePurchaseHistory();

  const [isLoading, setIsLoading] = useState(true);

  const [serviceImages, setServiceImages] = useState<ServiceImages[]>([]);

  const [serviceData, setServiceData] = useState<ServiceData>();

  const [fileData, setFileData] = useState<MFile[]>();

  const { idService, idCompra } = useParams();

  const [saleDetailInfo, setsaleDetailInfo] = useState(null);
  const purpleTheme = createTheme({
    palette: {
      primary: {
        main: serviceData?.colorNegocio || "#000",
      },
    },
  });

  useEffect(() => {
    const onGetServicedata = async () => {
      setIsLoading(true);

      const { serviceData, serviceImages } = await getServiceData(
        Number(idService)
      );

      setServiceData(serviceData);
      setServiceImages(serviceImages);

      if (serviceData.indOrigen == IND_ORIGEN_EDUCACION) {
        if (idService && serviceData?.indOrigen) {
          const { fileData } = await getFileData(
            Number(idService),
            serviceData?.indOrigen
          );
          setFileData(fileData);
        }
      }
      if (idCompra) {
        const infoSale = await getDetailPurchase(Number(idCompra));
        setsaleDetailInfo(infoSale);
      }

      setIsLoading(false);
    };

    onGetServicedata();
  }, []);

  return (
    <ThemeProvider theme={purpleTheme}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-row justify-around py-8 w-full bg-[#FBFBFB]">
          <div className="flex flex-col w-9/20 xs:w-full rounded-lg border border-principal-350 bg-white">
            <ServiceDescription
              bannerImages={serviceImages}
              serviceData={serviceData}
              fileData={fileData}
              idCompra={idCompra}
            />
          </div>
          <div className="flex flex-col w-9/20 xs:w-full rounded-lg border border-principal-350 bg-white">
            <SaleInfo
              detailInfo={saleDetailInfo}
              indOrigen={serviceData?.indOrigen ?? ""}
            />
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};
