import { Assignment, AssignmentInd, CheckBox, HourglassTop, IndeterminateCheckBox, Payments } from '@mui/icons-material';
import { Step, StepLabel, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react'

interface HousingSubsidyStepperProps {
    currentStep: number;
}

export const SchoolKitStepper = ({ currentStep }: HousingSubsidyStepperProps) => {
    const steps: any = [
        { icon: Assignment, label: 'Postulación' },
        { icon: IndeterminateCheckBox, label: 'Adjudicación' },
    ];

    return (
        <Stepper activeStep={currentStep} className='w-full center' alternativeLabel>
            {steps.map((step: any, index: any) => {

                return (
                    <Step key={step.label}>
                        <StepLabel color={index <= currentStep ? 'primary' : 'disabled'} StepIconProps={{ color: index <= currentStep ? 'primary' : 'disabled' }} StepIconComponent={step.icon}
                        >{step.label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    )
}
