import { GenerateTotpRequest, ValidateTotpRequest } from 'models/totpServiceModel';
import React from 'react';
import integrationApi from 'api/integrationApi';

const useTotp = () => {

    const urlTotp: string = "totp";


    const generateTotp = async (data: GenerateTotpRequest) => {
        try {

            const generateTotpRequest = await integrationApi.post<any>(
                `${urlTotp}/generarTotp`,
                data,
            );
            const generateTotpResponse = await Promise.resolve(generateTotpRequest);
            console.log(generateTotpResponse)

            return generateTotpResponse.data;
        } catch (error) {
            return null;
        }


    }


    const validateTotp = async (data: ValidateTotpRequest) => {
        try {

            const validateTotpRequest = await integrationApi.post<any>(
                `${urlTotp}/validarTotp`,
                data,
            );

            const validateTotpResponse = await Promise.resolve(validateTotpRequest);

            return validateTotpResponse;
        } catch (error) {
            return null;
        }
    }


    return { generateTotp, validateTotp }

}

export default useTotp;
