import { configureStore } from "@reduxjs/toolkit";
import { jwtSlice } from "./slices/JWT";
import { credentialsSlice } from "./slices/credentials";
import { authSlice } from "./slices/auth";
import { statusSlice } from "./slices/status";
import { individualSaleSlice } from "./slices/individualSale";
import { salesCountSlice } from "./slices/salesCount";
import { searchServicesSlice } from "./slices/searchServices";
import { formLightSlice } from "./slices/auth/formLightSlice";

export const store = configureStore({
  reducer: {
    jwt: jwtSlice.reducer,
    credentials: credentialsSlice.reducer,
    auth: authSlice.reducer,
    status: statusSlice.reducer,
    individualSale: individualSaleSlice.reducer,
    salesCount: salesCountSlice.reducer,
    searchServices: searchServicesSlice.reducer,
    formLight: formLightSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
