import { Edit, Login, AccountCircle, ArrowDropDown } from "@mui/icons-material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store";
import { SessionPopOver } from "./SessionPopOver";
// import { AuthContext } from "../auth/context/AuthContext";

interface SesionButtonsProps {
  styles: string;
}

export const SesionButtons = ({ styles }: SesionButtonsProps) => {
  // const { authState } = useContext<any>(AuthContext);

  const credentials = useSelector(
    (state: RootState) => state.credentials.credentials
  );

  const { authData, isLogged } = useSelector((state: RootState) => state.auth);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const getCapital = (value: string) =>
    value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

  const handleNavigate = () => {
    window.open(
      "https://" +
        credentials.urlAuthLogin +
        "/login?app_id=" +
        credentials.ecommerceAppId,
      "_self"
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={styles}>
      {isLogged ? (
        <>
          <button
            className="flex justify-center items-center px-2 rounded-lg md:px-4 text-principal-250 font-bold h-10"
            onClick={handleClick}
          >
            {authData?.afiliado?.primerNombre &&
              getCapital(authData?.afiliado?.primerNombre)}
            <AccountCircle
              fontSize="large"
              className="ml-2 text-principal-550"
            />
            <ArrowDropDown className="-ml-1" />
          </button>
          <SessionPopOver
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            userData={authData?.afiliado}
          />
        </>
      ) : (
        <>
          <button
            className="bg-principal-150 px-2 py-1  rounded-lg mr-4 bg-principal-200 md:py-2 md:px-4"
            onClick={() =>
              window.open(
                credentials.linkRegistro ? credentials.linkRegistro : "",
                "_self"
              )
            }
          >
            <Edit className="mr-0 lg:mr-2" />
            <span className="hidden lg:inline">Registrarse</span>
          </button>

          <button
            className="bg-principal-150 px-2 py-1 rounded-lg bg-principal-250 md:py-2 md:px-4"
            onClick={() => handleNavigate()}
          >
            <Login className="mr-0 lg:mr-2" />
            <span className="hidden lg:inline">Iniciar sesión</span>
          </button>
        </>
      )}
    </div>
  );
};
