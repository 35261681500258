import { useState } from 'react'
import integrationApi from 'api/integrationApi';
import { MQuotas } from 'models/quotas';


export const useQuotas = () => {
    const [isLoadingQuotes, setIsLoadingQuotes] = useState(true);

    const urlQuotes: string = "credit";

    const getQuotes = async (tipoIdentificacion: string, numeroIdentificacion: number) => {

        setIsLoadingQuotes(true);

        const quotes = await integrationApi.post<MQuotas>(`${urlQuotes}/consultaCupoCredito`, {
            tipoIdentificacion: tipoIdentificacion,
            numeroIdentificacion: numeroIdentificacion
        });


        setIsLoadingQuotes(false);
        return quotes?.data;

    }

    return {
        isLoadingQuotes,
        getQuotes
    }
}
