import { ServiceImages } from 'models/serviceImages';
import { CarouselDialog } from 'pages/Hotels/CarouselDialog';
import React, { useState } from 'react'

interface serviceCenterImagesProps {
    serviceCenterImages: ServiceImages[];
}

export const RoomListImages = ({ serviceCenterImages }: serviceCenterImagesProps) => {

    const [openDialog, setOpenDialog] = useState(false);


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div className='flex flex-wrap w-full p-4 bg-white border rounded-md'>
            <div className='w-1/3 h-40 md:h-60  p-2'>
                <div className='flex justify-center items-center h-full w-full rounded bg-cover' style={serviceCenterImages[0] ? { backgroundImage: `url(${serviceCenterImages[0]?.dataImagen})` } : { backgroundColor: '#808080' }} />
            </div>
            <div className='flex flex-wrap justify-between w-1/3 h-40 md:h-60 p-2'>
                <div className='flex justify-center items-center w-49/100 h-49/100 rounded bg-cover' style={serviceCenterImages[1] ? { backgroundImage: `url(${serviceCenterImages[1]?.dataImagen})` } : { backgroundColor: '#808080' }} />
                <div className='flex justify-center items-center w-49/100 h-49/100 rounded bg-cover' style={serviceCenterImages[2] ? { backgroundImage: `url(${serviceCenterImages[2]?.dataImagen})` } : { backgroundColor: '#808080' }} />
                <div className='flex justify-center items-center w-49/100 h-49/100 rounded bg-cover' style={serviceCenterImages[3] ? { backgroundImage: `url(${serviceCenterImages[3]?.dataImagen})` } : { backgroundColor: '#808080' }} />
                <div className='flex justify-center items-center w-49/100 h-49/100 rounded bg-cover' style={serviceCenterImages[4] ? { backgroundImage: `url(${serviceCenterImages[4]?.dataImagen})` } : { backgroundColor: '#808080' }} />
            </div>
            <div className='w-1/3 h-40 md:h-60  p-2'>
                <div className='flex justify-center items-center w-full h-full rounded bg-cover' onClick={() => serviceCenterImages[6] && handleOpenDialog()} style={serviceCenterImages[5] ? { backgroundImage: `url(${serviceCenterImages[5]?.dataImagen})` } : { backgroundColor: '#808080' }} >
                    {
                        serviceCenterImages.length > 6 &&
                        <div className='flex justify-center items-center w-full h-full rounded-lg bg-cover'
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}>
                            <p className='text-white border-b'>
                                + {serviceCenterImages.length - 6} fotos
                            </p>
                        </div>
                    }
                </div>
            </div>
            <CarouselDialog
                open={openDialog}
                onClose={handleCloseDialog}
                serviceCenterImages={serviceCenterImages}
            />
        </div>
    )
}
