import { useState } from "react";
import cartApi from "../api/cartApi";
import integrationApi from "../api/integrationApi";
import { PurchaseHistoryResponse } from "models/purchaseHistoryResponse";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { setSuccess } from "store/slices/status";

export const usePurchaseHistory = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoadingHistory, setIsLoadingHistory] = useState(true);

  const urlHistory: string = "compra";
  const urlQr: string = "qr";

  const getHistory = async (pageNumber: number, pageSize: number) => {
    setIsLoadingHistory(true);

    const history = cartApi.post<PurchaseHistoryResponse>(
      `${urlHistory}/getHistorialCompras`,
      { pageNumber: pageNumber, pageSize: pageSize }
    );

    const historyResp = await Promise.resolve(history);

    setIsLoadingHistory(false);
    return historyResp?.data;
  };

  const deletePurchase = async (idSale: number) => {
    setIsLoadingHistory(true);

    const salesShoppingCar = cartApi.post<any>(
      `${urlHistory}/eliminarRegistroCompra`,
      { idCompra: idSale }
    );

    const salesShoppingCarResp = await Promise.resolve(salesShoppingCar);

    if (salesShoppingCarResp?.status === 200) {
      dispatch(setSuccess("Se ha eliminado el item correctamente"));
      setTimeout(() => {
        dispatch(setSuccess(null));
      }, 6000);
    }

    setIsLoadingHistory(false);

    return salesShoppingCarResp;
  };

  const getDetailPurchase = async (idSale: number) => {
    const salesShoppingCar = cartApi.post<any>(`${urlHistory}/getInfoCompra`, {
      idCompra: idSale,
    });

    const salesShoppingCarResp = await Promise.resolve(salesShoppingCar);

    if (salesShoppingCarResp?.status === 200) {
      return salesShoppingCarResp?.data;
    }
  };

  const getQrsSale = async (idSale: number) => {
    const qrSalesShoppingCar = integrationApi.get<any>(
      `${urlQr}/qrsCompra?id=${idSale}`
    );

    const salesShoppingCarResp = await Promise.resolve(qrSalesShoppingCar);

    if (salesShoppingCarResp?.status === 200) {
      return salesShoppingCarResp?.data;
    }
  };

  const getVirtualEventDetail = async (idSale: number) => {
    const salesShoppingCar = cartApi.post<any>(
      `${urlHistory}/getDataVirtualEvent`,
      {
        idCompra: idSale,
      }
    );

    const saleInfo = await Promise.resolve(salesShoppingCar);

    if (saleInfo?.status === 200) {
      return saleInfo?.data;
    }
  };

  return {
    isLoadingHistory,
    deletePurchase,
    getHistory,
    getDetailPurchase,
    getQrsSale,
    getVirtualEventDetail,
  };
};
