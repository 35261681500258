import { ThemeProvider } from "@emotion/react";
import {
  Boy,
  DarkMode,
  EmojiPeople,
  ExpandCircleDownOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Divider,
  IconButton,
  Typography,
  createTheme,
} from "@mui/material";
import { HotelAditionalForm } from "components/HotelAditionalForm";
import { Loading } from "components/Loading";
import { ResumenImages } from "components/ResumenImages";
import { useHotels } from "hooks/useHotels";
import { useParam } from "hooks/useParam";
import { usePurchase } from "hooks/usePurchase";
import { useRoomsAvailable } from "hooks/useRoomsAvailable";
import { useShoppingCar } from "hooks/useShoppingCar";
import { CompraRequest, ReservaCompra, UsuarioCompra } from "models/compraCero";
import {
  LiquidationPlanRequest,
  ListRooming,
} from "models/liquidationPlanRequest";
import { HotelForm, ReservationForm } from "models/purchaseForm";
import { ReservationRequest, Roomy } from "models/reservationRequest";
import { MRoom } from "models/room";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "store";
import { setSuccess } from "store/slices/status";
import {
  ABREVIACION_ORIGEN_WEB,
  TIPO_USUARIO,
  OPORTUNIDAD,
  ORIGEN_WEB,
  TITULAR,
} from "utils/constants";

export const HotelResumen = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { authData, isLogged } = useSelector((state: RootState) => state.auth);
  const { documentNumber, documentType } = useSelector(
    (state: RootState) => state.individualSale.individualUserData
  );


  const location = useLocation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    watch,
    getValues
  } = useForm<ReservationForm[]>();
  const { getLiquidationPlan } = useRoomsAvailable();
  const { hotelReservation } = useHotels();
  const { zeroPay, compraValor } = usePurchase();



  const [liquidation, setLiquidation] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPurchase, setIsLoadingPurchase] = useState(false);

  const hotelData: HotelForm = JSON.parse(location?.state?.data);
  const room: MRoom = JSON.parse(location?.state?.selectedRoom);
  const days: number =
    moment(hotelData?.finishDate).date() -
    moment(hotelData?.initialDate).date();

  const beneficiariesSelected =
    authData?.tipoUsuario === TIPO_USUARIO
      ? hotelData?.boxValidator?.grupoFamiliar?.informacionGrupo?.filter(
        (beneficiarie: any) =>
          hotelData?.benefeciariesSelected.some(
            (selected: any) => selected === beneficiarie.identificacion
          )
      )
      : authData?.beneficiarioVOs.filter((beneficiarie: any) =>
        hotelData?.benefeciariesSelected.some(
          (selected: any) => selected === beneficiarie.identificacion
        )
      );

  const guestsAges = {
    adults:
      hotelData?.adults +
      beneficiariesSelected?.filter((beneficiary: any) => {
        const fechaNac = moment(beneficiary?.fechaNacimiento, "YYYY-MM-DD");
        const edad = moment().diff(fechaNac, "years");
        if (edad >= 18) {
          return beneficiary;
        }
      }).length,
    children:
      hotelData?.childs +
      beneficiariesSelected?.filter((beneficiary: any) => {
        const fechaNac = moment(beneficiary?.fechaNacimiento, "YYYY-MM-DD");
        const edad = moment().diff(fechaNac, "years");
        if (edad < 18) {
          return beneficiary;
        }
      }).length,
  };


  const color = location?.state?.color;

  const purpleTheme = createTheme({
    palette: {
      primary: {
        main: color || "#000",
      },
    },
  });

  useEffect(() => {
    setIsLoading(true);

    const onGetLiquidation = async () => {
      setIsLoading(true);

      const liquidationRequest: LiquidationPlanRequest = {
        holder: "S",
        general: "N",
        plan: room?.codePlan,
        moneyDolar: "N",
        listaLine: [
          {
            line: "",
            plan: room?.codePlan,
            countroom: "1",
            adult: guestsAges.adults,
            child: guestsAges.children,
            additonal: hotelData?.adults + hotelData?.childs,
            roomtype: room?.hotelTarifa?.codetyperoom,
            accommodation: room?.hotelTarifa?.codeclassroom,
            start: moment(hotelData?.initialDate).format("YYYYMMDD"),
            end: moment(hotelData?.finishDate).format("YYYYMMDD"),
          },
        ],
        listRooming: updateType(
          beneficiariesSelected?.map((beneficiary: any) => {
            const today = moment();
            const age = today.diff(
              moment(beneficiary?.fechaNacimiento),
              "years"
            );
            const listRooming: ListRooming = {
              line: "",
              documenttype: beneficiary?.abreviatura,
              document: beneficiary?.identificacion,
              classguest: age >= 18 ? "A" : "N",
              birthdate: moment(beneficiary?.fechaNacimiento).format(
                "YYYYMMDD"
              ),
              codeclase: beneficiary?.categoria,
              type: "A",
              age: age,
            };

            return listRooming;
          })
        ),
      };

      const liquidationResponse = await getLiquidationPlan(liquidationRequest);

      setLiquidation(liquidationResponse?.valorTotal);

      setIsLoading(false);
    };

    onGetLiquidation();
  }, []);

  const getRoomies = (aditionalsForm: ReservationForm[]) => {
    const roomies: Roomy[] = [];

    const guests: Roomy[] = beneficiariesSelected?.map((beneficiary: any) => {
      const today = moment();
      const age = today.diff(moment(beneficiary?.fechaNacimiento), "years");

      const roomy: Roomy = {
        documentType: beneficiary?.abreviatura,
        document: beneficiary?.identificacion,
        classguest: age >= 18 ? "A" : "N",
        birthdate: moment(beneficiary?.fechaNacimiento).format("YYYYMMDD"),
        gender: beneficiary?.sexo,
        name: beneficiary?.nombre,
        type: "A",
        codeclase: beneficiary?.categoria,
        age: age,
      };
      return roomy;
    });

    const aditionalGuests: Roomy[] = aditionalsForm?.map(
      (aditional: ReservationForm) => {
        const today = moment();
        const age = today.diff(moment(aditional?.birthDate), "years");

        const roomy: Roomy = {
          documentType: aditional?.documentType,
          document: aditional?.documentNumber,
          classguest: age >= 18 ? "A" : "N",
          birthdate: moment(aditional?.birthDate).format("YYYYMMDD"),
          gender: aditional?.gender,
          name: aditional?.fullName,
          type: "A",
          codeclase: "D",
          age: age,
        };
        return roomy;
      }
    );

    roomies.push(...guests, ...aditionalGuests);

    return updateType(roomies);
  };

  const getPurchaseUser = (aditionalsForm: ReservationForm[]) => {
    const purchaseUser: UsuarioCompra[] = [];

    const guests: UsuarioCompra[] = beneficiariesSelected?.map(
      (beneficiary: any) => {
        const fechaNac = moment(beneficiary?.fechaNacimiento).format(
          "YYYY-MM-DD"
        );
        const roomy: UsuarioCompra = {
          tipoIdentificacion: beneficiary?.abreviatura,
          numIdentificacion: beneficiary?.identificacion,
          fechaNacimiento: fechaNac,
          genero: beneficiary?.sexo,
          nombre: beneficiary?.nombre,
          idCate: Number(beneficiary.idCate),
          oportunidad: "",
          edad: moment().diff(fechaNac, "years"),
        };
        return roomy;
      }
    );

    const aditionalGuests: UsuarioCompra[] = aditionalsForm?.map(
      (aditional: ReservationForm) => {
        const fechaNac = moment(aditional?.birthDate).format("YYYY-MM-DD");
        const roomy: UsuarioCompra = {
          tipoIdentificacion: aditional?.documentType,
          numIdentificacion: aditional?.documentNumber,
          fechaNacimiento: fechaNac,
          genero: aditional?.gender,
          nombre: aditional?.fullName,
          idCate: 4,
          oportunidad: "",
          edad: moment().diff(fechaNac, "years"),
        };
        return roomy;
      }
    );

    purchaseUser.push(...guests, ...aditionalGuests);

    return purchaseUser;
  };

  const updateOportunidad = (users: UsuarioCompra[]) => {
    const userIndex = users.findIndex(
      (user) => user.numIdentificacion === authData?.afiliado.identificacion
    );

    if (userIndex !== -1) {
      users[userIndex].oportunidad = OPORTUNIDAD;
    } else {

      const olderUser = users.reduce(
        (max, user) => (user?.edad! > max?.edad! ? user : max),
        { edad: 0 }
      );

      const olderUserIndex = users.indexOf(olderUser);

      if (olderUserIndex !== -1) {
        users[olderUserIndex].oportunidad = OPORTUNIDAD;
      } else {
        const olderAditionalUser = users.reduce(
          (max, user) => (user?.edadMaxima! > max?.edadMaxima! ? user : max),
          { edadMaxima: 0 }
        );

        const olderAditionalUserIndex = users.indexOf(olderAditionalUser);

        users[olderAditionalUserIndex].oportunidad = OPORTUNIDAD;
      }
    }

    return users;
  };

  const updateType = (users: Roomy[]) => {
    const userIndex = users.findIndex(
      (user) => user.document === authData?.afiliado.identificacion
    );

    if (userIndex !== -1) {
      users[userIndex].type = TITULAR;
    } else {
      const olderUser = users.reduce(
        (max: Roomy, user: Roomy) => (user?.age! > max?.age! ? user : max),
        { age: 0 }
      );

      const olderUserIndex = users.indexOf(olderUser);

      if (olderUserIndex !== -1) {
        users[olderUserIndex].type = TITULAR;
      }
    }

    return users;
  };


  const [urlPasarela, seturlPasarela] = useState("");
  const { getParamData } = useParam();

  useEffect(() => {
    const getParamUrlPasarela = async () => {
      const getParamUrlPasarela = await getParamData("URL_PASARELA");
      // Get url pasarela param
      if (getParamUrlPasarela?.data) {
        const dataParameter = getParamUrlPasarela?.data;
        seturlPasarela(dataParameter.valorParametro);
      }
    }
    getParamUrlPasarela();
  }, [urlPasarela]);

  const {
    paySale,
  } = useShoppingCar();

  const handlePaySale = async (sale: any) => {
    const paySaleResultInfo = await paySale(sale.idCompra);
    setIsLoadingPurchase(false);
    dispatch(setSuccess(null));
    window.location.replace(urlPasarela + paySaleResultInfo?.infoPago);
  };

  // Realiza el envio del formulario
  const handleFormSubmit: SubmitHandler<any> = async (
    data: ReservationForm[] = []
  ) => {
    setIsLoadingPurchase(true);

    const roomies: Roomy[] = getRoomies(Object.values(data));

    const reservationRequest: ReservationRequest = {
      oportunidad: "",
      idServicio: room?.servicio?.idServicio,
      medioCompra: "W",
      valorReserva: liquidation,
      roomies: roomies,
      cantidadAdultos: guestsAges?.adults,
      cantidadNinios: guestsAges?.children,
      cantidadAdicionales: hotelData?.adults + hotelData?.childs,
      cantidadHabitaciones: 1,
      plan: room?.codePlan,
      roomType: room?.hotelTarifa?.codetyperoom,
      roomClass: room?.hotelTarifa?.codeclassroom,
      fechaIngreso: moment(hotelData?.initialDate).format("YYYYMMDD"),
      fechaSalida: moment(hotelData?.finishDate).format("YYYYMMDD"),
      propietario:
        authData?.empleadoCRM && authData?.empleadoCRM.idEmpleado
          ? authData.empleadoCRM.idEmpleado
          : "",
    };

    const reservation: ReservaCompra = await hotelReservation(
      reservationRequest
    );

    if (reservation) {
      const purchaseUser: UsuarioCompra[] = getPurchaseUser(
        Object.values(data)
      );

      const compraRequest: CompraRequest = {
        valorTotal: liquidation,
        porcentajeDcto: 0,
        origenCompra: ABREVIACION_ORIGEN_WEB,
        fechaInicioServicio: moment(hotelData?.initialDate).format(
          "YYYY/MM/DD"
        ),
        fechaFinServicio: moment(hotelData?.finishDate).format("YYYY/MM/DD"),
        fechaServicio: moment(hotelData?.initialDate).format("YYYY/MM/DD"),
        idServicio: room?.servicio?.idServicio,
        cantidad: purchaseUser?.length,
        medioCompra: ORIGEN_WEB,
        centroServicio: "" + hotelData?.idCentroServicio,
        compraUsuarios: updateOportunidad(purchaseUser),
        reserva: {
          oportunidad: reservation?.oportunidad,
          numeroReserva: reservation?.numeroReserva,
          numeroPreReserva: reservation?.numeroPreReserva,
          numTransaccion: reservation?.oportunidad,
          // codigoEspacioSalon: null,
          valorReserva: liquidation,
          fechaIngreso: moment(hotelData?.initialDate).format("YYYY/MM/DD"),
          fechaSalida: moment(hotelData?.finishDate).format("YYYY/MM/DD"),
          cantidadAdultos: guestsAges.adults,
          cantidadNinios: guestsAges.children,
          namePlan: room?.codePlan,
        },
        afiliadoIdentificacion:
          isLogged && authData?.tipoUsuario === TIPO_USUARIO
            ? documentNumber
            : undefined,
        afiliadoTipoIden:
          isLogged && authData?.tipoUsuario === TIPO_USUARIO
            ? documentType
            : undefined,
      };


      if (liquidation > 0) {
        const response = await compraValor(compraRequest);

        if (response) {
          response?.mensaje &&
            dispatch(
              setSuccess(
                "La reserva se realizó exitosamente, serás redirigido a la pasarela de pagos"
              )
            );
          setTimeout(() => {
            setIsLoadingPurchase(false);
            dispatch(setSuccess(null));

            handlePaySale(response);
          }, 5000);
        } else {
          setIsLoadingPurchase(false);
        }
      } else {
        const response = await zeroPay(compraRequest);
        if (response) {
          setIsLoadingPurchase(false);
          dispatch(
            setSuccess(
              "La reserva se realizó exitosamente, serás redirigido al historial de compras"
            )
          );
          navigate("/historial-compras");
        } else {
          setIsLoadingPurchase(false);
        }
      }
    } else {
      setIsLoadingPurchase(false);
    }
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <ThemeProvider theme={purpleTheme}>
          {isLoadingPurchase && <Loading />}
          <div className="flex flex-wrap justify-around py-8 px-8 md:px-0 w-full bg-principal-0 text-principal-600">
            <div className="flex flex-col w-full md:w-[48%]">
              {room?.imagenes?.length > 0 && (
                <div className="mb-4">
                  <ResumenImages serviceCenterImages={room?.imagenes} />
                </div>
              )}

              <div className="bg-white w-full border rounded-lg">
                <div className="p-4 border-b">
                  <Typography color="primary" className="font-bold text-lg">
                    Habitaciones
                  </Typography>
                </div>
                <div className="p-4">
                  <p className="font-bold text-principal-600 pb-2">
                    Habitación 1
                  </p>
                  <div className="flex pb-2">
                    <p className="pr-1 font-bold text-principal-600">
                      Tipo de habitación:
                    </p>
                    <p>{room?.hotelTarifa?.nameclassroom}</p>
                  </div>
                  <div className="flex pb-2">
                    <p className="pr-1 font-bold text-principal-600">
                      Acomodación:
                    </p>
                    <p>{room?.servicio?.acomodacion}</p>
                  </div>
                  <div className="flex pb-2">
                    <p className="pr-1 font-bold text-principal-600">
                      Descripción:
                    </p>
                    <p>{room?.hotelTarifa?.descriptionroom}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap w-full md:w-[48%]">
              <div className="flex items-center w-full bg-white mb-4 p-4 border rounded-lg">
                <IconButton
                  size="large"
                  edge="start"
                  aria-label="menu"
                  onClick={() => handleNavigate()}
                >
                  <ExpandCircleDownOutlined
                    className="rotate-90"
                    fontSize="large"
                    color="primary"
                  />
                </IconButton>
                <Typography color="primary" className="font-bold text-lg">
                  Total hospedaje:
                </Typography>
                <p className="pl-2 font-bold text-lg">
                  {liquidation?.toLocaleString("es-CO", {
                    style: "currency",
                    currency: "COP",
                    minimumFractionDigits: 0,
                  })}
                </p>
              </div>
              <div className="flex flex-col w-full bg-white mb-4 p-4 border rounded-lg">
                <Typography color="primary" className="font-bold text-lg">
                  Información de la reserva:
                </Typography>
                <div className="flex flex-wrap w-full py-4">
                  <div className="w-fit">
                    <p>Entrada</p>
                    <p className="font-bold text-principal-600">
                      {moment(hotelData?.initialDate).format("dddd, D MMMM")}
                    </p>
                    <p className="font-bold text-principal-600">
                      {moment(hotelData?.initialDate).format("YYYY")}
                    </p>
                  </div>
                  <div className="flex justify-center w-1/5">
                    <Divider orientation="vertical" flexItem />
                  </div>
                  <div className="w-fit">
                    <p>Salida</p>
                    <p className="font-bold text-principal-600">
                      {moment(hotelData?.finishDate).format("dddd, D MMMM")}
                    </p>
                    <p className="font-bold text-principal-600">
                      {moment(hotelData?.finishDate).format("YYYY")}
                    </p>
                  </div>
                </div>
                <div className="py-2">
                  <p>Duración total de la estancia:</p>
                  <div className="flex py-2">
                    <DarkMode className="text-principal-600" />
                    <p className="text-principal-600 pl-1 font-bold">
                      {days} {days > 1 ? "Noches" : "Noche"}
                    </p>
                  </div>
                </div>
                <div className="py-2">
                  <p>Cantidad de personas dentro de la reserva</p>
                  <div className="flex py-2">
                    <EmojiPeople className="text-principal-600" />
                    <p className="text-principal-600 pl-1 font-bold">
                      {guestsAges?.adults}{" "}
                      {guestsAges?.adults > 1 ? "Adultos" : "Adulto"}
                    </p>
                    {guestsAges?.children > 0 && (
                      <>
                        <Boy className="ml-4" />
                        <p className="text-principal-600 pl-1 font-bold">
                          {guestsAges?.children}{" "}
                          {guestsAges?.children > 1 ? "Niños" : "Niño"}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {hotelData?.adults + hotelData?.childs > 0 && (
                <>
                  <div className="w-full bg-white mb-4 border rounded-lg p-4">
                    <Typography color="primary" className="font-bold text-lg">
                      Datos de los adicionales al grupo familiar
                    </Typography>
                  </div>
                </>
              )}
              <div className="w-full bg-white p-4 max-h-96 overflow-y-scroll border rounded-lg">
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  {Array.from(
                    {
                      length: hotelData?.adults,
                    },
                    (_, i) => (
                      <div className="py-2" key={i}>
                        <div className="flex border-b py-1">
                          <EmojiPeople className="text-principal-600" />
                          <p className="font-bold text-principal-600 text-xl">
                            Adulto adicional {i + 1}
                          </p>
                        </div>
                        <HotelAditionalForm
                          register={register}
                          formIndex={i}
                          errors={errors}
                          setValue={setValue}
                          getValue={getValues}
                          watch={watch}
                        />
                      </div>
                    )
                  )}
                  {hotelData?.childs > 0 &&
                    Array.from(
                      {
                        length: hotelData?.childs,
                      },
                      (_, i) => (
                        <div className="py-2" key={i}>
                          <div className="flex border-b py-1">
                            <Boy className="text-principal-600" />
                            <p className="font-bold text-principal-600 text-xl">
                              Niño adicional {i + 1}
                            </p>
                          </div>
                          <HotelAditionalForm
                            register={register}
                            formIndex={i + hotelData?.adults}
                            errors={errors}
                            setValue={setValue}
                            getValue={getValues}
                            watch={watch}
                          />
                        </div>
                      )
                    )}
                  <div className="flex justify-end">
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      loading={isLoadingPurchase}
                    >
                      Comprar
                    </LoadingButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ThemeProvider>
      )}
    </>
  );
};
