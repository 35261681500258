import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { JWT } from 'models/jwt'

export interface jwtState {
    token: JWT,
    errorMessage: '',
}

const initialState: jwtState = {
    token: { correo: '', rol: '', token: '' },
    errorMessage: '',
}

export const jwtSlice = createSlice({
    name: 'jwt',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<JWT>) => {
            state.token = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setToken } = jwtSlice.actions