import { useState } from 'react';
import integrationApi from '../api/integrationApi';
import { CompraRequest } from 'models/compraCero';

export const usePurchase = () => {
    const [isLoadingPurchase, setIsLoadingPurchase] = useState(true);

    const urlPurchase: string = "compra";

    const zeroPay = async (payZero: CompraRequest) => {

        setIsLoadingPurchase(true);

        const purchase = integrationApi.post<any>(`${urlPurchase}/compraCero`, payZero);

        const purchaseResp = await Promise.resolve(
            purchase
        );
        
        setIsLoadingPurchase(false);

        return purchaseResp?.data;
    }

    const compraValor = async (compraValor: CompraRequest) => {

        setIsLoadingPurchase(true);

        const purchase = integrationApi.post<any>(`${urlPurchase}/shoppingCar`, compraValor);

        const purchaseResp = await Promise.resolve(
            purchase
        );

        setIsLoadingPurchase(false);
        return purchaseResp?.data;
    }

    return {
        isLoadingPurchase: isLoadingPurchase,
        zeroPay,
        compraValor
    }
}