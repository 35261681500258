import { ServiceImages } from 'models/serviceImages';
import { CarouselDialog } from 'pages/Hotels/CarouselDialog';
import React, { useState } from 'react'

interface hotelImagesProps {
    serviceCenterImages: ServiceImages[];
}

export const HotelImages = ({ serviceCenterImages }: hotelImagesProps) => {
    const [openDialog, setOpenDialog] = useState(false);


    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div className='flex flex-wrap justify-between w-full '>
            <div className='w-49/100 h-40 md:h-60 rounded-lg bg-cover'
                style={serviceCenterImages && serviceCenterImages[0] ? { backgroundImage: `url(${serviceCenterImages[0]?.dataImagen})` } : { backgroundColor: '#808080' }}
            />
            <div className='flex flex-col justify-between w-49/100 h-40 md:h-60'>
                <div className='w-full h-49/100 md:h-26 rounded-lg bg-cover'
                    style={serviceCenterImages && serviceCenterImages[1] ? { backgroundImage: `url(${serviceCenterImages[1]?.dataImagen})` } : { backgroundColor: '#808080' }}
                />

                <div className='w-full h-49/100 md:h-26 rounded-lg bg-cover' onClick={() => serviceCenterImages[3] && handleOpenDialog()} style={serviceCenterImages[2] ? { backgroundImage: `url(${serviceCenterImages[2]?.dataImagen})` } : { backgroundColor: '#808080' }}>
                    {
                        serviceCenterImages.length > 3 &&
                        <div className='flex justify-center items-center w-full h-full rounded-lg bg-cover'
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}>
                            <p className='text-white border-b'>
                                + {serviceCenterImages.length - 3} fotos
                            </p>
                        </div>
                    }

                </div>
            </div>
            <CarouselDialog
                open={openDialog}
                onClose={handleCloseDialog}
                serviceCenterImages={serviceCenterImages}
            />

        </div>
    )
}
