import { useState } from 'react'
import integrationApi from 'api/integrationApi';
import { MQuotas } from 'models/quotas';
import { Credit, MBalances } from 'models/balances';


export const useBalances = () => {
    const [isLoadingBalances, setIsLoadingBalances] = useState(true);

    const urlBalances: string = "credit";

    const getBalances = async (tipoIdentificacion: string, numeroIdentificacion: number) => {

        setIsLoadingBalances(true);

        const balances = await integrationApi.post<any>(`${urlBalances}/consultaSaldoCredito`, {
            tipoIdentificacion: tipoIdentificacion,
            numeroIdentificacion: numeroIdentificacion
        });

        setIsLoadingBalances(false);
        return balances?.data;

    }

    return {
        isLoadingBalances,
        getBalances
    }
}
