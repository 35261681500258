import React, { useRef, useState, useEffect } from 'react'
import { BusinessCard } from './BusinessCard';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Business } from 'models/business';
import { Loading } from './Loading';

interface BusinessProps {
    activeBusiness: Business[];
    setSelectedBusiness?: any;
    selectedBusiness?: Business
    selectedCategory?: string;
}

export const BusinessSlider = ({ activeBusiness, selectedBusiness, setSelectedBusiness, selectedCategory }: BusinessProps) => {

    const scrollContainerRef = useRef<HTMLDivElement>(null);

    const handleScroll = (scrollOffset: number) => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollLeft += scrollOffset;
        }
    };

    return (

        <div className="flex w-full flex-row items-center justify-center px-3" style={selectedBusiness && { backgroundColor: selectedBusiness.color }}>
            <ExpandCircleDownOutlinedIcon
                className="h-10 w-10 mr-2 cursor-pointer text-principal-450 rotate-90"
                style={selectedBusiness && { color: 'white' }}
                onClick={() => handleScroll(-200)}
            />
            <div
                className="flex px-2 py-2 scroll-container overflow-hidden"
                ref={scrollContainerRef}
            >

                {activeBusiness?.map((business) => (
                    <BusinessCard
                        key={business.idNegocio}
                        business={business}
                        setSelectedBusiness={setSelectedBusiness}
                        selectedBusiness={selectedBusiness === business ? selectedBusiness : null}
                        selectedCategory={selectedCategory}
                    />
                ))}

            </div>
            <ExpandCircleDownOutlinedIcon
                className="h-10 w-10 ml-2 cursor-pointer text-principal-450 -rotate-90"
                style={selectedBusiness && { color: 'white' }}
                onClick={() => handleScroll(200)}
            />
        </div>

    );
}
