import { Business } from 'models/business'
import { Category } from 'models/category';
import React, { useState } from 'react'
import { Link } from "react-router-dom";


interface CategoryProps {
    category: Category,
    setSelectedCategory: any,
    selectedCategory: boolean,
    selectedBusiness?: Business,
}

export const CategoryCard = ({ category, setSelectedCategory, selectedCategory, selectedBusiness }: CategoryProps) => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const getSVG = (svgBase64: string) => {
        return svgBase64
            ? atob(svgBase64.split('base64,')[1] ? svgBase64.split('base64,')[1] : '')
            : ''
    }

    return (
        <Link to={`/business/${selectedBusiness?.idNegocio}/${category.idCategoria}`} onClick={() => setSelectedCategory(category)}>
            <div className='flex flex-row items-center justify-center h-20 w-60 border-x bg-white'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={selectedBusiness && selectedCategory ? { borderBottom: `2px solid ${selectedBusiness.color}` } : {}}
            >
                <div className='pr-8'>
                    <div
                        className="flex justify-center items-center h-10 w-10"
                        style={
                            ((selectedBusiness && isHovered) || (selectedBusiness && selectedCategory)) ? { fill: `${selectedBusiness.color}` } : {}

                        }
                        dangerouslySetInnerHTML={{ __html: getSVG(category.icono) }}
                    />
                </div>
                <p className='text-center text-sm'
                    style={((selectedBusiness && isHovered) || (selectedBusiness && selectedCategory)) ? { color: `${selectedBusiness.color}` } : {}}
                >
                    {category?.nombre}
                </p>
            </div>
        </Link >
    )
}
