import React, { useEffect, useState } from "react";

import { ServiceTypeForm } from "components/ServiceTypeForm";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { useService } from "hooks/useService";
import { ServiceImages } from "models/serviceImages";
import { ServiceData } from "models/serviceData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loading } from "components/Loading";
import { ServiceDescription } from "components/ServiceDescription";
import { Embedded } from "components/Embedded";
import { ES_EMBEBIDO, IND_ORIGEN_EDUCACION, OPCION_VENTA_INDIVIDUAL, TIPO_USUARIO } from "utils/constants";
import moment from "moment";
import { useServiceCenter } from "hooks/useServiceCenter";
import { ServiceCenter } from "models/serviceCenter";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { ServiceCenterDescription } from "components/ServiceCenterDescription";
import { PopularService } from "models/popularService";
import { ServiceCenterForm } from "components/ServiceCenterForm";
import { IndividualSaleForm } from "components/IndividualSaleForm";
import { useExcludeService } from "hooks/useExcludeService";

export const Hotels = () => {
  const { authData, isLogged } = useSelector((state: RootState) => state.auth);

  const { getServiceCenterData, getPopularServices } = useServiceCenter();

  const [isLoading, setIsLoading] = useState(true);

  const [bannerImages, setBannerImages] = useState<ServiceImages[]>([]);

  const [serviceCenterData, setServiceCenterData] = useState<ServiceCenter>();

  const [popularServices, setPopularServices] = useState<PopularService[]>();

  const { idServiceCenter } = useParams();

  const location = useLocation();

  const color = location?.state?.color;

  const purpleTheme = createTheme({
    palette: {
      primary: {
        main: color || "#000",
      },
    },
  });

  useEffect(() => {
    const onGetServicedata = async () => {
      setIsLoading(true);

      const { serviceCenterData, serviceImages } = await getServiceCenterData(
        Number(idServiceCenter)
      );

      const popularServices = await getPopularServices(Number(idServiceCenter));

      setServiceCenterData(serviceCenterData);
      setBannerImages(serviceImages);
      setPopularServices(popularServices);

      setIsLoading(false);
    };

    onGetServicedata();
  }, []);

  return (
    <ThemeProvider theme={purpleTheme}>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-wrap justify-around py-8 px-8 md:px-0 w-full bg-principal-0">
          {authData?.tipoUsuario == TIPO_USUARIO &&
            authData?.cdaRolCdaOpcions?.some(
              (option: any) =>
                option?.idOpcionCdaOpcion == OPCION_VENTA_INDIVIDUAL
            ) && <IndividualSaleForm />}
          <div className="flex flex-col w-full md:w-[48%]">
            <ServiceCenterDescription
              serviceCenterImages={bannerImages}
              serviceCenterData={serviceCenterData}
              popularServices={popularServices}
            />
          </div>
          <div className="flex  w-full md:w-[48%] py-4 mt-4 md:mt-0 rounded-lg border border-principal-350 bg-white">
            <ServiceCenterForm
              serviceCenterData={serviceCenterData}
              color={color}
            />
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};
