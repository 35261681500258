import { Typography } from '@mui/material';
import { MHousingSubsidy } from 'models/housingSubsidyModel';
import React from 'react';
import { DISABLED_STATUS_HOUSING_SUBSIDY } from 'utils/constants';

interface HousingSubsidySummaryProps {
    housingSubsidy: MHousingSubsidy | undefined;
}

export const HousingSubsidySummary = ({
    housingSubsidy
}: HousingSubsidySummaryProps) => {

    const formatDate = (date: Date) => {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleString('es', options);
    };
    return (
        <div className='border rounded-lg'>
            {housingSubsidy?.estadoSubsidio &&
                housingSubsidy.estadoSubsidio === DISABLED_STATUS_HOUSING_SUBSIDY && (
                    <div className='border-b p-4'>
                        <Typography>
                            {housingSubsidy.estadoSubsidio}
                        </Typography>
                    </div>
                )}
            <div className='flex justify-between border-b p-4'>
                <p className='text-principal-600 font-bold'>Valor subsidio</p>
                <Typography color='primary' className='font-bold'>
                    {housingSubsidy?.valorSubsidio ? Number(housingSubsidy?.valorSubsidio).toLocaleString('es-CO', {
                        style: 'currency',
                        currency: 'COP',
                        minimumFractionDigits: 0,
                    }) : '$0'}
                </Typography>
            </div>
            <div className='border-b p-4'>
                <p className='text-principal-600 font-bold'>Fecha ultima actualización</p>
                <p className='text-principal-650'>
                    {housingSubsidy?.fechaVigencia
                        ? formatDate(new Date(housingSubsidy?.fechaVigencia))
                        : 'No registra'}
                </p>
            </div>
            <div className='border-b p-4'>
                <p className='text-principal-600 font-bold'>Fecha de adjudicación</p>
                <p className='text-principal-650'>
                    {housingSubsidy?.fechaverificacion
                        ? formatDate(new Date(housingSubsidy?.fechaverificacion))
                        : 'No registra'}
                </p>
            </div>
            <div className='border-b p-4'>
                <p className='text-principal-600 font-bold'>Información</p>
                <p className='text-principal-650'>{housingSubsidy?.informacion}</p>
            </div>
        </div>
    );
};
