import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface searchState {
    city?: number
    service?: string
    errorMessage: string,
}

const initialState: searchState = {
    city: undefined,
    service: '',
    errorMessage: '',
}

export const searchServicesSlice = createSlice({
    name: 'searchServices',
    initialState,
    reducers: {
        setCity: (state, action: PayloadAction<number>) => {
            state.city = action.payload
        },
        setService: (state, action: PayloadAction<string>) => {
            state.service = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setCity, setService } = searchServicesSlice.actions