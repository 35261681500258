import React, { Dispatch, useState } from "react";
import Popover from "@mui/material/Popover";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { AccountCircle, Logout } from "@mui/icons-material";
import { onLogout } from "store/slices/auth";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

interface PopOverProps {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl: Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  userData?: any;
}

export const SessionPopOver = ({
  anchorEl,
  setAnchorEl,
  userData,
}: PopOverProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const [isHovered, setIsHovered] = useState(false);

  const getCapital = (value: string) =>
    value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const { logout, isAuthenticated } = useAuth0();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onLogoutPress = () => {
    //TODO: Validar si es admin, entonces usar la siguiente linea

    dispatch(onLogout());

    // Esta validación es para auth0
    if (isAuthenticated) {
      logout({
        logoutParams: {
          returnTo: window.location.origin + "/delagenteweb/home",
        },
      });
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div
        className="border border-principal-350 w-64 sm:w-80 text-sm"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className="flex flex-row px-6 my-2"
          style={isHovered ? { fontWeight: "bold" } : {}}
        >
          <AccountCircle
            sx={{ fontSize: "60px" }}
            className="text-principal-550"
          />
          <div className="flex flex-col justify-center pl-4">
            <p>
              {userData?.primerNombre && getCapital(userData?.primerNombre)}{" "}
              {userData?.primerApellido && getCapital(userData?.primerApellido)}{" "}
              {userData?.segundoApellido &&
                getCapital(userData?.segundoApellido)}
            </p>
            <Link to={"/profile"} className="text-principal-550">Actualizar perfil</Link>
          </div>
        </div>
        <div className="px-6 py-2 bg-principal-400/20 border-y border-principal-350">
          <span className="font-bold text-lg">Datos personales</span>
        </div>
        <div
          className="flex flex-col px-6 py-4 "
          style={isHovered ? { fontWeight: "bold" } : {}}
        >
          <div className="flex flex-row w-full items-center">
            <span className="w-1/3 text-xs text-principal-300">Categorias</span>
            <span className="w-2/3">{userData?.categoria}</span>
          </div>
          <div className="flex flex-row w-full items-center">
            <span className="w-1/3 text-xs text-principal-300">
              {userData?.abreviatura ? userData?.abreviatura : "CC"}
            </span>
            <span className="w-2/3">{userData?.identificacion}</span>
          </div>
          <div className="flex flex-row w-full items-center">
            <span className="w-1/3 text-xs text-principal-300">Correo</span>
            <span className="w-2/3">{userData?.email}</span>
          </div>
        </div>
        <div className="px-6 py-2 bg-principal-400/20 border-y border-principal-350">
          <span className="font-bold text-lg">Acciones</span>
        </div>
        <div className="px-6 py-4">
          <button
            className="px-2 py-2 rounded-lg border border-principal-350 bg-principal-400/10 w-full"
            onClick={() => onLogoutPress()}
          >
            <Logout className="mr-0 lg:mr-2" />
            <span>Cerrar sesión</span>
          </button>
        </div>
      </div>
    </Popover>
  );
};
