import React, { useEffect, useState } from "react";
import image from "assets/images/evento-virtual.svg";
import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { usePurchaseHistory } from "hooks/usePurchaseHistory";
import Countdown from "react-countdown";

export const VirtualEvent = () => {
  const [queryParameters] = useSearchParams();

  const { getDetailPurchase, getVirtualEventDetail } = usePurchaseHistory();
  const [dateEvent, setdateEvent] = useState(null);
  const [urlEvent, setUrlEvent] = useState(null);

  useEffect(() => {
    const idCompraParam = null;

    const queryParam = queryParameters.get("idCompra");
    if (queryParam) {
      getShoppingDetail(Number(queryParam));
    }
  }, []);

  const getShoppingDetail = async (idCompra: number) => {
    const infoSale = await getVirtualEventDetail(Number(idCompra));
    const dateToSet = infoSale.fechaFinal;
    setdateEvent(dateToSet);
    setUrlEvent(infoSale.url);
  };

  const renderer = ({ hours, minutes, seconds, completed, days }: any) => {
    if (completed) {
      // El contador ha terminado
      return (
        <>
          <p className="text-2xl font-bold text-principal-600 pb-4">
            Bienvenido al evento
          </p>
          {urlEvent && (
            <Button
              variant="contained"
              color="primary"
              className="bg-principal-500"
              onClick={() => window.open(urlEvent)}
            >
              Ir al evento
            </Button>
          )}
        </>
      );
    } else {
      // Renderiza los valores de las horas, minutos y segundos
      const timeUnits = [
        { value: days, label: 'Días' },
        { value: hours, label: 'Horas' },
        { value: minutes, label: 'Minutos' },
        { value: seconds, label: 'Segundos' }
      ];

      return (
        <div className="flex flex-col justify-center items-center text-2xl font-bold text-principal-600 pb-4">
          <p>Tu evento comienza en:</p>
          <div className="flex text-2xl font-bold text-principal-500 pb-4">
            {timeUnits.map(({ value, label }) => (
                <div key={label} className="flex flex-col items-center p-2">
                    <span>{String(value).padStart(2, "0")}</span>
                    <span className="font-medium text-lg">{label}</span>
                </div>
            ))}
        </div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-row h-full ">
      <div className="flex flex-col sm:w-full md:w-full align-middle justify-center items-center  ">
        {dateEvent && <Countdown date={dateEvent} renderer={renderer} />}
      </div>

      <div className=" flex w-1/2">
        <img src={image} alt="" />
      </div>
    </div>
  );
};
