import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface credentialState {
    credentials: Record<string, string>,
    errorMessage: '',
}

const initialState: credentialState = {
    credentials: {},
    errorMessage: '',
}

export const credentialsSlice = createSlice({
    name: 'credentials',
    initialState,
    reducers: {
        setCredentials: (state, action: PayloadAction<Record<string, string>>) => {
            state.credentials = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const { setCredentials } = credentialsSlice.actions