import React, { useEffect, useState } from 'react'
import EmailIcon from '@mui/icons-material/Email';
import AlternateEmailTwoToneIcon from '@mui/icons-material/AlternateEmailTwoTone';
import { AdmissionData } from 'models/lightForm';

interface RecognizedUserProps {
    setStep: React.Dispatch<React.SetStateAction<number>>,
    admissionData?: AdmissionData,
    setViamessage: React.Dispatch<React.SetStateAction<string>>,
    setPreviousstep: React.Dispatch<React.SetStateAction<number>>

}
export const RecognizedUser = ({ setStep, admissionData, setViamessage, setPreviousstep }: RecognizedUserProps) => {

    const [errorNoInfo, setErrorNoInfo] = useState<boolean>(false);


    useEffect(() => {
        setPreviousstep(0)

        if (!admissionData?.celular && !admissionData?.email) {
            setErrorNoInfo(true)
        }


    }, []);

    const sendMessage = (via: string) => {
        setStep(1);
        setViamessage(via);
    }

    const hidePhoneNumber = (phoneNumber: String) => {
        if (phoneNumber) {
            // Calcula la longitud de la cadena de números
            const length = phoneNumber.length;
            // Calcula la cantidad de dígitos que necesitas ocultar
            const hideLength = Math.floor(length / 2);
            // Calcula el inicio del índice para ocultar dígitos
            const start = Math.floor((length - hideLength) / 2);
            // Construye la parte visible del número
            const visiblePart = phoneNumber.slice(0, start);
            // Construye la parte oculta del número
            const hiddenPart = '*'.repeat(hideLength);
            // Une las partes visible y oculta y las devuelve
            return visiblePart + hiddenPart + phoneNumber.slice(start + hideLength);
        }
    }

    const hideEmail = (email: string) => {
        if (email) {
            const parts = email.split('@');
            if (parts.length !== 2) {
                // El correo electrónico no tiene el formato adecuado
                return email;
            }

            // Obtiene la primera parte del correo electrónico (antes de '@')
            const firstPart = parts[0];
            // Calcula la longitud de la primera parte
            const firstPartLength = firstPart.length;
            // Calcula la cantidad de caracteres que necesitas ocultar
            const hideLength = Math.floor(firstPartLength / 2);
            // Calcula el índice para ocultar caracteres
            const start = Math.floor((firstPartLength - hideLength) / 2);
            // Construye la parte visible del correo electrónico
            const visiblePart = firstPart[0];
            // Construye la parte oculta del correo electrónico
            const hiddenPart = '*'.repeat(hideLength);
            // Une las partes visible y oculta y las devuelve junto con la parte del dominio del correo
            return visiblePart + hiddenPart + email.slice(start + hideLength);
        }
    }



    return (
        <div className='flex flex-col w-full'>

            <span className='flex justify-center text-principal-250 text-xl'>¡Hola, {admissionData?.nombre}!</span>
            {
                errorNoInfo &&
                <span className='flex justify-center text-red-500 text-xl'>No se encontró información de contacto</span>
            }
            <span className='my-5'>Elige una de las siguientes opciones para proceder con el proceso</span>

            {admissionData?.celular && <div className='flex justify-center w-full '>
                <button type="submit"
                    onClick={() => sendMessage('SMS')}
                    className="flex bg-principal-550 text-white py-4 w-full rounded-md justify-center items-center content-center align-center">
                    <EmailIcon
                        className="h-8 w-8 mr-2 cursor-pointer "
                        style={{ color: 'white' }}
                    />
                    <span className='justify-center'>Enviar SMS a {hidePhoneNumber(admissionData.celular)}</span>
                </button>
            </div>}

            {admissionData?.email && <div className='flex justify-center w-full mt-4'>
                <button
                    type="submit"
                    onClick={() => sendMessage('EMAIL')}
                    className="flex bg-principal-550 text-white py-4 w-full rounded-md justify-center items-center content-center align-center">
                    <AlternateEmailTwoToneIcon
                        className="h-8 w-8 mr-2 cursor-pointer "
                        style={{ color: 'white' }}
                    />
                    <span className='justify-center'>Enviar correo a {hideEmail(admissionData?.email)}</span>
                </button>
            </div>}

            <div className='my-2'>
                <span className=''>¿No reconoces alguno de estos datos? </span> {' '}
                <span className='text-principal-250 font-bold'>vuelve a completar el formulario</span>
            </div>

        </div>


    )
}

export default RecognizedUser